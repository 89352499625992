import { Grid, Typography } from "@material-ui/core";
import React from "react";

const PdfDataRow = ({ label, value }) => {
  return (
    <Grid direction="row" container className="pdf-data-row" alignItems="center">
      <Typography className="pdf-data-left-column">{label}</Typography>
      <Typography className="pdf-data-right-column">{value || "-"}</Typography>
    </Grid>
  );
};

export default PdfDataRow;
