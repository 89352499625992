// import packages
import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import classnames from "classnames";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import PhoneInput from "react-phone-input-2";

function SendPayLinkCom(props) {
  const [postId, setPostId] = useState("");
  const [currency, setCurrency] = useState("");
  const [customPrice, setCustomPrice] = useState("");
  const [sendSms, setSendSms] = useState(true);
  const [sendEmail, setSendEmail] = useState(false);
  const [postIdErr, setPostIdErr] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberErr, setPhoneNumberErr] = useState("+94");
  const [customPriceErr, setCustomPriceErr] = useState("");
  const [sendByErr, setSendByErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const [pricingTierId, setPricingTierId] = useState("");
  const [packageError, setPackageError] = useState("");

  const [sendType, setSendType] = useState("postId");

  useEffect(() => {
    getPackages();
  }, []);

  const handleCurrency = (type) => {
    setCurrency(type);
    setCustomPriceErr("");
  };

  // validate
  const validate = () => {
    let postIdErr = "";
    let phoneNumberErr = "";
    let customPriceErr = "";
    let sendByErr = "";
    let packageError = "";

    if (!postId) {
      postIdErr = "Post ID is required";
    }

    if (sendType === "phone" && phoneNumber.length < 4){
      phoneNumberErr = "Invalid Mobile Number";
    }

    if (!pricingTierId) {
      packageError = "Package is required";
    }

    if (currency || customPrice) {
      if (!currency) {
        customPriceErr = "Currency type is required";
      }
      if (!customPrice) {
        customPriceErr = "Price is required";
      }
    }

    if (!sendSms && !sendEmail) {
      sendByErr = "Please choose atleast one method";
    }

    if (postIdErr || phoneNumberErr || customPriceErr || sendByErr || packageError) {
      setPostIdErr(postIdErr);
      setPhoneNumberErr(phoneNumberErr);
      setCustomPriceErr(customPriceErr);
      setSendByErr(sendByErr);
      setPackageError(packageError);
      return false;
    }
    return true;
  };

  // submit change customPrice
  const handleSend = () => {
    if (validate()) {
      setLoading(true);

      const data = {
        postId: postId,
        sendSms: sendSms,
        sendEmail: sendEmail,
        pricingTierId: pricingTierId,
        ...(sendType === "phone") && {phoneNumber: phoneNumber},
        ...(customPrice) && {customPrice: customPrice},
        ...(currency) && {currency: currency},
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/admin/payment_links`, qs.stringify(data), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setPostId("");
          setPhoneNumber("+94");
          setCurrency("");
          setCustomPrice("");
          setSendSms(true);
          setSendEmail(false);
          setSendByErr("");
          setPricingTierId("");

          NotificationManager.success("Sent successfully!", "Success");
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error("Sending failed!", "Error");
        });
    }
  };

  const getPackages = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/pricing_tiers`)
      .then((res) => {
        setPackages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    setPhoneNumber("+94");
    setPhoneNumberErr("");
    setPostIdErr("");
    setPackageError("");
  },[sendType])

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Send Pay Link" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="content py-3">
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="package">
                    Send to <span className="required-tag">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={sendType}
                    onChange={(e) => setSendType(e.target.value)}
                  >
                    <option value="postId">Post Id</option>
                    <option value="phone">Other Phone Number</option>
                  </select>
                </div>

                {sendType === "phone" && (
                  <div className="form-group col-sm-6 px-2 3">
                    <label htmlFor="package">
                      Phone Number <span className="required-tag">*</span>
                    </label>
                    <PhoneInput
                      country="lk"
                      enableSearch={true}
                      disableSearchIcon={true}
                      countryCodeEditable={false}
                      prefix="+"
                      onChange={(value) => {
                        setPhoneNumber(value);
                        setPhoneNumberErr("");
                      }}
                      value={phoneNumber}
                    />
                    <div
                      className={`invalid-feedback ${
                        phoneNumberErr ? "d-block" : ""
                      }`}
                    >
                      {phoneNumberErr}
                    </div>
                  </div>
                )}

                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="postId">
                    Post ID <span className="required-tag">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": postIdErr,
                    })}
                    id="postId"
                    name="postId"
                    onChange={(e) => {
                      setPostId(e.target.value);
                      setPostIdErr("");
                    }}
                    value={postId}
                  />
                  <div className="invalid-feedback">{postIdErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="package">
                    Package <span className="required-tag">*</span>
                  </label>
                  <select
                    className={classnames("form-control", {
                      "is-invalid": packageError,
                    })}
                    id="package"
                    name="package"
                    value={pricingTierId}
                    onChange={(e) => {
                      setPricingTierId(e.target.value)
                      setPackageError("")
                    }}
                  >
                    <option defaultValue hidden>
                      -- Select --
                    </option>
                    {packages.map((el) => (
                      <option value={el.id} key={el.id}>
                        {el.id}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">{packageError}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="customPrice">Custom Price (Optional)</label>
                  <div class="input-group mb-2">
                    <div class="input-group-prepend">
                      <div class="dropdown">
                        <button
                          class="btn btn-outline-secondary dropdown-toggle text-dark"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            background: "#ececec",
                          }}
                        >
                          {currency ? currency : "Select"}
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <button
                            class="dropdown-item"
                            type="button"
                            onClick={() => handleCurrency("LKR")}
                          >
                            LKR (Sri Lanka)
                          </button>
                          <button
                            class="dropdown-item"
                            type="button"
                            onClick={() => handleCurrency("USD")}
                          >
                            USD (United States / Other)
                          </button>
                        </div>
                      </div>
                    </div>

                    <input
                      type="number"
                      className={classnames("form-control", {
                        "is-invalid": customPriceErr,
                      })}
                      id="customPrice"
                      name="customPrice"
                      placeholder="Optional"
                      onChange={(e) => {
                        setCustomPrice(e.target.value);
                        setCustomPriceErr("");
                      }}
                      value={customPrice}
                      style={{
                        borderTopRightRadius: 5,
                        borderBottomRightRadius: 5,
                      }}
                    />
                    <div className="invalid-feedback">{customPriceErr}</div>
                  </div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="customPrice">Pay Link Send by</label>
                  <div class="custom-control custom-checkbox mt-1 mb-1">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="sms"
                      checked={sendSms}
                      onChange={(e) => setSendSms(e.target.checked)}
                    />
                    <label class="custom-control-label" for="sms">
                      SMS
                    </label>
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="email"
                      checked={sendEmail}
                      onChange={(e) => setSendEmail(e.target.checked)}
                    />
                    <label class="custom-control-label" for="email">
                      Email
                    </label>
                  </div>
                  <div className="invalid-feedback d-block">{sendByErr}</div>
                </div>
              </div>
              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleSend}
                style={{ width: 100, float: "right" }}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default SendPayLinkCom;
