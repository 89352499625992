import React, { useState } from "react";
import {
  Grid,
  Select,
  Typography,
  MenuItem,
  Button,
  makeStyles,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { Table } from "react-bootstrap";
import { format } from "date-fns-tz";
import axios from "axios";
import DatePicker from "../../base/date-picker";

const styles = makeStyles({
  disabledButton: {
    backgroundColor: "grey !important",
  },
});

const UTMLogsTable = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [logType, setLogType] = useState(null);
  const [logData, setLogData] = useState([]);
  const [logError, setLogError] = useState(null);

  const formatDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd", {
      timeZone: "Asia/Colombo",
    });
    return formattedDate;
  };

  const handleShowResult = async () => {
    try {
      if ((fromDate === null) | (toDate === null) | (logType === null)) {
        return;
      }
      const searchParams = new URLSearchParams();
      searchParams.append("from", formatDate(fromDate));
      searchParams.append("to", formatDate(toDate));
      searchParams.append("type", logType);
      let URL = `${process.env.REACT_APP_API_URL}/v1/admin/utm_stats`;
      URL += `?${searchParams.toString()}`;
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${isAuthenticatedBoth()}` },
      });

      const payload = response.data;

      if (Array.isArray(payload.data)) {
        setLogData(payload.data);
      }
    } catch (error) {
      setLogError(error);
    }
  };

  return (
    <div className="content-col" style={{ paddingBottom: 10 }}>
      <div
        className="container-fluid inner-content"
        style={{ paddingBottom: 0 }}
      >
        <div
          className="ad-form-sec"
          style={{ maxWidth: "100%", marginBottom: "0" }}
        >
          <form noValidate>
            <div className="form-row header">
              <div className="form-group col px-sm-2 mb-0">
                <p className="heading mb-0">UTM Logs </p>
              </div>
            </div>
            <div className="content" style={{ paddingBottom: 25 }}>
              <Grid container direction="row" style={{ columnGap: 16 }}>
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  style={{ columnGap: 8, width: "auto" }}
                >
                  <Typography>Select Type</Typography>
                  <FormControl
                    style={{
                      width: 200,
                      paddingLeft: 15,
                      borderRadius: 3,
                      position: "relative",
                      border: "1px solid #000000",
                    }}
                  >
                    <Select
                      label="Months"
                      value={logType}
                      onChange={(e) => setLogType(e.target.value)}
                    >
                      <MenuItem key={1} value="utm_medium">
                        utm_medium
                      </MenuItem>
                      <MenuItem key={2} value="utm_source">
                        utm_source
                      </MenuItem>
                      <MenuItem key={3} value="utm_campaign">
                        utm_campaign
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <DatePicker
                  label="from"
                  selectedDate={fromDate}
                  onChangeDate={(date) => setFromDate(date)}
                />
                <DatePicker
                  label="to"
                  selectedDate={toDate}
                  onChangeDate={(date) => setToDate(date)}
                />

                <Button
                  size="small"
                  style={{
                    color: "white",
                    backgroundColor: "black",
                    textTransform: "capitalize",
                  }}
                  onClick={handleShowResult}
                  disabled={toDate === null || fromDate === null}
                  classes={{ disabled: styles.disabledButton }}
                >
                  Show Results
                </Button>
                {logData.length > 0 && (
                  <TableContainer style={{ marginTop: 58 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Campaign</TableCell>
                          <TableCell>Number of Users</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {logData.map((data, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{data.title}</TableCell>
                              <TableCell>{data.count}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
              <Grid direction="column">
                {logError !== null && (
                  <TableRow
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: 30,
                      color: "red",
                    }}
                  >
                    Unexpected Error Occurred. Try Again !
                  </TableRow>
                )}
              </Grid>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UTMLogsTable;
