import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import RecentSms from "../../components/admin/recent-sms/RecentSmsSearchCom";

function Post() {
  return (
    <AdminLayout>
      <RecentSms />
    </AdminLayout>
  );
}

export default Post;