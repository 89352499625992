import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import _12FromTo24Hours from "12fromto24hours";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import Alert from '@material-ui/lab/Alert';

import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import Switch from "react-input-switch";

function OfflineResponses(props) {
  const [post, setPost] = useState(false);
  const [magazine, setMagazine] = useState(false);

  useEffect(() => {
    if (props.ad && props.ad.id) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/admin/user_account?memberId=${props.ad.memberId}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            }
          }
        )
        .then((res) => {
          setPost(res.data.userPreferences.showOfflineId);
          setMagazine(res.data.userPreferences.subscribeMagazine);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const updateOfflineResponse = (type, value) => {
    let data;
    if (type=="post") {
      data = {
        showOfflineId: value,
      };
      setPost(value);
    } else {
      data = {
        subscribeMagazine: value,
      };
      setMagazine(value);
    }
    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/admin/user/${props.ad.memberId}/preferences`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        NotificationManager.success("Updated successfully!", "Success");
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Updating failed", "Error");
      });
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 10 }}
        >
          Offline Responses
        </Typography>
        {!props.ad.postalAddress && (
          <Alert severity="error" style={{marginBottom: 10}}>
            please add postal address to enable offline responses
          </Alert>
        )}
        <div className="row m-0" style={{paddingLeft:'20px'}}>
          <div className="col p-0">
            <Typography className="setting-on-off">
              <span className="on-off-label" style={{fontSize: '14px'}}>
                Accept written interests via post
              </span>
            </Typography>
          </div>
          <div className="col p-0">
            <Typography>
              <Switch
                on={true}
                off={false}
                value={post}
                onChange={(e) => updateOfflineResponse("post", !post)}
                className="interest-on-off-btn"
                style={{
                  margin: "auto",
                }}
                disabled={!props.ad.postalAddress}
              />
            </Typography>
          </div>
        </div>

        <div className="row m-0" style={{paddingLeft:'20px', paddingTop:'20px'}}>
          <div className="col p-0">
            <Typography className="setting-on-off">
              <span className="on-off-label" style={{fontSize: '14px'}}>
                Send monthly magazine with all advertisements
              </span>
            </Typography>
          </div>
          <div className="col p-0">
            <Typography>
              <Switch
                on={true}
                off={false}
                value={magazine}
                onChange={(e) => updateOfflineResponse("magazine", !magazine)}
                className="interest-on-off-btn"
                style={{
                  margin: "auto",
                }}
                disabled={!props.ad.postalAddress}
              />
            </Typography>
          </div>
        </div>
      </Paper>
      <NotificationContainer />
    </Grid>
  );
}

export default OfflineResponses;