import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import { connect } from "react-redux";

import { isAuthenticated } from "../../../../auth/auth";

function ConfirmBtn(props) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // create product object
    const data = props.data;

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/v1/agent_posts`,
      data: data,
      headers: {
        Authorization: `Bearer ${isAuthenticated()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        sessionStorage.removeItem("ad_data");
        NotificationManager.success("Created successfully!", "Success");

        setTimeout(() => {
          props.history.push("/dashboard/agents/created");
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="ad-form-sec">
        <form noValidate>
          <button
            type="button"
            className="btn-submit mt-0"
            onClick={() => props.history.push("/dashboard/create-ad")}
            style={{
              width: "auto",
              float: "left",
              background: "#efefef",
            }}
          >
            <i className="fas fa-angle-left"></i> Back
          </button>
          <button
            type="button"
            className="btn-submit mt-0"
            onClick={handleSubmit}
            style={{ width: 100, float: "right" }}
          >
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              "Confirm"
            )}
          </button>
        </form>
      </div>
      <NotificationContainer />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.createAd.data,
  };
};

export default connect(mapStateToProps, null)(withRouter(ConfirmBtn));
