import React from "react";
import classnames from "classnames";
import { heightArr } from "../../../../constant/heightArr";

function Basic(props) {
  const heights = heightArr

  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fname">
          First Name <span className="required-tag">*</span>
        </label>
        <input
          type="text"
          className={classnames("form-control", {
            "is-invalid": props.form.fnameErr,
          })}
          id="fname"
          name="fname"
          onChange={props.handleChange}
          value={props.form.fname}
          autoComplete="off"
        />
        <div className="invalid-feedback">{props.form.fnameErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="lname">
          Last Name <span className="required-tag">*</span>
        </label>
        <input
          type="text"
          className={classnames("form-control", {
            "is-invalid": props.form.lnameErr,
          })}
          id="lname"
          name="lname"
          onChange={props.handleChange}
          value={props.form.lname}
          autoComplete="off"
        />
        <div className="invalid-feedback">{props.form.lnameErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="dob">
          Birthdate <span className="required-tag">*</span>
        </label>
        <input
          type="date"
          className={classnames("form-control", {
            "is-invalid": props.form.dobErr,
          })}
          id="dob"
          name="dob"
          onChange={props.handleChange}
          value={props.form.dob}
          autoComplete="off"
        />
        <div className="invalid-feedback">{props.form.dobErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="gender">
          Gender <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.genderErr,
          })}
          id="gender"
          name="gender"
          onChange={props.handleChange}
          value={props.form.gender}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
        </select>
        <div className="invalid-feedback">{props.form.genderErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="ethnicityId">
          Ethnicity <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.ethnicityIdErr,
          })}
          id="ethnicityId"
          name="ethnicityId"
          onChange={props.handleChange}
          value={props.form.ethnicityId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.ethnicities.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.ethnicityIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="religionId">
          Religion <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.religionIdErr,
          })}
          id="religionId"
          name="religionId"
          onChange={props.handleChange}
          value={props.form.religionId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.religions.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.religionIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="caste">Caste</label>
        <input
          type="text"
          className="form-control"
          id="caste"
          name="caste"
          onChange={props.handleChange}
          value={props.form.caste}
          autoComplete="off"
        />
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="civilStatusId">
          Civil Status <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.civilStatusIdErr,
          })}
          id="civilStatusId"
          name="civilStatusId"
          onChange={props.handleChange}
          value={props.form.civilStatusId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.civilStatuses.map((el, i) => (
            <option value={el.id} key={i}>
              {el.status}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.civilStatusIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="height">
          Height <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.heightErr,
          })}
          id="height"
          name="height"
          onChange={props.handleChange}
          value={props.form.height}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {heights.map((el, i) => (
            <option value={el.value} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.heightErr}</div>
      </div>

      {props.form.civilStatusId !== "never_married" &&
        <div className="form-group col-md-6 px-sm-2 mb-3">
          <label htmlFor="children">
            Children <span className="required-tag">*</span>
          </label>
          <select
            className={classnames("form-control", {
              "is-invalid": props.form.childrenErr,
            })}
            id="children"
            name="children"
            onChange={props.handleChange}
            value={props.form.children}
          >
            <option defaultValue hidden>
              -- Select --
            </option>
            <option key="0" value="NO">No</option>
            <option key="1" value="YES">Yes</option>
            <option key="1" value="UNDISCLOSED">Prefer not to mention</option>
          </select>
          <div className="invalid-feedback">{props.form.childrenErr}</div>
        </div>
      }
    </div>
  );
}

export default Basic;
