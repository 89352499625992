import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import { Loader, Oval } from "react-loader-spinner";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function VertualAgentsKPIsCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const itemsPerPage = 20;

  useEffect(() => {
    if (isAuthenticatedBoth()) {
      setLoading(true)
      try {
        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/admin/operators`, {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          })
          .then((res) => {
            setAds(res.data);
            setLoading(false);
          });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  // pagination
  const noOfPages = Math.ceil(ads.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = ads.slice(indexOfFirst, indexOfLast);

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="virtual Agent" title="All Created Ads" />
        {!loading ? (
          <div className="container-fluid inner-content py-4">
            <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
              <div className="content p-0">
                <table
                  className="table table-hover table-responsive common-table border mb-0"
                  style={{ fontSize: 14 }}
                >
                  <thead className="header">
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Role</th>
                    </tr>
                  </thead>
                  <tbody style={{ cursor: "pointer" }}>
                    {currentItems.length ? (
                      currentItems.map((el, i) => (
                        <tr
                          key={i}
                          onClick={() =>
                            history.push(
                              `/dashboard/individual-kpi/${el.memberId}`
                            )
                          }
                        >
                          <td width="150">{el.memberId}</td>
                          <td width="200">{el.name}</td>
                          <td width="100">{el.role}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" width="480">
                          No Records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row m-0">
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
              <Oval
                width="40"
                height="40"
                color="#000000"
                secondaryColor="#000000"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default VertualAgentsKPIsCom;
