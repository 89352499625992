import React from "react";
import classnames from "classnames";

function AccountInfo(props) {
  return (
    <React.Fragment>
      <div className="form-row m-0">
        <div className="form-group col px-sm-2 mb-3 col-md-6">
          <label htmlFor="accountCreatedById">
            Account Created by{" "}
            {/* <span className="required-tag">*</span> */}
          </label>
          <select
            className={classnames("form-control", {
              "is-invalid": props.form.accountCreatedByIdErr,
            })}
            id="accountCreatedById"
            name="accountCreatedById"
            onChange={props.handleChange}
            value={props.form.accountCreatedById}
          >
            <option defaultValue hidden>
              -- Select --
            </option>
            <option value="SELF">Self</option>
            <option value="FAMILY">Parents / Family</option>
          </select>
          <div className="invalid-feedback">{props.form.accountCreatedByIdErr}</div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AccountInfo;
