import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import AddToBlacklistModal from "../modals/AddToBlacklistModal";
import BlacklistRemoveModal from "../modals/BlacklistRemoveModal";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";

function PendingBankApprovalsCom() {
  const [numbers, setNumbers] = useState([]);
  const [removingNumber, setRemovingNumber] = useState("");

  useEffect(() => {
    getBlacklist();
  }, []);

  const getBlacklist = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/phonenumbers/blacklist`, {
        headers: {
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
        },
      })
      .then((res) => {
        setNumbers(res.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddedSuccess = () => {
    document.getElementById("addBlacklistModalClose").click();
    NotificationManager.success("Phone number blacklisted successfully!", "Success");
    getBlacklist();
  }

  const removedSuccessfully = () => {
    document.getElementById("blacklistRemoveModalClose").click();
    NotificationManager.success("Phone number removed from blacklist successfully!", "Success");
    getBlacklist();
  }

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Phone Numbers" title="Blacklist" />
        <div className="container-fluid inner-content py-4">
          <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
            <button
              className="btn btn-success btn-sm m-1"
              style={{ width: 80 }}
              data-toggle="modal"
              data-target="#addToBlacklistModal"
            >
              Add New
            </button>
            {numbers.length == 0 && (
              <div style={{marginTop: '50px'}}>No Records</div>
            )}
            {numbers.length > 0 && (
              <>
                <div className="content p-0 mb-4">
                  <table
                    className="table table-hover table-responsive  common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Number</th>
                        <th className="border-0">Blacklised On</th>
                        <th className="border-0">Time</th>
                        <th className="border-0 text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {numbers.length ? (
                        numbers.map((el) => (
                          <tr key={el.number}>
                            <td width="200">
                              {el.number}
                            </td>
                            <td width="200">
                              {moment
                                .utc(el.createdAt)
                                .local()
                                .format("Do MMM YYYY")}
                            </td>
                            <td width="200">
                              {moment
                                .utc(el.createdAt)
                                .local()
                                .format("hh:mm:ss a")}
                            </td>
                            <td>
                              <button
                                className="btn btn-outline-danger btn-sm m-1"
                                data-toggle="modal"
                                data-target="#blacklistRemoveModal"
                                onClick={() => {
                                  setRemovingNumber(el.number);
                                }}
                                style={{ width: 80 }}
                              >
                                Remove
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-muted">
                            No Recods
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <NotificationContainer />
      <AddToBlacklistModal
        handleAddedSuccess={handleAddedSuccess}
      />
      <BlacklistRemoveModal
        removingNumber={removingNumber}
        removedSuccessfully={removedSuccessfully}
      />
    </>
  );
}

export default PendingBankApprovalsCom;
