import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import Filter from "./Filter";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import CommentsModal from "../modals/CommentsModal";
import PromptModal from "../modals/PromptModal";
import { emailValidator } from "../../../methods/emailValidator";
import { NotificationContainer, NotificationManager } from "react-notifications";

function FollowUpAdCreatedCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(
    sessionStorage.getItem("followup_post_page")
      ? JSON.parse(sessionStorage.getItem("followup_post_page"))
      : 1
  );
  const [noOfPages, setNoOfPages] = useState(1);
  const [totAds, setTotAds] = useState(0);
  const [search, setSearch] = useState("");
  const [comments, setComments] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [comment, setComment] = useState("");
  const [commentErr, setCommentErr] = useState("");
  const [loading, setLoading] = useState(true);
  const [from, setFrom] = useState(
    sessionStorage.getItem("followup_post_from_date")
      ? sessionStorage.getItem("followup_post_from_date")
      : ""
  );
  const [to, setTo] = useState(
    sessionStorage.getItem("followup_post_to_date")
      ? sessionStorage.getItem("followup_post_to_date")
      : ""
  );
  const [status, setStatus] = useState(
    sessionStorage.getItem("followup_post_status")
      ? sessionStorage.getItem("followup_post_status")
      : "PAYMENT_PENDING"
  );

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [exportEmail, setExportEmail] = useState("");
  const [isExporting, setIsExporting] = useState(false);

  const itemsPerPage = 25;

  useEffect(() => {
    let from;
    let to;

    if (
      sessionStorage.getItem("followup_post_from_date") ||
      sessionStorage.getItem("followup_post_to_date")
    ) {
      from = sessionStorage.getItem("followup_post_from_date");
      to = sessionStorage.getItem("followup_post_to_date");
    } else {
      from = moment().subtract(4, "days").format("YYYY-MM-DD");
      to = moment().subtract(2, "days").format("YYYY-MM-DD");
    }

    setFrom(from);
    setTo(to);

    handleSearchFunc(from, to);
  }, []);

  const handleSearchBtn = (from, to) => {
    sessionStorage.setItem("followup_post_page", 1);

    setPage(1);
    handleSearchFunc(from, to);
  };

  const handleSearchFunc = (from, to) => {
    var fromDate = moment(from, "YYYY-MM-DD");
    var toDate = moment(to, "YYYY-MM-DD");

    if (!fromDate.isValid() || !toDate.isValid()) {
    } else {
      setLoading(true);
      let url = (status == "ALL") ?
        `&pageSize=${itemsPerPage}&accountsOnly=false&postsOnly=true`
        :
        `&pageSize=${itemsPerPage}&accountsOnly=false&postsOnly=true&postStatus=${status}`;
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/operator/users?from=${from}&to=${to}&pageNum=${
            page - 1
          }${url}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          // sort by date
          const sortByDate = res.data.results.sort(
            (a, b) => b.createdAt - a.createdAt
          );

          // group by date
          const groups = sortByDate.reduce((groups, ad) => {
            const date = moment.utc(ad.createdAt).local().format("YYYY-MM-DD");
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(ad);
            return groups;
          }, {});

          const groupByCreatedAt = Object.keys(groups).map((date) => {
            return {
              date,
              data: groups[date],
            };
          });

          let edu = [];
          let nonEdu = [];
          let prof = [];
          let nonProf = [];

          let combineProf = [];
          let combineEduProfAndNonProf = [];
          let allSortData = [];

          groupByCreatedAt.forEach((item) => {
            edu = [];
            nonEdu = [];
            prof = [];
            nonProf = [];

            item.data.filter((el) => {
              if (
                el.post.education.level === "Bachelor's Degree or Equivalent" ||
                el.post.education.level === "Master's Degree or Equivalent" ||
                el.post.education.level === "Phd or Post Doctoral" ||
                el.post.education.level === "Professional Qualification" ||
                el.post.education.level === "Post Graduate Diploma"
              ) {
                edu = [...edu, el];
              } else {
                nonEdu = [...nonEdu, el];
              }
            });

            edu.filter((el) => {
              if (
                el.post.profession.name === "Doctor" ||
                el.post.profession.name === "Engineer" ||
                el.post.profession.name === "Lecturer" ||
                el.post.profession.name === "IT Professional" ||
                el.post.profession.name === "Researcher" ||
                el.post.profession.name === "Medical Professional" ||
                el.post.profession.name === "Accountant" ||
                el.post.profession.name === "Lawyer"
              ) {
                console.log("hi");
                prof = [...prof, el];
              } else {
                nonProf = [...nonProf, el];
              }
            });

            combineProf = prof.concat(nonProf);
            combineEduProfAndNonProf = combineProf.concat(nonEdu);

            allSortData = allSortData.concat(combineEduProfAndNonProf);
          });

          setAds(allSortData);
          setNoOfPages(res.data.pageCount);
          setTotAds(res.data.total);

          sessionStorage.setItem("followup_post_from_date", from);
          sessionStorage.setItem("followup_post_to_date", to);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const addComment = () => {
    if (comment) {
      setLoading(true);

      const data = qs.stringify({
        memberId: memberId,
        comment,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/operator/add_comment`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          handleSearchFunc(from, to);
          let url = (status == "ALL") ?
            `&pageSize=${itemsPerPage}&accountsOnly=false&postsOnly=true`
            :
            `&pageSize=${itemsPerPage}&accountsOnly=false&postsOnly=true&postStatus=${status}`;
          axios
            .get(
              `${
                process.env.REACT_APP_API_URL
              }/v1/operator/users?from=${from}&to=${to}&pageNum=${
                page - 1
              }${url}`,
              {
                headers: {
                  Authorization: `Bearer ${isAuthenticatedBoth()}`,
                },
              }
            )
            .then((res) => {
              const findNewComments = res.data.results.filter(
                (el) => el.memberId === memberId
              );

              setComment("");
              setComments(findNewComments[0].comments);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setCommentErr("Please type your comment");
    }
  };

  const exportFollowupUserList = () => {
    setIsExporting(true);
    const urlParams = new URLSearchParams("accountsOnly=false&postsOnly=true");

    if (status !== "ALL" && status !== null) {
      urlParams.append("postStatus", status);
    }

    if (moment(from,"YYYY-MM-DD",true).isValid() && moment(to,"YYYY-MM-DD",true).isValid()) {
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/operator/users?${urlParams.toString()}&from=${from}&to=${to}&emailFullList=${exportEmail}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setIsExporting(false);
          NotificationManager.success("Email Sent", "Success");
          setShowEmailModal(false);
          setExportEmail("");
        })
        .catch((err) => {
          setIsExporting(false);
          NotificationManager.error("Email sending failed", "Error");
        });
    } else{
      setIsExporting(false);
      NotificationManager.error("Invalid Date Range", "Error");
    }
  };

  useEffect(() => {
    handleSearchFunc(from, to);
    sessionStorage.setItem("followup_post_status", status);
    sessionStorage.setItem("followup_post_page", page);
  }, [page, status]);

  const handlePagination = (event, value) => {
    setPage(value);
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleStatus = (status) => {
    setStatus(status);
    setPage(1);
  };

  const handleEmailChange = (e) => {
    setExportEmail(e.target.value);
  }

  const closePromptModal = () => {
    setShowEmailModal(false);
  }

  const filterBySearch = ads.filter((el) => {
    if (search) {
      return (
        el.post.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.memberId.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.post.firstName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.post.lastName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.post.education.level.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        el.post.profession.name.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        (el.phone &&
          el.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.email && 
          el.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="Follow-up" title="Ad Created" />

        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Filter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              handleSearchBtn={handleSearchBtn}
              loading={loading}
            />

            <div className="row m-0">
              <div className="col p-0">
                <form
                  style={{
                    display: "block",
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 0,
                  }}
                >
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <div
                        className="input-group mb-0"
                        style={{ width: "350px" }}
                      >
                        <input
                          type="text"
                          className="form-control pr-5"
                          placeholder="IDs / Name / Job / Edu / Phone / Email"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          autoComplete="off"
                          style={{ borderRadius: "5px" }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "8px",
                            color: "#949494",
                            zIndex: 3,
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="row m-0 mt-3">
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <div className="d-flex flex-row justify-content-between mb-2 align-items-end">
                  <div>
                    <span style={{ fontSize: 14}}>
                      {ads.length > 0
                        ? `Showing ${(page - 1) * itemsPerPage} - ${
                            (page - 1) * itemsPerPage + ads.length
                          } out of ${totAds} results`
                        : "No results"}
                    </span>
                  </div>

                  <div className="d-flex" style={{gap:"10px"}}>
                    <div>
                      <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() => setShowEmailModal(true)}
                      >
                        Export
                      </button>
                    </div>
                <div>
                  <button
                    className="btn btn-outline-secondary btn-sm dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                      marginLeft: "auto",
                      display: "block",
                    }}
                  >
                    {status}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("PAYMENT_PENDING")}
                    >
                      Payment Pending
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("IN_REVIEW")}
                    >
                      In-Review
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("LIVE")}
                    >
                      Live
                    </button>
                    {/* <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("EXPIRED")}
                    >
                      Expired
                    </button> */}
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("DISABLED")}
                    >
                      Disabled
                    </button>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => handleStatus("ALL")}
                    >
                      All
                    </button>
                  </div>
                </div>
                </div>
                </div>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Created Date</th>
                        <th className="border-0">Ad ID</th>
                        <th className="border-0">Post ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Education</th>
                        <th className="border-0">Profession</th>
                        <th className="border-0">status</th>
                        <th className="border-0">Phone</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterBySearch.length ? (
                        filterBySearch.map((el, i) => (
                          <tr key={i}>
                            <td width="200">
                              <p className="mb-0" style={{ width: 145 }}>
                                {moment
                                  .utc(el.post && el.post.createdAt ? el.post.createdAt : el.createdAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </p>
                            </td>
                            <td width="100">{el.memberId}</td>
                            <td width="100">{el.post.id}</td>
                            <td width="125">
                              {el.post.firstName} {el.post.lastName}
                            </td>
                            <td width="125">{el.post.education.level}</td>
                            <td width="125">{el.post.profession.name}</td>
                            <td width="125">{el.post.status}</td>
                            <td width="150">{el.phone ? el.phone : "-"}</td>
                            <td width="150">{el.email ? el.email : "-"}</td>
                            <td width="400" style={{ position: "relative" }}>
                              <p style={{ width: 300 }} className="mb-1">
                                {el.comments.length > 0
                                  ? el.comments.sort((a, b) =>
                                      b.createdAt.localeCompare(a.createdAt)
                                    )[0].comment
                                  : "-"}
                              </p>
                              <p
                                style={{ width: 300, fontSize: 12 }}
                                className="mb-0 text-muted"
                              >
                                {el.comments.length > 0
                                  ? moment
                                      .utc(
                                        el.comments.sort((a, b) =>
                                          b.createdAt.localeCompare(a.createdAt)
                                        )[0].createdAt
                                      )
                                      .local()
                                      .format("DD MMM YYYY hh:mm a")
                                  : ""}
                              </p>

                              {!el.post.operatorCreated ?
                                <i
                                  className="fas fa-plus add-comment-btn"
                                  data-toggle="modal"
                                  data-target="#comments-modal"
                                  style={{
                                    position: "absolute",
                                    bottom: 6,
                                    right: 6,
                                  }}
                                  onClick={() => {
                                    setComments(el.comments);
                                    setMemberId(el.memberId);
                                  }}
                                ></i>
                                : <label style={{marginLeft:"10px", display:"inline"}} className="createdby-label">Agent Created</label>
                              }
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" width="480">
                            No Recods
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {noOfPages > 1 && (
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handlePagination}
                        className="pagi"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <PromptModal
        showModal={showEmailModal}
        closeModal={closePromptModal}
        heading={"Export CSV"}
        title={"Enter your Email"}
        inputType={"email"}
        actionName={"Send"}
        onActionClick={exportFollowupUserList}
        value={exportEmail}
        handleChange={handleEmailChange}
        isActionDisabled={!emailValidator(exportEmail)}
        loadingModal={isExporting}
      />
      <CommentsModal
        comments={comments}
        comment={comment}
        setComment={setComment}
        commentErr={commentErr}
        setCommentErr={setCommentErr}
        addComment={addComment}
        loading={loading}
      />
      <NotificationContainer/>
    </>
  );
}

export default FollowUpAdCreatedCom;
