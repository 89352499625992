import React, { useState } from "react";
import axios from "axios";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function FindAddressesCom() {
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState({});
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (search) {
      setLoading(true);
      let data = "";

      if (search.length === 7) {
        data = `extId=${search}`;
      } else {
        data = `offlineId=${search}`;
      }

      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/admin/posts/find?${data}`, {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setAddress(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setAddress({});
          setLoading(false);
        });
    }
  };

  return (
    <div className="content-col">
      <Breadcrumb title="Find Addresses" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="refCode / Post ID"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <button
                    className="btn btn-dark d-block ml-2"
                    onClick={handleSearch}
                    style={{ width: 50 }}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <i className="fas fa-search"></i>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          {address.postalAddress ? (
            <div className="content p-0">
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <tbody>
                  <tr>
                    <td width="125">
                      <b>Post ID</b>
                    </td>
                    <td width="300">{address.id}</td>
                  </tr>
                  <tr>
                    <td width="125">
                      <b>Ref Code</b>
                    </td>
                    <td width="300">{address.offlineId}</td>
                  </tr>
                  <tr>
                    <td width="125">
                      <b>Member ID</b>
                    </td>
                    <td width="300">{address.memberId}</td>
                  </tr>
                  <tr>
                    <td width="125">
                      <b>Account Name</b>
                    </td>
                    <td width="300">
                      {address.personalInfo.fname} {address.personalInfo.lname}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Address</b>
                    </td>
                    <td>
                      <p className="mb-1">{address.postalAddress.name}</p>
                      <p className="mb-1">{address.postalAddress.line1}</p>
                      <p className="mb-1">{address.postalAddress.line2}</p>
                      <p className="mb-1">{address.postalAddress.city}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <p>No Recods</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default FindAddressesCom;
