import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import InreviewAdsCom from "../../components/admin/ads/InreviewAdsCom";

function InreviewAds() {
  return (
    <AdminLayout>
      <InreviewAdsCom />
    </AdminLayout>
  );
}

export default InreviewAds;
