import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import SendSMSCom from "../components/admin/SMS/SendSMSCom";

function SendBankDetails() {
  return (
    <AdminLayout>
      <SendSMSCom />
    </AdminLayout>
  );
}

export default SendBankDetails;
