import React, { useEffect, useRef } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useDispatch } from "react-redux";

// visitors
import Login from "./pages/Login";

// admin
import Dashboard from "./pages/admin/Dashboard";
import AllAds from "./pages/admin/AllAds";
import InreviewAds from "./pages/admin/InreviewAds";
import EditRequests from "./pages/admin/EditRequests";
import Post from "./pages/admin/Post";
import Interests from "./pages/Interests";
import ActivityLogs from "./pages/admin/ActivityLog";
import CreateAdVirtualAgent from "./pages/admin/CreateAdVirtualAgent";
import ReviewAdVirtualAgent from "./pages/admin/ReviewAdVirtualAgent";
import VertualAgentsKPIs from "./pages/admin/VertualAgentsKPIs";
import IndividualKPIs from "./pages/admin/IndividualKPIs";
import AllAdsByVirtualAgents from "./pages/admin/AllAdsByVirtualAgents";
import FollowUpOnlyAccount from "./pages/admin/FollowUpOnlyAccount";
import FollowUpAdCreated from "./pages/admin/FollowUpAdCreated";
import FollowUpExpiredAds from "./pages/admin/FollowUpExpiredAds";
import CreateAd from "./pages/admin/CreateAd";
import ReviewAd from "./pages/admin/ReviewAd";
import AgentsAds from "./pages/admin/AgentsAds";
import AgentsAdsCreated from "./pages/admin/AgentsAdsCreated";
import PendingBankApprovals from "./pages/admin/PendingBankApprovals";
import CreateAgent from "./pages/admin/CreateAgent";
import AllAgents from "./pages/admin/AllAgents";
import VerifyPhoneNumber from "./pages/admin/VerifyPhoneNumber";
import SendPayLink from "./pages/admin/SendPayLink";
import FindAddresses from "./pages/admin/FindAddresses";
import Settings from "./pages/admin/Settings";
import sendBankDetails from "./pages/admin/SendBankDetails";
import VerifyNIC from "./pages/admin/VerifyNIC";
import AllAgentAds from "./pages/AllAgentAds";
import PhoneNumbersBlacklist from "./pages/admin/PhoneNumbersBlacklist";
import KPIs from "./pages/KPIs";
import SendSMS from "./pages/SendSMS";
import AddReview from "./pages/AddReview";
import StatDashboard from "./pages/StatDashboard";
import RecentSms from "./pages/admin/RecentSms";

// load isAuthenticated method
import { isAuthenticated } from "./auth/auth";
import { isAuthenticatedBoth } from "./auth/authBoth";

//actions
import { getGenericData } from "./actions/genericData";
import UserReviews from "./pages/admin/UserReviews";
import UnPublishReviews from "./pages/admin/UnpublishReviews";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { storeVirtualAdDetails } from "./actions/storeVirtualAdDetails";

// LoginRoute
const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !isAuthenticated() && !isAuthenticatedBoth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

// create a private route for admin
const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

// create a private route for admin
const AdminOperatorRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticatedBoth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      localStorage.getItem("at")
    ) {
      logout();
    }
    return Promise.reject(error);
  }
);

const logout = () => {
  localStorage.removeItem("at");
  localStorage.removeItem("un");
  localStorage.removeItem("ur");

  window.location.href = "/";
};

const GlobalHandler = ({ children }) => {
  const history = useHistory();
  const previouseLocationRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const routerListener = history.listen((location) => {
      onRouteChange(location.pathname, previouseLocationRef.current);
      previouseLocationRef.current = location.pathname;
    });

    return () => {
      routerListener();
    };
  });

  const onRouteChange = (current, previous) => {
    if (previous === null) {
      return;
    }

    if (
      previous === "/dashboard/virtual-ad-create" &&
      current !== "/dashboard/review-virtual-ad"
    ) {
      dispatch(storeVirtualAdDetails({}));
    }
  };

  return <>{children}</>;
};

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGenericData());
  });

  return (
    <Router>
      <GlobalHandler>
        <Switch>
          <LoginRoute path="/" exact component={Login} />

          <AdminOperatorRoute
            path="/dashboard/agents/created"
            exact
            component={AgentsAdsCreated}
          />
          <AdminOperatorRoute path="/dashboard" exact component={Dashboard} />

          <AdminOperatorRoute path="/ads/all-ads" exact component={AllAds} />

          <AdminOperatorRoute
            path="/ads/inreview-ads"
            exact
            component={InreviewAds}
          />

          <AdminOperatorRoute
            path="/edit-requests"
            exact
            component={EditRequests}
          />

          <AdminOperatorRoute path="/post" exact component={Post} />

          <AdminOperatorRoute path="/interests" exact component={Interests} />

          <AdminOperatorRoute
            path="/activity-log"
            exact
            component={ActivityLogs}
          ></AdminOperatorRoute>

          <AdminOperatorRoute
            path="/dashboard/virtual-ad-create"
            exact
            component={CreateAdVirtualAgent}
          />

          <AdminOperatorRoute
            path="/dashboard/review-virtual-ad"
            exact
            component={ReviewAdVirtualAgent}
          />

          <AdminOperatorRoute
            path="/dashboard/ads-created-by-vertual-agent"
            exact
            component={AllAdsByVirtualAgents}
          />

          <AdminOperatorRoute
            path="/dashboard/vertual-agents/kpi"
            exact
            component={VertualAgentsKPIs}
          />

          <AdminOperatorRoute
            path="/dashboard/individual-kpi/:id"
            exact
            component={IndividualKPIs}
          />

          <AdminOperatorRoute
            path="/follow-ups/only-account-created"
            exact
            component={FollowUpOnlyAccount}
          />

          <AdminOperatorRoute
            path="/follow-ups/ads-created"
            exact
            component={FollowUpAdCreated}
          />

          <AdminOperatorRoute
            path="/follow-ups/expired-ads"
            exact
            component={FollowUpExpiredAds}
          />

          <AdminOperatorRoute
            path="/dashboard/create-ad"
            exact
            component={CreateAd}
          />

          <AdminOperatorRoute
            path="/dashboard/review"
            exact
            component={ReviewAd}
          />

          <AdminOperatorRoute
            path="/dashboard/agents/new"
            exact
            component={AgentsAds}
          />

          <AdminRoute
            path="/dashboard/pending-bank-approvals"
            exact
            component={PendingBankApprovals}
          />

          <AdminOperatorRoute
            path="/dashboard/create-agent"
            exact
            component={CreateAgent}
          />
          <AdminOperatorRoute
            path="/dashboard/all-agents"
            exact
            component={AllAgents}
          />
          <AdminOperatorRoute
            path="/verify-phone-number"
            exact
            component={VerifyPhoneNumber}
          />
          <AdminOperatorRoute path="/verify-nic" exact component={VerifyNIC} />
          <AdminOperatorRoute
            path="/send-pay-link"
            exact
            component={SendPayLink}
          />
          <AdminOperatorRoute
            path="/send-bank-details"
            exact
            component={sendBankDetails}
          />
          <AdminOperatorRoute
            path="/find-addresses"
            exact
            component={FindAddresses}
          />
          <AdminOperatorRoute path="/settings" exact component={Settings} />
          <AdminOperatorRoute
            path="/all-agent-ads"
            exact
            component={AllAgentAds}
          />
          <AdminOperatorRoute
            path="/phone-numbers-blacklist"
            exact
            component={PhoneNumbersBlacklist}
          />
          <AdminOperatorRoute path="/kpi" exact component={KPIs} />
          <AdminOperatorRoute path="/send-sms" exact component={SendSMS} />
          <AdminOperatorRoute path="/recent-sms" exact component={RecentSms} />
          <AdminOperatorRoute path="/add-review" exact component={AddReview} />
          <AdminOperatorRoute
            path="/user-reviews"
            exact
            component={UserReviews}
          />
          <AdminOperatorRoute
            path="/unpublish-reviews"
            exact
            component={UnPublishReviews}
          />
          <AdminOperatorRoute
            path="/stat-dashboard"
            exact
            component={StatDashboard}
          />
        </Switch>
      </GlobalHandler>
    </Router>
  );
}

export default App;
