import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { Paper, Grid } from "@material-ui/core";
import EditAdNameFormatModal from "../../modals/EditAdNameFormat";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import { NotificationManager } from "react-notifications";

const Basic = (props) => {
  const [nameFormat, setNameFormat] = useState(
    Boolean(props.ad.personalInfo.displayNameFormat)
      ? props.ad.personalInfo.displayNameFormat
      : null
  );
  const [showModal, setShowModal] = useState(false);

  const NameFormat = (format) => {
    switch (format) {
      case "FIRST":
        return "First name only";
      case "LAST":
        return "Last name only";
      case "FIRST_LAST":
        return "Full name";
      case "FIRST_LAST_CHAR1":
        return " First name and first letter of last name";
      case "FIRST_CHAR1_LAST":
        return " First letter of first name and full last name";
      default:
        return "-";
    }
  };

  const CloseModal = () => {
    setShowModal(false);
    setNameFormat(
      Boolean(props.ad.personalInfo.displayNameFormat)
        ? props.ad.personalInfo.displayNameFormat
        : null
    );
  };

  const HandleSave = () => {
    if (Boolean(nameFormat)) {
      const data = {
        personalInfo: {
          displayNameFormat: nameFormat,
        },
      };

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v1/admin/posts/${props.ad.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${isAdminOrOperatorL2()}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          NotificationManager.success("Post updated", "Success");
          props.getAd();
          setShowModal(false);
        })
        .catch((err) => {
          NotificationManager.error("Post updating failed", "Error");
          setShowModal(false);
        });
    } else {
      NotificationManager.error("Empty fields!", "Error");
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          className="post-details-con"
          style={{ background: "#f2f2f2" }}
        >
          <div className="row m-0">
            <div className="col p-0">
              <div className="review-col rev-col-left">
                <div className="con-row">
                  <div className="con-col">Member ID</div>
                  <div className="con-col">{props.ad.memberId}</div>
                </div>
                <div className="con-row">
                  <div className="con-col">Created At</div>
                  <div className="con-col">
                    {moment
                      .utc(props.ad.createdAt)
                      .local()
                      .format("DD-MMM-YYYY hh:mm a")}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">First Published</div>
                  <div className="con-col">
                    {props.ad.firstPublishedAt
                      ? moment
                          .utc(props.ad.firstPublishedAt)
                          .local()
                          .format("DD-MMM-YYYY hh:mm a")
                      : "-"}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">Latest Published</div>
                  <div className="con-col">
                    {props.ad.publishedAt
                      ? moment
                          .utc(props.ad.publishedAt)
                          .local()
                          .format("DD-MMM-YYYY hh:mm a")
                      : "-"}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">Name format</div>
                  <div className="con-col">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowModal(true)}
                    >
                      {NameFormat(props.ad.personalInfo.displayNameFormat)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="review-col rev-col-right">
                <div className="con-row">
                  <div className="con-col">Expires At</div>
                  <div className="con-col">
                    {props.ad.expiresAt
                      ? moment
                          .utc(props.ad.expiresAt)
                          .local()
                          .format("DD-MMM-YYYY hh:mm a")
                      : "-"}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">Free Renew</div>
                  <div className="con-col">
                    {props.ad.freeRenewDone ? "Done" : "Not yet"}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">Photos Added</div>
                  <div className="con-col">{props.ad.images.length}</div>
                </div>
                <div className="con-row">
                  <div className="con-col">Exclude From Search</div>
                  <div className="con-col">
                    {props.ad.excludeFromSearch ? "Yes" : "No"}
                  </div>
                </div>
                <div className="con-row">
                  <div className="con-col">Account Owner</div>
                  <div
                    className="con-col"
                    style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                    onClick={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "AccountOwner",
                              "OwnerId",
                              "select",
                              props.ad.createdBy,
                              props.accountOwnershipOptions,
                              null,
                              null
                            );
                          }
                        : null
                    }
                  >
                    {props.ad.createdBy === "SELF"
                      ? "Self"
                      : "Parents / Family"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
      </Grid>

      <EditAdNameFormatModal
        showModal={showModal}
        nameFormat={nameFormat}
        updateNameFormat={setNameFormat}
        handleClose={CloseModal}
        handleSave={HandleSave}
      />
    </>
  );
};

export default Basic;
