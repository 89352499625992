import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import SendPayLinkCom from "../../components/admin/pay-link/SendPayLinkCom";

function SendPayLink() {
  return (
    <AdminLayout>
      <SendPayLinkCom />
    </AdminLayout>
  );
}

export default SendPayLink;
