import React from "react";
import classnames from "classnames";
import "react-phone-input-2/lib/style.css";

function Description(props) {
  return (
    <>
      <div className="form-row m-0">
        <div className="form-group col-12 px-sm-2 mb-3">
          <textarea
            className={classnames("form-control", {
              "is-invalid": props.form.additionalInfoErr,
            })}
            name="additionalInfo"
            onChange={props.handleChange}
            value={props.form.additionalInfo}
            rows="5"
            placeholder="Optional"
          />
          <div className="invalid-feedback">{props.form.additionalInfoErr}</div>
        </div>
      </div>
    </>
  );
}

export default Description;
