import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import FindAddressesCom from "../../components/admin/addresses/FindAddressesCom";

function FindAddresses() {
  return (
    <AdminLayout>
      <FindAddressesCom />
    </AdminLayout>
  );
}

export default FindAddresses;
