// import packages
import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import ConfirmSendSMSModal from "../modals/ConfirmSendSMSModal";

function SendSMSCom(props) {
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePhone = (phone) => {
    setPhone(`+${phone}`);
    setPhoneErr("");
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
    setMessageErr("");
  }

  // validate
  const validate = () => {
    let phoneErr = "";
    let messageErr = "";
    if (phone.length < 4) {
      phoneErr = "Please fill phone number";
      setPhoneErr("Please fill phone number");
    }
    if (!message) {
      messageErr = "Message content is required";
      setMessageErr("Message content is required");
    }

    if (phoneErr || messageErr) {
      return false;
    } else {
      return true;
    }
  };

  const handleSend = () => {
    validate();
  };

  const sendSMS = () => {
    if (validate()) {
      setLoading(true);
      let data = qs.stringify({ phone : phone, message: message });
      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/operator/generic_sms`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setMessage("");
          setPhone("+94");
          NotificationManager.success("Sent successfully!", "Success");
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
          NotificationManager.error(err.response.data.message, "Error");
        });
    }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Send SMS" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="content py-3">
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="memberId">
                    Phone Number
                  </label>
                  <PhoneInput
                    country="lk"
                    value={phone}
                    enableSearch={true}
                    disableSearchIcon={true}
                    countryCodeEditable={false}
                    prefix="+"
                    onChange={(phone) => handlePhone(phone)}
                  />
                  <div style={{color:"#dc3545"}}>{phoneErr}</div>                  
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="memberId">
                    Message Content
                  </label>
                  <textarea
                    className="form-control"
                    name="additionalInfo"
                    onChange={(e) => handleChange(e)}
                    value={message}
                    rows="5"
                    placeholder="message"
                  />
                  <div style={{color:"#dc3545"}}>{messageErr}</div>
                </div>
              </div>
              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleSend}
                style={{ width: 100, float: "right" }}
                data-toggle="modal"
                data-target="#confirmSendSMSModal"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      {(message && phone.length > 3) ? <ConfirmSendSMSModal sendSMS={sendSMS} /> : null}
      <NotificationContainer />
    </>
  );
}

export default SendSMSCom;
