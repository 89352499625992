import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import _12FromTo24Hours from "12fromto24hours";
import classnames from "classnames";

import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAuthenticated } from "../../../../auth/auth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import DeleteAddressModal from "../../modals/DeleteAddressModal";

function Address(props) {
  const [memberId, setMemberId] = useState(props.ad.memberId);
  const [name, setName] = useState(props.ad.postalAddress ? props.ad.postalAddress.name : "");
  const [line1, setLine1] = useState(props.ad.postalAddress ? props.ad.postalAddress.line1 : "");
  const [line2, setLine2] = useState(props.ad.postalAddress ? props.ad.postalAddress.line2 : "");
  const [city, setCity] = useState(props.ad.postalAddress ? props.ad.postalAddress.city : "");
  const [countryCode, setCountryCode] = useState(props.ad.postalAddress ? props.ad.postalAddress.country.code : "LK");
  const [adminEdited, setAdminEdited] = useState(props.ad.postalAddress ? props.ad.postalAddress.adminEdited : false);
  const [nameErr, setNameErr] = useState("");
  const [line1Err, setLine1Err] = useState("");
  const [cityErr, setCityErr] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  const validate = () => {
    setNameErr("");
    setLine1Err("");
    setCityErr("");
    if (!name) {
      setNameErr("Name is required")
    }
    if (!line1) {
      setLine1Err("Line 1 is required")
    }
    if (!city) {
      setCityErr("City is required")
    }
    if (!name || !line1 || !city) {
      return false;
    } else {
      return true;
    }

  }

  const updateAddress = () => {
    if (validate()) {
      setUpdateLoading(true);
      const data = qs.stringify({
        memberId: memberId,
        name: name,
        line1: line1,
        line2: line2,
        city: city,
        countryCode: countryCode
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/admin/user_address`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setUpdateLoading(false);
          setAdminEdited(true);
          NotificationManager.success("Adress updated successfully!", "Success");
        })
        .catch((err) => {
          console.log(err);
          setUpdateLoading(false);
          NotificationManager.error("Address updating failed", "Error");
        });
    }
  }

  const handleDeleteAddress = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/admin/user_address/?memberId=${memberId}`,{
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        NotificationManager.success("Adress deleted successfully!", "Success");
        setName("");
        setLine1("");
        setLine2("");
        setCity("");
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Address deleting failed", "Error");
      });
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Postal Address
        </Typography>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left" style={{width: "100%"}}>
              <div className="con-row" style={{display: "flex", alignItems: "center"}}>
                <div className="con-col" style={{width: "20%"}}>Name</div>
                <div className="con-col">
                  <input
                    type="text"
                    className={classnames("form-control pr-5", {
                      "is-invalid": nameErr,
                    })}
                    placeholder="Name"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <div className="invalid-feedback">{nameErr}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left" style={{width: "100%"}}>
              <div className="con-row" style={{display: "flex", alignItems: "center", background: "#fff"}}>
                <div className="con-col" style={{width: "20%"}}>Line 1</div>
                <div className="con-col">
                  <input
                    type="text"
                    className={classnames("form-control pr-5", {
                      "is-invalid": line1Err,
                    })}
                    placeholder="Line 1"
                    value={line1}
                    name="line1"
                    onChange={(e) => setLine1(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <div className="invalid-feedback">{line1Err}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left" style={{width: "100%"}}>
              <div className="con-row" style={{display: "flex", alignItems: "center"}}>
                <div className="con-col" style={{width: "20%"}}>Line 2</div>
                <div className="con-col">
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="Line 2"
                    value={line2}
                    name="line2"
                    onChange={(e) => setLine2(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left" style={{width: "100%"}}>
              <div className="con-row" style={{display: "flex", alignItems: "center", background: "#fff"}}>
                <div className="con-col" style={{width: "20%"}}>City</div>
                <div className="con-col">
                  <input
                    type="text"
                    className={classnames("form-control pr-5", {
                      "is-invalid": cityErr,
                    })}
                    placeholder="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city"
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <div className="invalid-feedback">{cityErr}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left" style={{width: "100%"}}>
              <div className="con-row" style={{display: "flex", alignItems: "center"}}>
                <div className="con-col" style={{width: "20%"}}>Admin Edited</div>
                <div className="con-col">
                  {(adminEdited) ? "Yes" : "No"}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0">
            <div className="col p-0">
              {isAdminOrOperatorL2() && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-4 ml-2"
                    style={{ float: "right" }}
                    data-toggle="modal"
                    data-target="#deleteAddressModal"
                  >
                    Delete Address
                  </button>
                  {updateLoading ?
                    (<button
                      type="button"
                      className="btn btn-success mt-4"
                      style={{ float: "right" }}
                    >
                      Update Address
                      &nbsp;<span className="spinner-border spinner-border-sm"></span>
                    </button>)
                  :
                  (<button
                    type="button"
                    className="btn btn-success mt-4"
                    onClick={updateAddress}
                    style={{ float: "right" }}
                  >
                    Update Address
                  </button>)}
                </>
              )}
            </div>
          </div>
      </Paper>
      <NotificationContainer />
      <DeleteAddressModal handleDeleteAddress={handleDeleteAddress}/>
    </Grid>
  );
}

export default Address;