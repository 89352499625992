import React, { useState, useEffect } from "react";
import axios from "axios";

function Personal(props) {
  const [adData, setAdData] = useState({
    religionId: "",
    ethnicityId: "",
    civilStatusId: "",
    residentCountry: "",
    residentCountryCodeOther: "",
    residentRegionId: "",
    visaTypeId: "",
    educationLevelId: null,
    professionId: null,
    foodPreferenceId: "",
  });

  useEffect(() => {
    // filtes
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const sortedReligions = res.data.religions;

        const sortedEthnicities = res.data.ethnicities;

        const sortedStatuses = res.data.civilStatuses;

        const sortedVisa = res.data.visaStatuses;

        const sortedEdu = res.data.educationLevels;

        const sortedPro = res.data.professions;

        const sortedFood = res.data.foodPreferences;

        // ethnicity
        const ethnicityId = sortedEthnicities.filter(
          (filter) => filter.id === props.data.post.personalInfo.ethnicityId
        );

        // religion
        const religionId = sortedReligions.filter(
          (filter) => filter.id === props.data.post.personalInfo.religionId
        );

        // civil status
        const civilStatusId = sortedStatuses.filter(
          (filter) => filter.id === props.data.post.personalInfo.civilStatusId
        );

        // resident country
        const resCountry = res.data.countries.filter(
          (filter) =>
            filter.code === props.data.post.personalInfo.residentCountryCode
        );

        // visa type
        const visaTypeId = sortedVisa.filter(
          (filter) => filter.id === props.data.post.personalInfo.visaTypeId
        );

        // edu
        const educationLevelId = sortedEdu.filter(
          (filter) =>
            filter.id === props.data.post.personalInfo.educationLevelId
        );

        // profession
        const professionId = sortedPro.filter(
          (filter) => filter.id === props.data.post.personalInfo.professionId
        );

        // food
        const foodPreferenceId = sortedFood.filter(
          (filter) =>
            filter.id === props.data.post.personalInfo.foodPreferenceId
        );

        setAdData((adData) => ({
          ...adData,
          ethnicityId: ethnicityId[0].name,
          religionId: religionId[0].name,
          civilStatusId: civilStatusId[0].status,
          residentCountry: resCountry[0].name,
          visaTypeId: visaTypeId[0].visaType,
          educationLevelId: educationLevelId[0].level,
          professionId: professionId[0].name,
          foodPreferenceId: foodPreferenceId[0].type,
        }));
      })
      .catch((err) => console.log(err));

    if (props.data.post.personalInfo.residentCountryCode === "ZZ") {
      // country
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
        .then((res) => {
          const other = res.data.filter(
            (el) =>
              el.code === props.data.post.personalInfo.residentCountryCodeOther
          );
          setAdData((adData) => ({
            ...adData,
            residentCountryOther: other[0].name,
          }));
        })
        .catch((err) => console.log(err));
    } else {
      // regions
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=${props.data.post.personalInfo.residentCountryCode}`
        )
        .then((res) => {
          const residentRegionId = res.data.filter(
            (filter) =>
              filter.id === props.data.post.personalInfo.residentRegionId
          );

          setAdData((adData) => ({
            ...adData,
            residentRegionId: residentRegionId[0].regionName,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [props.data.post]);

  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Personal Info</p>
          </div>
        </div>
        <div className="content" style={{ paddingTop: 10 }}>
          <p className="form-heading">Basic</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">First Name</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.fname}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Last Name</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.lname}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Birthdate</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.dob}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Gender</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.gender === "MALE"
                    ? "Male"
                    : "Female"}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Ethnicity</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.ethnicityId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Religion</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.religionId}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Caste</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.caste
                    ? props.data.post.personalInfo.caste
                    : "-"}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Civil Status</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.civilStatusId}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Height</div>
                <div className="col-7 p-2 font-weight-300">
                  {Math.floor(
                    Math.round(props.data.post.personalInfo.height / 2.54) / 12
                  )}
                  '{" "}
                  {Math.round(props.data.post.personalInfo.height / 2.54) % 12}"
                </div>
              </div>
            </div>
            {(adData.civilStatusId !== "never_married" && props.data.post.personalInfo.children) ?
              <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
                <div className="row m-0">
                  <div className="col-5 p-2">Children</div>
                  <div className="col-7 p-2 font-weight-300">
                    {props.data.post.personalInfo.children === "YES" && ("Yes")}
                    {props.data.post.personalInfo.children === "UNDISCLOSED" && ("Prefer not to mention")}
                    {props.data.post.personalInfo.children === "NO" && ("No")}
                  </div>
                </div>
              </div>
              :
              <div className="col-sm p-0 ml-sm-1 mb-1 background-dark bg-white"></div>
            }
          </div>

          <p className="form-heading">Residency</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Country of Residence</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.residentCountryCode === "ZZ"
                    ? adData.residentCountryOther
                    : adData.residentCountry}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">State / District</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.residentCountryCode === "ZZ"
                    ? "-"
                    : adData.residentRegionId}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">City</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.residentCity}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              {props.data.post.personalInfo.residentCountryCode !== "LK" && (
                <div className="row m-0">
                  <div className="col-5 p-2">Visa Type</div>
                  <div className="col-7 p-2 font-weight-300">
                    {adData.visaTypeId}
                  </div>
                </div>
              )}
            </div>
          </div>

          <p className="form-heading">Education & Profession</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Education Level</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.educationLevelId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Profession</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.professionId === "Other"
                    ? props.data.post.personalInfo.otherProfession
                    : adData.professionId}
                </div>
              </div>
            </div>
          </div>

          <p className="form-heading">Habits</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Drinking</div>
                <div className="col-7 p-2 font-weight-300 text-capitalize">
                  {props.data.post.personalInfo.drinking === "YES"
                    ? "Yes"
                    : props.data.post.personalInfo.drinking === "No"
                    ? "No"
                    : "Occasionally"}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Smoking</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.personalInfo.smoking === "YES"
                    ? "Yes"
                    : "No"}
                </div>
              </div>
            </div>
          </div>

          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">food Preference</div>
                <div className="col-7 p-2 font-weight-300">
                  {adData.foodPreferenceId}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark bg-white"></div>
          </div>

          <p className="form-heading">Ad Description</p>
          <div className="row m-0 px-2 mb-3 review-row">
            <div className="col-sm p-0 mb-1 background-dark">
              <div className="row m-0">
                <div className="col p-2">
                  {props.data.post.personalInfo.additionalInfo
                    ? props.data.post.personalInfo.additionalInfo
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Personal;
