import { VIRTUAL_POST_DATA } from "../actions/types";

const initialState = {
  data: {},
};

const virtualAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIRTUAL_POST_DATA:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default virtualAdReducer;
