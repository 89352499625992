import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PostCom from "../../components/admin/post/PostCom";

function Post() {
  return (
    <AdminLayout>
      <PostCom />
    </AdminLayout>
  );
}

export default Post;
