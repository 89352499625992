import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import AddReviewCom from "../components/admin/reviews/AddReviewCom";

function SendBankDetails() {
  return (
    <AdminLayout>
      <AddReviewCom />
    </AdminLayout>
  );
}

export default SendBankDetails;
