import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import _12FromTo24Hours from "12fromto24hours";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function NICImageUpload(props) {
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [src1, setSrc1] = useState("");
  const [src2, setSrc2] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [image1Uploading, setImage1Uploading] = useState(false);
  const [image2Uploading, setImage2Uploading] = useState(false);

  const handleUpload = (e) => {    
    setSubmitLoading(true);
    let imageName = e.target.name;
    if (imageName === 'photo1') {
      setImage1Uploading(true);
    } else {
      setImage2Uploading(true);
    }
    let imageFile = new FormData();
    imageFile.append("file", e.target.files[0]);
    var src = URL.createObjectURL(e.target.files[0]);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/generic/upload`,
        imageFile,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${isAuthenticatedBoth()}`
          },
        }
      )
      .then((res) => {
        if (imageName === 'photo1') {
          setSrc1(src);
          setImage1(res.data.name);
          setImage1Uploading(false);
        } else {
          setSrc2(src);
          setImage2(res.data.name);
          setImage2Uploading(false);
        }
        setSubmitLoading(false);
      })
      .catch((err) => {
        setSubmitLoading(false);
        console.log(err);
      });
  }

  const sendVerification = () => {
    setSubmitLoading(true);
    let objectData;
    if (image2) {
      objectData = qs.stringify({
        nicImageId : image1,
        nicImage2Id : image2,
      })
    } else {
      objectData = qs.stringify({
        nicImageId : image1,
      })
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/operator_posts/${props.ad.id}/id_verifications`,
        objectData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`
          },
        }
      )
      .then((res) => {
        NotificationManager.success("NIC verification request sent!", "Success");
        props.getAd();
        setSubmitLoading(false);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Action failed!", "Error");
        setSubmitLoading(false);
      });
  }

  const resetData = () => {
    setImage1("");
    setImage2("");
    setSrc1("");
    setSrc2("");
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          NIC Verification Request
        </Typography>
        <div className="row m-0">
          <div className="col p-0">
            <div style={{display:"flex"}}>
              <PhotoProvider>
                  {image1 &&
                    <div>
                      <PhotoConsumer src={src1}
                      >
                        <img
                          src={src1}
                          alt="horescope images"
                          className="photos"
                          style={{marginTop: '0'}}
                        />
                      </PhotoConsumer>
                    </div>}
                    {(!image1 && isAdminOrOperatorL2()) &&
                      <input
                        type="file"
                        id="nic1Upload"
                        className="imgUpload"
                        name="photo1"
                        onChange={handleUpload}
                        accept="image/x-png,image/jpeg,image/jpg"
                      />
                    }
                    {(!image1 && isAdminOrOperatorL2()) && (
                      <label className="img-upload-label" htmlFor="nic1Upload" style={{margin: "0 15px 0 0", height: "120px", width: "120px"}}>
                        {image1Uploading ? <span className="spinner-border spinner-border-sm"></span> : <i class="fas fa-plus add-img-icon"></i>}
                      </label>
                    )}
                </PhotoProvider>
                <PhotoProvider>
                  {image2 &&
                    <div>
                      <PhotoConsumer src={src2}
                      >
                        <img
                          src={src2}
                          alt="horescope images"
                          className="photos"
                          style={{marginTop: '0'}}
                        />
                      </PhotoConsumer>
                    </div>}
                    {(!image2 && isAdminOrOperatorL2()) &&
                      <input
                        type="file"
                        id="nic1Upload2"
                        className="imgUpload"
                        name="photo2"
                        onChange={handleUpload}
                        accept="image/x-png,image/jpeg,image/jpg"
                      />
                    }
                    {(!image2 && isAdminOrOperatorL2()) && (
                      <label className="img-upload-label" htmlFor="nic1Upload2" style={{margin: "0 15px 0 0", height: "120px", width: "120px"}}>
                        {image2Uploading ? <span className="spinner-border spinner-border-sm"></span> : <i class="fas fa-plus add-img-icon"></i>}
                      </label>
                    )}
                </PhotoProvider>
            </div>
          </div>
        </div>
        <div className="row m-0">
            <div className="col p-0">
              <>
                {(image1 || image2) &&
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-4 ml-2"
                    style={{ float: "right", minWidth: '150px' }}
                    onClick={submitLoading ? null : resetData}
                  >
                    {submitLoading ? <span className="spinner-border spinner-border-sm"></span> : 'Cancel'}
                  </button>
                }
                {(image1) &&
                  <button
                    type="button"
                    className="btn btn-success mt-4"
                    onClick={submitLoading ? null : sendVerification}
                    style={{ float: "right", minWidth: '150px' }}
                  >
                    {submitLoading ? <span className="spinner-border spinner-border-sm"></span> : 'Send Request'}
                  </button>
                }
              </>
            </div>
          </div>
      </Paper>
    </Grid>
  );
}

export default NICImageUpload;
