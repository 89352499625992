import React from "react";

function NextBtn(props) {
  return (
    <div className="ad-form-sec">
      <button
        type="button"
        className="btn-submit mt-0"
        onClick={props.handleSubmit}
        style={{ width: "auto", float: "right" }}
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Next
      </button>
      {props.requiredAllFieldsErr && (
        <p
          style={{
            fontSize: "12px",
            color: "rgb(244, 67, 54)",
            marginRight: "12px",
            position: "relative",
            textAlign: "right",
            right: "-64px",
            bottom: "-45px",
          }}
        >
          * Fill all required fields
        </p>
      )}
    </div>
  );
}

export default NextBtn;
