import React from "react";

import Basic from "../ad-components/Basic";
import Residency from "../ad-components/Residency";
import Education from "../ad-components/Education";
import Habits from "../ad-components/Habits";

import Description from "../ad-components/Description";

function Personal(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Personal Info</p>
            <p className="info-desc">
              <span className="required-tag">*</span> Indicates required fields
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: 10 }}>
          <p className="form-heading">Basic</p>
          <Basic
            filters={props.filters}
            form={props.form}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Residency</p>
          <Residency
            filters={props.filters}
            form={props.form}
            regions={props.regions}
            handleChange={props.handleChange}
            handleChangeCountry={props.handleChangeCountry}
          />

          <p className="form-heading">Education & Profession</p>
          <Education
            filters={props.filters}
            form={props.form}
            showOtherProf={props.showOtherProf}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Habits</p>
          <Habits
            filters={props.filters}
            form={props.form}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Ad Description</p>
          <Description form={props.form} handleChange={props.handleChange} />
        </div>
      </form>
    </div>
  );
}

export default Personal;
