// import packages
import React from "react";

import { connect } from "react-redux";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import StepperVirtual from "../stepper/StepperVirtual";
import Personal from "../create-ad/review-components/Personal";
import Parent from "../create-ad/review-components/Parent";
import Private from "../create-ad/review-components/Private";
import Payment from "../create-ad/review-components/Payment";
import ConfirmBtnVirtual from "../create-ad/review-components/ConfirmBtnVirtual";

function ReviewAdVirtualAgentCom(props) {
  return (
    <div className="content-col">
      <Breadcrumb mainTitle="virtual Agent" title="Create Ad" />

      <div className="container-fluid inner-content">
        <StepperVirtual />

        <div className="ad-form-sec">
          {!props.isEmptyPost && (
            <>
              <Personal data={props.data} />
              <Parent data={props.data} />
              <Private data={props.data} />
              <Payment data={props.data} />
              <ConfirmBtnVirtual />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.virtualAd.data,
    isEmptyPost:
      Object.keys(state.virtualAd.data).length === 0 &&
      state.virtualAd.data.constructor === Object,
  };
};

export default connect(mapStateToProps, null)(ReviewAdVirtualAgentCom);
