import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import Filter from "../follow-ups/Filter";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";

function AllAgentAdsCom() {
  const [ads, setAds] = useState([]);
  const [displaying, setDisplaying] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [totAds, setTotAds] = useState(0);
  const [loading, setLoading] = useState(true);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [search, setSearch] = useState("");
  const [operators, setOperators] = useState([]);
  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    handleSearchFunc(from, to);
  }, []);

  useEffect(() => {
    if (isAdminOrOperatorL2()) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/admin/operators`, {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          },
        })
        .then((res) => {
          setOperators(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleSearchBtn = (from, to) => {
    setPage(1);
    handleSearchFunc(from, to, search);
  };

  const handleFilter = (search) => {
    setSearch(search)
  };

  const handleSearchFunc = (from, to) => {
    var fromDate = moment(from, "YYYY-MM-DD");
    var toDate = moment(to, "YYYY-MM-DD");
    var filters = "";
    if (search) {
      filters = "?memberId="+search;
      if (!fromDate.isValid() || !toDate.isValid()) {
        if (!fromDate.isValid() && !toDate.isValid()) {
          filters = filters;
        } else if (!fromDate.isValid()) {
          filters = filters+"&to="+to;
        } else {
          filters = filters+"&from="+from;
        }
      } else {
        filters = filters+"&from="+from+"&to="+to;
      }
    } else {
      if (!fromDate.isValid() || !toDate.isValid()) {
        if (!fromDate.isValid() && !toDate.isValid()) {
          filters = filters;
        } else if (!fromDate.isValid()) {
          filters = `?to=${to}`;
        } else {
          filters = `?from=${from}`;
        }
      } else {
        filters = `?from=${from}&to=${to}`;
      }
    }
    setLoading(true);
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/operator_posts/all${filters}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setPage(1);
        setAds(res.data)
        setTotAds(res.data.length)
        setLoading(false);        
        setNoOfPages(Math.ceil(res.data.length/itemsPerPage));
        setDisplaying(res.data.slice(0,itemsPerPage));
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAds([]);
        setTotAds(0);
        setNoOfPages(0);
        setDisplaying([]);
      });
  };

  const handlePagination = (event, value) => {
    setDisplaying(ads.slice((value-1)*itemsPerPage,value*itemsPerPage));
    setPage(value);
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="Ads" title="All Agent Ads" />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="row m-0">
              <div className="col p-0" style={{marginBottom: "-40px", marginTop: "20px", width: "505px"}}>
                <form
                  style={{
                    display: "block",
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 0,
                  }}
                >
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <div
                        className="input-group mb-0"
                        style={{ width: "350px" }}
                      >
                        {isAdminOrOperatorL2() ?
                          <select value={search} onChange={(e) => handleFilter(e.target.value)} className="form-control pr-5">
                            <option value=""> Select an operator </option>
                            {operators.length && (
                              operators.map((el, i) => (
                                <option value={el.memberId}>{el.memberId} - {el.name}</option>
                              ))
                            )}
                          </select>
                          :
                          <input
                            type="text"
                            className="form-control pr-5"
                            placeholder="Operator Member ID"
                            value={search}
                            onChange={(e) => handleFilter(e.target.value)}
                            autoComplete="off"
                            style={{ borderRadius: "5px" }}
                          />
                        }
                        {/* <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "8px",
                            color: "#949494",
                            zIndex: 3,
                          }}
                        ></i> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <Filter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              handleSearchBtn={handleSearchBtn}
              loading={loading}
            />
            <div className="row m-0 mt-3">
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <p style={{ fontSize: 14, marginBottom: 10 }}>
                  {ads.length > 0
                    ? 
                    page * itemsPerPage > totAds
                      ?
                      `Showing ${(page - 1) * itemsPerPage} - ${totAds} out of ${totAds} results`
                      :
                      `Showing ${(page - 1) * itemsPerPage} - ${page * itemsPerPage} out of ${totAds} results`
                    :
                    "No results"}
                </p>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Created Date</th>
                        <th className="border-0">Ad ID</th>
                        <th className="border-0">Post ID</th>
                        <th className="border-0">Display Name</th>
                        <th className="border-0">status</th>
                        <th className="border-0">Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ads.length ? (
                        displaying.map((el, i) => (
                          <tr key={i}
                            style={{cursor: 'pointer'}}
                            onClick={() => history.push(`/post?id=${el.id}`)}
                          >
                            <td width="230" style={{display:"flex"}}>
                              {el.idVerified ? <i style={{color:"#2979ff", fontSize:"12px", marginLeft:"6px"}} className="fas fa-check-circle"></i> : null}
                              <p className="mb-0" style={{ width: 200 }}>
                                {moment
                                  .utc(el.createdAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </p>
                            </td>
                            <td width="150">{el.memberId}</td>
                            <td width="150">{el.id}</td>
                            <td width="165">
                              {el.personalInfo.displayName}
                            </td>
                            <td width="225">{el.status}</td>
                            <td width="150">{el.phone ? el.phone : "-"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" width="480">
                            No Recods
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {noOfPages > 1 && (
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handlePagination}
                        className="pagi"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AllAgentAdsCom;
