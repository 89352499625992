import React from "react";

import Horoscope from "../ad-components/Horoscope";
import Contact from "../ad-components/Contact";
import OfflineResponses from "../ad-components/OfflineResponses";
import AccountInfo from "../ad-components/AccountInfo";

function Private(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Private Info</p>
            <p className="info-desc">
              <span className="required-tag">*</span> Indicates required fields
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: 10 }}>
          <p className="form-heading">Horoscope Info</p>
          <Horoscope
            filters={props.filters}
            form={props.form}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Contact Details</p>
          <Contact
            filters={props.filters}
            form={props.form}
            phone={props.phone}
            handlePhone={props.handlePhone}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Offline Responses</p>
          <OfflineResponses
            filters={props.filters}
            form={props.form}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Account Info</p>
          <AccountInfo
            filters={props.filters}
            form={props.form}
            handleChange={props.handleChange}
          />
        </div>
      </form>
    </div>
  );
}

export default Private;
