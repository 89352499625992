import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import Config from "../../../../config";
import { isEmpty } from "lodash";
import GenericApi from "../../../../services/generics";

const Parent = (props) => {
  const [father, setFather] = useState({});
  const [mother, setMother] = useState({});
  const [fatherCommunities, setFatherCommunities] = useState([]);

  const { ad: advertisement } = props;

  useEffect(() => {
    if (isEmpty(advertisement)) {
      return;
    }

    const parentInfo = advertisement.parentInfo ?? [];
    if (!isEmpty(parentInfo)) {
      const father =
        parentInfo.find((current) => current.type === "FATHER") ?? {};
      const mother =
        parentInfo.find((current) => current.type === "MOTHER") ?? {};

      if (!isEmpty(father)) {
        const religionId = father.religion?.id;
        if (!isEmpty(religionId)) {
          GenericApi.getCommunitiesByReligionId({ religionId }).then(
            (response) => {
              if (!response.success) {
                return; // TODO: error handling
              }
              setFatherCommunities(response.body);
            }
          );
        }
      }

      setFather(father);
      setMother(mother);
    }
  }, [advertisement]);

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        style={{ minHeight: "20vh", position: "relative" }}
        className="post-details-con"
      >
        <Typography variant="h6" className="heading">
          Parents' Info
        </Typography>

        <div className="row m-0">
          <div className="col p-0">
            <div className="review-col rev-col-left">
              <p className="review-sub-sec-heading" style={{ paddingLeft: 0 }}>
                Father
              </p>
            </div>
            <div className="review-col desktop-mother-col rev-col-right">
              <p className="review-sub-sec-heading" style={{ paddingLeft: 0 }}>
                Mother
              </p>
            </div>

            <div
              className="review-col rev-col-left"
              style={{ marginBottom: 15 }}
            >
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
                <div className="con-row">
                  <div className="con-col">Ethnicity</div>
                  <div
                    className="con-col"
                    style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                    onClick={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Father's Ethnicity",
                              "ethnicityId",
                              "select",
                              father?.ethnicity?.id,
                              props.ethnicityOptions,
                              "parentInfo",
                              "FATHER"
                            );
                          }
                        : null
                    }
                  >
                    {father?.ethnicity?.name}
                  </div>
                </div>
              )}

              <div className="con-row">
                <div className="con-col">Religion</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openEditModal(
                            "Father's Religion",
                            "religionId",
                            "select",
                            father.religion.id,
                            props.religionOptions,
                            "parentInfo",
                            "FATHER"
                          );
                        }
                      : null
                  }
                >
                  {father?.religion?.name}
                </div>
              </div>
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
                <div className="con-row">
                  <div className="con-col">Caste</div>
                  <div
                    className="con-col"
                    style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                    onClick={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Father's Caste",
                              "caste",
                              "text",
                              father.caste ? father.caste : "",
                              [],
                              "parentInfo",
                              "FATHER"
                            );
                          }
                        : null
                    }
                  >
                    {father.caste ? father.caste : "-"}
                  </div>
                </div>
              )}

              <div className="con-row">
                <div className="con-col">Profession</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openParentsProfessionModal(
                            "parentInfo",
                            "FATHER",
                            "professionId",
                            "otherProfession",
                            father?.profession?.id
                          );
                        }
                      : null
                  }
                >
                  {father.profession
                    ? father.profession.name === "Other"
                      ? father.professionOther
                      : father.profession.name
                    : "-"}
                </div>
              </div>
              <div className="con-row">
                <div className="con-col">Country of Residence</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={() => {
                    if (!isAdminOrOperatorL2()) {
                      return;
                    }
                    props.openEditModal(
                      "Father's Community",
                      "residentCountryCode",
                      "select",
                      father.residentCountry?.code ?? "",
                      props.countryOptions,
                      "parentInfo",
                      "FATHER",
                      "id",
                      "type"
                    );
                  }}
                >
                  {father.residentCountry ? father.residentCountry.name : "-"}
                </div>
              </div>

              <div className="con-row">
                <div className="con-col">Additional Info</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openEditModal(
                            "Father's Additional Info",
                            "additionalInfo",
                            "text",
                            father.additionalInfo ? father.additionalInfo : "",
                            [],
                            "parentInfo",
                            "FATHER"
                          );
                        }
                      : null
                  }
                >
                  {father.additionalInfo ? father.additionalInfo : "-"}
                </div>
              </div>
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
                <div>
                  <div className="con-row">
                    <div className="con-col">Community</div>
                    <div
                      className="con-col"
                      style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                      onClick={(e) => {
                        if (!isAdminOrOperatorL2()) {
                          return;
                        }
                        props.openEditModal(
                          "Father's Community",
                          "communityId",
                          "select",
                          father?.community?.id ?? "",
                          fatherCommunities,
                          "parentInfo",
                          "FATHER",
                          "id",
                          "name"
                        );
                      }}
                    >
                      {father?.community?.name || "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Sub Community</div>
                    <div
                      className="con-col"
                      style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                      onClick={
                        isAdminOrOperatorL2()
                          ? (e) => {
                              props.openEditModal(
                                "Father's Sub Community",
                                "subCommunity",
                                "text",
                                father?.subCommunity || "-",
                                [],
                                "parentInfo",
                                "FATHER"
                              );
                            }
                          : null
                      }
                    >
                      {father?.subCommunity || "-"}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="review-col mobile-mother-col rev-col-left">
              <p
                className="review-sub-sec-heading"
                style={{ paddingLeft: 0, marginTop: 10 }}
              >
                Mother
              </p>
            </div>

            <div className="review-col rev-col-right">
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
                <div className="con-row">
                  <div className="con-col">Ethnicity</div>
                  <div
                    className="con-col"
                    style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                    onClick={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Mother's Ethnicity",
                              "ethnicityId",
                              "select",
                              mother?.ethnicity?.id,
                              props.ethnicityOptions,
                              "parentInfo",
                              "MOTHER"
                            );
                          }
                        : null
                    }
                  >
                    {mother?.ethnicity?.name}
                  </div>
                </div>
              )}

              <div className="con-row">
                <div className="con-col">Religion</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openEditModal(
                            "MOTHER's Religion",
                            "religionId",
                            "select",
                            mother.religion.id,
                            props.religionOptions,
                            "parentInfo",
                            "MOTHER"
                          );
                        }
                      : null
                  }
                >
                  {mother?.religion?.name}
                </div>
              </div>
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
                <div className="con-row">
                  <div className="con-col">Caste</div>
                  <div
                    className="con-col"
                    style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                    onClick={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Mother's Caste",
                              "caste",
                              "text",
                              mother.caste ? mother.caste : "",
                              [],
                              "parentInfo",
                              "MOTHER"
                            );
                          }
                        : null
                    }
                  >
                    {mother?.caste || "-"}
                  </div>
                </div>
              )}

              <div className="con-row">
                <div className="con-col">Profession</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openParentsProfessionModal(
                            "parentInfo",
                            "MOTHER",
                            "professionId",
                            "otherProfession",
                            mother?.profession?.id
                          );
                        }
                      : null
                  }
                >
                  {mother.profession
                    ? mother.profession.name === "Other"
                      ? mother.professionOther
                      : mother.profession.name
                    : "-"}
                </div>
              </div>
              <div className="con-row">
                <div className="con-col">Country of Residence</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={(e) => {
                    if (!isAdminOrOperatorL2()) {
                      return;
                    }
                    props.openEditModal(
                      "Mother's Community",
                      "residentCountryCode",
                      "select",
                      mother.residentCountry?.code ?? "",
                      props.countryOptions,
                      "parentInfo",
                      "MOTHER",
                      "id",
                      "type"
                    );
                  }}
                >
                  {mother.residentCountry ? mother.residentCountry.name : "-"}
                </div>
              </div>

              <div className="con-row">
                <div className="con-col">Additional Info</div>
                <div
                  className="con-col"
                  style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                  onClick={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openEditModal(
                            "Mother's Additional Info",
                            "additionalInfo",
                            "text",
                            mother.additionalInfo ? mother.additionalInfo : "",
                            [],
                            "parentInfo",
                            "MOTHER"
                          );
                        }
                      : null
                  }
                >
                  {mother.additionalInfo ? mother.additionalInfo : "-"}
                </div>
              </div>
              {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
                <div>
                  <div className="con-row">
                    <div className="con-col">Community</div>
                    <div
                      className="con-col"
                      style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                      onClick={(e) => {
                        if (!isAdminOrOperatorL2()) {
                          return;
                        }
                        props.openEditModal(
                          "Mother's Community",
                          "communityId",
                          "select",
                          mother?.community?.id ?? "",
                          fatherCommunities,
                          "parentInfo",
                          "MOTHER",
                          "id",
                          "name"
                        );
                      }}
                    >
                      {mother?.community?.name || "-"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Sub Community</div>
                    <div
                      className="con-col"
                      style={isAdminOrOperatorL2() ? { cursor: "pointer" } : {}}
                      onClick={
                        isAdminOrOperatorL2()
                          ? (e) => {
                              props.openEditModal(
                                "Father's Sub Community",
                                "subCommunity",
                                "text",
                                mother?.subCommunity || "-",
                                [],
                                "parentInfo",
                                "MOTHER"
                              );
                            }
                          : null
                      }
                    >
                      {mother?.subCommunity || "-"}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default Parent;
