import PostServiceApi from "../../services/post-service";

export const getUserAccount = async ({params}) => {
  try {
    const response = await PostServiceApi.getUserAccount({params});
    if (!response.success) {
      throw new Error("Unable to get account");
    }
    return response;
  } catch (error) {
    throw error;
  }
};
