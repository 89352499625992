import React, { useEffect, useRef, useState } from "react";
import { isEmpty } from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";

const EditAdCountryModal = ({
  showModal,
  handleSave,
  countryOptions,
  visaStatusOptions,
  handleCloseCountryModal,
  regions,
  otherCountries,
  selectedCountryID,
  updateCountry,
  updateRegion,
  updateOtherCountry,
  updateCity,
  updateVisaStatus,
  currentCountry,
  currentRegion,
  currentCity,
  currentOtherCountry,
  currentVisaType,
}) => {
  const [errors, setErrors] = useState({
    country: "",
    region: "",
    city: "",
    visaStatus: "",
  });
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    updateVisaStatus(currentCountry === "LK" ? "citizen" : "");
    updateRegion("");
    updateCity("");
    updateOtherCountry("");
  }, [currentCountry]);

  const validateForm = () => {
    const newErrors = {};
    if (!selectedCountryID && isEmpty(currentOtherCountry))
      newErrors.country = "Country is required.";
    if (selectedCountryID && isEmpty(currentRegion))
      newErrors.region = "Region is required.";
    if (isEmpty(currentCity)) newErrors.city = "City is required.";
    if (currentCountry !== "LK" && isEmpty(currentVisaType))
      newErrors.visaStatus = "Visa status is required.";

    setErrors(newErrors);

    return isEmpty(newErrors);
  };

  const onSave = () => {
    if (validateForm()) {
      handleSave();
    }
  };

  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
      onClose={handleCloseCountryModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-header">
        Edit Profile Details
      </DialogTitle>

      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          {selectedCountryID ? (
            <label>Country </label>
          ) : (
            <label>Country Type</label>
          )}
          <select
            id="countrySelector"
            className="form-control"
            name="fCountryOfResidency"
            value={currentCountry}
            onChange={(e) => updateCountry(e.target.value)}
          >
            {countryOptions.map((option, i) => (
              <option key={i} value={option.id} selected>
                {option.type}
              </option>
            ))}
          </select>
        </DialogContentText>

        {selectedCountryID ? (
          <DialogContentText id="alert-dialog-description">
            <label>State / Region </label>
            <select
              className="form-control"
              name="stateOfCountry"
              value={currentRegion}
              onChange={(e) => updateRegion(e.target.value)}
              disabled={!Boolean(regions.length)}
            >
              <option value="" disabled hidden>
                Select an Option
              </option>
              {regions.map((option, i) => (
                <option key={i} value={option.id}>
                  {option.regionName}
                </option>
              ))}
            </select>
            {errors.region && (
              <Typography style={{ color: "red", fontSize: 12 }}>
                {errors.region}
              </Typography>
            )}
          </DialogContentText>
        ) : (
          <DialogContentText id="alert-dialog-description">
            <label>Select Country</label>
            <select
              className="form-control"
              name="stateOfCountry"
              value={currentOtherCountry}
              onChange={(e) => updateOtherCountry(e.target.value)}
            >
              <option value="" disabled hidden>
                Select an Option
              </option>
              {otherCountries.map((option, i) => (
                <option key={i} value={option.code}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.country && (
              <Typography style={{ color: "red", fontSize: 12 }}>
                {errors.country}
              </Typography>
            )}
          </DialogContentText>
        )}

        <DialogContentText id="alert-dialog-description">
          <label>City</label>
          <input
            type="text"
            className="form-control"
            style={{ marginBottom: "8px" }}
            value={currentCity}
            onChange={(e) => updateCity(e.target.value)}
          ></input>
          {errors.city && (
            <Typography style={{ color: "red", fontSize: 12 }}>
              {errors.city}
            </Typography>
          )}
        </DialogContentText>

        <DialogContentText id="alert-dialog-description">
          <label>Visa Status</label>
          <select
            className="form-control"
            name="visaStatusOfCountry"
            value={currentCountry === "LK" ? "citizen" : currentVisaType}
            onChange={(e) => updateVisaStatus(e.target.value)}
            disabled={currentCountry === "LK"}
          >
            <option value="citizen" hidden disabled>
              Citizen
            </option>
            {visaStatusOptions.map((option, i) => (
              <option key={i} value={option.id}>
                {option.type}
              </option>
            ))}
          </select>
          {errors.visaStatus && (
            <Typography style={{ color: "red", fontSize: 12 }}>
              {errors.visaStatus}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn"
          style={{ width: 95 }}
          onClick={() => handleCloseCountryModal()}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          style={{ width: 95 }}
          onClick={onSave}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdCountryModal;
