import React from "react";

import Father from "../ad-components/Father";
import Mother from "../ad-components/Mother";

function Parent(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Parent's Info</p>
            <p className="info-desc">
              <span className="required-tag">*</span> Indicates required fields
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: 10 }}>
          <p className="form-heading">Father</p>
          <Father
            filters={props.filters}
            form={props.form}
            fShowOtherProf={props.fShowOtherProf}
            handleChange={props.handleChange}
          />

          <p className="form-heading">Mother</p>
          <Mother
            filters={props.filters}
            form={props.form}
            mShowOtherProf={props.mShowOtherProf}
            handleChange={props.handleChange}
          />
        </div>
      </form>
    </div>
  );
}

export default Parent;
