import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import VerifyNICCom from "../../components/admin/verify-nic/VerifyNICCom";

function VerifyNIC() {
  return (
    <AdminLayout>
      <VerifyNICCom />
    </AdminLayout>
  );
}

export default VerifyNIC;
