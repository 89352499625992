import React, { useState, useEffect } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import DeleteSiblingModal from "../../modals/DeleteSiblingModal";
import AddSiblingModal from "../../modals/AddSiblingModal";

const Siblings = (props) => {
  const [deleteSiblingId, setDeleteSiblingId] = useState("");
  const handleDeleteSibling = () => {
    props.deleteSiblings(deleteSiblingId);    
  }

  const styles = {
    siblingNumber : {
      paddingLeft: 0, 
      marginTop: 0
    },
    deleteSiblingAccess : {
      cursor: "pointer", 
      textAlign: "right", 
      fontSize: "10px", 
      color: "#458af7"
    },
    deleteSibling : {
      textAlign: "right", 
      fontSize: "10px", 
      color: "#458af7"
    },
    addSiblingAccess : {
      cursor: "pointer", 
      textAlign: "left", 
      fontSize: "14px", 
      color: "#458af7", 
      textDecoration: "underline"
    },
    addSibling : {
      textAlign: "left", 
      fontSize: "14px", 
      color: "#458af7", 
      textDecoration: "underline"
    }
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        style={{ minHeight: "20vh", position: "relative" }}
        className="post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: "30px" }}
        >
          Siblings' Info
        </Typography>
        <div className="row m-0">
          <div className="col p-0">
            {props.ad?.siblingInfo.map((sibling, index) => (
              <>
                <div className="row m-0">
                  <div className="col p-0">
                    <div className="review-col rev-col-left">
                      <h6
                        className="review-sub-sec-heading"
                        // style={{ paddingLeft: 0, marginTop: 0 }}
                        style={styles.siblingNumber}
                      >
                        {`Sibling 0${index + 1}`}
                      </h6>
                    </div>
                  </div>
                </div>
                <div
                  className="review-col rev-col-left"
                  style={{ marginBottom: 15 }}
                >
                  <div className="con-row">
                    <div className="con-col">Relationship</div>
                    <div
                      className="con-col"
                    >
                      {sibling.relationship.charAt(0) +
                        sibling.relationship.substring(1).toLowerCase()}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Relationship Type</div>
                    <div
                      className="con-col"
                    >
                      {sibling.relationshipType.charAt(0) +
                        sibling.relationshipType.substring(1).toLowerCase()}
                    </div>
                  </div>
                </div>
                <div
                  className="review-col rev-col-right"
                  style={{ marginBottom: 15 }}
                >
                  <div className="con-row">
                    <div className="con-col">Married status</div>
                    <div
                      className="con-col"
                    >
                      {sibling.isMarried ? "Married" : "Unmarried"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">{sibling.professionOther ? "Other Profession" : "Profession"}</div>
                    <div
                      className="con-col"
                    >
                      {sibling.professionOther || sibling.profession?.name}
                    </div>
                  </div>
                  <div 
                    style={isAdminOrOperatorL2() ?  styles.deleteSiblingAccess  :  styles.deleteSibling }
                    onClick={() => setDeleteSiblingId(sibling.id)}
                    data-toggle="modal"
                    data-target="#deleteSiblingModal"
                  >
                    Delete Sibling
                  </div>
                </div>
                <hr style={{ marginTop: "-0.3rem" }} />
              </>
            ))}
            <div 
              style={isAdminOrOperatorL2() ? styles.addSiblingAccess : styles.addSibling}
              data-toggle="modal"
              data-target="#addSibilingsModal"
            >
              Add a Sibling
            </div>
          </div>
        </div>
      </Paper>
      <DeleteSiblingModal handleDeleteSibling={handleDeleteSibling} />
      <AddSiblingModal professionOptions={props.professionOptions} adid={props.ad.id} getAd={props.getAd}/>
    </Grid>
  );
};

export default Siblings;
