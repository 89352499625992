import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import axios from "axios";
import { isAuthenticatedBoth } from "../../../../../../auth/authBoth";
import { NotificationManager } from "react-notifications";

import "./style.css";

const PdfFormSection = ({
  setSelectedInterest,
  selectedInterest,
  handlePrint,
  adId,
}) => {
  const [interestType, setInterestType] = useState("matched");
  const [interestProfiles, setInterestProfiles] = useState([]);

  const [isFetchingProfiles, setIsFetchingProfiles] = useState(false);

  const fetchInterests = async () => {
    let URL = process.env.REACT_APP_API_URL;
    try {
      switch (interestType) {
        case "matched":
        case "unmatched":
          URL = `${URL}/v2/posts/${adId}/interests/matches?type=${interestType}`;
          break;
        default:
          URL = `${URL}/v2/posts/${adId}/interests/${interestType}`;
          break;
      }
      setIsFetchingProfiles(true);
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
          "Content-Type": "application/json",
        },
      });
      const interests =
        interestType === "new_matches" ? response.data : response.data.results;
      setInterestProfiles(interests);
      setSelectedInterest({});
    } catch (e) {
      console.error("Error! ", e);
      NotificationManager.error("Error Getting Profiles", "Error");
    } finally {
      setIsFetchingProfiles(false);
    }
  };

  useEffect(() => {
    fetchInterests();
  }, [interestType]);

  let filteredProfiles = [];

  if (!isEmpty(interestProfiles)) {
    filteredProfiles = interestProfiles.filter(
      (profile) => !profile.isDisabledProfile && !profile.archived
    );
  }

  return (
    <Grid container direction="column" style={{ rowGap: 16 }}>
      <Grid container direction="column" style={{ rowGap: 8 }}>
        <Typography style={{}}>Select the profile status</Typography>
        <Select
          value={interestType}
          onChange={(e) => setInterestType(e.target.value)}
          variant="outlined"
          className="half-width"
        >
          <MenuItem value="matched">Matched</MenuItem>
          <MenuItem value="unmatched">Unmatched</MenuItem>
          <MenuItem value="new_matches">new matches</MenuItem>
          <MenuItem value="received">Received</MenuItem>
          <MenuItem value="sent">Sent</MenuItem>
        </Select>
      </Grid>
      <Grid
        item
        direction="row"
        container
        alignItems="center"
        style={{ columnGap: 8 }}
      >
        <Typography>
          Select a profile
          {isFetchingProfiles && (
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              style={{ marginLeft: 8 }}
            />
          )}
        </Typography>
      </Grid>
      <Autocomplete
        options={filteredProfiles}
        getOptionLabel={(option) => option.post?.personalInfo?.displayName}
        className="half-width"
        onChange={(e, newInputValue) => {
          if (newInputValue == null) {
            setSelectedInterest({});
            return;
          }
          setSelectedInterest(newInputValue.post);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select" variant="outlined" />
        )}
      />
      <Grid
        container
        direction="row"
        className="preview-notice"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography className="preview-notice-heading">Preview</Typography>
          <Typography className="preview-notice-sub-heading">
            You can preview the profile here.
          </Typography>
        </Grid>
        <button
          type="button"
          class="btn btn-outline-dark"
          disabled={isEmpty(selectedInterest)}
          onClick={handlePrint}
        >
          Save PDF
        </button>
      </Grid>
    </Grid>
  );
};

export default PdfFormSection;
