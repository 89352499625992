import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import CreateAdVirtualAgentCom from "../../components/admin/virtual-agent/CreateAdVirtualAgentCom";

function CreateAdVirtualAgent(data) {
  return (
    <AdminLayout>
      <CreateAdVirtualAgentCom memberId={data.location.state?.memberId} />
    </AdminLayout>
  );
}

export default CreateAdVirtualAgent;
