import React, { useEffect, useState } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import Config from "../../../../config";
import DataField from "../../data-field";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
const Personal = (props) => {
  const [communities, setCommunities] = useState([]);
  useEffect(() => {
    getCommunityselections();
  }, [props.ad.personalInfo.religion.id]);

  const childernPreferenceType = props.ad.personalInfo.children;

  const childrenPreferenceTitle = {
    YES: "Yes",
    NO: "No",
    UNDISCLOSED: "Prefer not to mention",
  };

  const getCommunityselections = () => {
    try {
      let data = props.ad?.personalInfo?.religion?.id;
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/communities?religionId=${data}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setCommunities(res.data.map((el) => ({ type: el.name, id: el.id })));
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <Paper
      elevation={0}
      style={{ minHeight: "20vh", position: "relative" }}
      className="post-details-con"
    >
      <Typography variant="h6" className="heading">
        Personal Info
      </Typography>

      <Grid container direction="column" style={{ marginTop: 20 }}>
        <Typography className="review-sub-sec-heading">Basic </Typography>
        <Grid container style={{ marginTop: 20, columnGap: 20 }}>
          <Grid style={{ width: "48%" }}>
            {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
              <DataField
                fieldName={"Ethnicity"}
                fieldValue={props.ad?.personalInfo?.ethnicity?.name || "-"}
                fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Ethnicity",
                          "ethnicityId",
                          "select",
                          props.ad.personalInfo.ethnicity.id,
                          props.ethnicityOptions,
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              />
            )}
            <DataField
              fieldName={"Religion"}
              fieldValue={props.ad?.personalInfo?.religion?.name || "-"}
              fieldStyle={{ cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Religion",
                        "religionId",
                        "select",
                        props.ad.personalInfo.religion.id,
                        props.religionOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            <DataField
              fieldName={"Age"}
              fieldValue={props.ad.personalInfo.age}
              fieldStyle={{ background: "#f2f2f2" }}
            />
            <DataField
              fieldName={"Gender"}
              fieldValue={
                props.ad.personalInfo.gender === "FEMALE"
                  ? "Female"
                  : props.ad.personalInfo.gender === "MALE"
                  ? "Male"
                  : ""
              }
              fieldStyle={{ cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Gender",
                        "gender",
                        "select",
                        props.ad.personalInfo.gender,
                        [
                          { id: "FEMALE", type: "Female" },
                          { id: "MALE", type: "Male" },
                        ],
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            <DataField
              fieldName={"Differently abled"}
              fieldValue={props?.ad?.differentlyAbled ? "Yes" : "No"}
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Differently abled",
                        "differentlyAbled",
                        "select",
                        props?.ad?.differentlyAbled ? "yes" : "no",
                        [
                          { id: "yes", type: "Yes" },
                          { id: "no", type: "No" },
                        ],
                        null,
                        "SELF"
                      );
                    }
                  : null
              }
            />
            {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
              <DataField
                fieldName={"Community"}
                fieldValue={props.ad?.personalInfo?.community?.name || "-"}
                fieldStyle={{ cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2
                    ? () => {
                        props.openEditModal(
                          "Community/caste",
                          "communityId",
                          "select",
                          props.ad?.personalInfo?.community?.name || "-",
                          communities,
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              />
            )}
          </Grid>
          <Grid style={{ width: "45%" }}>
            {Config.CURRENT_PLATFORM === Config.PLATFORMS.LK && (
              <DataField
                fieldName={"Caste"}
                fieldValue={props.ad?.personalInfo?.caste || "-"}
                fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Caste",
                          "caste",
                          "text",
                          props.ad.personalInfo.caste
                            ? props.ad.personalInfo.caste
                            : "",
                          [],
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              />
            )}
            <DataField
              fieldName={"Civil Status"}
              fieldValue={props.ad?.personalInfo?.civilStatus?.status || "-"}
              fieldStyle={{ cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openCivilStatusModal(
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            <DataField
              fieldName={"Height"}
              fieldValue={
                `${Math.floor(
                  Math.round(props.ad.personalInfo.height / 2.54) / 12
                )} '` +
                " " +
                `${Math.round(props.ad.personalInfo.height / 2.54) % 12}"`
              }
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Height",
                        "height",
                        "select",
                        props.ad.personalInfo.height,
                        props.heightOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            {props.ad?.personalInfo?.civilStatus?.status !==
              "Never Married" && (
              <DataField
                fieldName={"Children"}
                fieldValue={childrenPreferenceTitle[childernPreferenceType]}
                fieldStyle={{ cursor: "pointer" }}
                onClickShowModal={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Children Status",
                          "children",
                          "select",
                          props.ad.personalInfo.children,
                          [
                            { id: "YES", type: "Yes" },
                            { id: "NO", type: "No" },
                            {
                              id: "UNDISCLOSED",
                              type: "Prefer not to mention",
                            },
                          ],
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              />
            )}
            {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
              <>
                <DataField
                  fieldName={"Mother Tounge"}
                  fieldValue={props.ad?.personalInfo?.motherTongue?.name}
                  fieldStyle={{ cursor: "pointer" }}
                  onClickShowModal={
                    isAdminOrOperatorL2()
                      ? (e) => {
                          props.openEditModal(
                            "Mother Tounge",
                            "motherTongueId",
                            "select",
                            props.ad?.personalInfo?.motherTongue?.name,
                            props.motherTongueOptions,
                            "personalInfo",
                            "SELF"
                          );
                        }
                      : null
                  }
                />
                {props.ad?.personalInfo?.religion.name === "Hindu" && (
                  <DataField
                    fieldName={"Gothra"}
                    fieldValue={props.ad?.personalInfo?.gothra?.name}
                    fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Gothra",
                              "gothraId",
                              "select",
                              props.ad?.personalInfo?.gothra?.name,
                              props.gothraOptions,
                              "personalInfo",
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                )}
                {Config.CURRENT_PLATFORM === Config.PLATFORMS.IN && (
                  <DataField
                    fieldName={"Sub community"}
                    fieldValue={props.ad?.personalInfo?.subCommunity}
                    fieldStyle={{ cursor: "pointer" }}
                    onClickShowModal={
                      isAdminOrOperatorL2()
                        ? (e) => {
                            props.openEditModal(
                              "Sub community/caste",
                              "subCommunity",
                              "text",
                              props.ad?.personalInfo?.subCommunity,
                              [],
                              "personalInfo",
                              "SELF"
                            );
                          }
                        : null
                    }
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ marginTop: 20 }}>
        <Typography className="review-sub-sec-heading">Residency </Typography>
        <Grid container style={{ marginTop: 20, columnGap: 20 }}>
          <Grid style={{ width: "48%" }}>
            <DataField
              fieldName={props.selectedCountryID ? "Country" : "Country Type"}
              fieldValue={props.ad?.personalInfo?.residentCountry?.name || "-"}
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openCountryDetailsModal(
                        "residentDetails",
                        props.ad.personalInfo?.residentCountry.code,
                        props.ad.personalInfo?.residentRegion?.id,
                        props.ad.personalInfo?.residentCity,
                        props.ad.personalInfo?.visaType?.id,
                        "personalInfo"
                      );
                    }
                  : null
              }
            />
            {props.ad.personalInfo.residentRegion &&
            !props.ad.personalInfo.residentCountryOther ? (
              <DataField
                fieldName={"State / District"}
                fieldValue={
                  props.ad.personalInfo.residentRegion
                    ? props.ad.personalInfo.residentRegion.regionName
                    : ""
                }
              />
            ) : props.ad.personalInfo.residentCountryOther ? (
              <DataField
                fieldName={"Resident Country"}
                fieldValue={props.ad.personalInfo.residentCountryOther.name}
              />
            ) : null}
          </Grid>

          <Grid style={{ width: "45%" }}>
            <DataField
              fieldName={"City"}
              fieldValue={props.ad?.personalInfo?.residentCity || "-"}
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Resident City",
                        "residentCity",
                        "text",
                        props.ad.personalInfo.residentCity,
                        [],
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            <DataField
              fieldName={"Visa Status"}
              fieldValue={props.ad.personalInfo.visaType.visaType}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ marginTop: 20 }}>
        <Typography className="review-sub-sec-heading">
          Education & Profession{" "}
        </Typography>
        <Grid container style={{ marginTop: 20, columnGap: 20 }}>
          <Grid style={{ width: "48%" }}>
            <DataField
              fieldName={"Education"}
              fieldValue={props.ad?.personalInfo?.educationLevel?.level || "-"}
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Education Level",
                        "educationLevelId",
                        "select",
                        props.ad.personalInfo.educationLevel.id,
                        props.educationLevelOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
          </Grid>

          <Grid style={{ width: "45%" }}>
            <DataField
              fieldName={"Profession"}
              fieldValue={
                props.ad?.personalInfo?.profession.name === "Other"
                  ? props.ad?.personalInfo?.professionOther
                  : props.ad?.personalInfo?.profession.name
              }
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Profession",
                        "professionId",
                        "select",
                        props.ad?.personalInfo?.profession.id,
                        props.professionOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" style={{ marginTop: 20 }}>
        <Typography className="review-sub-sec-heading">Habits</Typography>
        <Grid container style={{ marginTop: 20, columnGap: 20 }}>
          <Grid style={{ width: "48%" }}>
            <DataField
              fieldName={"Drinking"}
              fieldValue={
                props.ad.personalInfo.drinking === "YES"
                  ? "Yes"
                  : props.ad.personalInfo.drinking === "NO"
                  ? "No"
                  : "Occasionally"
              }
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Drinking",
                        "drinking",
                        "select",
                        props.ad.personalInfo.drinking,
                        props.drinkingOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
            <DataField
              fieldName={"Smoking"}
              fieldValue={
                props.ad.personalInfo.smoking === "YES" ? "Yes" : "No"
              }
              fieldStyle={{ cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Smoking",
                        "smoking",
                        "select",
                        props.ad.personalInfo.smoking,
                        props.smokingOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
          </Grid>

          <Grid style={{ width: "45%" }}>
            <DataField
              fieldName={"Food Preference"}
              fieldValue={props.ad?.personalInfo?.foodPreference?.type}
              fieldStyle={{ background: "#f2f2f2", cursor: "pointer" }}
              onClickShowModal={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Food Preference",
                        "foodPreferenceId",
                        "select",
                        props.ad.personalInfo.foodPreference.id,
                        props.foodPreferenceOptions,
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Personal;
