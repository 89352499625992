import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import DashboardCom from "../../components/admin/dashboard/DashboardCom";

function Dashboard() {
  return (
    <AdminLayout>
      <DashboardCom />
    </AdminLayout>
  );
}

export default Dashboard;
