import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import axios from "axios";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { Pagination, Rating } from "@material-ui/lab";

import qs from "qs";

import Filter from "../follow-ups/Filter";
import ReviewModal from "../modals/ReviewModal";
import moment from "moment";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import "./style.css";

const pageSize = 30;

const UserReviewsCom = () => {
  const [reviews, setReviews] = useState();
  const [selectedReview, setSelectedReview] = useState({});
  const [pageNum, setPageNum] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [loadingModal, setIsLoadingModal] = useState(false);

  const [isFetchingData, setisFetchingData] = useState(false);

  const scrolTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const getAllReviews = useCallback(async () => {
    if (!(Boolean(from) && Boolean(to))) {
      return;
    }

    const fromMoment = moment(from, "YYYY-MM-DD");
    const toMoment = moment(to, "YYYY-MM-DD");
    if (fromMoment.isAfter(toMoment)) {
      NotificationManager.error("Invalid Date Range", "Error");
      return;
    }

    try {
      setisFetchingData(true);
      let URL = process.env.REACT_APP_API_URL;
      const searchParams = new URLSearchParams({
        from,
        to,
        pageNum: (pageNum - 1).toString(),
        pageSize: pageSize.toString(),
      });
      URL += `/v1/admin/user/reviews?${searchParams.toString()}`;
      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
          "Content-Type": "application/json",
        },
      });
      setReviews(response.data);
      setNoOfPages(response.data.pageCount);
      scrolTop();
    } catch (e) {
      NotificationManager.error("Error Fetching Data", "Error");
    } finally {
      setisFetchingData(false);
    }
  }, [from, to, pageNum]);

  const handlePublish = () => {
    setIsLoadingModal(true);

    let data = qs.stringify({
      source: "WEBSITE",
      name: selectedReview.post.personalInfo.displayName,
      content: selectedReview.overallReview,
      rating: selectedReview.overallRating,
      userReviewId: selectedReview.id,
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}/v1/admin/reviews`, data, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        NotificationManager.success("Review published Successfully");
        setIsLoadingModal(false);
        setOpenReviewModal(false);
      })
      .catch((err) => {
        if (err.response?.data?.code === 1002) {
          NotificationManager.error("Review already published");
        } else {
          NotificationManager.error("Review publish failed");
        }
        setIsLoadingModal(false);
      });
  };

  useEffect(() => {
    getAllReviews();
  }, [getAllReviews]);

  const handlePagination = (event, value) => {
    setPageNum(value);
  };

  const handleSearch = () => {
    getAllReviews();
  };

  const closeReviewModal = () => {
    setOpenReviewModal(false);
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Reviews" title="User Reviews" />
        <Filter
          from={from}
          to={to}
          setFrom={setFrom}
          setTo={setTo}
          handleSearchBtn={handleSearch}
          loading={isFetchingData}
        />
        {Boolean(from) && Boolean(to) && (
          <div
            className="container-fluid inner-content"
            style={{ width: "100%" }}
          >
            <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
              <div className="content p-0">
                <table
                  className="table table-hover table-responsive common-table border mb-0"
                  style={{ fontSize: 14 }}
                >
                  <thead className="header">
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">Name</th>
                      <th className="border-0">Posted Date</th>
                      <th className="border-0">Match Rating</th>
                      <th className="border-0">Product Rating</th>
                      <th className="border-0">Comment</th>
                      <th className="border-0">Publish</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reviews !== undefined &&
                      (Object.keys(reviews.results) != 0 ? (
                        reviews.results.map((review) => {
                          return (
                            <tr>
                              <td>{review.post.memberId}</td>
                              <td>{review.post.personalInfo.displayName}</td>
                              <td>
                                {moment
                                  .utc(review.createdAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </td>
                              <td>
                                <Rating
                                  name="size-small"
                                  disabled
                                  size="small"
                                  value={review.matchRating}
                                  className="start-rating"
                                />
                              </td>
                              <td>
                                <Rating
                                  name="size-small"
                                  defaultValue={review.overallRating}
                                  disabled
                                  size="small"
                                  style={{ color: "red" }}
                                />
                              </td>
                              <td>
                                {review.overallReview.length > 0 ? "Yes" : "No"}
                              </td>
                              <td>
                                <button
                                  className="btn btn-dark btn-sm"
                                  onClick={() => {
                                    setSelectedReview(review);
                                    setOpenReviewModal(true);
                                  }}
                                >
                                  Publish
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7">No Recods</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row m-0">
              <div className="col p-0">
                <div className="pagination-div">
                  <Pagination
                    count={noOfPages}
                    page={pageNum}
                    onChange={handlePagination}
                    className="pagi"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedReview.constructor === Object &&
        Object.keys(selectedReview).length > 0 && (
          <ReviewModal
            openModal={openReviewModal}
            handleClose={closeReviewModal}
            data={selectedReview}
            onButtonClick={handlePublish}
            loadingModal={loadingModal}
          />
        )}
      <NotificationContainer />
    </>
  );
};

export default UserReviewsCom;
