import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import DashboardCom from "../components/visitor/statDashboard/dashboard";

function Settings() {
  return (
    <AdminLayout>
      <DashboardCom />
    </AdminLayout>
  );
}

export default Settings;