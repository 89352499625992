import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

const isActiveList1 = (history) => {
  if (history.location.pathname === "/dashboard/create-ad") {
    return {
      borderColor: "#997c0b",
      background: "#fff",
    };
  }
};

function Stepper(props) {
  return (
    <div className="step-progress-bar">
      <div className="step-progress-bar-complete-listing" style={{ zIndex: 1 }}>
        <ul style={{ paddingLeft: 0, marginBottom: 0 }}>
          <li>
            <p
              className={
                props.history.location.pathname === "/dashboard/create-ad" ||
                props.history.location.pathname === "/dashboard/review"
                  ? "numb-after"
                  : "step-numb"
              }
              style={isActiveList1(props.history)}
            ></p>
            <p
              className="step-name"
              style={
                props.history.location.pathname === "/dashboard/create-ad" ||
                props.history.location.pathname === "/dashboard/review"
                  ? { color: "#997c0b", fontWeight: 500 }
                  : null
              }
            >
              Ad Info
            </p>
          </li>

          <li>
            <p
              className={classnames("step-numb", {
                "numb-after":
                  props.history.location.pathname === "/dashboard/review",
              })}
              style={
                props.history.location.pathname === "/dashboard/review"
                  ? {
                      borderColor: "#997c0b",
                      background: "#fff",
                    }
                  : null
              }
            ></p>
            <p
              className="step-name"
              style={
                props.history.location.pathname === "/dashboard/review"
                  ? { color: "#997c0b", fontWeight: 500 }
                  : null
              }
            >
              Review & Confirm
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default withRouter(Stepper);
