import axios from "axios";
import { isAuthenticatedBoth } from "../../auth/authBoth";

export const UpdateInterestPrefernce = async( data, postId) => {
    return await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/interest_preferences`,
    data,
    {
      headers: {
        Authorization: `Bearer ${isAuthenticatedBoth()}`,
      },
    })
}

export const ResetInterestPreference = async( postId ) => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/interest_preferences`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
}