import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

const ParentCountryModal = ({
  showModal,
  handleSave,
  countryOptions,
  selectedCountryID,
  updateCountry,
  currentCountry,
  handleClose,
}) => {
  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-header">
        Edit Country Details
      </DialogTitle>

      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          {selectedCountryID ? (
            <label>Country </label>
          ) : (
            <label>Country Type</label>
          )}
          <select
            id="countrySelector"
            className="form-control"
            name="fCountryOfResidency"
            value={currentCountry}
            onChange={(e) => updateCountry(e.target.value)}
          >
            {countryOptions.map((option, i) => (
              <option key={i} value={option.id} selected>
                {console.log("key=>", i)}
                {console.log("option=>", option)}
                {option.type}
              </option>
            ))}
          </select>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn"
          style={{ width: 95 }}
          onClick={() => handleClose(false)}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          style={{ width: 95 }}
          onClick={() => handleSave()}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ParentCountryModal;
