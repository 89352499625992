import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PendingBankApprovalsCom from "../../components/admin/bank-approvals/PendingBankApprovalsCom";

function PendingBankApprovals() {
  return (
    <AdminLayout>
      <PendingBankApprovalsCom />
    </AdminLayout>
  );
}

export default PendingBankApprovals;
