// import packages
import React, { useState } from "react";
import classnames from "classnames";
import { Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Typography } from "@material-ui/core";

import DatePicker from "../../base/date-picker";

function PieChartComp(props) {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  const [isCustomDateChecked,setIsCustomDateChecked] = useState(false);

  const changeDuration = (e) => {
    if(e.target.value === "custom"){
      setIsCustomDateChecked(true);
    } else {
      setIsCustomDateChecked(false)
    }
    props.handleDuration(props.chartName, e.target.value);
  }
  
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const setCustomStartDate = (date) => {
    props.handleStartDate(props.chartName,date)
  }

  const setCustomEndDate = (date) => {
    props.handleEndDate(props.chartName,date)
  }
  
  return (
    <>
      <div className="content-col" style={{paddingBottom: '0'}}>
        <div className="container-fluid inner-content" style={{paddingBottom: '0'}}>
          <div className="ad-form-sec" style={{maxWidth: '100%', marginBottom: '0'}}>
            <form noValidate>
              <div className="form-row header">
                <div className="form-group col px-sm-2 mb-0">
                  <p className="heading mb-0">{props.title}</p>
                </div>
              </div>
              <div className="content">
                <div className="form-group">
                  <div className="graph-header">
                    <div className="graph-duration-picker">
                      <select
                        className={classnames("form-control")}
                        id="ethnicityId"
                        name="ethnicityId"
                        onChange={changeDuration}
                        value={props.duration}
                      >
                        <option value="7d">Last 7 Days</option>
                        <option value="6m">Last 6 Months</option>
                        <option value="4w">Last 4 Weeks</option>
                        <option value="12m">Last 12 Months</option>
                        <option value="custom">Custom Date</option>
                      </select>
                    </div>
                    {isCustomDateChecked && (
                      <div className="graph-date-picker">
                          <DatePicker
                            label="from"
                            selectedDate={props.startDate}
                            onChangeDate={(date) => setCustomStartDate(date)}
                          />
                          <DatePicker
                            label="to"
                            selectedDate={props.endDate}
                            onChangeDate={(date) => setCustomEndDate(date)}
                          />
                        </div>
                    )}
                    <div>
                      <label className="form-control" style={{ marginLeft: '20px', border: 'none' }}>Total Count : {props.total}</label>
                    </div>
                  </div>
                  <div>
                    <ResponsiveContainer width="99%" height={300}>
                      { props.loading ?
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{margin: 'auto', display: 'flex'}}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <PieChart width={800} height={800} >
                          <Legend verticalAlign="bottom" height={6}/>
                          <Pie
                            data={props.data}
                            // cx={350}
                            cy={150}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                            isAnimationActive={true}
                          >
                            {props.data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      }
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PieChartComp;