import React, { useState } from "react";

import Close from "../../../images/close-white.png";

function EditRejectModal(props) {
  const [message, setMessage] = useState("");
  return (
    <div
      className="modal fade"
      id="editRejectModal"
      tabIndex="-1"
      aria-labelledby="editRejectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editRejectModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="editRejectModalClose"
            />
          </div>
          <div className="modal-body">
            <p>Do you want to reject this edit request?</p>

            <div className="form-group col p-0 mb-3 m-auto text-center">
              <textarea
                type="text"
                className="form-control"
                name="amount"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                autoComplete="off"
                placeholder="Message"
              />
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => props.handleEditReq(props.id, message, false)}
              style={{ width: 95 }}
            >
              {props.loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Reject"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRejectModal;
