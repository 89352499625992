import React from "react";
import moment from "moment";
import classnames from "classnames";

import Close from "../../../images/close-white.png";

function CommentsModal(props) {
  return (
    <div
      className="modal fade comments-modal"
      id="comments-modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="comments-modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              style={{ fontSize: 22, fontWeight: "normal" }}
            >
              Comments
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="modal-body">
            <form>
              <div className="form-row">
                <div className="form-group col">
                  <label htmlFor="comment" className="title ml-0">
                    Comment <span className="required-tag">*</span>
                  </label>
                  <div className="input-group">
                    <textarea
                      className={classnames("form-control", {
                        "is-invalid": props.commentErr,
                      })}
                      name="comment"
                      value={props.comment}
                      onChange={(e) => {
                        props.setComment(e.target.value);
                        props.setCommentErr("");
                      }}
                      placeholder="Type your comment and click add ..."
                      rows="4"
                    />
                    <div className="invalid-feedback" style={{ fontSize: 14 }}>
                      {props.commentErr}
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <button
              type="button"
              className="btn btn-success float-right"
              onClick={props.addComment}
              style={{ width: 100 }}
            >
              {props.loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Add"
              )}
            </button>

            <table
              className="table table-striped meeting-notes-table"
              style={{ marginTop: 70, fontSize: 14 }}
            >
              <tbody>
                {props.comments
                  .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                  .map((el) => (
                    <tr key={el.id}>
                      <td width="100" className="py-1 px-2 text-muted">
                        {moment
                          .utc(el.createdAt)
                          .local()
                          .format("DD MMM YYYY hh:mm a")}
                      </td>
                      <td className="p-1">{el.comment}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommentsModal;
