import { Grid, Typography } from "@material-ui/core";
import React from "react";

import "./style.css";

const HoroscopeRequiredNotice = () => {
  return (
    <Grid container className="horoscope-notice-container">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className="horoscope-notice"
        wrap="nowrap"
      >
        <i className="fas fa-eye-slash"></i>
        <Typography className="horoscope-notice-text">
          Please update horoscope details in your ad, in order to view horoscope
          details of this party.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default HoroscopeRequiredNotice;
