import React, { useState, useEffect } from 'react'
import { Paper, Grid, Typography, Button } from "@material-ui/core";
import Switch from "react-input-switch";
import propTypes from 'prop-types';
import axios from 'axios';
import { isAuthenticatedBoth } from '../../../../auth/authBoth';
import { NotificationContainer, NotificationManager } from "react-notifications"


const Notificationpreference = ({ memberID }) => {
    const [interestReceived, setInterestReceived] = useState("both");
    const [interestReceivedOld, setInterestReceivedOld] = useState("both");
    const [matched, setMatched] = useState("both");
    const [matchedOld, setMatchedOld] = useState("both");
    const [interestAutoReject, setInterestAutoReject] = useState("email");
    const [interestAutoRejectOld, setInterestAutoRejectOld] = useState("email");
    const [unreadMsg, setUnreadMsg] = useState("both");
    const [unreadMsgOld, setUnreadMsgOld] = useState("both");
    const [lastOnline, setLastOnline] = useState(false);
    const [lastOnlineOld, setLastOnlineOld] = useState(false);
    const [enableHytch, setEnableHytch] = useState(false);
    const [enableHytchOld, setEnableHytchOld] = useState(false);
    const [showBtn, setShowBtn] = useState(false);

    Notificationpreference.propTypes = {
        memberID: propTypes.string.isRequired
    }

    useEffect(() => {
        GetNotificationPreferences();
    }, [])

    useEffect(() => {
        if (
            interestReceived !== interestReceivedOld ||
            matched !== matchedOld ||
            interestAutoReject !== interestAutoRejectOld ||
            unreadMsg !== unreadMsgOld ||
            lastOnline !== lastOnlineOld ||
            enableHytch !== enableHytchOld
        ) {
            setShowBtn(true)
        }
        else {
            setShowBtn(false)
        }
    }, [
        enableHytch,
        interestAutoReject,
        interestReceived,
        lastOnline,
        matched,
        unreadMsg,
    ])

    const GetNotificationPreferences = () => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/v1/admin/user_account?memberId=${memberID}`,
                {
                    headers: {
                        Authorization: `Bearer ${isAuthenticatedBoth()}`,
                    }
                }
            )
            .then((res) => {
                const {
                    autorejectEmail,
                    enableHytch,
                    enableLastOnline,
                    likeEmail,
                    likeSms,
                    matchEmail,
                    matchSms,
                    unreadReminderEmail,
                    unreadReminderSms } = res.data.userPreferences
                setInterestReceived((likeEmail && likeSms) ? 'both' : (likeEmail && !likeSms) ? 'email' : (likeSms && !likeEmail) ? 'sms' : 'both');
                setInterestReceivedOld((likeEmail && likeSms) ? 'both' : (likeEmail && !likeSms) ? 'email' : (likeSms && !likeEmail) ? 'sms' : 'both');
                setMatched((matchEmail && matchSms) ? 'both' : (matchEmail && !matchSms) ? 'email' : (matchSms && !matchEmail) ? 'sms' : 'both');
                setMatchedOld((matchEmail && matchSms) ? 'both' : (matchEmail && !matchSms) ? 'email' : (matchSms && !matchEmail) ? 'sms' : 'both');
                setInterestAutoReject(autorejectEmail ? 'email' : 'off');
                setInterestAutoRejectOld(autorejectEmail ? 'email' : 'off');
                setUnreadMsg((unreadReminderEmail && unreadReminderSms) ? 'both' : (unreadReminderEmail && !unreadReminderSms) ? 'email' : (unreadReminderSms && !unreadReminderEmail) ? 'sms' : 'off');
                setUnreadMsgOld((unreadReminderEmail && unreadReminderSms) ? 'both' : (unreadReminderEmail && !unreadReminderSms) ? 'email' : (unreadReminderSms && !unreadReminderEmail) ? 'sms' : 'off');
                setLastOnline(enableLastOnline);
                setLastOnlineOld(enableLastOnline);
                setEnableHytch(enableHytch);
                setEnableHytchOld(enableHytch);
                setShowBtn(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const EditPreferences = () => {
        const data = {
            likeSms: interestReceived === "both" || interestReceived === "sms",
            likeEmail: interestReceived === "both" || interestReceived === "email",
            matchSms: matched === "both" || matched === "sms",
            matchEmail: matched === "both" || matched === "email",
            autorejectEmail: interestAutoReject === "email",
            unreadReminderEmail: unreadMsg === "both" || unreadMsg === "email",
            unreadReminderSms: unreadMsg === "both" || unreadMsg === "sms",
            enableLastOnline: lastOnline,
            enableHytch: enableHytch
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/v1/admin/user/${memberID}/preferences`, data, {
                headers: {
                    Authorization: `Bearer ${isAuthenticatedBoth()}`,
                    "content-type": "application/json",
                },
            })
            .then((res) => {
                NotificationManager.success("Post updated", "Success");
                setShowBtn(false);
                setInterestReceivedOld(interestReceived);
                setMatchedOld(matched);
                interestAutoRejectOld(interestAutoReject);
                unreadMsgOld(unreadMsg);
                lastOnlineOld(lastOnline);
                enableHytchOld(enableHytch);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancel = () => {
        setInterestReceived(interestReceivedOld);
        setMatched(matchedOld);
        setInterestAutoReject(interestAutoRejectOld);
        setUnreadMsg(unreadMsgOld);
        setLastOnline(lastOnlineOld);
        setEnableHytch(enableHytchOld);
        setShowBtn(false);
    }


    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        elevation={0}
                        className="complete-ad-div settings-container post-details-con"
                    >
                        <Typography
                            variant="h6"
                            className="heading"
                            style={{ marginBottom: 30 }}
                        >
                            Preferences
                        </Typography>
                        <Grid container direction='column' spacing={3} style={{ margin: '0' }}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Notification Preferences
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container style={{ gap: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <Typography variant='body2'>Interest Received</Typography>
                                        </Grid>
                                        <Grid item xs={7} >
                                            <select
                                                className="form-control"
                                                onChange={(e) => setInterestReceived(e.target.value)}
                                                style={{ fontSize: '0.9rem' }}
                                                value={interestReceived}

                                            >
                                                <option key='1' value='both'>Email & SMS</option>
                                                <option key='2' value='email'>Email Only</option>
                                                <option key='3' value='sms'>SMS Only</option>
                                            </select>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                            <Typography variant='body2'>Matched</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <select
                                                className="form-control"
                                                onChange={(e) => setMatched(e.target.value)}
                                                style={{ fontSize: '0.9rem' }}
                                                value={matched}
                                            >
                                                <option key='1' value='both'>Email & SMS</option>
                                                <option key='2' value='email'>Email Only</option>
                                                <option key='3' value='sms'>SMS Only</option>
                                            </select>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'end' }}>
                                            <Typography variant='body2'>Interest Auto-reject Reminder</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <select
                                                className="form-control"
                                                onChange={(e) => setInterestAutoReject(e.target.value)}
                                                style={{ fontSize: '0.9rem' }}
                                                value={interestAutoReject}
                                            >
                                                <option key='1' value="email">Email</option>
                                                <option key='2' value="off">Off</option>
                                            </select>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'end' }}>
                                            <Typography variant='body2'>Unread Messages Reminder</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <select
                                                className="form-control"
                                                onChange={(e) => setUnreadMsg(e.target.value)}
                                                style={{ fontSize: '0.9rem' }}
                                                value={unreadMsg}
                                            >
                                                <option key='1' value='both'>Email & SMS</option>
                                                <option key='2' value='email'>Email Only</option>
                                                <option key='3' value='sms'>SMS Only</option>
                                                <option key='4' value='off'>Off</option>
                                            </select>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="subtitle2"
                                >
                                    Account Preferences
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container style={{ gap: '10px' }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'end' }}>
                                            <Typography variant='body2'>Enable Last Online Status</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Switch
                                                on={true}
                                                off={false}
                                                value={lastOnline}
                                                onChange={(e) => setLastOnline(!lastOnline)}
                                                className="interest-on-off-btn"
                                                style={{
                                                    margin: "auto",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', textAlign: 'end' }}>
                                            <Typography variant='body2'>Enable Hytch</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Switch
                                                on={true}
                                                off={false}
                                                value={enableHytch}
                                                onChange={(e) => setEnableHytch(!enableHytch)}
                                                className="interest-on-off-btn"
                                                style={{
                                                    margin: "auto",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>
                            {showBtn && <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    variant="contained"
                                    className="common-cancel-btn"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    className="common-save-btn"
                                    onClick={EditPreferences}
                                >
                                    Save
                                </Button>
                            </Grid>}
                        </Grid>

                    </Paper>
                </Grid>
            </Grid>
            <NotificationContainer />
        </>

    )
}

export default Notificationpreference