import React, { Component } from "react";

import CommonLogin from "../components/visitor/login/CommonLogin";

import "../sass/style.css";

export default class Login extends Component {
  render() {
    return <CommonLogin />;
  }
}
