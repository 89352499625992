import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "lodash";
import {
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { format } from "date-fns-tz";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { Button, Grid } from "@material-ui/core";
import DatePicker from "../../base/date-picker";
import moment from "moment";
import "./_statDashboard.scss";

const styles = makeStyles({
  disabledButton: {
    backgroundColor: "grey !important",
  },
});

const PaymentHistoryTable = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [promoCode, setPromoCode] = useState(null);
  const [pricingTiers, setPricingTiers] = useState([]);
  const [tableModel, setTableModel] = useState({});
  const [crossAxisModel, setCrossAxisModel] = useState({});

  useEffect(() => {
    const tableModel = generateTableModel(pricingTiers);
    setTableModel(tableModel);
  }, [pricingTiers]);

  useEffect(() => {
    if (Object.keys(tableModel).length === 0) {
      return;
    }
    const crossTableModel = {};
    for (const [criteria, criteriaValues] of Object.entries(tableModel)) {
      const payments = Object.values(criteriaValues);
      let summation = {
        card: 0,
        cash: 0,
      };
      if (Array.isArray(payments)) {
        payments.forEach((paymentObj) => {
          summation.card = summation.card + paymentObj.card;
          summation.cash = summation.cash + paymentObj.cash;
        });
      }
      crossTableModel[criteria] = summation;
    }
    setCrossAxisModel(crossTableModel);
  }, [tableModel]);

  const formatDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd", {
      timeZone: "Asia/Colombo",
    });
    return formattedDate;
  };

  const onClickShowResult = async () => {
    try {
      if ((fromDate === null) | (toDate === null)) {
        return;
      }
      const searchParams = new URLSearchParams();
      searchParams.append("from", formatDate(fromDate));
      searchParams.append("to", formatDate(toDate));
      if (!isEmpty(promoCode)) {
        searchParams.append("promoCode", promoCode.toString());
      }
      let URL = `${process.env.REACT_APP_API_URL}/v1/admin/payment_stats`;
      URL += `?${searchParams.toString()}`;
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${isAuthenticatedBoth()}` },
      });
      if (response.status === 200) {
        const payload = response.data;
        if (Array.isArray(payload.pricingTiers)) {
          setPricingTiers(payload.pricingTiers);
        }
      }
    } catch (e) {
      console.log("Error! [PaymentHistoryTable] onClickShowResults ", e);
    }
  };

  const getPricingTierMapping = (pricingTierObj) => {
    const { pricingTier } = pricingTierObj;
    const mapping = {
      cardNew: `new.${pricingTier}.card`,
      cardRenew: `renew.${pricingTier}.card`,
      cashNew: `new.${pricingTier}.cash`,
      cashRenew: `renew.${pricingTier}.cash`,
    };
    return mapping;
  };

  const generateTableModel = (pricingTiers) => {
    const output = {};
    for (const pricingTier of pricingTiers) {
      const mappings = getPricingTierMapping(pricingTier);
      Object.entries(mappings).forEach(([extractKey, outputKeys]) => {
        const value = pricingTier[extractKey];
        const [criteria, paymentPackage, paymentMethod] = outputKeys.split(".");
        if (output[criteria] === undefined) {
          output[criteria] = {};
        }
        if (output[criteria][paymentPackage] === undefined) {
          output[criteria][paymentPackage] = {};
        }
        output[criteria][paymentPackage][paymentMethod] = value;
      });
    }
    return output;
  };

  const getCriteriaTotal = (criteria) => {
    if (Object.entries(crossAxisModel).length === 0) {
      return 0;
    }
    const criteriaValues = crossAxisModel[criteria];
    const summation = Object.values(criteriaValues).reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue;
      }
    );
    return summation;
  };

  const onChangePromoCodes = (e) => {
    setPromoCode(e.target.value);
  };

  return (
    <div className="content-col" style={{ paddingBottom: 10 }}>
      <div
        className="container-fluid inner-content"
        style={{ paddingBottom: 0 }}
      >
        <div
          className="ad-form-sec"
          style={{ maxWidth: "100%", marginBottom: "0" }}
        >
          <form noValidate>
            <div className="form-row header">
              <div className="form-group col px-sm-2 mb-0">
                <p className="heading mb-0">Payment History</p>
              </div>
            </div>
            <div className="content" style={{ paddingBottom: 25 }}>
              <Grid
                container
                direction="row"
                className="widget-filter-container"
              >
                <DatePicker
                  label="from"
                  selectedDate={
                    toDate
                      ? moment(fromDate).isBefore(toDate) ||
                        moment(fromDate).isSame(toDate)
                        ? fromDate
                        : null
                      : fromDate
                  }
                  onChangeDate={(date) => setFromDate(date)}
                />
                <DatePicker
                  label="to"
                  selectedDate={toDate}
                  onChangeDate={(date) => setToDate(date)}
                />
                <TextField
                  variant="outlined"
                  label="Add promo code"
                  size="small"
                  onChange={onChangePromoCodes}
                />
                <Button
                  className="widget-filter-btn"
                  onClick={onClickShowResult}
                  disabled={
                    !moment(fromDate).isBefore(toDate) &&
                    !moment(fromDate).isSame(toDate)
                  }
                  classes={{ disabled: styles.disabledButton }}
                >
                  Show Results
                </Button>
              </Grid>
              {Object.entries(tableModel).length > 0 && (
                <TableContainer style={{ marginTop: 8 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="header-table-cell">
                          Criteria
                        </TableCell>
                        <TableCell className="header-table-cell">
                          Package
                        </TableCell>
                        <TableCell className="header-table-cell">
                          Card Payments
                        </TableCell>
                        <TableCell className="header-table-cell">
                          Cash Payments
                        </TableCell>
                        <TableCell className="header-total-table-cell">
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(tableModel).map(
                        ([criteria, payments]) => {
                          return (
                            <React.Fragment key={criteria}>
                              {Object.entries(payments).map(
                                ([packageName, packagePayments], index) => (
                                  <TableRow
                                    key={`${criteria}-${packageName}`}
                                    className="header-table-cell"
                                  >
                                    {index === 0 && (
                                      <TableCell
                                        rowSpan={
                                          Object.keys(payments).length > 2
                                            ? 3
                                            : null
                                        }
                                        className="body-criteria-cell"
                                      >
                                        {criteria}
                                      </TableCell>
                                    )}
                                    <TableCell className="header-table-cell">
                                      {packageName}
                                    </TableCell>
                                    <TableCell className="header-table-cell">
                                      {packagePayments.card}
                                    </TableCell>
                                    <TableCell className="header-table-cell">
                                      {packagePayments.cash}
                                    </TableCell>
                                    <TableCell className="body-total-count-cell">
                                      {packagePayments.card +
                                        packagePayments.cash}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}

                              <TableRow
                                key={criteria}
                                className="body-total-row"
                              >
                                <TableCell
                                  colSpan={2}
                                  className="body-total-cell"
                                >
                                  Total
                                </TableCell>
                                <TableCell className="body-total-cell">
                                  {crossAxisModel[criteria]?.card ?? 0}
                                </TableCell>
                                <TableCell className="body-total-cell">
                                  {crossAxisModel[criteria]?.cash ?? 0}
                                </TableCell>
                                <TableCell className="body-total-cell">
                                  {getCriteriaTotal(criteria)}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistoryTable;
