import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import FormLinks from "./FormLinks";
import AgentsDetailsModal from "../modals/AgentsDetailsModal";

function ManageAllAds() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [agentInfo, setAgentInfo] = useState({});

  const itemsPerPage = 20;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        const pendings = res.data.filter((el) => el.post === undefined);
        setAds(pendings);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const filterBySearch = ads.filter((el) => {
    if (search) {
      return (
        (el.agentInfo &&
          el.agentInfo.code.toLowerCase().indexOf(search.toLowerCase()) !==
            -1) ||
        el.id.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    } else {
      return el;
    }
  });

  const handleAgents = (code) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/agents/${code}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setAgentInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Agent" title="Pending to Create" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="search-by-id">
                  Search by Ad ID or phone number
                </label>
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    id="search-by-id"
                    placeholder="Ad ID / Name / Phone"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Submited At</th>
                  <th className="border-0">Scan ID</th>
                  <th className="border-0">Agent Code</th>
                  <th className="border-0">Application</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el) => (
                    <tr key={el.id}>
                      <td width="130">
                        {moment.utc(el.createdAt).local().format("Do MMM YYYY")}
                        <p className="from-now">
                          {moment.utc(el.createdAt).local().fromNow()}
                        </p>
                      </td>
                      <td>{el.id}</td>
                      <td>
                        <span
                          data-toggle="modal"
                          data-target="#agentsDetailsModal"
                          onClick={() => handleAgents(el.agentInfo.code)}
                          style={{ cursor: "pointer", color: "#007bff" }}
                        >
                          {el.agentInfo.code}
                        </span>
                      </td>
                      <td>
                        <FormLinks images={el.images} scanId={el.id} />
                      </td>
                      <td>
                        <Link
                          to={`/dashboard/create-ad?id=${el.id}&code=${el.agentInfo.code}`}
                          className="btn btn-success btn-sm"
                          style={{ width: 90 }}
                        >
                          Create Ad
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No Recods</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>

        {Object.keys(agentInfo).length === 0 &&
        agentInfo.constructor === Object ? null : (
          <AgentsDetailsModal agent={agentInfo} />
        )}
      </div>
    </div>
  );
}

export default ManageAllAds;
