import React from "react";
import classnames from "classnames";

function Payment(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Payment Info</p>
            <p className="info-desc">
              <span className="required-tag">*</span> Indicates required fields
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: 10 }}>
          <div className="form-group col-md-6 px-sm-2 mb-3">
            <label htmlFor="paymentComplete">
              Payment Status <span className="required-tag">*</span>
            </label>
            <select
              className={classnames("form-control", {
                "is-invalid": props.form.paymentCompleteErr,
              })}
              id="paymentComplete"
              name="paymentComplete"
              onChange={props.handleChange}
              value={props.form.paymentComplete}
            >
              <option defaultValue hidden>
                -- Select --
              </option>
              <option value={true}>Paid</option>
              <option value={false}>Not paid</option>
            </select>
            <div className="invalid-feedback">
              {props.form.paymentCompleteErr}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Payment;
