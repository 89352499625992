import React from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import _12FromTo24Hours from "12fromto24hours";
import moment from "moment";

function Reports(props) {
  const sortedReportList = props.ad.incomingReports.sort((i, j) => {
    return new Date(j.createdAt) - new Date(i.createdAt);
  });

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography variant="h6" className="heading">
          Reports
        </Typography>
        <div
          className="row m-0"
          style={{ paddingLeft: "20px", paddingTop: "20px" }}
        >
          <div>
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th width="200" className="border-0">
                    Created Date
                  </th>
                  <th width="200" className="border-0">
                    Reported By
                  </th>
                  <th width="550" className="border-0">
                    Reason
                  </th>
                  <th width="250" className="border-0">
                    Reason Other
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedReportList.length ? (
                  sortedReportList.map((el, i) => (
                    <tr key={i}>
                      <td width="500">
                        {moment
                          .utc(el.createdAt)
                          .local()
                          .format("DD-MMM-YYYY hh:mm a")}
                      </td>
                      <td width="550">{el.reportedBy.id}</td>
                      <td width="550">{el.reason.reason}</td>
                      <td width="250">{el.reasonOther}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Records
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default Reports;
