import { Grid } from "@material-ui/core";
import React from "react";

const PdfDataRightColumn = ({children}) => {
  return (
    <Grid direction="column" style={{width: "50%"}} className="pdf-data-section">
      {children}
    </Grid>
  );
};

export default PdfDataRightColumn;
