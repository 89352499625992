import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function EditRequestsCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/posts/edit_requests`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        const live = res.data.filter((el) => el.status === "PENDING");

        setAds(live);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const filterBySearch = useMemo(() => {
    if (isEmpty(search)) {
      return ads;
    }
    return ads.filter((element) => {
      const { id, personalInfo, phone, email } = element.post;

      const comparators = [
        personalInfo?.fname,
        personalInfo?.lname,
        id,
        phone,
        email,
      ]
        .filter((element) => !isEmpty(element) && typeof element === "string")
        .map((element) => element.toLowerCase());

      return comparators.find((element) => element.includes(search));
    });
  }, [ads, search]);

  //   pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Edit Ads" title="Pendings Requests" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="Post ID / Name / Phone / Email"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Post ID</th>
                  <th className="border-0">First Name</th>
                  <th className="border-0">Last Name</th>
                  <th className="border-0">Phone</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr
                      key={i}
                      onClick={() => history.push(`/post?id=${el.post.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td width="100">{el.post.id}</td>
                      <td width="125">{el.post.personalInfo.fname}</td>
                      <td width="125">{el.post.personalInfo.lname}</td>
                      <td width="150">{el.post.phone ? el.post.phone : "-"}</td>
                      <td width="200">{el.post.email ? el.post.email : "-"}</td>
                      <td>{el.post.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditRequestsCom;
