import React, { useState, useEffect } from "react";
import classnames from "classnames";
import axios from "axios";

function Mother(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="mOriginCountryCode">
          Country of Residence <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.mOriginCountryCodeErr,
          })}
          id="mOriginCountryCode"
          name="mOriginCountryCode"
          onChange={props.handleChange}
          value={props.form.mOriginCountryCode}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.countries.map((el, i) => (
            <option value={el.code} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">
          {props.form.mOriginCountryCodeErr}
        </div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="mEthnicityId">
          Ethnicity <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.mEthnicityIdErr,
          })}
          id="mEthnicityId"
          name="mEthnicityId"
          onChange={props.handleChange}
          value={props.form.mEthnicityId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.ethnicities.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.mEthnicityIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="mReligionId">
          Religion <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.mReligionIdErr,
          })}
          id="mReligionId"
          name="mReligionId"
          onChange={props.handleChange}
          value={props.form.mReligionId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.religions.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.mReligionIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="mCaste">Caste</label>
        <input
          type="text"
          className="form-control"
          id="mCaste"
          name="mCaste"
          onChange={props.handleChange}
          value={props.form.mCaste}
          autoComplete="off"
        />
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="mProfessionId">Profession</label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.mAdditionalInfoErr,
          })}
          id="mProfessionId"
          name="mProfessionId"
          onChange={props.handleChange}
          value={props.form.mProfessionId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.professions.map((el, i) => (
            <option value={el.id} key={el.id}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.mProfessionIdErr}</div>
      </div>

      {props.mShowOtherProf && (
        <div className="form-group col-md-6 px-sm-2 mb-3">
          <label htmlFor="mOtherProfession">
            Type Profession <span className="required-tag">*</span>
          </label>
          <input
            type="text"
            className={classnames("form-control", {
              "is-invalid": props.form.mOtherProfessionErr,
            })}
            id="mOtherProfession"
            name="mOtherProfession"
            onChange={props.handleChange}
            value={props.form.mOtherProfession}
            autoComplete="off"
          />
          <div className="invalid-feedback">
            {props.form.mOtherProfessionErr}
          </div>
        </div>
      )}

      <div className="form-group col-12 px-sm-2 mb-3">
        <label htmlFor="mAdditionalInfo">Additional Info</label>
        <textarea
          className={classnames("form-control", {
            "is-invalid": props.form.mAdditionalInfoErr,
          })}
          name="mAdditionalInfo"
          onChange={props.handleChange}
          value={props.form.mAdditionalInfo}
          rows="5"
          placeholder="Optional"
        />
        <div className="invalid-feedback">{props.form.mAdditionalInfoErr}</div>
      </div>
    </div>
  );
}

export default Mother;
