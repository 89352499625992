import React from "react";
import PdfDataSection from "../../templates/pdf-data-section";
import PdfDataLeftColumn from "../../templates/pdf-data-section/data-left-column";
import PdfDataRightColumn from "../../templates/pdf-data-section/data-right-column";
import PdfDataRow from "../../templates/pdf-data-section/data-row";
import { Grid } from "@material-ui/core";
import { CalHeight } from "../../methods/calHeight";
import PdfDescription from "../../templates/pdf-data-section/description";
import moment from "moment";
import HoroscopeRequiredNotice from "../../templates/pdf-data-section/horoscope-required-notice";

import "./style.css";
import { stringToTitleCase } from "../../methods/stringToTitleCase";

const PdfContent = ({ content, hasHoroscopeInfo }) => {
  const getAge = () => {
    const age = `${content.personalInfo?.age || ""} Years`;
    return age;
  };

  return (
    <Grid direction="column" container className="pdf-content">
      <PdfDescription
        profileDescription={content.personalInfo?.additionalInfo}
      />
      <PdfDataSection heading="Personal Info" leftDataHeding="Basic">
        <PdfDataLeftColumn>
          <PdfDataRow
            label="Ethnicity"
            value={content.personalInfo?.ethnicity?.name}
          />
          <PdfDataRow
            label="Religion"
            value={content.personalInfo?.religion?.name}
          />
          <PdfDataRow label="Age" value={getAge()} />
          <PdfDataRow
            label="Gender"
            value={stringToTitleCase(content.personalInfo?.gender || "")}
          />
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow label="Caste" value={content.personalInfo?.caste} />
          <PdfDataRow
            label="Civil Status"
            value={content.personalInfo?.civilStatus?.status}
          />
          {content.personalInfo?.children && (
            <PdfDataRow
              label="Children"
              value={stringToTitleCase(content.personalInfo?.children || "")}
            />
          )}
          <PdfDataRow
            label="Height"
            value={CalHeight(content.personalInfo?.height || "")}
          />
          <PdfDataRow
            label="Differently abled"
            value={content.differentlyAbled ? "Yes" : "No"}
          />
        </PdfDataRightColumn>
      </PdfDataSection>

      <PdfDataSection leftDataHeding="Residency">
        <PdfDataLeftColumn>
          <PdfDataRow
            label={
              content.personalInfo?.residentCountry?.code == "ZZ"
                ? "Country Type"
                : "Country"
            }
            value={content.personalInfo?.residentCountry?.name}
          />

          {content.personalInfo?.residentCountry?.code !== "JP" && (
            <PdfDataRow
              label={
                content.personalInfo?.residentCountry?.code == "ZZ"
                  ? "Resident Country"
                  : "State / District"
              }
              value={
                content.personalInfo?.residentCountry?.code == "ZZ"
                  ? content.personalInfo?.residentCountryOther?.name
                  : content.personalInfo?.residentRegion?.regionName
              }
            />
          )}
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow label="City" value={content.personalInfo?.residentCity} />
          <PdfDataRow
            label="Visa Status"
            value={content.personalInfo?.visaType?.visaType}
          />
        </PdfDataRightColumn>
      </PdfDataSection>

      <PdfDataSection leftDataHeding="Education & Profession">
        <PdfDataLeftColumn>
          <PdfDataRow
            label="Education"
            value={content.personalInfo?.educationLevel?.level}
          />
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow
            label="Profession"
            value={
              content.personalInfo?.profession?.name === "Other"
                ? content.personalInfo?.professionOther
                : content.personalInfo?.profession?.name
            }
          />
        </PdfDataRightColumn>
      </PdfDataSection>

      <PdfDataSection leftDataHeding="Habits">
        <PdfDataLeftColumn>
          <PdfDataRow
            label="Drinking"
            value={stringToTitleCase(content.personalInfo?.drinking || "")}
          />
          <PdfDataRow
            label="Smoking"
            value={stringToTitleCase(content.personalInfo?.smoking || "")}
          />
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow
            label="Food Preference"
            value={content.personalInfo?.foodPreference?.type}
          />
        </PdfDataRightColumn>
      </PdfDataSection>

      <PdfDataSection
        heading="Parents' Info"
        leftDataHeding="Father"
        rightDataHeading="Mother"
      >
        <PdfDataLeftColumn>
          <PdfDataRow
            label="Ethnicity"
            value={content.parentInfo?.[0]?.ethnicity?.name}
          />
          <PdfDataRow
            label="Religion"
            value={content.parentInfo?.[0]?.religion?.name}
          />
          <PdfDataRow label="Caste" value={content.parentInfo?.[0]?.caste} />
          <PdfDataRow
            label="Profession"
            value={
              content.parentInfo?.[0]?.profession?.name === "Other"
                ? content.parentInfo?.[0]?.professionOther
                : content.parentInfo?.[0]?.profession?.name
            }
          />
          <PdfDataRow
            label="Country of Residence"
            value={content.parentInfo?.[0]?.residentCountry?.name}
          />
          <PdfDataRow
            label="Additional Info"
            value={content.parentInfo?.[0]?.additionalInfo}
          />
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow
            label="Ethnicity"
            value={content.parentInfo?.[1]?.ethnicity?.name}
          />
          <PdfDataRow
            label="Religion"
            value={content.parentInfo?.[1]?.religion?.name}
          />
          <PdfDataRow label="Caste" value={content.parentInfo?.[1]?.caste} />
          <PdfDataRow
            label="Profession"
            value={
              content.parentInfo?.[1]?.profession?.name === "Other"
                ? content.parentInfo?.[1]?.professionOther
                : content.parentInfo?.[1]?.profession?.name
            }
          />
          <PdfDataRow
            label="Country of Residence"
            value={content.parentInfo?.[1]?.residentCountry?.name}
          />
          <PdfDataRow
            label="Additional Info"
            value={content.parentInfo?.[1]?.additionalInfo}
          />
        </PdfDataRightColumn>
      </PdfDataSection>

      <PdfDataSection heading="Horoscope Info">
        <PdfDataLeftColumn>
          <PdfDataRow label="Birthday" value={content.horoscopeInfo?.dob} />
          {hasHoroscopeInfo && (
            <>
              <PdfDataRow
                label="Birth City"
                value={content.horoscopeInfo?.birthCity}
              />
              <PdfDataRow
                label="Additional Info"
                value={content.horoscopeInfo?.additionalData}
              />
            </>
          )}
        </PdfDataLeftColumn>
        <PdfDataRightColumn>
          <PdfDataRow
            label="Matching"
            value={
              content.horoscopeMatching == true ? " Essential" : "Not essential"
            }
          />
          {hasHoroscopeInfo && (
            <PdfDataRow
              label="Birth Time"
              value={
                content.horoscopeInfo?.birthTime
                  ? moment(content.horoscopeInfo.birthTime, "HH:mm").format(
                      "hh:mm A"
                    )
                  : "-"
              }
            />
          )}
        </PdfDataRightColumn>
        {!hasHoroscopeInfo && <HoroscopeRequiredNotice />}
      </PdfDataSection>
    </Grid>
  );
};

export default PdfContent;
