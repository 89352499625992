import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import qs from "qs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import PaymentApproveModal from "../modals/PaymentApproveModal";
import PaymentRejectModal from "../modals/PaymentRejectModal";

import { isAuthenticated } from "../../../auth/auth";

function PendingBankApprovalsCom() {
  const [pendings, setPendings] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [approved, setApproved] = useState([]);
  const [scanId, setScanId] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getBankTransfers();
  }, []);

  const getBankTransfers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/agent_payments/scanned`, {
        headers: {
          Authorization: `Bearer ${isAuthenticated()}`,
        },
      })
      .then((res) => {
        const sortByDate = res.data.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );

        const onlyPendings = sortByDate.filter(
          (el) => el.status === "SUBMITTED"
        );
        const onlyRejected = sortByDate.filter(
          (el) => el.status === "REJECTED"
        );
        const onlyApproved = sortByDate.filter(
          (el) => el.status === "ACCEPTED"
        );
        setPendings(onlyPendings);
        setRejected(onlyRejected);
        setApproved(onlyApproved);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  const handleApprove = (scanId, agentCode, status) => {
    setLoading(true);

    const data = qs.stringify({
      accept: status,
      agentCode: agentCode,
      amendedAmount: amount,
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/agent_payments/scanned/${scanId}/approve`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticated()}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success(
          `${status ? "Approved!" : "Rejected"}`,
          "Success"
        );
        document.getElementById("paymentApproveModalClose").click();
        document.getElementById("paymentRejectModalClose").click();

        setLoading(false);
        getBankTransfers();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Agent" title="Payments" />

        <div className="container-fluid inner-content py-4">
          <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
            {pendings.length > 0 && (
              <>
                <p className="invoice-sec-heading">Pending Payments</p>
                <div className="content p-0 mb-4">
                  <table
                    className="table table-hover table-responsive  common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Paid At</th>
                        <th className="border-0">Status</th>
                        <th className="border-0">Amount</th>
                        <th className="border-0 text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pendings.length ? (
                        pendings.map((el) => (
                          <tr key={el.id}>
                            <td width="200">
                              {moment
                                .utc(el.createdAt)
                                .local()
                                .format("Do MMM YYYY")}
                              <p className="from-now">
                                {moment.utc(el.createdAt).local().fromNow()}
                              </p>
                            </td>

                            <td width="200">
                              <p className="status pending">Pending</p>
                            </td>
                            <td>
                              <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                            </td>
                            <td>
                              <button
                                className="btn btn-success btn-sm m-1"
                                style={{ width: 80 }}
                                data-toggle="modal"
                                data-target="#paymentApproveModal"
                                onClick={() => {
                                  setScanId(el.id);
                                  setAgentCode(el.agentCode);
                                }}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-outline-danger btn-sm m-1"
                                data-toggle="modal"
                                data-target="#paymentRejectModal"
                                onClick={() => {
                                  setScanId(el.id);
                                  setAgentCode(el.agentCode);
                                }}
                                style={{ width: 80 }}
                              >
                                Reject
                              </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" className="text-muted">
                            No Recods
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            <p className="invoice-sec-heading">Rejected Payments</p>
            <div className="content p-0 mb-4">
              <table
                className="table table-hover table-responsive  common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Paid At</th>
                    <th className="border-0">Status</th>
                    <th className="border-0">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {rejected.length ? (
                    rejected.map((el) => (
                      <tr key={el.id}>
                        <td width="500">
                          {moment
                            .utc(el.createdAt)
                            .local()
                            .format("Do MMM YYYY")}
                          <p className="from-now">
                            {moment.utc(el.createdAt).local().fromNow()}
                          </p>
                        </td>

                        <td width="100">
                          <p className="status expired">Rejected</p>
                        </td>
                        <td>
                          <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-muted">
                        No Recods
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <p className="invoice-sec-heading">Approved Payments</p>
            <div className="content p-0 mb-4">
              <table
                className="table table-hover table-responsive  common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Paid At</th>
                    <th className="border-0">Status</th>
                    <th className="border-0" align="right">
                      Requested
                    </th>
                    <th className="border-0" align="right">
                      Approved
                    </th>
                    <th className="border-0" align="right">
                      Difference
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {approved.length ? (
                    approved.map((el) => (
                      <tr key={el.id}>
                        <td width="300">
                          {moment
                            .utc(el.createdAt)
                            .local()
                            .format("Do MMM YYYY")}
                          <p className="from-now">
                            {moment.utc(el.createdAt).local().fromNow()}
                          </p>
                        </td>
                        <td width="100">
                          <p className="status live">Approved</p>
                        </td>
                        <td align="right">
                          <p style={{ marginBottom: 0 }}>Rs. {el.amount}</p>
                        </td>
                        <td align="right">
                          <p style={{ marginBottom: 0 }}>
                            Rs.{" "}
                            {el.amendedAmount ? el.amendedAmount : el.amount}
                          </p>
                        </td>
                        <td align="right">
                          <p style={{ marginBottom: 0 }}>
                            {el.amount -
                              (el.amendedAmount
                                ? el.amendedAmount
                                : el.amount) ===
                            0
                              ? "-"
                              : `Rs. ${Math.abs(
                                  el.amount -
                                    (el.amendedAmount
                                      ? el.amendedAmount
                                      : el.amount)
                                )}`}
                          </p>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" className="text-muted">
                        No Recods
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />

      <PaymentApproveModal
        scanId={scanId}
        agentCode={agentCode}
        amount={amount}
        handleChange={handleChange}
        handleApprove={handleApprove}
        loading={loading}
      />

      <PaymentRejectModal
        scanId={scanId}
        agentCode={agentCode}
        handleApprove={handleApprove}
        loading={loading}
      />
    </>
  );
}

export default PendingBankApprovalsCom;
