import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import ReviewAdCom from "../../components/admin/create-ad/ReviewAdCom";

function ReviewAd() {
  return (
    <AdminLayout>
      <ReviewAdCom />
    </AdminLayout>
  );
}

export default ReviewAd;
