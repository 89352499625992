import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticated } from "../../../auth/auth";
import { isAuthenticatedBoth } from "../../../auth/authBoth";

function VirtualAgentsCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);

  const itemsPerPage = 20;

  useEffect(() => {
    if (isAuthenticated()) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/admin/operator_posts`, {
          headers: {
            Authorization: `Bearer ${isAuthenticated()}`,
          },
        })
        .then((res) => {
          setAds(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/operator_posts`, {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setAds(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  // pagination
  const noOfPages = Math.ceil(ads.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = ads.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="virtual Agent" title="All Created Ads" />

      <div className="container-fluid inner-content py-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Ad ID</th>
                  <th className="border-0">Post ID</th>
                  <th className="border-0">Name</th>
                  <th className="border-0">Phone</th>
                  <th className="border-0">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr key={i}>
                      <td width="100">{el.memberId}</td>
                      <td width="100">{el.id}</td>
                      <td width="200">
                        {el.personalInfo.fname} {el.personalInfo.lname}
                      </td>
                      <td width="200">{el.phone}</td>
                      <td>{el.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VirtualAgentsCom;
