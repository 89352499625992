import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import AgentsAdsCreatedCom from "../../components/admin/agents-ads/AgentsAdsCreatedCom";

function AgentsAdsCreated() {
  return (
    <AdminLayout>
      <AgentsAdsCreatedCom />
    </AdminLayout>
  );
}

export default AgentsAdsCreated;
