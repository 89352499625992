// import packages
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import moment from "moment";

import { storeCreateAdDetails } from "../../../actions/storeCreateAdDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";
import Stepper from "../stepper/Stepper";
import Agent from "./sections/Agent";
import Personal from "./sections/Personal";
import Parent from "./sections/Parent";
import Private from "./sections/Private";
import NextBtn from "./sections/NextBtn";
import {isAuthenticatedBoth} from "../../../auth/authBoth";

// import Preview from "./preview/Preview";

const initial = {
  agentCode: "",
  scannedImageId: "",

  fname: "",
  lname: "",
  dob: "",
  gender: "",
  height: null,
  religionId: "",
  ethnicityId: "",
  caste: "",
  civilStatusId: "",
  residentCountryCode: "LK",
  residentCountryCodeOther: "",
  residentRegionId: null,
  residentCity: "",
  visaTypeId: "",
  educationLevelId: null,
  professionId: null,
  otherProfession: "",
  drinking: "",
  smoking: "",
  foodPreferenceId: "",
  additionalInfo: "",

  // father
  fOriginCountryCode: "",
  fEthnicityId: "",
  fReligionId: "",
  fProfessionId: "",
  fOtherProfession: "",
  fCaste: "",
  fAdditionalInfo: "",

  // mother
  mOriginCountryCode: "",
  mEthnicityId: "",
  mReligionId: "",
  mProfessionId: "",
  mOtherProfession: "",
  mCaste: "",
  mAdditionalInfo: "",

  // horoscope
  originCountryCode: "",
  horoscopeMatching: "",
  birthCity: "",
  birthTime: "",

  // contact
  email: "",
  phone: "",

  // address
  showOfflineId: "true",
  subscribeMagazine: "true",
  recipName: "",
  line1: "",
  line2: "",
  city: "",

  fnameErr: "",
  lnameErr: "",
  dobErr: "",
  genderErr: "",
  heightErr: "",
  religionIdErr: "",
  ethnicityIdErr: "",
  civilStatusIdErr: "",
  residentCountryCodeErr: "",
  residentCountryCodeOtherErr: "",
  residentRegionIdErr: "",
  residentCityErr: "",
  visaTypeIdErr: "",
  educationLevelIdErr: "",
  professionIdErr: "",
  otherProfessionErr: "",
  drinkingErr: "",
  smokingErr: "",
  foodPreferenceIdErr: "",

  fOriginCountryCodeErr: "",
  fReligionIdErr: "",
  fEthnicityIdErr: "",
  fOtherProfessionErr: "",

  mOriginCountryCodeErr: "",
  mReligionIdErr: "",
  mEthnicityIdErr: "",
  mOtherProfessionErr: "",

  originCountryCodeErr: "",
  horoscopeMatchingErr: "",
  birthCityErr: "",
  birthTimeErr: "",

  emailErr: "",
  phoneErr: "",

  showOfflineIdErr: "",
  subscribeMagazineErr: "",
  recipNameErr: "",
  line1Err: "",
  cityErr: "",
};

function CreateAdCom(props) {
  const [filters, setFilters] = useState({
    religions: [],
    ethnicities: [],
    civilStatuses: [],
    countries: [],
    visaStatuses: [],
    educationLevels: [],
    professions: [],
    foodPreferences: [],
    regions: [],
    otherCountries: [],
  });
  const [form, setForm] = useState(initial);
  const [showOtherProf, setShowOtherProf] = useState(false);
  const [fShowOtherProf, setFShowOtherProf] = useState(false);
  const [mShowOtherProf, setMShowOtherProf] = useState(false);
  const [requiredAllFieldsErr, setRequiredAllFieldsErr] = useState(false);

  useEffect(() => {
    // filtes
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const sortedReligions = res.data.religions.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedEthnicities = res.data.ethnicities.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedStatuses = res.data.civilStatuses.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedCountries = res.data.countries.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        const sortedVisa = res.data.visaStatuses.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedEdu = res.data.educationLevels.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        const sortedPro = res.data.professions.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        let findOther = res.data.professions.filter(
          (filter) => filter.name === "Other"
        );

        const sortedFood = res.data.foodPreferences.sort(
          (a, b) => a.sortOrder - b.sortOrder
        );

        setFilters((filters) => ({
          ...filters,
          religions: sortedReligions,
          ethnicities: sortedEthnicities,
          civilStatuses: sortedStatuses,
          countries: sortedCountries,
          visaStatuses: sortedVisa,
          educationLevels: sortedEdu,
          professions: sortedPro,
          foodPreferences: sortedFood,
          idOfOther: findOther[0].id,
        }));
      })
      .catch((err) => console.log(err));

    if (
      props.data.post &&
      props.data.post.personalInfo.residentCountryCode === "ZZ"
    ) {
      // country
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
        .then((res) => {
          setFilters((filters) => ({
            ...filters,
            otherCountries: res.data,
            regions: [],
          }));
        })
        .catch((err) => console.log(err));
    } else {
      // regions
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=LK`
        )
        .then((res) => {
          const sortRegions = res.data.sort((a, b) =>
            a.regionName.localeCompare(b.regionName)
          );
          setFilters((filters) => ({
            ...filters,
            regions: sortRegions,
          }));
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    let url_string = document.location.href;
    let url = new URL(url_string);
    let id = url.searchParams.get("id");
    let agentCode = url.searchParams.get("code");

    if (id) {
      setForm({
        ...form,
        scannedImageId: id,
        agentCode: agentCode,
      });
    }

    if (!props.isEmptyPost) {
      getFormData();
    }
  }, []);

  const getFormData = () => {

    setForm((form) => ({
      ...form,
      fname: props.data.post.personalInfo.fname,
      lname: props.data.post.personalInfo.lname,
      dob: props.data.post.personalInfo.dob,
      gender: props.data.post.personalInfo.gender,
      ethnicityId: props.data.post.personalInfo.ethnicityId,
      religionId: props.data.post.personalInfo.religionId,
      caste: props.data.post.personalInfo.caste,
      civilStatusId: props.data.post.personalInfo.civilStatusId,
      height: props.data.post.personalInfo.height,
      residentCountryCode: props.data.post.personalInfo.residentCountryCode,
      residentCountryCodeOther: props.data.post.personalInfo
        .residentCountryCodeOther
        ? props.data.post.personalInfo.residentCountryCodeOther
        : null,
      residentRegionId: props.data.post.personalInfo.residentRegionId,
      residentCity: props.data.post.personalInfo.residentCity,
      visaTypeId: props.data.post.personalInfo.visaTypeId,
      educationLevelId: props.data.post.personalInfo.educationLevelId,
      professionId: props.data.post.personalInfo.professionId,
      otherProfession: props.data.post.personalInfo.otherProfession,
      drinking: props.data.post.personalInfo.drinking,
      smoking: props.data.post.personalInfo.smoking,
      foodPreferenceId: props.data.post.personalInfo.foodPreferenceId,
      additionalInfo: props.data.post.personalInfo.additionalInfo,
      fOriginCountryCode: props.data.post.parentInfo[0].originCountryCode,
      fEthnicityId: props.data.post.parentInfo[0].ethnicityId,
      fReligionId: props.data.post.parentInfo[0].religionId,
      fCaste: props.data.post.parentInfo[0].caste,
      fProfessionId: props.data.post.parentInfo[0].professionId,
      fOtherProfession: props.data.post.parentInfo[0].otherProfession,
      fAdditionalInfo: props.data.post.parentInfo[0].additionalInfo,
      mOriginCountryCode: props.data.post.parentInfo[1].originCountryCode,
      mEthnicityId: props.data.post.parentInfo[1].ethnicityId,
      mReligionId: props.data.post.parentInfo[1].religionId,
      mCaste: props.data.post.parentInfo[1].caste,
      mProfessionId: props.data.post.parentInfo[1].professionId,
      mOtherProfession: props.data.post.parentInfo[1].otherProfession,
      mAdditionalInfo: props.data.post.parentInfo[1].additionalInfo,
      originCountryCode: props.data.post.personalInfo.originCountryCode,
      horoscopeMatching: props.data.post.horoscopeMatching,
      birthTime: props.data.post.birthTime,
      birthCity: props.data.post.birthCity,
      email: props.data.post.email,
      phone: props.data.phoneNumber.replace("+", ""),

      showOfflineId: props.data.preferences.showOfflineId ? "true" : "false",
      subscribeMagazine: props.data.preferences.subscribeMagazine
        ? "true"
        : "false",
      recipName: props.data.userAddress ? props.data.userAddress.name : "",
      line1: props.data.userAddress ? props.data.userAddress.line1 : "",
      line2: props.data.userAddress ? props.data.userAddress.line2 : "",
      city: props.data.userAddress ? props.data.userAddress.city : "",
    }));

    setShowOtherProf(
      props.data.post.personalInfo.professionId === 7 ? true : false
    );

    setFShowOtherProf(
      props.data.post.parentInfo[0].professionId === 7 ? true : false
    );

    setMShowOtherProf(
      props.data.post.parentInfo[1].professionId === 7 ? true : false
    );
  };

  const handleChange = (e) => {
    if (e.target.name === "professionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setShowOtherProf(true);
      } else {
        setShowOtherProf(false);
      }
    }

    if (e.target.name === "fProfessionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setFShowOtherProf(true);
      } else {
        setFShowOtherProf(false);
      }
    }

    if (e.target.name === "mProfessionId") {
      if (parseInt(e.target.value) === filters.idOfOther) {
        setMShowOtherProf(true);
      } else {
        setMShowOtherProf(false);
      }
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };


  const handlePhone = (phone) => {
    setForm({
      ...form,
      phone: phone,
      phoneErr: "",
    });
  };

  const handleChangeCountry = (e) => {
    if (e.target.value === "ZZ") {
      setForm({
        ...form,
        residentRegionId: "",
        residentCountryCodeOther: "",
        residentCountryCode: e.target.value,
      });

      // country
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/generic/countries`)
        .then((res) => {
          setFilters((filters) => ({
            ...filters,
            otherCountries: res.data,
            regions: [],
          }));
        })
        .catch((err) => console.log(err));
    } else {
      setForm({
        ...form,
        residentRegionId: "",
        residentCountryCodeOther: "",
        residentCountryCode: e.target.value,
      });

      // regions
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/generic/regions?countryCode=${e.target.value}`
        )
        .then((res) => {
          const sortRegions = res.data.sort((a, b) =>
            a.regionName.localeCompare(b.regionName)
          );
          setFilters((filters) => ({
            ...filters,
            regions: sortRegions,
          }));
        })
        .catch((err) => console.log(err));
    }
  };

  // validate
  const validate = () => {
    let agentCodeErr = "";
    let scannedImageIdErr = "";
    let fnameErr = "";
    let lnameErr = "";
    let dobErr = "";
    let genderErr = "";
    let religionIdErr = "";
    let ethnicityIdErr = "";
    let civilStatusIdErr = "";
    let heightErr = "";
    let residentCountryCodeErr = "";
    let residentCountryCodeOtherErr = "";
    let residentRegionIdErr = "";
    let residentCityErr = "";
    let visaTypeIdErr = "";
    let educationLevelIdErr = "";
    let professionIdErr = "";
    let otherProfessionErr = "";
    let drinkingErr = "";
    let smokingErr = "";
    let foodPreferenceIdErr = "";
    let fOriginCountryCodeErr = "";
    let mOriginCountryCodeErr = "";
    let fEthnicityIdErr = "";
    let mEthnicityIdErr = "";
    let fReligionIdErr = "";
    let mReligionIdErr = "";
    let fOtherProfessionErr = "";
    let mOtherProfessionErr = "";
    let originCountryCodeErr = "";
    let horoscopeMatchingErr = "";
    let birthCityErr = "";
    let birthTimeErr = "";
    let emailErr = "";
    let phoneErr = "";
    let showOfflineIdErr = "";
    let subscribeMagazineErr = "";
    let recipNameErr = "";
    let line1Err = "";
    let cityErr = "";

    if (!form.agentCode) {
      agentCodeErr = "Agent code is required";
    }

    if (!form.scannedImageId) {
      scannedImageIdErr = "Scan ID is required";
    }

    if (!form.fname) {
      fnameErr = "First name is required";
    } else if (form.fname.length < 2) {
      fnameErr = "First name is too short";
    } else if (!form.fname.match(/^\D+$/)) {
      fnameErr = "First name is Invalid";
    }

    if (!form.lname) {
      lnameErr = "Last name is required";
    } else if (form.lname.length < 2) {
      lnameErr = "First name is too short";
    } else if (!form.lname.match(/^\D+$/)) {
      lnameErr = "First name is Invalid";
    }

    if (!form.dob) {
      dobErr = "Birthdate is required";
    } else if (
      new Date(moment(form.dob).format("MM/DD/YYYY")) >
      new Date(moment().subtract(18, "years").calendar())
    ) {
      dobErr = "Age should be more than 18 years";
    }

    if (!form.gender) {
      genderErr = "Gender is required";
    }

    if (!form.religionId) {
      religionIdErr = "Religion is required";
    }

    if (!form.ethnicityId) {
      ethnicityIdErr = "Ethnicity is required";
    }

    if (!form.civilStatusId) {
      civilStatusIdErr = "Civil status is required";
    }

    if (!form.height) {
      heightErr = "Height is required";
    }

    if (!form.residentCountryCode) {
      residentCountryCodeErr = "Resident country code is required";
    }

    if (!form.residentCity) {
      residentCityErr = "City is required";
    }

    if (form.residentCountryCode === "ZZ") {
      if (!form.residentCountryCodeOther) {
        residentCountryCodeOtherErr = "Country  is required";
      }
    } else {
      if (!form.residentRegionId) {
        residentRegionIdErr = "State / District  is required";
      }
    }

    if (form.residentCountryCode !== "LK") {
      if (!form.visaTypeId) {
        visaTypeIdErr = "visa type is required";
      }
    }

    if (!form.educationLevelId) {
      educationLevelIdErr = "Education Level is required";
    }

    if (!form.professionId) {
      professionIdErr = "Profession is required";
    }

    if (form.professionId === filters.idOfOther) {
      if (parseInt(form.professionId) === filters.idOfOther) {
        if (!form.otherProfession) {
          otherProfessionErr = "Please type your profession";
        } else if (form.otherProfession.length < 2) {
          otherProfessionErr = "Invalid profession";
        }
      }
    }

    if (!form.drinking) {
      drinkingErr = "Drinking is required";
    }

    if (!form.smoking) {
      smokingErr = "Smoking is required";
    }

    if (!form.foodPreferenceId) {
      foodPreferenceIdErr = "Food preference is required";
    }

    if (!form.foodPreferenceId) {
      foodPreferenceIdErr = "Food preference is required";
    }

    if (!form.fOriginCountryCode) {
      fOriginCountryCodeErr = "Origin Country is required";
    }

    if (!form.mOriginCountryCode) {
      mOriginCountryCodeErr = "Origin Country is required";
    }

    if (!form.fEthnicityId) {
      fEthnicityIdErr = "Ethnicity is required";
    }

    if (!form.mEthnicityId) {
      mEthnicityIdErr = "Ethnicity is required";
    }

    if (!form.fReligionId) {
      fReligionIdErr = "Religion is required";
    }

    if (!form.mReligionId) {
      mReligionIdErr = "Religion is required";
    }

    if (
      form.fProfessionId &&
      parseInt(form.fProfessionId) === filters.idOfOther
    ) {
      if (!form.fOtherProfession) {
        fOtherProfessionErr = "Please type profession";
      } else if (form.fOtherProfession.length < 3) {
        fOtherProfessionErr = "Invalid profession";
      }
    }

    if (
      form.mProfessionId &&
      parseInt(form.mProfessionId) === filters.idOfOther
    ) {
      if (!form.mOtherProfession) {
        mOtherProfessionErr = "Please type profession";
      } else if (form.mOtherProfession.length < 3) {
        mOtherProfessionErr = "Invalid profession";
      }
    }

    if (!form.originCountryCode) {
      originCountryCodeErr = "Birth Country is required";
    }

    if (!form.horoscopeMatching) {
      horoscopeMatchingErr = "Horoscope matching is required";
    }

    if (form.horoscopeMatching === "true") {
      // if (!form.birthCity) {
      //   birthCityErr = "Birth city is required";
      // }

      if (!form.birthTime) {
        birthTimeErr = "Birth time is required";
      }
    }

    if (form.email) {
      if (!form.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        emailErr = "Email is invalid";
      }
    }

    if (!form.phone) {
      phoneErr = "Phone is required";
    }

    if (!form.showOfflineId) {
      showOfflineIdErr = "This field is required";
    }

    if (!form.subscribeMagazine) {
      subscribeMagazineErr = "This field is required";
    }

    if (form.showOfflineId === "true" || form.subscribeMagazine === "true") {
      if (!form.recipName) {
        recipNameErr = "Name of recipient is required";
      } else if (
        form.recipName.match(/[`0-9!@#$%^&*()_+\-=\[\]{};':"\\|<>\/?~]/)
      ) {
        recipNameErr = "Name of recipient is Invalid";
      } else if (!form.recipName.trim()) {
        recipNameErr = "Name of recipient is Invalid";
      }

      if (!form.line1) {
        line1Err = "Address line 1 is required";
      } else if (!form.line1.trim()) {
        line1Err = "Address line 1 is Invalid";
      }

      if (!form.city) {
        cityErr = "City is required";
      } else if (!form.city.trim()) {
        cityErr = "City is Invalid";
      }
    }

    if (
      agentCodeErr ||
      scannedImageIdErr ||
      fnameErr ||
      lnameErr ||
      dobErr ||
      genderErr ||
      religionIdErr ||
      ethnicityIdErr ||
      civilStatusIdErr ||
      heightErr ||
      residentCountryCodeErr ||
      residentCountryCodeOtherErr ||
      residentRegionIdErr ||
      residentCityErr ||
      visaTypeIdErr ||
      educationLevelIdErr ||
      professionIdErr ||
      otherProfessionErr ||
      drinkingErr ||
      smokingErr ||
      foodPreferenceIdErr ||
      fOriginCountryCodeErr ||
      mOriginCountryCodeErr ||
      fEthnicityIdErr ||
      mEthnicityIdErr ||
      fReligionIdErr ||
      mReligionIdErr ||
      fOtherProfessionErr ||
      mOtherProfessionErr ||
      originCountryCodeErr ||
      horoscopeMatchingErr ||
      birthCityErr ||
      birthTimeErr ||
      emailErr ||
      phoneErr ||
      showOfflineIdErr ||
      subscribeMagazineErr ||
      recipNameErr ||
      line1Err ||
      cityErr
    ) {
      setForm({
        ...form,
        agentCodeErr,
        scannedImageIdErr,
        fnameErr,
        lnameErr,
        dobErr,
        genderErr,
        religionIdErr,
        ethnicityIdErr,
        civilStatusIdErr,
        heightErr,
        residentCountryCodeErr,
        residentCountryCodeOtherErr,
        residentRegionIdErr,
        residentCityErr,
        visaTypeIdErr,
        educationLevelIdErr,
        professionIdErr,
        otherProfessionErr,
        drinkingErr,
        smokingErr,
        foodPreferenceIdErr,
        fOriginCountryCodeErr,
        mOriginCountryCodeErr,
        fEthnicityIdErr,
        mEthnicityIdErr,
        fReligionIdErr,
        mReligionIdErr,
        fOtherProfessionErr,
        mOtherProfessionErr,
        originCountryCodeErr,
        horoscopeMatchingErr,
        birthCityErr,
        birthTimeErr,
        emailErr,
        phoneErr,
        showOfflineIdErr,
        subscribeMagazineErr,
        recipNameErr,
        line1Err,
        cityErr,
      });

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const phoneData = qs.stringify({
        userId: `+${form.phone}`,
        idType: "PHONE",
      });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/auth/user_exists?${phoneData}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`
            }
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setForm({
              ...form,
              phoneErr: "Phone number is used by another account",
            });
          } else {

            // create product object
            const data = {
              agentCode: form.agentCode,
              phoneNumber: `+${form.phone}`,
              agentScanId: form.scannedImageId,
              post: {
                email: form.email ? form.email : null,
                makePhonePublic: false,
                horoscopeMatching:
                  form.horoscopeMatching === "true" ? true : false,
                birthTime: form.birthTime ? form.birthTime : null,
                birthCity: form.birthCity ? form.birthCity : null,
                description: null,
                images: null,
                parentInfo: [
                  {
                    type: "FATHER",
                    originCountryCode: form.fOriginCountryCode,
                    religionId: form.fReligionId,
                    ethnicityId: form.fEthnicityId,
                    professionId: parseInt(form.fProfessionId),
                    otherProfession:
                      parseInt(form.fProfessionId) === filters.idOfOther
                        ? form.fOtherProfession
                          ? form.fOtherProfession
                          : null
                        : null,
                    caste: form.fCaste ? form.fCaste : null,
                    additionalInfo: form.fAdditionalInfo
                      ? form.fAdditionalInfo
                      : null,
                  },
                  {
                    type: "MOTHER",
                    originCountryCode: form.mOriginCountryCode,
                    religionId: form.mReligionId,
                    ethnicityId: form.mEthnicityId,
                    professionId: parseInt(form.mProfessionId),
                    otherProfession:
                      parseInt(form.mProfessionId) === filters.idOfOther
                        ? form.mOtherProfession
                          ? form.mOtherProfession
                          : null
                        : null,
                    caste: form.mCaste ? form.mCaste : null,
                    additionalInfo: form.mAdditionalInfo
                      ? form.mAdditionalInfo
                      : null,
                  },
                ],
                personalInfo: {
                  fname: form.fname,
                  lname: form.lname,
                  dob: form.dob,
                  gender: form.gender,
                  height: form.height,
                  religionId: form.religionId,
                  ethnicityId: form.ethnicityId,
                  originCountryCode: form.originCountryCode,
                  residentCountryCode: form.residentCountryCode,
                  residentCountryCodeOther: form.residentCountryCodeOther
                    ? form.residentCountryCodeOther
                    : null,
                  residentRegionId: form.residentRegionId
                    ? parseInt(form.residentRegionId)
                    : null,
                  residentCity: form.residentCity,
                  visaTypeId: form.visaTypeId ? form.visaTypeId : "citizen",
                  educationLevelId: parseInt(form.educationLevelId),
                  professionId: parseInt(form.professionId),
                  otherProfession:
                    parseInt(form.professionId) === 7
                      ? form.otherProfession
                        ? form.otherProfession
                        : null
                      : null,
                  civilStatusId: form.civilStatusId,
                  drinking: form.drinking,
                  smoking: form.smoking,
                  foodPreferenceId: form.foodPreferenceId,
                  caste: form.caste ? form.caste : null,
                  additionalInfo: form.additionalInfo
                    ? form.additionalInfo
                    : null,
                },
              },

              userAddress:
                form.showOfflineId === "false" &&
                form.subscribeMagazine === "false"
                  ? null
                  : {
                      name: form.recipName.trim(),
                      line1: form.line1.replace(/,\s*$/, "").trim(),
                      line2: form.line2
                        ? form.line2.replace(/,\s*$/, "").trim()
                        : "",
                      city: form.city.replace(/,\s*$/, "").trim(),
                      countryCode: "LK",
                    },
              preferences: {
                showOfflineId: form.showOfflineId === "true" ? true : false,
                subscribeMagazine:
                  form.subscribeMagazine === "true" ? true : false,
              },
            };

            props.storeCreateAdDetails(data);

            sessionStorage.setItem("ad_data", JSON.stringify(data));

            props.history.push("/dashboard/review");
          }
        })
        .catch((err) => console.log(err));
    } else {
      setRequiredAllFieldsErr(true);
    }
  };

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Agent" title="Create Ad" />

      <div className="container-fluid inner-content">
        <Stepper />

        <Agent filters={filters} form={form} handleChange={handleChange} />

        <Personal
          filters={filters}
          form={form}
          showOtherProf={showOtherProf}
          handleChange={handleChange}
          handleChangeCountry={handleChangeCountry}
        />

        <Parent
          filters={filters}
          form={form}
          fShowOtherProf={fShowOtherProf}
          mShowOtherProf={mShowOtherProf}
          handleChange={handleChange}
        />

        <Private
          filters={filters}
          form={form}
          phone={form.phone}
          requiredAllFieldsErr={requiredAllFieldsErr}
          handleChange={handleChange}
          handlePhone={handlePhone}
          handleSubmit={handleSubmit}
        />

        <NextBtn
          requiredAllFieldsErr={requiredAllFieldsErr}
          handleSubmit={handleSubmit}
        />
      </div>
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ storeCreateAdDetails }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    data: state.createAd.data,
    isEmptyPost:
      Object.keys(state.createAd.data).length === 0 &&
      state.createAd.data.constructor === Object,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(CreateAdCom));
