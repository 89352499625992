// import packages
import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function SendBankDetailsCom(props) {
  const [memberId, setMemberId] = useState("");
  const [phone, setPhone] = useState("");
  const [sendByErr, setSendByErr] = useState("");
  const [memberIdErr, setMemberIdErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendByPhone, setSendByPhone] = useState(false);
  const [sendByMemberId, setSendByMemberId] = useState(true);

  const handlePhone = (phone) => {
    setPhone(`+${phone}`);
    setSendByErr("");
  };

  // validate
  const validate = () => {
    let sendByErr = "";
    let memberIdErr = "";
    if (sendByPhone && phone.length < 4) {
      sendByErr = "Please fill phone number";
      setSendByErr(sendByErr);
      return false;
    } else if (sendByMemberId && !memberId) {
      memberIdErr = "Please fill member Id";
      setSendByErr("");
      setMemberIdErr(memberIdErr);
      return false;
    } else {
      return true;
    }
  };

  const handleSend = () => {
    if (validate()) {
      setLoading(true);
      let data = "";
      if (sendByPhone) {
        data = qs.stringify({
          phone
        });
      } else {
        data = qs.stringify({
          memberId
        });
      }

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/operator/bank_details_sms`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setMemberId("");
          setSendByErr("");
          setPhone("+94");
          NotificationManager.success("Sent successfully!", "Success");
        })
        .catch((err) => {
          setLoading(false);
          NotificationManager.error(err.response.data.message, "Error");
        });
    }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Send Bank Details" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="content py-3">
                <div className="col-sm-6 px-2 3">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="customRadio"
                      checked={sendByMemberId}
                      className="custom-control-input"
                      onChange={(e) => {
                        setSendByPhone(false);
                        setSendByMemberId(true);
                        setMemberIdErr("");
                        setSendByErr("");
                      }}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                    >
                      Send Using Member ID
                    </label>
                  </div>
                  <div className="custom-control custom-radio mt-3 mb-4">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="customRadio"
                      checked={sendByPhone}
                      className="custom-control-input"
                      onChange={(e) => {
                        setSendByPhone(true);
                        setSendByMemberId(false);
                        setMemberIdErr("");
                        setSendByErr("");
                      }}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      Send Using Phone Number
                    </label>
                  </div>
                </div>
                { sendByMemberId && (
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="memberId">
                    Member ID
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": memberIdErr,
                    })}
                    id="memberId"
                    name="memberId"
                    onChange={(e) => {
                      setMemberId(e.target.value);
                      setSendByErr("");
                    }}
                    value={memberId}
                  />
                  <div style={{color:"#dc3545"}}>{memberIdErr}</div>
                </div>)}

                { sendByPhone && (
                  <div className="form-group col-sm-6 px-2 3">
                    <label htmlFor="memberId">
                      Phone Number
                    </label>

                    <PhoneInput
                      country="lk"
                      value={phone}
                      enableSearch={true}
                      disableSearchIcon={true}
                      countryCodeEditable={false}
                      prefix="+"
                      onChange={(phone) => handlePhone(phone)}
                    />
                    <div style={{color:"#dc3545"}}>{sendByErr}</div>
                  </div>)}

              </div>
              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleSend}
                style={{ width: 100, float: "right" }}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Send"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default SendBankDetailsCom;
