import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import AgentsAdsCom from "../../components/admin/agents-ads/AgentsAdsCom";

function AgentsAds() {
  return (
    <AdminLayout>
      <AgentsAdsCom />
    </AdminLayout>
  );
}

export default AgentsAds;
