import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import qs from "qs";
import axios from "axios";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";
import Close from "../../../images/close-white.png";

function PaymentApproveModal(props) {
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);

  const addToBlacklist = (e) => {
    setPhoneError("")
    e.preventDefault();
    if (phone) {
      setLoading(true);
      axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/phonenumbers/blacklist`,
        qs.stringify({ number: phone }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          },
        }
      )
      .then((res) => {
        setPhone("+94")
        setLoading(false);
        props.handleAddedSuccess();
      })
      .catch((err) => {
        setLoading(false);
        setPhoneError(err.response.data.message);
      });
    } else {
      setPhoneError("Number is required");
    }
    
  };

  const handlePhone = (e) => {
    setPhone(e);
    setPhoneError("");
  }

  return (
    <div
      className="modal fade"
      id="addToBlacklistModal"
      tabIndex="-1"
      aria-labelledby="paymentApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentApproveModalLabel">
              Add to blacklist
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="addBlacklistModalClose"
            />
          </div>
          <div className="modal-body">
            <div className="form-group col-8 p-0 mb-3 m-auto">
              <h6 className="mb-2">Phone Number</h6>
              <PhoneInput
                country="lk"
                value={phone}
                enableSearch={true}
                disableSearchIcon={true}
                countryCodeEditable={false}
                prefix="+"
                onChange={(e) => handlePhone(e)}
              />
              <p
                className="text-danger mt-1 mb-0"
                style={{ fontSize: 14 }}
              >
                {phoneError}
              </p>
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={addToBlacklist}
              style={{ width: 95 }}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Blacklist"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentApproveModal;
