import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function InreviewAdsCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/posts?status=IN_REVIEW`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        const sortByDate = res.data.sort((a, b) =>
          b.createdAt.localeCompare(a.createdAt)
        );

        setAds(sortByDate);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const filterBySearch = ads.filter((el) => {
    if (search) {
      return (
        el.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.memberId.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.personalInfo.fname.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        el.personalInfo.lname.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        (el.phone &&
          el.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  //   pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Ads" title="In Review" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="IDs / Name / Phone / Email"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="row m-0 mt-3">
        <div className="col p-0">
          <div className="pagination-div">
            {noOfPages > 1 && (
              <Pagination
                count={noOfPages}
                page={page}
                onChange={handlePagination}
                className="pagi"
              />
            )}
          </div>
        </div>
      </div>

      <div className="container-fluid inner-content pt-0 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <p style={{ fontSize: 14, marginBottom: 10 }}>
            {ads.length > 0
              ? `Showing ${indexOfFirst + 1} - ${
                  indexOfFirst + currentItems.length
                } out of ${ads.length} results`
              : "No results"}
          </p>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Ad ID</th>
                  <th className="border-0">Post ID</th>
                  <th className="border-0">First Name</th>
                  <th className="border-0">Last Name</th>
                  <th className="border-0">Phone</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Status</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr
                      key={i}
                      onClick={() => history.push(`/post?id=${el.id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td width="100">{el.memberId}</td>
                      <td width="100">{el.id}</td>
                      <td width="125">{el.personalInfo.fname}</td>
                      <td width="125">{el.personalInfo.lname}</td>
                      <td width="150">{el.phone ? el.phone : "-"}</td>
                      <td width="200">{el.email ? el.email : "-"}</td>
                      <td>{el.status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InreviewAdsCom;
