import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactDatePicker from "react-datepicker";

import "./styles.scss";

const DatePicker = ({ label, selectedDate, onChangeDate }) => {
  return (
    <Grid container direction="row" className="date-picker-container">
      <Typography className="label">{label}</Typography>
      <ReactDatePicker
        showIcon
        selected={selectedDate}
        fixedHeight
        className="picker-input"
        onChange={(date) => {
          onChangeDate(date);
        }}
        maxDate={new Date()}
        dateFormat="yyyy-MM-dd"
      />
    </Grid>
  );
};

export default DatePicker;
