import axios from "axios";
import React, { useState } from "react";
import moment from "moment/moment";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import PhoneInput from "react-phone-input-2";

function RecentSms() {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(false);
  const [inputErrorMsg, setInputErrorMsg] = useState("");
  const [inputError, setInputError] = useState(false);
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    setMessages([]);

    if (search) {
      setError(false);
      setLoading(true);

      const params = new URLSearchParams();
      params.append("phoneNumber", `+${search}`);

        axios
          .get(`${process.env.REACT_APP_API_URL}/v1/admin/sms_logs?${params}`, {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          })
          .then((res) => {
            if (res.data.length === 0) {
              setError(true);
              setLoading(false);
            } else {
              setMessages(res.data);
              setLoading(false);
            }
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
    } else {
      setInputErrorMsg("Field empty");
      setInputError(true);
    }
  };

  const formatDate = (dateVal) => {
    if (moment(dateVal).isValid()) {
      return moment(dateVal).format("YYYY-MMM-D | h:mm:ss a");
    } else {
      return "Not available";
    }
  };

  return (
    <div className="content-col">
      <Breadcrumb title="Search Recent SMS" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="d-flex flex-column">
              <div className="d-flex flex-row">
                <PhoneInput
                  inputProps={{
                    autoFocus: true,
                  }}
                  country="lk"
                  value={search}
                  prefix="+"
                  onChange={(phone) => setSearch(phone)}
                  enableSearch={true}
                  countryCodeEditable={false}
                  disableSearchIcon={true}
                  onClick={() => setInputError(false)}
                />
                <button
                  className="btn btn-dark d-block ml-2"
                  style={{ width: 50 }}
                  onClick={handleSearch}
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <i className="fas fa-search"></i>
                  )}
                </button>
              </div>
              <div>
                {inputError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "13px",
                      marginTop: "3px",
                      marginLeft: "5px",
                    }}
                  >
                    {inputErrorMsg}
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-0 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          {messages?.length > 0 ? (
            <div className="content p-0">
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th className="border-0">Phone no.</th>
                    <th className="border-0">Message</th>
                    <th className="border-0">Date/Time</th>
                  </tr>
                </thead>
                <tbody>
                  {messages?.map((el, i) => (
                    <tr key={i}>
                      <td width="200">{el?.number}</td>
                      <td width="600">{el?.message}</td>
                      <td width="300">
                        {moment
                          .utc(el?.createdAt)
                          .local()
                          .format("DD-MMM-YYYY hh:mm a")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : error ? (
            <p>Not found!</p>
          ) : (
            <p>No records</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default RecentSms;
