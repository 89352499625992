import React from "react";

function Filter(props) {
  return (
    <div className="row mt-5 mb-4 filter-row">
      <div className="col p-0 pb-3">
        <div className="form-row m-0 mb-1" style={{justifyContent: "center"}}>
          <div className="filter-col">
            <label>From</label>
            <input
              type="date"
              className="form-control"
              name="from"
              value={props.from}
              onChange={(e) => props.setFrom(e.target.value)}
            />
          </div>
          <div className="filter-col">
            <label>To</label>
            <input
              type="date"
              className="form-control"
              name="to"
              value={props.to}
              onChange={(e) => props.setTo(e.target.value)}
            />
          </div>
        </div>
        <button
          className="btn btn-dark d-block m-auto"
          onClick={() => props.handleSearchBtn(props.from, props.to)}
          style={{ width: 150 }}
        >
          {props.loading ? (
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            "Search"
          )}
        </button>
      </div>
    </div>
  );
}

export default Filter;
