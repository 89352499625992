// import packages
import React, { useState, useEffect } from "react";
import axios from "axios";
import classnames from "classnames";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";
import DashboardItemCom from "./chartComp";
import PieChartComp from "./pieChartComp";
import PaymentHistoryTable from "./PaymentHistoryTable";
import UTMLogsTable from "./UTMLogsTable";
import moment from "moment";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { format } from "date-fns-tz";
import "./_statDashboard.scss";

function DashBoardCom(props) {
  const [dailyVisitsType, setDailyVisitsType] = useState("daily");
  const [dailyVisitsDuration, setDailyVisitsDuration] = useState("6m");
  const [dailyVisitsData, setDailyVisitsData] = useState([]);
  const [dailyVisitsLoading, setDailyVisitsLoading] = useState(true);
  const [dailyVisitsMax, setDailyVisitsMax] = useState(100);
  const [dailyVisitsTotal, setDailyVisitsTotal] = useState(0);
  const [dailyVisitsStartDate, setDailyVisitsStartDate] = useState(new Date());
  const [dailyVisitsEndDate, setDailyVisitsEndDate] = useState(new Date());

  const [uniqueVisitorsType, setUniqueVisitorsType] = useState("daily");
  const [uniqueVisitorsDuration, setUniqueVisitorsDuration] = useState("6m");
  const [uniqueVisitorsData, setUiqueVisitorsData] = useState([]);
  const [uniqueVisitorsLoading, setUniqueVisitorsLoading] = useState(true);
  const [uniqueVisitorsMax, setUniqueVisitorsMax] = useState(100);
  const [uniqueVisitorsTotal, setUniqueVisitorsTotal] = useState(0);
  const [uniqueVisitorsStartDate, setUniqueVisitorsStartDate] = useState(
    new Date()
  );
  const [uniqueVisitorsEndDate, setUniqueVisitorsEndDate] = useState(
    new Date()
  );

  const [dailySessionsType, setDailySessionsType] = useState("daily");
  const [dailySessionsDuration, setDailySessionsDuration] = useState("6m");
  const [dailySessionsData, setDailySessionsData] = useState([]);
  const [dailySessionsLoading, setDailySessionsLoading] = useState(true);
  const [dailySessionsMax, setDailySessionsMax] = useState(100);
  const [dailySessionsTotal, setDailySessionsTotal] = useState(0);
  const [dailySessionsStartDate, setDailySessionsStartDate] = useState(
    new Date()
  );
  const [dailySessionsEndDate, setDailySessionsEndDate] = useState(new Date());

  const [bounceBackRateType, setBounceBackRateType] = useState("daily");
  const [bounceBackRatesDuration, setBounceBackRatesDuration] = useState("6m");
  const [bounceBackRateData, setBounceBackRateData] = useState([]);
  const [bounceBackRateLoading, setBounceBackRateLoading] = useState(true);
  const [bounceBackRateMax, setBounceBackRateMax] = useState(100);
  const [bounceBackRateTotal, setBounceBackRateTotal] = useState(0);
  const [bounceBackRateStartDate, setBounceBackRateStartDate] = useState(
    new Date()
  );
  const [bounceBackRateEndDate, setBounceBackRateEndDate] = useState(
    new Date()
  );

  const [sessionDurationType, setSessionDurationType] = useState("daily");
  const [sessionDurationDuration, setSessionDurationDuration] = useState("6m");
  const [sessionDurationData, setSessionDurationData] = useState([]);
  const [sessionDurationLoading, setSessionDurationLoading] = useState(true);
  const [sessionDurationMax, setSessionDurationMax] = useState(100);
  const [sessionDurationTotal, setSessionDurationTotal] = useState(0);
  const [sessionDurationStartDate, setSessionDurationStartDate] = useState(
    new Date()
  );
  const [sessionDurationEndDate, setSessionDurationEndDate] = useState(
    new Date()
  );

  const [userDeviceDistributionType, setUserDeviceDistributionType] =
    useState("daily");
  const [userDeviceDistributionDuration, setUserDeviceDistributionDuration] =
    useState("6m");
  const [userDeviceDistributionData, setUserDeviceDistributionData] = useState(
    []
  );
  const [userDeviceDistributionLoading, setUserDeviceDistributionLoading] =
    useState(true);
  const [userDeviceDistributionTotal, setUserDeviceDistributionTotal] =
    useState(0);
  const [userDeviceDistributionStartDate, setUserDeviceDistributionStartDate] =
    useState(new Date());
  const [userDeviceDistributionEndDate, setUserDeviceDistributionEndDate] =
    useState(new Date());

  const [averagePostViewsType, setAveragePostViewsType] = useState("daily");
  const [averagePostViewsDuration, setAveragePostViewsDuration] =
    useState("6m");
  const [averagePostViewsData, setAveragePostViewsData] = useState([]);
  const [averagePostViewsLoading, setAveragePostViewsLoading] = useState(true);
  const [averagePostViewsMax, setAveragePostViewsMax] = useState(100);
  const [averagePostViewsTotal, setAveragePostViewsTotal] = useState(0);
  const [averagePostViewsStartDate, setAveragePostViewsStartDate] = useState(
    new Date()
  );
  const [averagePostViewsEndDate, setAveragePostViewsEndDate] = useState(
    new Date()
  );

  const [impressionsPerAdType, setImpressionsPerAdType] = useState("daily");
  const [impressionsPerAdDuration, setImpressionsPerAdDuration] =
    useState("6m");
  const [impressionsPerAdData, setImpressionsPerAdData] = useState([]);
  const [impressionsPerAdLoading, setImpressionsPerAdLoading] = useState(true);
  const [impressionsPerAdMax, setImpressionsPerAdMax] = useState(100);
  const [impressionsPerAdTotal, setImpressionsPerAdTotal] = useState(0);
  const [impressionsPerAdStartDate, setImpressionsPerAdStartDate] = useState(
    new Date()
  );
  const [impressionsPerAdEndDate, setImpressionsPerAdEndDate] = useState(
    new Date()
  );

  const [liveAdsCountType, setLiveAdsCountType] = useState("daily");
  const [liveAdsCountDuration, setLiveAdsCountDuration] = useState("6m");
  const [liveAdsCountData, setLiveAdsCountData] = useState([]);
  const [liveAdsCountLoading, setLiveAdsCountLoading] = useState(true);
  const [liveAdsCountMax, setLiveAdsCountMax] = useState(100);
  const [liveAdsCountTotal, setLiveAdsCountTotal] = useState(0);
  const [liveAdsCountStartDate, setLiveAdsCountStartDate] = useState(
    new Date()
  );
  const [liveAdsCountEndDate, setLiveAdsCountEndDate] = useState(new Date());

  const [dailyLiveAdType, setDailyLiveAdType] = useState("daily");
  const [dailyLiveAdDuration, setDailyLiveAdDuration] = useState("6m");
  const [dailyLiveAdData, setDailyLiveAdData] = useState([]);
  const [dailyLiveAdLoading, setDailyLiveAdLoading] = useState(true);
  const [dailyLiveAdMax, setDailyLiveAdMax] = useState(100);
  const [dailyLiveAdStartDate, setDailyLiveAdStartDate] = useState(new Date());
  const [dailyLiveAdEndDate, setDailyLiveAdEndDate] = useState(new Date());

  const [dailyRenewalsAdType, setDailyRenewalsAdType] = useState("daily");
  const [dailyRenewalsAdDuration, setDailyRenewalsAdDuration] = useState("6m");
  const [dailyRenewalsAdData, setDailyRenewalsAdData] = useState([]);
  const [dailyRenewalsAdLoading, setDailyRenewalsAdLoading] = useState(true);
  const [dailRenewalsAdMax, setDailyRenewalsAdMax] = useState(100);
  const [dailyRenewalsStartDate, setDailyRenewalsAdStartDate] = useState(
    new Date()
  );
  const [dailyRenewalsAdEndDate, setDailyRenewalsAdEndDate] = useState(
    new Date()
  );

  const [dailyExpiredAdsType, setDailyExpiredAdsType] = useState("daily");
  const [dailyExpiredAdsDuration, setDailyExpiredAdsDuration] = useState("6m");
  const [dailyExpiredAdsData, setDailyExpiredAdsData] = useState([]);
  const [dailyExpiredAdsLoading, setDailyExpiredAdsLoading] = useState(true);
  const [dailyExpiredAdsMax, setDailyExpiredAdsMax] = useState(0);
  const [dailyExpiredAdsStartDate, setDailyExpiredAdsStartDate] = useState(
    new Date()
  );
  const [dailyExpiredAdsEndDate, setDailyExpiredAdsEndDate] = useState(
    new Date()
  );

  const [dailyUnpublishedAdsType, setDailyUnpublishedAdsType] =
    useState("daily");
  const [dailyUnpublishedAdsDuration, setDailyUnpublishedAdsDuration] =
    useState("6m");
  const [dailyUnpublishedAdsData, setDailyUnpublishedAdsData] = useState([]);
  const [dailyUnpublishedAdsLoading, setDailyUnpublishedAdsLoading] =
    useState(true);
  const [dailyUnpublishedAdsMax, setDailyUnpublishedAdsMax] = useState(0);
  const [dailyUnpublishedAdsStartDate, setDailyUnpublishedAdsStartDate] =
    useState(new Date());
  const [dailyUnpublishedAdsEndDate, setDailyUnpublishedAdsEndDate] = useState(
    new Date()
  );

  const [dailyExpiringForecastAdsType, setDailyExpiringForecastAdsType] =
    useState("daily");
  const [
    dailyExpiringForecastAdsDuration,
    setDailyExpiringForecastAdsDuration,
  ] = useState("4w");
  const [dailyExpiringForecastAdsData, setDailyExpiringForecastAdsData] =
    useState([]);
  const [dailyExpiringForecastAdsLoading, setDailyExpiringForecastAdsLoading] =
    useState(true);
  const [dailyExpiringForecastAdsMax, setDailyExpiringForecastAdsMax] =
    useState(0);

  const today = new Date();

  let nextMonthDay = new Date();

  nextMonthDay.setMonth(nextMonthDay.getMonth() + 1);
  const [
    dailyExpiringForecastAdsStartDate,
    setDailyExpiringForecastAdsStartDate,
  ] = useState(today);
  const [dailyExpiringForecastAdsEndDate, setDailyExpiringForecastAdsEndDate] =
    useState(nextMonthDay);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    setDailyVisitsLoading(true);
    generateGraph(
      "website-visits",
      dailyVisitsType,
      dailyVisitsDuration,
      dailyVisitsStartDate,
      dailyVisitsEndDate
    );
  }, [
    dailyVisitsType,
    dailyVisitsDuration,
    dailyVisitsStartDate,
    dailyVisitsEndDate,
  ]);

  useEffect(() => {
    setUniqueVisitorsLoading(true);
    generateGraph(
      "unique-visitors",
      uniqueVisitorsType,
      uniqueVisitorsDuration,
      uniqueVisitorsStartDate,
      uniqueVisitorsEndDate
    );
  }, [
    uniqueVisitorsType,
    uniqueVisitorsDuration,
    uniqueVisitorsStartDate,
    uniqueVisitorsEndDate,
  ]);

  useEffect(() => {
    setDailySessionsLoading(true);
    generateGraph(
      "daily-sessions",
      dailySessionsType,
      dailySessionsDuration,
      dailySessionsStartDate,
      dailySessionsEndDate
    );
  }, [
    dailySessionsType,
    dailySessionsDuration,
    dailySessionsStartDate,
    dailySessionsEndDate,
  ]);

  useEffect(() => {
    setBounceBackRateLoading(true);
    generateGraph(
      "bounce-back-rate",
      bounceBackRateType,
      bounceBackRatesDuration,
      bounceBackRateStartDate,
      bounceBackRateEndDate
    );
  }, [
    bounceBackRateType,
    bounceBackRatesDuration,
    bounceBackRateStartDate,
    bounceBackRateEndDate,
  ]);

  useEffect(() => {
    setSessionDurationLoading(true);
    generateGraph(
      "session-duration",
      sessionDurationType,
      sessionDurationDuration,
      sessionDurationStartDate,
      sessionDurationEndDate
    );
  }, [
    sessionDurationType,
    sessionDurationDuration,
    sessionDurationStartDate,
    sessionDurationEndDate,
  ]);

  useEffect(() => {
    setUserDeviceDistributionLoading(true);
    generateGraph(
      "user-device-distribution",
      userDeviceDistributionType,
      userDeviceDistributionDuration,
      userDeviceDistributionStartDate,
      userDeviceDistributionEndDate
    );
  }, [
    userDeviceDistributionType,
    userDeviceDistributionDuration,
    userDeviceDistributionStartDate,
    userDeviceDistributionEndDate,
  ]);

  useEffect(() => {
    setAveragePostViewsLoading(true);
    generateGraph(
      "average-post-views",
      averagePostViewsType,
      averagePostViewsDuration,
      averagePostViewsStartDate,
      averagePostViewsEndDate
    );
  }, [
    averagePostViewsType,
    averagePostViewsDuration,
    averagePostViewsStartDate,
    averagePostViewsEndDate,
  ]);

  useEffect(() => {
    setImpressionsPerAdLoading(true);
    generateGraph(
      "impressions-per-ad",
      impressionsPerAdType,
      impressionsPerAdDuration,
      impressionsPerAdStartDate,
      impressionsPerAdEndDate
    );
  }, [
    impressionsPerAdType,
    impressionsPerAdDuration,
    impressionsPerAdStartDate,
    impressionsPerAdEndDate,
  ]);

  useEffect(() => {
    setLiveAdsCountLoading(true);
    generateGraph(
      "live-ads-count",
      liveAdsCountType,
      liveAdsCountDuration,
      liveAdsCountStartDate,
      liveAdsCountEndDate
    );
  }, [
    liveAdsCountType,
    liveAdsCountDuration,
    liveAdsCountStartDate,
    liveAdsCountEndDate,
  ]);

  useEffect(() => {
    setDailyLiveAdLoading(true);
    generateGraph(
      "daily-new-live-ads-count",
      dailyLiveAdType,
      dailyLiveAdDuration,
      dailyLiveAdStartDate,
      dailyLiveAdEndDate
    );
  }, [
    dailyLiveAdDuration,
    dailyLiveAdType,
    dailyLiveAdStartDate,
    dailyLiveAdEndDate,
  ]);

  useEffect(() => {
    setDailyRenewalsAdLoading(true);
    generateGraph(
      "daily-renewed-ads-count",
      dailyRenewalsAdType,
      dailyRenewalsAdDuration,
      dailyRenewalsStartDate,
      dailyRenewalsAdEndDate
    );
  }, [
    dailyRenewalsAdType,
    dailyRenewalsAdDuration,
    dailyRenewalsStartDate,
    dailyRenewalsAdEndDate,
  ]);

  useEffect(() => {
    setDailyExpiredAdsLoading(true);
    generateGraph(
      "daily-expired-ads-count",
      dailyExpiredAdsType,
      dailyExpiredAdsDuration,
      dailyExpiredAdsStartDate,
      dailyExpiredAdsEndDate
    );
  }, [
    dailyExpiredAdsType,
    dailyExpiredAdsDuration,
    dailyExpiredAdsStartDate,
    dailyExpiredAdsEndDate,
  ]);

  useEffect(() => {
    setDailyUnpublishedAdsLoading(true);
    generateGraph(
      "daily-unpublished-ads-count",
      dailyUnpublishedAdsType,
      dailyUnpublishedAdsDuration,
      dailyUnpublishedAdsStartDate,
      dailyUnpublishedAdsEndDate
    );
  }, [
    dailyUnpublishedAdsType,
    dailyUnpublishedAdsDuration,
    dailyUnpublishedAdsStartDate,
    dailyUnpublishedAdsEndDate,
  ]);

  useEffect(() => {
    setDailyExpiringForecastAdsLoading(true);
    generateGraph(
      "daily-expiring-forecast",
      dailyExpiringForecastAdsType,
      dailyExpiringForecastAdsDuration,
      dailyExpiringForecastAdsStartDate,
      dailyExpiringForecastAdsEndDate
    );
  }, [
    dailyExpiringForecastAdsType,
    dailyExpiringForecastAdsDuration,
    dailyExpiringForecastAdsStartDate,
    dailyExpiringForecastAdsEndDate,
  ]);

  const setDateFormat = (frequency, dimension) => {
    if (frequency === "monthly") {
      return moment(dimension).format("yyyy MMM");
    } else if (frequency === "weekly") {
      return dimension.substring(0, 4) + "-" + dimension.substring(4);
    } else {
      return moment(dimension, "YYYYMMDD").format("yyyy MMM DD");
    }
  };

  const formatDate = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd", {
      timeZone: "Asia/Colombo",
    });
    return formattedDate;
  };

  const generateGraph = (
    chartName,
    timeUnit,
    chartDuration,
    startDate,
    endDate
  ) => {
    const urlParams = new URLSearchParams();

    urlParams.append("report", chartName);
    urlParams.append("frequency", timeUnit);
    urlParams.append("duration", chartDuration);

    if (
      chartDuration == "custom" &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      urlParams.append("startDate", formatDate(startDate));
      urlParams.append("endDate", formatDate(endDate));
    }

    if (chartName === "daily-expiring-forecast") {
      urlParams.append("futureDuration", true);
    }

    axios
      // .get(`${process.env.REACT_APP_API_URL}/v1/admin/analytics/reports?report=${chartName}&frequency=${timeUnit}&duration=${chartDuration}`, {
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/v1/admin/analytics/reports?${urlParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.report === "website-visits") {
          setDailyVisitsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setDailyVisitsData(newArrayOfObj);
          setDailySessionsTotal(res.data.total);
          setDailyVisitsLoading(false);
          setDailyVisitsTotal(res.data.total);
        } else if (res.data.report === "unique-visitors") {
          setUniqueVisitorsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setUiqueVisitorsData(newArrayOfObj);
          setUniqueVisitorsTotal(res.data.total);
          setUniqueVisitorsLoading(false);
        } else if (res.data.report === "daily-sessions") {
          setDailySessionsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setDailySessionsData(newArrayOfObj);
          setDailySessionsTotal(res.data.total);
          setDailySessionsLoading(false);
        } else if (res.data.report === "bounce-back-rate") {
          setBounceBackRateMax(parseFloat(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseFloat(user.value).toFixed(2),
          }));
          setBounceBackRateData(newArrayOfObj);
          setBounceBackRateTotal(parseFloat(res.data.total).toFixed(2) + "%");
          setBounceBackRateLoading(false);
        } else if (res.data.report === "session-duration") {
          setSessionDurationMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setSessionDurationData(newArrayOfObj);
          setSessionDurationTotal(parseFloat(res.data.total).toFixed(2));
          setSessionDurationLoading(false);
        } else if (res.data.report === "user-device-distribution") {
          let newArrayOfObj = res.data.dataset.map((user) => ({
            name: user.dimension,
            value: parseInt(user.value),
          }));
          setUserDeviceDistributionData(newArrayOfObj);
          setUserDeviceDistributionTotal(res.data.total);
          setUserDeviceDistributionLoading(false);
        } else if (res.data.report === "average-post-views") {
          setAveragePostViewsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setAveragePostViewsData(newArrayOfObj);
          setAveragePostViewsTotal(res.data.total);
          setAveragePostViewsLoading(false);
        } else if (res.data.report === "daily-expiring-forecast") {
          setImpressionsPerAdMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setImpressionsPerAdData(newArrayOfObj);
          setImpressionsPerAdTotal(res.data.total);
          setImpressionsPerAdLoading(false);
        } else if (res.data.report === "live-ads-count") {
          setLiveAdsCountMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            // date: res.data.frequency === 'monthly' ? moment(user.dimension).format('yyyy MMM') : moment(user.dimension).format('yyyy MMM DD'),
            value: parseInt(user.value),
          }));
          setLiveAdsCountData(newArrayOfObj);
          setLiveAdsCountTotal(res.data.total);
          setLiveAdsCountLoading(false);
        } else if (res.data.report === "daily-new-live-ads-count") {
          setDailyLiveAdMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            value: parseInt(user.value),
          }));
          setDailyLiveAdData(newArrayOfObj);
          setDailyLiveAdLoading(false);
        } else if (res.data.report === "daily-renewed-ads-count") {
          setDailyRenewalsAdMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            value: parseInt(user.value),
          }));
          setDailyRenewalsAdData(newArrayOfObj);
          setDailyRenewalsAdLoading(false);
        } else if (res.data.report === "daily-expired-ads-count") {
          setDailyExpiredAdsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            value: parseInt(user.value),
          }));
          setDailyExpiredAdsData(newArrayOfObj);
          setDailyExpiredAdsLoading(false);
        } else if (res.data.report === "daily-unpublished-ads-count") {
          setDailyUnpublishedAdsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            value: parseInt(user.value),
          }));
          setDailyUnpublishedAdsData(newArrayOfObj);
          setDailyUnpublishedAdsLoading(false);
        } else if (res.data.report == "impressions-per-ad") {
          setDailyExpiringForecastAdsMax(parseInt(res.data.maximum));
          let newArrayOfObj = res.data.dataset.map((user) => ({
            ...user,
            date: setDateFormat(res.data.frequency, user.dimension),
            value: parseInt(user.value),
          }));
          setDailyExpiringForecastAdsData(newArrayOfObj);
          setDailyExpiringForecastAdsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDailyVisitsLoading(false);
        setUniqueVisitorsLoading(false);
        setDailySessionsLoading(false);
        setBounceBackRateLoading(false);
        setSessionDurationLoading(false);
        setUserDeviceDistributionLoading(false);
        setAveragePostViewsLoading(false);
        setImpressionsPerAdLoading(false);
        setLiveAdsCountLoading(false);
        setDailyRenewalsAdLoading(false);
        setDailyLiveAdLoading(false);
        setDailyUnpublishedAdsLoading(false);
        setDailyExpiringForecastAdsLoading(false);
      });
  };

  const handleChartType = (chartName, type) => {
    if (chartName === "website-visits") {
      setDailyVisitsType(type);
    } else if (chartName === "unique-visitors") {
      setUniqueVisitorsType(type);
    } else if (chartName === "daily-sessions") {
      setDailySessionsType(type);
    } else if (chartName === "bounce-back-rate") {
      setBounceBackRateType(type);
    } else if (chartName === "session-duration") {
      setSessionDurationType(type);
    } else if (chartName === "user-device-distribution") {
      setUserDeviceDistributionType(type);
    } else if (chartName === "average-post-views") {
      setAveragePostViewsType(type);
    } else if (chartName === "impressions-per-ad") {
      setImpressionsPerAdType(type);
    } else if (chartName === "live-ads-count") {
      setLiveAdsCountType(type);
    } else if (chartName === "daily-new-live-ads-count") {
      setDailyLiveAdType(type);
    } else if (chartName === "daily-renewed-ads-count") {
      setDailyRenewalsAdType(type);
    } else if (chartName === "daily-expired-ads-count") {
      setDailyExpiredAdsType(type);
    } else if (chartName == "daily-unpublished-ads-count") {
      setDailyUnpublishedAdsType(type);
    } else if (chartName === "daily-expiring-forecast") {
      setDailyExpiringForecastAdsType(type);
    }
  };

  const handlechartDuration = (chartName, val) => {
    if (chartName === "website-visits") {
      setDailyVisitsDuration(val);
    } else if (chartName === "unique-visitors") {
      setUniqueVisitorsDuration(val);
    } else if (chartName === "daily-sessions") {
      setDailySessionsDuration(val);
    } else if (chartName === "bounce-back-rate") {
      setBounceBackRatesDuration(val);
    } else if (chartName === "session-duration") {
      setSessionDurationDuration(val);
    } else if (chartName === "user-device-distribution") {
      setUserDeviceDistributionDuration(val);
    } else if (chartName === "average-post-views") {
      setAveragePostViewsDuration(val);
    } else if (chartName === "impressions-per-ad") {
      setImpressionsPerAdDuration(val);
    } else if (chartName === "live-ads-count") {
      setLiveAdsCountDuration(val);
    } else if (chartName === "daily-new-live-ads-count") {
      setDailyLiveAdDuration(val);
    } else if (chartName === "daily-renewed-ads-count") {
      setDailyRenewalsAdDuration(val);
    } else if (chartName === "daily-expired-ads-count") {
      setDailyExpiredAdsDuration(val);
    } else if (chartName == "daily-unpublished-ads-count") {
      setDailyUnpublishedAdsDuration(val);
    } else if (chartName === "daily-expiring-forecast") {
      setDailyExpiringForecastAdsDuration(val);
    }
  };

  const handleCustomStartDate = (chartName, date) => {
    if (chartName === "website-visits") {
      setDailyVisitsStartDate(date);
    } else if (chartName === "unique-visitors") {
      setUniqueVisitorsStartDate(date);
    } else if (chartName === "daily-sessions") {
      setDailySessionsStartDate(date);
    } else if (chartName === "bounce-back-rate") {
      setBounceBackRateStartDate(date);
    } else if (chartName === "session-duration") {
      setSessionDurationStartDate(date);
    } else if (chartName === "user-device-distribution") {
      setUserDeviceDistributionStartDate(date);
    } else if (chartName === "average-post-views") {
      setAveragePostViewsStartDate(date);
    } else if (chartName === "impressions-per-ad") {
      setImpressionsPerAdStartDate(date);
    } else if (chartName === "live-ads-count") {
      setLiveAdsCountStartDate(date);
    } else if (chartName === "daily-new-live-ads-count") {
      setDailyLiveAdStartDate(date);
    } else if (chartName === "daily-renewed-ads-count") {
      setDailyRenewalsAdStartDate(date);
    } else if (chartName === "daily-expired-ads-count") {
      setDailyExpiredAdsStartDate(date);
    } else if (chartName == "daily-unpublished-ads-count") {
      setDailyUnpublishedAdsStartDate(date);
    } else if (chartName === "daily-expiring-forecast") {
      setDailyExpiringForecastAdsStartDate(date);
    }
  };

  const handleCustomEndDate = (chartName, date) => {
    if (chartName === "website-visits") {
      setDailyVisitsEndDate(date);
    } else if (chartName === "unique-visitors") {
      setUniqueVisitorsEndDate(date);
    } else if (chartName === "daily-sessions") {
      setDailySessionsEndDate(date);
    } else if (chartName === "bounce-back-rate") {
      setBounceBackRateEndDate(date);
    } else if (chartName === "session-duration") {
      setSessionDurationEndDate(date);
    } else if (chartName === "user-device-distribution") {
      setUserDeviceDistributionEndDate(date);
    } else if (chartName === "average-post-views") {
      setAveragePostViewsEndDate(date);
    } else if (chartName === "impressions-per-ad") {
      setImpressionsPerAdEndDate(date);
    } else if (chartName === "live-ads-count") {
      setLiveAdsCountEndDate(date);
    } else if (chartName === "daily-new-live-ads-count") {
      setDailyLiveAdEndDate(date);
    } else if (chartName === "daily-renewed-ads-count") {
      setDailyRenewalsAdEndDate(date);
    } else if (chartName === "daily-expired-ads-count") {
      setDailyExpiredAdsEndDate(date);
    } else if (chartName == "daily-unpublished-ads-count") {
      setDailyUnpublishedAdsEndDate(date);
    } else if (chartName === "daily-expiring-forecast") {
      setDailyExpiringForecastAdsEndDate(date);
    }
  };

  return (
    <>
      <div className="content-col" style={{ marginBottom: "0", padding: "0" }}>
        <Breadcrumb title="Dashboard" />
        <div className="container-fluid inner-content">
          <DashboardItemCom
            total={dailyVisitsTotal}
            max={dailyVisitsMax}
            loading={dailyVisitsLoading}
            chartName="website-visits"
            handleType={handleChartType}
            type={dailyVisitsType}
            data={dailyVisitsData}
            handleDuration={handlechartDuration}
            duration={dailyVisitsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyVisitsStartDate}
            endDate={dailyVisitsEndDate}
            title="Website Visits"
          />
          <DashboardItemCom
            total={uniqueVisitorsTotal}
            max={uniqueVisitorsMax}
            loading={uniqueVisitorsLoading}
            chartName="unique-visitors"
            handleType={handleChartType}
            type={uniqueVisitorsType}
            data={uniqueVisitorsData}
            handleDuration={handlechartDuration}
            duration={uniqueVisitorsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={uniqueVisitorsStartDate}
            endDate={uniqueVisitorsEndDate}
            title="Unique Visitors"
          />
          <DashboardItemCom
            total={dailySessionsTotal}
            max={dailySessionsMax}
            loading={dailySessionsLoading}
            chartName="daily-sessions"
            handleType={handleChartType}
            type={dailySessionsType}
            data={dailySessionsData}
            handleDuration={handlechartDuration}
            duration={dailySessionsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailySessionsStartDate}
            endDate={dailySessionsEndDate}
            title="Daily Sessions"
          />
          <DashboardItemCom
            total={bounceBackRateTotal}
            max={bounceBackRateMax}
            loading={bounceBackRateLoading}
            chartName="bounce-back-rate"
            handleType={handleChartType}
            type={bounceBackRateType}
            data={bounceBackRateData}
            handleDuration={handlechartDuration}
            duration={bounceBackRatesDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={bounceBackRateStartDate}
            endDate={bounceBackRateEndDate}
            title="Bounce Back Rate"
          />
          <DashboardItemCom
            total={sessionDurationTotal}
            max={sessionDurationMax}
            loading={sessionDurationLoading}
            chartName="session-duration"
            handleType={handleChartType}
            type={sessionDurationType}
            data={sessionDurationData}
            handleDuration={handlechartDuration}
            duration={sessionDurationDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={sessionDurationStartDate}
            endDate={sessionDurationEndDate}
            title="Session Duration"
          />
          <PieChartComp
            total={userDeviceDistributionTotal}
            loading={userDeviceDistributionLoading}
            chartName="user-device-distribution"
            handleType={handleChartType}
            type={userDeviceDistributionType}
            data={userDeviceDistributionData}
            handleDuration={handlechartDuration}
            duration={userDeviceDistributionDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={userDeviceDistributionStartDate}
            endDate={userDeviceDistributionEndDate}
            title="User Device Distribution"
          />
          <DashboardItemCom
            total={averagePostViewsTotal}
            max={averagePostViewsMax}
            loading={averagePostViewsLoading}
            chartName="average-post-views"
            handleType={handleChartType}
            type={averagePostViewsType}
            data={averagePostViewsData}
            handleDuration={handlechartDuration}
            duration={averagePostViewsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={averagePostViewsStartDate}
            endDate={averagePostViewsEndDate}
            title="Average Post Views"
          />
          <DashboardItemCom
            total={impressionsPerAdTotal}
            max={impressionsPerAdMax}
            loading={impressionsPerAdLoading}
            chartName="impressions-per-ad"
            handleType={handleChartType}
            type={impressionsPerAdType}
            data={impressionsPerAdData}
            handleDuration={handlechartDuration}
            duration={impressionsPerAdDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={impressionsPerAdStartDate}
            endDate={impressionsPerAdEndDate}
            title="Impressions Per Ad"
          />
          <DashboardItemCom
            total={liveAdsCountTotal}
            max={liveAdsCountMax}
            loading={liveAdsCountLoading}
            chartName="live-ads-count"
            handleType={handleChartType}
            type={liveAdsCountType}
            data={liveAdsCountData}
            handleDuration={handlechartDuration}
            duration={liveAdsCountDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={liveAdsCountStartDate}
            endDate={liveAdsCountEndDate}
            title="Live Ads Count"
          />
          <DashboardItemCom
            total={"NA"}
            max={dailyLiveAdMax}
            loading={dailyLiveAdLoading}
            chartName="daily-new-live-ads-count"
            handleType={handleChartType}
            type={dailyLiveAdType}
            data={dailyLiveAdData}
            handleDuration={handlechartDuration}
            duration={dailyLiveAdDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyLiveAdStartDate}
            endDate={dailyLiveAdEndDate}
            title="Daily New Live Ads Count"
          />
          <DashboardItemCom
            total={"NA"}
            max={dailRenewalsAdMax}
            loading={dailyRenewalsAdLoading}
            chartName="daily-renewed-ads-count"
            handleType={handleChartType}
            type={dailyRenewalsAdType}
            data={dailyRenewalsAdData}
            handleDuration={handlechartDuration}
            duration={dailyRenewalsAdDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyRenewalsStartDate}
            endDate={dailyRenewalsAdEndDate}
            title="Daily Renewal Ads Count"
          />
          <DashboardItemCom
            total={"NA"}
            max={dailyExpiredAdsMax}
            loading={dailyExpiredAdsLoading}
            chartName="daily-expired-ads-count"
            handleType={handleChartType}
            type={dailyExpiredAdsType}
            data={dailyExpiredAdsData}
            handleDuration={handlechartDuration}
            duration={dailyExpiredAdsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyExpiredAdsStartDate}
            endDate={dailyExpiredAdsEndDate}
            title="Daily Expired Ads Count"
          />
          <DashboardItemCom
            total={"NA"}
            max={dailyUnpublishedAdsMax}
            loading={dailyUnpublishedAdsLoading}
            chartName="daily-unpublished-ads-count"
            handleType={handleChartType}
            type={dailyUnpublishedAdsType}
            data={dailyUnpublishedAdsData}
            handleDuration={handlechartDuration}
            duration={dailyUnpublishedAdsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyUnpublishedAdsStartDate}
            endDate={dailyUnpublishedAdsEndDate}
            title="Daily Unpublished Ads Count"
          />
          <DashboardItemCom
            total={"NA"}
            max={dailyExpiringForecastAdsMax}
            loading={dailyExpiringForecastAdsLoading}
            chartName="daily-expiring-forecast"
            handleType={handleChartType}
            type={dailyExpiringForecastAdsType}
            data={dailyExpiringForecastAdsData}
            handleDuration={handlechartDuration}
            duration={dailyExpiringForecastAdsDuration}
            handleStartDate={handleCustomStartDate}
            handleEndDate={handleCustomEndDate}
            startDate={dailyExpiringForecastAdsStartDate}
            endDate={dailyExpiringForecastAdsEndDate}
            title="Daily Expiring Ads Forecast"
            DurationOptions={() => (
              <>
                <option value="7d">Next 4 weeks</option>
              </>
            )}
            frequencies={["daily"]}
          />
          <PaymentHistoryTable />
          <UTMLogsTable />
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default DashBoardCom;
