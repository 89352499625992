import React, { useState } from "react";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";
import Close from "../../../images/close-white.png";
import axios from "axios";

function PaymentRejectModal(props) {
  const [loading, setLoading] = useState(false);

  const removeNumber = () => {
    setLoading(true);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/v1/admin/phonenumbers/blacklist/${props.removingNumber}`,{
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAdminOrOperatorL2()}`,
        },
      })
      .then((res) => {
        setLoading(false);
        props.removedSuccessfully();
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }

  return (
    <div
      className="modal fade"
      id="blacklistRemoveModal"
      tabIndex="-1"
      aria-labelledby="paymentRejectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentRejectModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="blacklistRemoveModalClose"
            />
          </div>
          <div className="modal-body">
            <p>Do you want to remove this number from blacklist?</p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={removeNumber}
              style={{ width: 95 }}
            >
              {loading ? (
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              ) : (
                "Remove"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentRejectModal;
