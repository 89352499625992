import { CREATE_POST_DATA } from "../actions/types";

const initialState = {
  data: sessionStorage.getItem("ad_data")
    ? JSON.parse(sessionStorage.getItem("ad_data"))
    : {},
};

const createAdReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POST_DATA:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default createAdReducer;
