import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import KPIsCom from "../components/admin/virtual-agent/KPIsCom";
// import AllAgentAdsCom from "../components/admin/agents-ads/AllAgentAdsCom";

function AllAgentAds() {
  return (
    <AdminLayout>
      <KPIsCom />
    </AdminLayout>
  );
}

export default AllAgentAds;
