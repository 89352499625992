exports.isAuthenticated = () => {
  if (typeof window !== "undefined") {
    if (localStorage.getItem("at") && localStorage.getItem("ur") === "ADMIN") {
      return localStorage.getItem("at");
    } else {
      return false;
    }
  } else {
    return false;
  }
};
