import httpAdapter, {
  ContentType,
  ApiVersion,
  HttpMethod,
} from "../../utils/http-adaper";

const PostServiceApi = {
  putPostUpdate: async ({ id, data }) => {
    return await httpAdapter.request({
      method: HttpMethod.PUT,
      apiVersion: ApiVersion.V1,
      path: `admin/posts/${id}`,
      authneticated: true,
      body: data,
      contentType: ContentType.JSON,
    });
  },
  getUserTempPostData: async (memberId) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: `admin/user/${memberId}/temp_post`,
      authneticated: true,
    });
  },
  getUserAccount: async ({ params }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "admin/user_account",
      queryParams: params,
      authneticated: true,
    });
  },
};

export default PostServiceApi;
