import React from "react";
import classnames from "classnames";

function Agent(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Agent Info</p>
            <p className="info-desc">
              <span className="required-tag">*</span> Indicates required fields
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: 10 }}>
          <div className="form-row m-0">
            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="agentCode">
                Agent Code <span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": props.form.agentCodeErr,
                })}
                id="agentCode"
                name="agentCode"
                onChange={props.handleChange}
                value={props.form.agentCode}
              />
              <div className="invalid-feedback">{props.form.agentCodeErr}</div>
            </div>

            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="scannedImageId">
                Scaned ID <span className="required-tag">*</span>
              </label>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": props.form.scannedImageIdErr,
                })}
                id="scannedImageId"
                name="scannedImageId"
                onChange={props.handleChange}
                value={props.form.scannedImageId}
              />
              <div className="invalid-feedback">
                {props.form.scannedImageIdErr}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Agent;
