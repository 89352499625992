import { Grid, Typography } from "@material-ui/core";
import React from "react";

import "./style.css";

const PdfDataSection = ({
  heading,
  leftDataHeding,
  rightDataHeading,
  children,
}) => {
  return (
    <Grid direction="column" item container>
      {heading && (
        <Grid className="data-section-header">
          <Typography className="data-section-heading">{heading}</Typography>
        </Grid>
      )}
      {rightDataHeading && leftDataHeding ? (
        <Grid direction="row" container>
          <Grid className="double-sub-header">
            <Typography className="data-section-sub-heading">
              {leftDataHeding}
            </Typography>
          </Grid>
          <Grid className="double-sub-header">
            <Typography className="data-section-sub-heading">
              {rightDataHeading}
            </Typography>
          </Grid>
        </Grid>
      ) : leftDataHeding ? (
        <Grid className="single-sub-header">
          <Typography className="data-section-sub-heading">
            {leftDataHeding}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      <Grid direction="row" container className="data-section-container">
        {children}
      </Grid>
    </Grid>
  );
};

export default PdfDataSection;
