import React from "react";
import { useHistory } from "react-router-dom";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";

const BackBtnHeader = (props) => {
  const history = useHistory();
  return (
    <div style={{ height: 20, marginTop: 25 }}>
      <span style={backStyle} onClick={() => history.goBack()}>
        <i className="fas fa-angle-left"></i> Back
      </span>
      {/* {props.status !== "IN_REVIEW" && ( */}
        {isAdminOrOperatorL2() &&
          <>
          <div
            style={interestProfileStyles}
            onClick={() => props.setShowPdfModal(true)}
          >
            <i className="fas fa-external-link-alt"></i> Export Interest
            Profiles
          </div>
          <a href={`/interests?id=${props.id}`} style={publicProfileStyle}>
            <i className="fas fa-external-link-alt"></i> Interests
          </a>
        </>
        }
        <a
          href={`${process.env.REACT_APP_WEB_URL}/post?id=${props.id}`}
          style={publicProfileStyle}
        >
          <i className="fas fa-external-link-alt"></i> Public Profile
        </a>
         <a
          href={`/activity-log?id=${props.id}`}
          style={publicProfileStyle}
        >
          <i className="fas fa-external-link-alt"></i> Activity Log
        </a>
      {/* )} */}
    </div>
  );
};

const backStyle = {
  display: "block",
  float: "left",
  fontSize: "14px",
  cursor: "pointer",
};

const publicProfileStyle = {
  display: "block",
  float: "right",
  fontSize: "13px",
  marginRight: '10px'
};

const interestProfileStyles = {
  display: "block",
  float: "right",
  fontSize: "13px",
  marginRight: '10px',
  color: "#007BFF",
  cursor: "pointer",
}

export default BackBtnHeader;
