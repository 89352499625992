import React from "react";
import AdminLayout from "../../components/admin/layouts/AdminLayout";
import UserReviewsCom from "../../components/admin/reviews/UserReviewsCom";

const UserReviews = () => {
  return (
    <AdminLayout>
      <UserReviewsCom />
    </AdminLayout>
  );
};

export default UserReviews;
