import React from "react";
import Close from "../../../images/close-white.png";

function DeleteModal({ title, description, handleDelete }) {
  return (
    <div
      className="modal fade"
      id="deleteModal"
      tabIndex="-1"
      aria-labelledby="editApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editApproveModalLabel">
              {title}
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="editApproveModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>{description}</p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button
              type="button"
              className="btn"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
              style={{ width: 95 }}
              data-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
