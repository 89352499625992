import React, { useState } from "react";
import qs from "qs";
import axios from "axios";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import Close from "../../../images/close-white.png";

function AddServiceCommentModal(props) {
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");
  const [loading, setLoading] = useState(false);

  const addComment = (e) => {
    setCommentError("")
    e.preventDefault();
    if (comment) {
      setLoading(true);
      let data = qs.stringify({ comment: comment, memberId: props.memberId });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/operator/service_comments`,
          data,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          props.handleAddedSuccess();
          setLoading(false);
          setComment("");
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setCommentError("Comment is required");
    }
  };

  return (
    <div
      className="modal fade"
      id="addServiceCommentModal"
      tabIndex="-1"
      aria-labelledby="paymentApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentApproveModalLabel">
              Add service comment
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="addCommentModalClose"
            />
          </div>
          <div className="modal-body">
            <div className="form-group col-12 p-0 mb-3 m-auto">
              <h6 className="mb-2">Comment</h6>
              <textarea
                name="comment"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                  setCommentError("");
                }}
                placeholder="Type your comment and click Add comment..."
                rows="4"
                style={{width: '100%'}}
              />
              <p
                className="text-danger mt-1 mb-0"
                style={{ fontSize: 14 }}
              >
                {commentError}
              </p>
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={addComment}
              style={{ width: 155 }}
            >
              {loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Add comment"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddServiceCommentModal;
