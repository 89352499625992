import React, { useState } from "react";
import classnames from "classnames";
import { Button, ButtonGroup, Typography } from "@material-ui/core";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import DatePicker from "../../base/date-picker";

const FREQUENCY_DEFAULTS = Object.freeze(["daily", "weekly", "monthly"]);

const DefaultDurationOptions = () => {
  return (
    <>
      <option value="7d">Last 7 Days</option>
      <option value="6m">Last 6 Months</option>
      <option value="4w">Last 4 Weeks</option>
      <option value="12m">Last 12 Months</option>
      <option value="custom">Custom Date</option>
    </>
  );
};

function ChartCom(props) {
  const [isCustomDateChecked, setIsCustomDateChecked] = useState(false);

  const changeDuration = (e) => {
    if (e.target.value === "custom") {
      setIsCustomDateChecked(true);
    } else {
      setIsCustomDateChecked(false);
    }
    props.handleDuration(props.chartName, e.target.value);
  };

  const setCustomStartDate = (date) => {
    props.handleStartDate(props.chartName, date);
  };

  const setCustomEndDate = (date) => {
    props.handleEndDate(props.chartName, date);
  };

  const { DurationOptions, frequencies } = props;
  const frequencyOptions = frequencies ?? FREQUENCY_DEFAULTS;

  return (
    <>
      <div className="content-col" style={{ paddingBottom: "0" }}>
        <div
          className="container-fluid inner-content"
          style={{ paddingBottom: "0" }}
        >
          <div
            className="ad-form-sec"
            style={{ maxWidth: "100%", marginBottom: "0" }}
          >
            <form noValidate>
              <div className="form-row header">
                <div className="form-group col px-sm-2 mb-0">
                  <p className="heading mb-0">{props.title}</p>
                </div>
              </div>
              <div className="content">
                <div className="form-group">
                  <div className="graph-header">
                    <div className="graph-duration-picker">
                      <select
                        className={classnames("form-control")}
                        id="ethnicityId"
                        name="ethnicityId"
                        onChange={changeDuration}
                        value={props.duration}
                      >
                        {(DurationOptions !== undefined && (
                          <DurationOptions />
                        )) || <DefaultDurationOptions />}
                      </select>
                    </div>
                    {isCustomDateChecked && (
                      <div className="graph-date-picker">
                        <DatePicker
                          label="from"
                          selectedDate={props.startDate}
                          onChangeDate={(date) => {
                            setCustomStartDate(date);
                          }}
                        />
                        <DatePicker
                          label="to"
                          selectedDate={props.endDate}
                          onChangeDate={(date) => setCustomEndDate(date)}
                        />
                      </div>
                    )}
                    <div>
                      <ButtonGroup
                        color="primary"
                        size="small"
                        aria-label="small outlined button group"
                        className="form-control"
                        style={{ marginLeft: "10px" }}
                      >
                        {frequencyOptions.map((frequency) => (
                          <Button
                            key={frequency}
                            className={`frequency-btn ${
                              props.type === frequency
                                ? "frequency-btn-active"
                                : ""
                            }`}
                            onClick={() =>
                              props.handleType(props.chartName, frequency)
                            }
                          >
                            {frequency}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                    <div>
                      <label
                        className="form-control"
                        style={{ marginLeft: "20px", border: "none" }}
                      >
                        Total Count : {props.total}
                      </label>
                    </div>
                  </div>
                  <div>
                    <ResponsiveContainer width="99%" height={300}>
                      {props.loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                          style={{ margin: "auto", display: "flex" }}
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <LineChart
                          data={props.data}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <XAxis
                            dataKey="date"
                            style={{
                              fontSize: "0.8rem",
                            }}
                          />
                          <YAxis
                            type="number"
                            domain={[0, props.max + 10]}
                            allowDataOverflow={true}
                            label={{
                              value: "Value",
                              angle: -90,
                              position: "insideLeft",
                            }}
                            style={{
                              fontSize: "0.8rem",
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <CartesianGrid stroke="#f5f5f5" />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            activeDot={{ r: 4 }}
                            dot={{ r: 2 }}
                            isAnimationActive={true}
                          />
                        </LineChart>
                      )}
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChartCom;
