import React from "react";
import classnames from "classnames";

function OfflineResponses(props) {
  return (
    <React.Fragment>
      <div className="form-row m-0">
        <div className="form-group col px-sm-2 mb-3">
          <label htmlFor="showOfflineId">
            Accept written interests via post{" "}
            {/* <span className="required-tag">*</span> */}
          </label>
          <select
            className={classnames("form-control", {
              "is-invalid": props.form.showOfflineIdErr,
            })}
            id="showOfflineId"
            name="showOfflineId"
            onChange={props.handleChange}
            value={props.form.showOfflineId}
          >
            <option defaultValue hidden>
              -- Select --
            </option>
            <option value="true">Enable</option>
            <option value="false">Disable</option>
          </select>
          <div className="invalid-feedback">{props.form.showOfflineIdErr}</div>
        </div>

        <div className="form-group col px-sm-2 mb-3">
          <label htmlFor="subscribeMagazine">
            Send me the monthly magazine
            {/* <span className="required-tag"> *</span> */}
          </label>
          <select
            className={classnames("form-control", {
              "is-invalid": props.form.subscribeMagazineErr,
            })}
            id="subscribeMagazine"
            name="subscribeMagazine"
            onChange={props.handleChange}
            value={props.form.subscribeMagazine}
          >
            <option defaultValue hidden>
              -- Select --
            </option>
            <option value="true">Enable</option>
            <option value="false">Disable</option>
          </select>
          <div className="invalid-feedback">
            {props.form.subscribeMagazineErr}
          </div>
        </div>
      </div>

      {props.form.showOfflineId === "true" ||
      props.form.subscribeMagazine === "true" ? (
        <React.Fragment>
          <p className="form-heading" style={{ fontSize: 14 }}>
            Mailing Address
          </p>

          <div className="form-row m-0">
            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="recipName">
                Name of recipient <span className="required-tag">*</span>
              </label>
              <input
                className={classnames("form-control", {
                  "is-invalid": props.form.recipNameErr,
                })}
                id="recipName"
                name="recipName"
                onChange={props.handleChange}
                value={props.form.recipName}
              />
              <div className="invalid-feedback">{props.form.recipNameErr}</div>
            </div>
          </div>
          <div className="form-row m-0">
            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="line1">
                Address Line 1<span className="required-tag"> *</span>
              </label>
              <input
                className={classnames("form-control", {
                  "is-invalid": props.form.line1Err,
                })}
                id="line1"
                name="line1"
                onChange={props.handleChange}
                value={props.form.line1}
              />
              <div className="invalid-feedback">{props.form.line1Err}</div>
            </div>
          </div>
          <div className="form-row m-0">
            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="line2">Address Line 2 (Optional)</label>
              <input
                className={classnames("form-control", {
                  "is-invalid": props.form.line2Err,
                })}
                id="line2"
                name="line2"
                onChange={props.handleChange}
                value={props.form.line2}
              />
              <div className="invalid-feedback">{props.form.line2Err}</div>
            </div>
          </div>
          <div className="form-row m-0">
            <div className="form-group col-md-6 px-sm-2 mb-3">
              <label htmlFor="city">
                City<span className="required-tag"> *</span>
              </label>
              <input
                className={classnames("form-control", {
                  "is-invalid": props.form.cityErr,
                })}
                id="city"
                name="city"
                onChange={props.handleChange}
                value={props.form.city}
              />
              <div className="invalid-feedback">{props.form.cityErr}</div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

export default OfflineResponses;
