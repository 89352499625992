import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import PhoneNumbersBlacklistCom from "../../components/admin/blacklist/PhoneNumbersBlacklistCom";

function PhoneNumbersBlacklist() {
  return (
    <AdminLayout>
      <PhoneNumbersBlacklistCom />
    </AdminLayout>
  );
}

export default PhoneNumbersBlacklist;
