import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import FiltersContext from "../../../context/index";

const INTEREST_FILTERS = Object.freeze({
  matches: "matches",
  unmatches: "unmatches",
  "new-matches": "new-matches",
  received: "received",
  sent: "sent",
  spam: "spam",
});

const INTEREST_FILTERS_NAME = {
  [INTEREST_FILTERS.matches]: "Matched",
  [INTEREST_FILTERS.unmatches]: "Unmatched",
  [INTEREST_FILTERS["new-matches"]]: "New Matches",
  [INTEREST_FILTERS.received]: "Received",
  [INTEREST_FILTERS.sent]: "Sent",
  [INTEREST_FILTERS.spam]: "Spam",
};

function AllInterestsCom() {
  const [interestType, setInterestType] = useState(
    FiltersContext.getFilterValue() ?? INTEREST_FILTERS.received
  );
  const [ads, setAds] = useState([]);
  const [displaying, setDisplaying] = useState([]);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [totAds, setTotAds] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    handleInterests(interestType);
  }, []);

  const querryParams = useLocation().search;
  const id = new URLSearchParams(querryParams).get("id");

  const getReceivedInterests = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/received`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setAds(res.data.results);
          setPage(1);
          setTotAds(res.data.results.length);
          setLoading(false);
          setNoOfPages(Math.ceil(res.data.results.length / itemsPerPage));
          setDisplaying(res.data.results.slice(0, itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSentInterests = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/sent`, {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setAds(res.data.results);
          setPage(1);
          setTotAds(res.data.results.length);
          setLoading(false);
          setNoOfPages(Math.ceil(res.data.results.length / itemsPerPage));
          setDisplaying(res.data.results.slice(0, itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getMatchedInterests = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/matches?type=matched`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setAds(res.data.results);
          setPage(1);
          setTotAds(res.data.results.length);
          setLoading(false);
          setNoOfPages(Math.ceil(res.data.results.length / itemsPerPage));
          setDisplaying(res.data.results.slice(0, itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getUnmmatchedPosts = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/matches?type=unmatched`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setAds(res.data.results);
          setPage(1);
          setTotAds(res.data.results.length);
          setLoading(false);
          setNoOfPages(Math.ceil(res.data.results.length / itemsPerPage));
          setDisplaying(res.data.results.slice(0, itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getNewMatches = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/new_matches`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setAds(res.data);
          setPage(1);
          setTotAds(res.data.length);
          setLoading(false);
          setNoOfPages(Math.ceil(res.data.length / itemsPerPage));
          setDisplaying(res.results.slice(0, itemsPerPage));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSpamInterests = () => {
    if (isAuthenticatedBoth()) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v2/posts/${id}/interests/received?archived=false&spam=true&pageSize=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setAds(res.data.results);
          setPage(1);
          setTotAds(res.data.results.length);
          setNoOfPages(Math.ceil(res.data.results.length / itemsPerPage));
          setDisplaying(res.data.results.slice(0, itemsPerPage));
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleInterests = (type) => {
    setInterestType(type);
    FiltersContext.setInterestType(type);
    switch (type) {
      case INTEREST_FILTERS.received:
        getReceivedInterests();
        break;
      case INTEREST_FILTERS.sent:
        getSentInterests();
        break;
      case INTEREST_FILTERS.matches:
        getMatchedInterests();
        break;
      case INTEREST_FILTERS.unmatches:
        getUnmmatchedPosts();
        break;
      case INTEREST_FILTERS["new-matches"]:
        getNewMatches();
        break;
      case INTEREST_FILTERS.spam:
        getSpamInterests();
        break;
    }
  };

  const handlePagination = (event, value) => {
    setDisplaying(ads.slice((value - 1) * itemsPerPage, value * itemsPerPage));
    setPage(value);
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const styles = {
    firstTwoColumns: {
      padding: "0.3rem",
      border: "none",
      borderRight: "1px solid #dee2e6",
    },
    lastColumn: {
      padding: "0.3rem",
      border: "none",
    },
  };

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="Ads" title="Interests" />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="row m-0">
              <div
                className="col p-0"
                style={{
                  marginBottom: "-40px",
                  marginTop: "20px",
                  width: "505px",
                }}
              >
                <form
                  style={{
                    display: "block",
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 0,
                  }}
                >
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <div
                        className="input-group mb-0"
                        style={{ width: "350px" }}
                      >
                        <select
                          value={interestType}
                          onChange={(e) => handleInterests(e.target.value)}
                          className="form-control pr-5"
                        >
                          {Object.keys(INTEREST_FILTERS).map((currentType) => (
                            <option key={currentType} value={currentType}>
                              {INTEREST_FILTERS_NAME[currentType]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row" style={{ marginTop: "40px" }}>
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <p style={{ fontSize: 14, marginBottom: 10 }}>
                  {ads.length > 0
                    ? page * itemsPerPage > totAds
                      ? `Showing ${
                          (page - 1) * itemsPerPage
                        } - ${totAds} out of ${totAds} results`
                      : `Showing ${(page - 1) * itemsPerPage} - ${
                          page * itemsPerPage
                        } out of ${totAds} results`
                    : "No results"}
                </p>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0" width="230">
                          Created Date
                        </th>
                        <th className="border-0" width="150">
                          Ad ID
                        </th>
                        <th className="border-0" width="150">
                          Display Name
                        </th>
                        <th className="border-0" width="350">
                          Time Line
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ads.length ? (
                        displaying.map((el, i) => (
                          <tr
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            <td width="200">
                              {el.idVerified ? (
                                <i
                                  style={{
                                    color: "#2979ff",
                                    fontSize: "12px",
                                    marginLeft: "6px",
                                  }}
                                  className="fas fa-check-circle"
                                ></i>
                              ) : null}
                              <p className="mb-0" style={{ width: 200 }}>
                                {moment
                                  .utc(el.matchedAt ? el.matchedAt : el.likedAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </p>
                            </td>
                            <td width="150">{el.post && el.post.id}</td>
                            <td width="150">
                              {el.post && el.post.personalInfo.displayName}
                            </td>
                            <td width="380">
                              <table>
                                {el.timeline.map((data, i) => (
                                  <tr>
                                    <td style={styles.firstTwoColumns} key={i}>
                                      {data.eventType
                                        .toString()
                                        .charAt(0)
                                        .toUpperCase() +
                                        data.eventType
                                          .toString()
                                          .slice(1)
                                          .toLowerCase() +
                                        " by"}
                                    </td>
                                    <td style={styles.firstTwoColumns} key={i}>
                                      {(
                                        data.actionBy
                                          .toString()
                                          .charAt(0)
                                          .toUpperCase() +
                                        data.actionBy
                                          .toString()
                                          .slice(1)
                                          .toLowerCase()
                                      ).replace(/_/g, " ")}
                                    </td>
                                    <td style={styles.lastColumn} key={i}>
                                      {moment
                                        .utc(data.eventTime)
                                        .local()
                                        .format("DD-MMM-YYYY hh:mm a")}
                                    </td>
                                  </tr>
                                ))}
                              </table>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" width="480">
                            No Records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {noOfPages > 1 && (
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handlePagination}
                        className="pagi"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AllInterestsCom;
