// import packages
import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import classnames from "classnames";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import ConfirmAddReviewModal from "../modals/ConfirmAddReviewModal";
import moment from "moment";

function AddReviewCom(props) {
  const [source, setSource] = useState("");
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [rating, setRating] = useState("");
  const [publishDate, setPublishDate]= useState("")
  const [sourceErr, setSourceErr] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [contentErr, setContentErr] = useState("");
  const [ratingErr, setRatingErr] = useState("");
  const [publishDateErr, setPublishDateErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal ,setShowModal] = useState(false)
  
  // validate
  const validate = () => {
    let sourceErr = "";
    let nameErr = "";
    let contentErr = "";
    let ratingErr = "";
    let publishDateErr ="";
    if (!source) {
      sourceErr = "Please fill review source";
      setSourceErr("Please fill review source");
    }
    if (!name) {
      nameErr = "Name is required";
      setNameErr("Name is required");
    }
    if (!content) {
      contentErr = "Content is required";
      setContentErr("Content is required");
    }
    if (!rating) {
      ratingErr = "Rating is required";
      setRatingErr("Rating is required");
    }
    if (!publishDate) {
      publishDateErr = "Publish date is required";
      setPublishDateErr("Publish date is required");
    }

    if (sourceErr || nameErr || contentErr || ratingErr || publishDateErr) {
      return false;
    } else {
      return true;
    }
  };

  const handleAdd = () => {
    if(validate()){
      setShowModal(true)
    }
  }; 

  const getDateAndCurrentTime = () => {
    if(!publishDate) {
      return null;
    }

   const formattedDateTime = moment.utc(publishDate).format("YYYY-MM-DDTHH:mm:ss")
    return formattedDateTime;
  };

  const addReview = () => {
    const reviewTime = getDateAndCurrentTime();
    if (validate()) {
      setLoading(true);
      let data = qs.stringify({ source : source, name: name, content: content, rating: rating ,reviewTime: reviewTime });    
      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/admin/reviews`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setSource("");
          setName("");
          setContent("");
          setRating("");
          setPublishDate("");
          NotificationManager.success("Added successfully!", "Success");
        })
        .catch((err) => {
          console.log(err)
          setLoading(false);
          NotificationManager.error(err.response.data.message, "Error");
        });
    }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb title="Send SMS" />
        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="content py-3">
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="source">
                    Source
                  </label>
                  <select
                    className={classnames("form-control", {
                      "is-invalid": sourceErr,
                    })}
                    id="source"
                    name="source"
                    onChange={(e) => {
                      setSource(e.target.value);
                      setSourceErr("");
                    }}
                    value={source}
                  >
                    <option defaultValue hidden>
                      -- Select --
                    </option>
                    <option value="FACEBOOK">Facebook</option>
                    <option value="GOOGLE">Google</option>
                    <option value="EMAIL">Email</option>
                    <option value="INSTAGRAM">Instagram</option>
                    <option value="WEBSITE">Website</option>
                  </select>
                  <div style={{color:"#dc3545"}}>{sourceErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="name">
                    Name
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": nameErr,
                    })}
                    id="name"
                    name="name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameErr("");
                    }}
                    value={name}
                  />
                  <div style={{color:"#dc3545"}}>{nameErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="content">
                    Content
                  </label>
                  <textarea
                    className={classnames("form-control", {
                      "is-invalid": contentErr,
                    })}
                    name="content"
                    onChange={(e) => {
                      setContent(e.target.value);
                      setContentErr("");
                    }}
                    value={content}
                    rows="5"
                    placeholder="content"
                  />
                  <div style={{color:"#dc3545"}}>{contentErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="rating">
                    Rating
                  </label>
                  <input
                    type="number"
                    step="0.01"
                    className={classnames("form-control", {
                      "is-invalid": ratingErr,
                    })}
                    id="rating"
                    name="rating"
                    onChange={(e) => {
                      setRating(e.target.value);
                      setRatingErr("");
                    }}
                    value={rating}
                  />
                  <div style={{color:"#dc3545"}}>{ratingErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="publish-date">
                    Publish Date
                  </label>
                  <input 
                    type="date"  
                    className={classnames("form-control", {
                      "is-invalid": publishDateErr,
                     })}
                     id="publish-date"
                    name="publish-date"
                    onChange={(e)=>{
                        setPublishDate(e.target.value)
                        setPublishDateErr("");
                    }}
                    >
                  </input>
                  <div style={{color:"#dc3545"}}>{publishDateErr}</div>
                </div>
              </div>
              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleAdd}
                style={{ width: 200, float: "right" }}
                data-toggle="modal"
                data-target="#confirmAddReviewModal"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Add Review"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      {showModal ? <ConfirmAddReviewModal addReview={addReview} /> : null}
      <NotificationContainer />
    </>
  );
}

export default AddReviewCom;
