import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Typography
} from "@material-ui/core";
import ReactCodeInput from "react-verification-code-input";
import PropTypes from 'prop-types'


const VerifyMobileModal = ({ showModal, closeModal, loadingModal, setOtp, phoneNo, verifyOtp }) => {

    VerifyMobileModal.propTypes = {
        showModal: PropTypes.bool.isRequired,
        closeModal: PropTypes.func.isRequired,
        loadingModal: PropTypes.bool.isRequired,
        setOtp: PropTypes.func.isRequired,
        phoneNo: PropTypes.string,
        verifyOtp: PropTypes.func.isRequired
    }

    return (
        <Dialog
            open={showModal}
            PaperProps={{
                style: {
                    minWidth: '300px',
                    minHeight: 'fit-content',
                }
            }}
            disableEscapeKeyDown={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle
                id="alert-dialog-title"
                className="modal-header"
            >
                Verify Phone Number
            </DialogTitle>
            <DialogContent style={{ minHeight: 80 }}>
                <DialogContentText id="alert-dialog-description">
                    <Typography
                        variant='body1'
                        align='center'
                        style={{ fontSize: '16px', lineHeight: '16px', fontWeight: '400', marginTop: '15px', color: 'rgba(0, 0, 0, 0.7)' }}
                    >
                        Your Phone Number
                    </Typography>
                    <Typography
                        variant='body1'
                        align='center'
                        style={{ fontSize: '17px', lineHeight: '16px', fontWeight: '600', marginTop: '15px', color: 'black' }}
                    >
                        {`+${phoneNo}`}
                    </Typography>
                    <Typography
                        variant='body1'
                        align='center'
                        style={{ fontSize: '15px', lineHeight: '16px', fontWeight: '400', marginTop: '30px', marginBottom: '10px' }}
                    >
                        Enter OTP Code
                    </Typography>
                    <ReactCodeInput
                        type='number'
                        fields={4}
                        autocomplete="one-time-code"
                        onChange={(val) => setOtp(val)} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {loadingModal ?
                    <>
                        <button
                            type="button"
                            className="btn"
                            style={{ width: 95 }}
                        >
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            style={{ width: 95 }}
                        >
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </button>
                    </>
                    :
                    <>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => closeModal(false)}
                            style={{ width: 95 }}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => verifyOtp()}
                            style={{ width: 95 }}
                        >
                            Verify
                        </button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}

export default VerifyMobileModal