import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import FollowUpOnlyAccountCom from "../../components/admin/follow-ups/FollowUpOnlyAccountCom";

function FollowUpOnlyAccount() {
  return (
    <AdminLayout>
      <FollowUpOnlyAccountCom />
    </AdminLayout>
  );
}

export default FollowUpOnlyAccount;
