import React, { useState, useEffect } from "react";
import axios from "axios";
import classnames from "classnames";

function Education(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="educationLevelId">
          Education Level <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.educationLevelIdErr,
          })}
          id="educationLevelId"
          name="educationLevelId"
          value={props.form.educationLevelId}
          onChange={props.handleChange}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.educationLevels.map((el) => (
            <option value={el.id} key={el.id}>
              {el.level}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.educationLevelIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="professionId">
          Profession <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.professionIdErr,
          })}
          id="professionId"
          name="professionId"
          value={props.form.professionId}
          onChange={props.handleChange}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.professions.map((el) => (
            <option value={el.id} key={el.id}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.professionIdErr}</div>
      </div>

      {props.showOtherProf && (
        <div className="form-group col-md-6 px-sm-2 mb-3">
          <label htmlFor="otherProfession">
            Type Your Profession <span className="required-tag">*</span>
          </label>
          <input
            type="text"
            className={classnames("form-control", {
              "is-invalid": props.form.otherProfessionErr,
            })}
            id="otherProfession"
            name="otherProfession"
            onChange={props.handleChange}
            value={props.form.otherProfession}
            autoComplete="off"
          />
          <div className="invalid-feedback">
            {props.form.otherProfessionErr}
          </div>
        </div>
      )}
    </div>
  );
}

export default Education;
