import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import AllAgentAdsCom from "../components/admin/agents-ads/AllAgentAdsCom";
// import AllAgentAdsCom from "../components/admin/agents-ads/AllAgentAdsCom";

function AllAgentAds() {
  return (
    <AdminLayout>
      <AllAgentAdsCom />
    </AdminLayout>
  );
}

export default AllAgentAds;
