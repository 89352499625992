import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import AllAdsCom from "../../components/admin/ads/AllAdsCom";

function AllAds() {
  return (
    <AdminLayout>
      <AllAdsCom />
    </AdminLayout>
  );
}

export default AllAds;
