import Axios, { AxiosError } from "axios";
import EnvVar from "../../config/EnvVar";
import QueryString from "qs";

const ACCESS_TOKEN_STORAGE_KEY = "at";

export const HttpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const ApiVersion = {
  V1: "v1",
  V2: "v2",
};

export const ContentType = {
  FORM_URL_ENCODED: "application/x-www-form-urlencoded",
  FORM_DATA: "multipart/form-data",
  JSON: "application/json",
};

export const ResponseType = {
  JSON: "json",
  BLOB: "blob",
};

class HttpAdapter {
  singleton;

  constructor() {
    this.singleton = Axios.create({
      baseURL: process.env.REACT_APP_API_URL ?? "",
    });
  }

  validateRequestObject(requestObj) {
    if (!Object.keys(HttpMethod).includes(requestObj.method)) {
      throw new Error("invalid http method provided");
    }
    if (typeof requestObj.path !== "string" || requestObj.path.length === 0) {
      throw new Error("resource path could not be empty");
    }
    if (!Object.values(ApiVersion).includes(requestObj.apiVersion)) {
      throw new Error("invalid api version provided");
    }
    if (!Object.values(ResponseType).includes(requestObj.responseType)) {
      throw new Error("invalid response type");
    }
  }

  sanitizeRequestObject(requestObj) {
    if (requestObj.headers.constructor !== "object") {
      requestObj.headers = {};
    }
    if (requestObj.queryParams.constructor !== "object") {
      requestObj.queryParams = {};
    }
    if (requestObj.body !== undefined) {
      if (requestObj.body instanceof FormData) {
        requestObj.contentType = ContentType.FORM_DATA;
      } else if (
        requestObj.contentType === ContentType.FORM_URL_ENCODED &&
        requestObj.body.constructor === Object
      ) {
        requestObj.body = QueryString.stringify(requestObj.body);
      } else {
        requestObj.contentType = ContentType.JSON;
      }
    }

    requestObj.headers["Content-Type"] = requestObj.contentType;

    if (requestObj.authneticated) {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      if (accessToken !== null) {
        requestObj.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  }

  async request({
    method = HttpMethod.GET,
    path,
    apiVersion = ApiVersion.V1,
    authneticated = false,
    body = undefined,
    headers = {},
    queryParams = {},
    contentType = ContentType.FORM_URL_ENCODED,
    responseType = ResponseType.JSON,
  }) {
    const requestObj = {
      method,
      path,
      apiVersion,
      authneticated,
      body,
      headers,
      queryParams,
      contentType,
      responseType,
    };

    const output = {
      success: false,
      statusCode: undefined,
      request: requestObj,
      body: undefined,
    };

    try {
      this.validateRequestObject(requestObj);
      this.sanitizeRequestObject(requestObj);
      const response = await this.singleton.request({
        url: `${requestObj.apiVersion}/${requestObj.path}`,
        method,
        headers: requestObj.headers,
        // TODO: Observe why request object does not include queryparams
        // params: requestObj.queryParams,
        params: queryParams,
        data: requestObj.body,
        responseType: requestObj.responseType,
      });
      output.success = true;
      output.statusCode = response.status;
      output.body = response.data;
    } catch (error) {
      output.success = false;
      if (error instanceof AxiosError) {
        const { response } = error;
        output.statusCode = response.status;
        output.body = response.data;
      }
    }

    return output;
  }
}

const httpAdapter = new HttpAdapter();
export default httpAdapter;
