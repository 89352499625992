import React, { useEffect } from "react";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { Pagination, Rating } from "@material-ui/lab";
import { useState } from "react";
import axios from "axios";
import { isEmpty } from "lodash";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import qs from "qs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import PromptModal from "../modals/PromptModal";
import moment from "moment";

const UnpublishReviewsCom = () => {
  const [publishedReviews, setPublishedReviews] = useState([]);
  const [unPublishId, setUnpublishId] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [noOfPages, setNoOfPages] = useState(1);
  const [pageNum, setPageNum] = useState(1);

  const [isFetchingReviews, setIsfetchingReviews] = useState(false);

  const getPublishedReviews = async () => {
    const pageSize = 10;
    try {
      setIsfetchingReviews(true);
      const BASE_URL = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${BASE_URL}/v1/generic/reviews?pageNum=${
          pageNum - 1
        }&pageSize=${pageSize}`,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      );
      setNoOfPages(response.data.pageCount);
      setPublishedReviews(response.data.results);
    } catch (e) {
      console.log("Error [Reviews]", e);
    } finally {
      setIsfetchingReviews(false);
    }
  };

  const handleUnpublish = async (id) => {
    try {
      const BASE_URL = process.env.REACT_APP_API_URL;
      let url = `${BASE_URL}/v1/admin/reviews/${unPublishId}`;

      let data = qs.stringify({ unpublish: true });

      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      });
      NotificationManager.success("Review Unpublished", "Success");
      getPublishedReviews();
    } catch (e) {
      NotificationManager.error("Error");
      console.log("Error [review-unpublish]", e);
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handlePagination = (event, value) => {
    setPageNum(value);
  };

  const handleModalClose = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    getPublishedReviews();
  }, [pageNum]);

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Reviews" title="Unpublish Reviews" />
        <div
          className="container-fluid inner-content"
          style={{ width: "100%" }}
        >
          <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
            <div className="content p-0">
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th
                      className="border border-secondary text-center"
                    >
                      Name
                    </th>
                    <th
                      className="border border-secondary text-center"
                    >
                      Published At
                    </th>
                    <th
                      className="border border-secondary text-center"
                    >
                      Rating
                    </th>
                    <th
                      className="border border-secondary text-center"
                    >
                      Review
                    </th>
                    <th
                      className="border border-secondary text-center"
                    >
                      Source
                    </th>
                    <th
                      className="border border-secondary text-center"
                    >
                      Unpublish
                    </th>
                  </tr>
                </thead>
                <tbody className="border border-secondary">
                  {isFetchingReviews ? (
                    <td colSpan="6">
                      <div class="text-center">
                        <span class="spinner-border" role="status" />
                      </div>
                    </td>
                  ) : !isEmpty(publishedReviews) ? (
                    publishedReviews.map((review) => (
                      <tr>
                        <td className="border border-secondary">
                          {review.name}
                        </td>
                        <td className="border border-secondary">
                          {moment.utc(review.createdAt).local().format("YYYY-MM-DD h:mm:ss a")}
                        </td>
                        <td className="border border-secondary">
                          <Rating
                            name="size-small"
                            disabled
                            size="small"
                            value={review.rating}
                            className="start-rating"
                          />
                        </td>
                        <td
                          className="border border-secondary"
                          style={{ maxWidth: 500, maxHeight: 10 }}
                        >
                          {review.content}
                        </td>
                        <td className="border border-secondary">
                          {review.source}
                        </td>
                        <td className="border border-secondary">
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              setUnpublishId(review.id);
                              setShowDeleteModal(true);
                            }}
                          >
                            Unpublish
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No Recods</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row m-0">
            <div className="col p-0">
              <div className="pagination-div">
                <Pagination
                  count={noOfPages}
                  page={pageNum}
                  onChange={handlePagination}
                  className="pagi"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
      {showDeleteModal && (
        <PromptModal
          showModal={showDeleteModal}
          heading="Unpublish Review"
          title="Are you sure you want to unpublish this review?"
          actionName="Unpublish"
          onActionClick={handleUnpublish}
          closeModal={handleModalClose}
          btnStyle="danger"
        />
      )}
    </>
  );
};

export default UnpublishReviewsCom;
