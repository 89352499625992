import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import AllAgentsCom from "../../components/admin/all-agents/AllAgentsCom";

function AllAgents() {
  return (
    <AdminLayout>
      <AllAgentsCom />
    </AdminLayout>
  );
}

export default AllAgents;
