import React from "react";
import propTypes from "prop-types";
import { Paper, Grid, Typography } from "@material-ui/core";

const InterestPreference = ({
  interestPreference,
  residentCountries,
  ethnicities,
  civilStatuses,
  minAge,
  maxAge,
  heightMin,
  heightMax,
  religions,
  ShowEditInterestSection
}) => {
  InterestPreference.propTypes = {
    interestPreference: propTypes.bool.isRequired,
    residentCountries: propTypes.array.isRequired,
    ethnicities: propTypes.array.isRequired,
    civilStatuses: propTypes.array.isRequired,
    minAge: propTypes.number,
    maxAge: propTypes.number,
    heightMin: propTypes.number,
    heightMax: propTypes.number,
    religions: propTypes.array.isRequired,
    ShowEditInterestSection:propTypes.func.isRequired
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <div style={{display:'flex', justifyContent:'space-between'}}>
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Interest Preferences
        </Typography>
        <span
            className="edit-interest-link"
            onClick={() => ShowEditInterestSection()}
          >
            <i className="fas fa-external-link-alt"></i>  Edit
          </span>
        </div>      
        <div className="row m-0">
          <div className="col p-0">
            {interestPreference ? (
              <>
                <div className="review-col rev-col-left">
                  <div className="con-row">
                    <div className="con-col">Age Range</div>
                    <div className="con-col">
                      {minAge ? `${minAge} - ${maxAge}` : "Any"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Ethnicity</div>
                    <div className="con-col">
                      {ethnicities?.length === 0 ? (
                        "Any"
                      ) : (
                        <span>{ethnicities.join(", ")}</span>
                      )}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Religion</div>
                    <div className="con-col">
                      {religions?.length === 0 ? (
                        "Any"
                      ) : (
                        <span>{religions.join(", ")}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="review-col rev-col-right">
                  <div className="con-row">
                    <div className="con-col">Height Range</div>
                    <div className="con-col">
                      {heightMin ? `${heightMin}cm - ${heightMax}cm` : "Any"}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Civil Status</div>
                    <div className="con-col">
                      {civilStatuses?.length === 0 ? (
                        "Any"
                      ) : (
                        <span>{civilStatuses.join(", ")}</span>
                      )}
                    </div>
                  </div>
                  <div className="con-row">
                    <div className="con-col">Resident Country</div>
                    <div className="con-col">
                      {residentCountries?.length === 0 ? (
                        "Any"
                      ) : (
                        <span>{residentCountries.join(", ")}</span>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center",font:"inherit", fontSize:"15px", fontWeight:"400", lineHeight:"16px", color:"#333333" }}>
                <span>Interest preferences are not available</span>
              </div>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default InterestPreference;
