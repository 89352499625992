import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/admin/layouts/AdminLayout";
import { Grid, Paper, Typography } from "@material-ui/core";
import { isAuthenticatedBoth } from "../../auth/authBoth";
import { useHistory } from "react-router-dom";
import moment from "moment";

import axios from "axios";

const ActivityLog = (props) => {
  const [activity, setActivity] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getActivityLogs();
  }, []);

  const getActivityLogs = () => {
    const serachParams = new URLSearchParams(props.location.search);
    const id = serachParams.get("id");

    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/posts/${id}/audit_log`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setActivity(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const eventTypeHandler = (eventType) => {
    switch (eventType) {
      case "CREATE":
        return "Post Create";
        break;
      case "UPDATE":
        return "Post Update";
        break;
      case "PAY_CC":
        return "Card Payment";
        break;
      case "PAY_BN":
        return "Bank Tx";
        break;
      case "PUBLISH":
        return "Post Published";
        break;
      case "RENEW_FREE":
        return "Free Renewal";
        break;
      case "RENEW_CC":
        return "Card Payment (Renew)";
        break;
      case "RENEW_BN_TX":
        return "Bank Tx (Renew)";
        break;
      case "DISABLE":
        return "Post Disabled";
        break;
      default:
        return "no activity available";
    }
  };

  return (
    <AdminLayout>
      <Grid>
        <Paper elevation={0} className="post-details-con">
          <span style={{ cursor: "pointer" }} onClick={() => history.goBack()}>
            <i className="fas fa-angle-left"></i> Back
          </span>
          <Typography variant="h6" className="heading" style={{ margin: 30 }}>
            Activity Logs
          </Typography>
          <div
            className="row m-0"
            style={{ paddingLeft: "20px", paddingTop: "20px" }}
          >
            <div>
              <table
                className="table table-hover table-responsive common-table border mb-0"
                style={{ fontSize: 14 }}
              >
                <thead className="header">
                  <tr>
                    <th width="250" className="border-0">
                      Created Date
                    </th>
                    <th width="250" className="border-0">
                      Created Time
                    </th>
                    <th width="250" className="border-0">
                      Activity
                    </th>
                    <th width="250" className="border-0">
                      Description
                    </th>
                    <th width="200" className="border-0">
                      Activity By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activity.length > 0 ? (
                    activity.map((item, i) => (
                      <tr key={i}>
                        <td>{moment(item.createdAt).format("YYYY-MM-DD")}</td>
                        <td>{moment(item.createdAt).format(" h:mm:ss a")}</td>
                        <td>{eventTypeHandler(item.eventType)}</td>
                        <td>{item.description || ""}</td>
                        <td>
                          {item.actionBy?.name || "Self"}
                          {item.actionBy?.name ? " - " : ""}
                          {item.actionBy?.memberId}{" "}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div>No Records</div>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </Paper>
      </Grid>
    </AdminLayout>
  );
};

export default ActivityLog;
