import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import CreateAgentCom from "../../components/admin/all-agents/CreateAgentCom";

function CreateAgent() {
  return (
    <AdminLayout>
      <CreateAgentCom />
    </AdminLayout>
  );
}

export default CreateAgent;
