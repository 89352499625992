import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import PostServiceApi from "../../../services/post-service";
import { COMMON_SELECTION_OPTIONS } from "../../../static-data";

const CivilStatusUpdateModal = ({
  openModal,
  closeModal,
  civilStatusOptions,
  advertisement,
  getAdvertisement,
}) => {
  const [civilStatus, setCivilStatus] = useState("");
  const [childrenStatus, setChildrenStatus] = useState("");
  const [childrenFieldError, setChildrenFieldError] = useState("");

  useEffect(() => {
    setCivilStatus(advertisement.personalInfo?.civilStatus?.id ?? "");
    setChildrenStatus(advertisement.personalInfo?.children ?? "");
  }, [advertisement]);

  const validateForm = () => {
    if (civilStatus === "never_married") {
      return true;
    }
    let output = true;

    if (isEmpty(childrenStatus)) {
      setChildrenFieldError("Please select an option");
      output = false;
    }
    return output;
  };

  const handleSave = async () => {
    try {
      const isValidForm = validateForm();
      if (!isValidForm) {
        return;
      }
      const data = {
        personalInfo: {
          civilStatusId: civilStatus,
          children: (civilStatus === "never_married") ? "" : childrenStatus,
        },
      };

      const response = await PostServiceApi.putPostUpdate({
        id: advertisement.id,
        data,
      });

      if (response.success) {
        getAdvertisement();
        closeModal(false);
        setChildrenFieldError("");
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onChange = (event) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    switch (fieldName) {
      case "civilStatus":
        setCivilStatus(fieldValue);
        setChildrenStatus("");
        break;
      case "children":
        setChildrenStatus(fieldValue);
        break;
    }
  };

  return (
    <Dialog
      open={openModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
      onClose={() => {
        setChildrenFieldError("");
        closeModal(false);
      }}
    >
      <DialogTitle className="modal-header">Edit Civil Status</DialogTitle>
      <DialogContent>
        <Grid container direction="column" style={{ rowGap: 13 }}>
          <Grid item container direction="column">
            <Typography>Civil status</Typography>
            <select
              className="form-control"
              name="civilStatus"
              onChange={onChange}
              value={civilStatus}
            >
              {civilStatusOptions.map((value, index) => (
                <option key={index} value={value.id}>
                  {value.type}
                </option>
              ))}
            </select>
          </Grid>
          {civilStatus !== "never_married" && (
            <Grid item container direction="column">
              <Typography>Children</Typography>
              <select
                className="form-control"
                name="children"
                onChange={onChange}
                value={childrenStatus}
              >
                <option value="" hidden>
                  select option
                </option>
                {COMMON_SELECTION_OPTIONS.map((value, index) => (
                  <option key={index} value={value.id}>
                    {value.type}
                  </option>
                ))}
              </select>
              {childrenFieldError && (
                <Typography color="error" style={{fontSize: 12}}>{childrenFieldError}</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn"
          style={{ width: 95 }}
          onClick={() => closeModal(false)}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          style={{ width: 95 }}
          onClick={handleSave}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default CivilStatusUpdateModal;
