import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import { Paper, Grid, Typography } from "@material-ui/core";
import ReactCountryFlag from "react-country-flag";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import "react-photo-view/dist/index.css";

import groom from "../../../../images/groom.png";
import bride from "../../../../images/bride.png";

import AdPublishModal from "../../modals/AdPublishModal";
import AdRenewModal from "../../modals/AdRenewModal";
import EditAdNameModal from "../../modals/EditAdNameModal";
import TopAdModal from "../../modals/TopAdModal";

import { isAuthenticated } from "../../../../auth/auth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import ArchiveModal from "../../modals/ArchiveModal";
import DisableModal from "../../modals/DisableModal";
import BlacklistModal from "../../modals/BlacklistModal";
import ChangeStatusModal from "../../modals/ChangeStatusModal";
import ShowHideFromSearchModal from "../../modals/ShowHideFromSearchModal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { ToastContainer, toast } from "react-toastify";

const Header = (props) => {
  const [imageData, setImageData] = useState([]);
  const [loadingDisableArchive, setLoadingDisableArchive] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState(false);
  const [disableArchivePost, setDisableArchivePost] = useState({});
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [loadingBlacklisting, setLoadingBlacklisting] = useState(false);
  const [loadingChangeStatus, setLoadingChangeStatus] = useState(false);
  const [loadingShowHidePost, setLoadingShowHidePost] = useState(false);
  const [showEditNameModal, setShowEditNameModal] = useState(false);
  const [firstName, setFirstName] = useState(
    props?.ad?.personalInfo?.fname ?? ""
  );
  const [lastName, setLastName] = useState(
    props?.ad?.personalInfo?.lname ?? ""
  );

  useEffect(() => {
    setImageData([]);
    if (props.ad.images && props.ad.images.length > 0) {
      for (let i = 0; i < props.ad.images.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/images/${props.ad.images[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    }
  }, [props.ad.images]);

  useEffect(() => {
    if (props.ad.status === "DISABLED") {
      setArchiveStatus(true);
    }
    handleGetPost(props.ad.id);
  }, [props.ad]);

  useEffect(() => {
    handleGetIsBlacklisted(props.ad.memberId);
  }, [props.ad]);


  const getAcountCreatedBy = () => {
    switch(props.ad.createdBy){
      case "SELF": return "Self"
      case "PARENT": return "Parents"
      case "FAMILY": return "Family"
      case "FRIEND": return "Friend"
      default: return "Error"
    }
  }
   
  const handleGetIsBlacklisted = (memberId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator/is_blacklisted?memberId=${memberId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setIsBlacklisted(res.data.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetPost = (postId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator/search_users?postId=${postId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setDisableArchivePost(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleArchive = (memberId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/generic/archive_user`,
        `memberId=${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        document.getElementById("archiveModalClose").click();
        setArchiveStatus(true);
        props.getAd();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDisable = (postId) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/disable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        document.getElementById("disableModalClose").click();
        props.getAd();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlacklist = (phone) => {
    setLoadingBlacklisting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/phonenumbers/blacklist`,
        qs.stringify({ number: phone }),
        {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        document.getElementById("blacklistModalClose").click();
        setIsBlacklisted(true);
        setLoadingBlacklisting(false);
        NotificationManager.success("Phone number blacklisted", "Success");
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Blacklisting failed", "Error");
        setLoadingBlacklisting(false);
      });
  };

  const handleTopAd = () => {
    const data = qs.stringify({
      paymentAmount: props.amount,
      paymentCurrency: props.selectedCurrency,
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/operator_posts/${props.ad.id}/set_featured`,
        data,
        {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        document.getElementById("topAdModalClose").click();
        props.getAd();
        NotificationManager.success("Converted to a Top Ad", "Success");
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Coverting to a Top Ad failed", "Error");
      });
  };

  const handleChangeStatus = (comment) => {
    setLoadingChangeStatus(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/${props.ad.id}/update_status`,
        qs.stringify({ comment: comment }),
        {
          headers: {
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        document.getElementById("changeStatusModalClose").click();
        props.getAd();
        NotificationManager.success("Status changed", "Success");
        setLoadingChangeStatus(false);
        if (comment) {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error("Status changing failed", "Error");
        setLoadingChangeStatus(false);
      });
  };

  const handleShowHidePost = (status) => {
    setLoadingShowHidePost(true);
    const data = {
      excludeFromSearch: status,
    };
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/v1/admin/posts/${props.ad.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        document.getElementById("showHideFromSearchModalClose").click();
        props.getAd();
        NotificationManager.success("Post visible status changed", "Success");
        setLoadingShowHidePost(false);
      })
      .catch((err) => {
        console.log(err);
        NotificationManager.error(
          "Post visible status changing failed",
          "Error"
        );
        setLoadingShowHidePost(false);
      });
  };

  const openEditNameModel = (fName, lName, nameFormat) => {
    setShowEditNameModal(true);
    setFirstName(fName);
    setLastName(lName);
  };

  const handleSave = () => {
    if (firstName !== "" && lastName !== "") {
      const data = {
        personalInfo: {
          fname: firstName,
          lname: lastName,
        },
      };
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/v1/admin/posts/${props.ad.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${isAdminOrOperatorL2()}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          NotificationManager.success("Post updated", "Success");
          props.getAd();
          setShowEditNameModal(false);
        })
        .catch((err) => {
          NotificationManager.error("Post updating failed", "Error");
          setShowEditNameModal(false);
        });
    } else {
      NotificationManager.error("Empty fields!", "Error");
    }
  };

  const getLastOnlineStatus = () => {
    let timeValue = "";
    if (props.ad.lastOnline !== undefined) {
      switch (props.ad.lastOnline) {
        case "0_DAY":
          timeValue = "Today";
          break;
        case "1_DAY":
          timeValue = "Yesterday";
          break;
        case "7_DAY":
          timeValue = "Within 7 days";
          break;
        case "30_DAY":
          timeValue = "Within 30 days";
          break;
        case ">30_DAY":
          timeValue = "30+ days ago";
          break;
      }
    }
    return timeValue;
  };

  return (
    <>
      <Grid item xs={12}>
        <Paper
          elevation={0}
          style={{ position: "relative", marginTop: 10 }}
          className="post-details-con"
        >
          <div className="post-details-col ">
            {props.ad.personalInfo.gender === "MALE" ? (
              <img src={groom} className="applicant-photo" alt="groom" />
            ) : (
              <img src={bride} className="applicant-photo" alt="bride" />
            )}

            <Typography variant="h6" className="name">
              <span
                style={{ cursor: "pointer", textTransform: "capitalize" }}
                onClick={() =>
                  openEditNameModel(
                    props?.ad?.personalInfo?.fname,
                    props?.ad?.personalInfo?.lname,
                    props?.ad?.personalInfo?.displayNameFormat
                  )
                }
              >
                {`${props.ad.personalInfo.fname} ${props.ad.personalInfo.lname}`}
              </span>
            </Typography>
            <p className="location">
              {props.ad.personalInfo.age && (
                <>
                  {props.ad.personalInfo.age}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {props.ad.personalInfo.residentCity}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {props.ad.personalInfo.professionOther
                    ? props.ad.personalInfo.professionOther
                    : props.ad.personalInfo.profession.name}{" "}
                  <span style={{ color: "#8c8c8c" }}>&#8226;</span>{" "}
                  {Math.floor(
                    Math.round(props.ad.personalInfo.height / 2.54) / 12
                  )}
                  ' {Math.round(props.ad.personalInfo.height / 2.54) % 12}"
                </>
              )}
            </p>
          </div>
          <div className="post-date-col">
            <p align="right" className="date-of-post">
              {props.ad.personalInfo.originCountry.code === "ZZ" ? (
                <i
                  className="fas fa-globe-americas"
                  data-tip="Other Country"
                ></i>
              ) : (
                <ReactCountryFlag
                  countryCode={props.ad.personalInfo.originCountry.code}
                  svg
                  style={{
                    fontSize: "1.25em",
                  }}
                  aria-label={props.ad.personalInfo.originCountry.name}
                  data-tip={props.ad.personalInfo.originCountry.name}
                />
              )}
            </p>
          </div>

          {isBlacklisted && (
            <label className="blacklisted-label">Blacklisted Number</label>
          )}

          {props.ad.createdBy ? (
            <label className="createdby-label">
              {`Account created by: ${getAcountCreatedBy()}`}
            </label>
          ) : null}

          {props.ad.operatorCreated && (
            <label className="createdby-label">
              Account created by : Agent
            </label>
          )}

          {props.ad.idVerified && (
            <label className="verified-label">Id Verified</label>
          )}

          {props.ad.status === "LIVE" ? (
            <>
              <p
                className="status live d-block"
                style={{ margin: "5px 0 0 71px", width: "fit-content" }}
              >
                {props.ad.status}
              </p>

              {props.ad.isFeatured ? (
                <label
                  className="createdby-label"
                  style={{ background: "#f7ae6f" }}
                >
                  Top Ad
                </label>
              ) : null}

              {(props.ad.status === "LIVE" || props.ad.status === "EXPIRED") &&
              moment
                .utc(moment.utc(props.ad.expiresAt).subtract(7, "days"))
                .local()
                .format() < moment().format() ? (
                <RenewBtn />
              ) : null}
              <div className="last-online-block">
                <i className="fas fa-circle alert-online-icon text-success mr-2"></i>
                Last Online:
                <span className="last-online-status">
                  {` ${getLastOnlineStatus()}`}
                </span>
              </div>
            </>
          ) : props.ad.status === "EXPIRED" ? (
            <>
              <p
                className="status expired d-block"
                style={{ margin: "5px 0 0 71px", width: "fit-content" }}
              >
                {props.ad.status}
              </p>
              <div className="last-online-block">
                <i className="fas fa-circle alert-online-icon text-success mr-2"></i>
                Last Online:
                <span className="last-online-status">
                  {` ${getLastOnlineStatus()}`}
                </span>
              </div>
              {isAdminOrOperatorL2() && <RenewBtn />}
            </>
          ) : props.ad.status === "DISABLED" ? (
            <>
              <p
                className="status disabled d-block"
                style={{ margin: "5px 0 0 71px", width: "fit-content" }}
              >
                {props.ad.status}
              </p>
              {/* {isAdminOrOperatorL2() && <PublishBtn />} */}
              <div className="last-online-block">
                <i className="fas fa-circle alert-online-icon text-success mr-2"></i>
                Last Online:
                <span className="last-online-status">
                  {` ${getLastOnlineStatus()}`}
                </span>
              </div>
            </>
          ) : props.ad.status === "IN_REVIEW" ? (
            <>
              <p
                className="status reviewing d-block"
                style={{ margin: "5px 0 0 71px", width: "fit-content" }}
              >
                {props.ad.status}
              </p>
              <div className="last-online-block">
                <i className="fas fa-circle alert-online-icon text-success mr-2"></i>
                Last Online:
                <span className="last-online-status">
                  {` ${getLastOnlineStatus()}`}
                </span>
              </div>
              {isAdminOrOperatorL2() && <PublishBtn />}
            </>
          ) : (
            <>
              <p
                className="status pending d-block"
                style={{ margin: "5px 0 0 71px", width: "fit-content" }}
              >
                {props.ad.status}
              </p>
              <div className="last-online-block">
                <i className="fas fa-circle alert-online-icon text-success mr-2"></i>
                Last Online:
                <span className="last-online-status">
                  {` ${getLastOnlineStatus()}`}
                </span>
              </div>
              {isAdminOrOperatorL2() && <PublishBtn />}
            </>
          )}

          {isAdminOrOperatorL2() && (
            <ArchiveUserBtn post={disableArchivePost} ad={props.ad} />
          )}

          {imageData.length > 0 ? (
            <PhotoProvider>
              {imageData.map((item, index) => (
                <PhotoConsumer key={index} src={item}>
                  <img src={item} alt="" className="photos" />
                </PhotoConsumer>
              ))}
            </PhotoProvider>
          ) : null}

          {props.ad.personalInfo.additionalInfo ? (
            <p
              className={isAdminOrOperatorL2() ? "desc-admin" : "desc"}
              onClick={
                isAdminOrOperatorL2()
                  ? (e) => {
                      props.openEditModal(
                        "Description",
                        "additionalInfo",
                        "text",
                        props.ad.personalInfo.additionalInfo,
                        [],
                        "personalInfo",
                        "SELF"
                      );
                    }
                  : null
              }
              style={{ marginBottom: 0, marginTop: 20 }}
            >
              {props.ad.personalInfo.additionalInfo}
            </p>
          ) : (
            <div>
              <a
                className="desc-link"
                onClick={
                  isAdminOrOperatorL2()
                    ? (e) => {
                        props.openEditModal(
                          "Description",
                          "additionalInfo",
                          "text",
                          props.ad.personalInfo.additionalInfo,
                          [],
                          "personalInfo",
                          "SELF"
                        );
                      }
                    : null
                }
              >
                Add Description
              </a>
            </div>
          )}
        </Paper>
      </Grid>

      <AdPublishModal
        handlePublish={props.handlePublish}
        loading={props.loading}
        isOverRidePackage={props.isOverRidePackage}
        overRidePackage={props.overRidePackage}
        packages={props.packages}
        selectedPackage={props.selectedPackage}
        changeSelectedPackage={props.changeSelectedPackage}
        changeSelectedCurrency={props.changeSelectedCurrency}
        changeSelectedAmount={props.changeSelectedAmount}
        status={props.ad ? props.ad.status : null}
        defaultPackage={props.defaultPackage}
        selectedCurrency={props.selectedCurrency}
        amount={props.amount}
      />

      <AdRenewModal
        handleRenew={props.handleRenew}
        loading={props.loading}
        isOverRidePackage={props.isOverRidePackage}
        overRidePackage={props.overRidePackage}
        packages={props.packages}
        selectedPackage={props.selectedPackage}
        changeSelectedPackage={props.changeSelectedPackage}
        changeSelectedCurrency={props.changeSelectedCurrency}
        changeSelectedAmount={props.changeSelectedAmount}
        defaultPackage={props.defaultPackage}
        selectedCurrency={props.selectedCurrency}
        amount={props.amount}
      />

      <TopAdModal
        handleTopAd={handleTopAd}
        loading={props.loading}
        selectedCurrency={props.selectedCurrency}
        changeSelectedCurrency={props.changeSelectedCurrency}
        changeSelectedAmount={props.changeSelectedAmount}
        amount={props.amount}
      />

      <ArchiveModal
        disableArchivePost={disableArchivePost}
        handleArchive={handleArchive}
        loadingDisableArchive={loadingDisableArchive}
      />

      <DisableModal
        disableArchivePost={disableArchivePost}
        handleDisable={handleDisable}
        loadingDisableArchive={loadingDisableArchive}
      />

      <BlacklistModal
        post={disableArchivePost}
        handleBlacklist={handleBlacklist}
        loadingBlacklisting={loadingBlacklisting}
      />

      <ChangeStatusModal
        post={disableArchivePost}
        handleChangeStatus={handleChangeStatus}
        loadingChangeStatus={loadingChangeStatus}
      />

      <ShowHideFromSearchModal
        handleShowHidePost={handleShowHidePost}
        loadingShowHidePost={loadingShowHidePost}
        isHidden={props.ad.excludeFromSearch}
      />

      <NotificationContainer />

      <EditAdNameModal
        showModal={showEditNameModal}
        setShowModal={setShowEditNameModal}
        fName={firstName}
        lName={lastName}
        updateFname={setFirstName}
        updateLname={setLastName}
        handleSave={handleSave}
      />
      <ToastContainer />
    </>
  );
};

const PublishBtn = () => {
  return (
    <button
      type="button"
      data-toggle="modal"
      data-target="#adPublishModal"
      className="action-btn"
    >
      Publish
    </button>
  );
};

const RenewBtn = () => {
  return (
    <button
      type="button"
      data-toggle="modal"
      data-target="#adRenewModal"
      className="action-btn"
    >
      Renew
    </button>
  );
};

const ArchiveUserBtn = (props) => {
  return (
    <div className="dropdown" style={{ bottom: "60px", textAlign: "right" }}>
      <button
        className="btn dropdown-toggle disable-archive-action-btn p-0"
        type="button"
        id="dropdownMenu2"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <i className="fas fa-ellipsis-v"></i>
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
        <button
          type="button"
          data-toggle="modal"
          data-target="#archiveModal"
          className="dropdown-item"
        >
          Archive User
        </button>
        <button
          type="button"
          data-toggle="modal"
          data-target="#disableModal"
          className="dropdown-item"
        >
          Disable
        </button>
        {isAdminOrOperatorL2() &&
          props.post &&
          props.post.post &&
          props.post.post.status === "DISABLED" && (
            <button
              type="button"
              data-toggle="modal"
              data-target="#changeStatusModal"
              className="dropdown-item"
            >
              Change Status
            </button>
          )}
        {isAdminOrOperatorL2() && (
          <>
            <button
              type="button"
              data-toggle="modal"
              data-target="#topAdModal"
              className="dropdown-item"
            >
              Top Ad
            </button>
            <button
              type="button"
              data-toggle="modal"
              data-target="#blacklistModal"
              className="dropdown-item"
            >
              Blacklist Number
            </button>
          </>
        )}
        <button
          type="button"
          data-toggle="modal"
          data-target="#showHideFromSearchModal"
          className="dropdown-item"
        >
          {props.ad.excludeFromSearch ? "Show Post" : "Hide Post"}
        </button>
      </div>
    </div>
  );
};

export default Header;
