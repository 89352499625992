import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import FollowUpAdCreatedCom from "../../components/admin/follow-ups/FollowUpAdCreatedCom";

function FollowUpAdCreated() {
  return (
    <AdminLayout>
      <FollowUpAdCreatedCom />
    </AdminLayout>
  );
}

export default FollowUpAdCreated;
