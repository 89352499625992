import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import FollowUpExpiredAdsCom from "../../components/admin/follow-ups/FollowUpExpiredAdsCom";

function FollowUpExpiredAds() {
  return (
    <AdminLayout>
      <FollowUpExpiredAdsCom />
    </AdminLayout>
  );
}

export default FollowUpExpiredAds;
