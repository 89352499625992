import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { NotificationContainer } from "react-notifications";
import { useReactToPrint } from "react-to-print";

import PdfFormSection from "./profile-pdf-export/components/form-section";
import PdfSection from "./profile-pdf-export/components/pdf-section";

const ProfilePdfExportModal = ({
  showModal,
  onClose,
  adId,
  hasHoroscopeInfo,
}) => {
  const contentRef = useRef(null);

  const [selectedInterest, setSelectedInterest] = useState({});
  const [isfetchingPdfData, setIsFetchingPdfData] = useState(false);

  const handlePrint = useReactToPrint({
    documentTitle: !isEmpty(selectedInterest)
      ? `Poruwa.lk_${selectedInterest.personalInfo?.displayName}`
      : "",
    content: () => contentRef.current,
  });

  return (
    <Dialog
      open={showModal}
      onClose={() => onClose(false)}
      PaperProps={{
        style: {
          minWidth: "70%",
          minHeight: "70%",
        },
      }}
    >
      <DialogTitle className="profile-pdf-export-modal-title">
        <Typography>Download Profile PDF</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          className="profile-pdf-export-modal-content"
        >
          <PdfFormSection
            adId={adId}
            setSelectedInterest={setSelectedInterest}
            selectedInterest={selectedInterest}
            handlePrint={handlePrint}
          />
          <Divider />
          {isfetchingPdfData && (
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div
            ref={contentRef}
            style={
              !isEmpty(selectedInterest)
                ? {
                    backgroundColor: "#F2F4F4",
                    borderWidth: 1,
                    borderStyle: "solid",
                    borderColor: "black",
                  }
                : {}
            }
          >
            {!isEmpty(selectedInterest) && (
              <PdfSection
                selectedInterest={selectedInterest}
                hasHoroscopeInfo={hasHoroscopeInfo}
                setIsFetching={setIsFetchingPdfData}
              />
            )}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions className="profile-pdf-export-modal-action"></DialogActions>
      <NotificationContainer />
    </Dialog>
  );
};

export default ProfilePdfExportModal;
