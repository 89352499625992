import React, { useState } from "react";

import Close from "../../../images/close-white.png";

function TopAdModal(props) {
  const [amount, setAmount] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [isBankTransfer, setIsBankTransfer] = useState(false);

  const selectCurrency = (id) => {
    setCurrency(id);
    props.changeSelectedCurrency(id);
  };

  const enteredAmount = (Val) => {
    setAmount(Val);
    props.changeSelectedAmount(Val);
  };

  const bankTransferHandle = (e) => {
    setIsBankTransfer(e.target.checked);
  };

  return (
    <div
      className="modal fade"
      id="topAdModal"
      tabIndex="-1"
      aria-labelledby="topAdModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="topAdModalLabel">
              Are you sure?
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="topAdModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 20 }}>
              Do you want to make this Ad a Top Ad?
            </p>
            <div class="form-check" style={{paddingBottom:10}}>
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                checked={isBankTransfer}
                onChange={(e) => bankTransferHandle(e)}
              />
              <label class="form-check-label" for="flexCheckDefault">
                Bank Transaction
              </label>
            </div>
            {isBankTransfer && (
              <div>
                <div style={{ marginBottom: 10 }}>
                  <label
                    className="col-md-6 px-md-0 px-sm-2 mb-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Payment Currency *
                  </label>
                  <select
                    className="form-control col-md-8 px-sm-2 mb-1"
                    id="currency"
                    name="currency"
                    value={props.selectCurrency}
                    onChange={(e) => selectCurrency(e.target.value)}
                  >
                    <option defaultValue hidden>
                      -- Select --
                    </option>
                    <option value="USD">USD</option>
                    <option value="LKR">LKR</option>
                  </select>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <label
                    className="col-md-6 px-md-0 px-sm-2 mb-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Payment Amount *
                  </label>
                  <input
                    onChange={(e) => enteredAmount(e.target.value)}
                    className="form-control col-md-8 px-sm-2 mb-1"
                    type="number"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={props.handleTopAd}
              disabled={!amount || !currency}
              style={{ width: 95 }}
            >
              {props.loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopAdModal;
