import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  NotificationManager,
} from "react-notifications";

import PdfHeader from "../pdf-header";
import PdfContent from "../pdf-content";
import "./style.css"

import { isAuthenticatedBoth } from "../../../../../../auth/authBoth";

const PdfSection = ({ selectedInterest, hasHoroscopeInfo, setIsFetching }) => {
  const [pdfProfileInfo, setPdfProfileInfo] = useState({});

  const fetchPdfData = async () => {
    try {
      if (selectedInterest.id == undefined) {
        return;
      }
      setIsFetching(true);
      const BASE_URL = process.env.REACT_APP_API_URL;
      let URL = `${BASE_URL}/v1/admin/posts/${selectedInterest.id}`;

      const response = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
          "Content-Type": "application/json",
        },
      });
      setPdfProfileInfo(response.data);
    } catch (error) {
      NotificationManager.error("Error getting data", "Error");
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchPdfData();
  }, [selectedInterest]);

  return (
    <Grid
      direction="column"
      className="pdf-section"
    >
      <PdfHeader headerInfo={pdfProfileInfo} />
      <PdfContent
        content={pdfProfileInfo}
        hasHoroscopeInfo={hasHoroscopeInfo}
      />
    </Grid>
  );
};

export default PdfSection;
