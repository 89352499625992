import React, { useState, useEffect } from "react";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import AgentsDetailsModal from "../modals/AgentsDetailsModal";
import { isAuthenticatedBoth } from "../../../auth/authBoth";

function AllAgentsCom() {
  const [agents, setAgents] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [agentInfo, setAgentInfo] = useState({});

  const itemsPerPage = 20;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/agents`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setAgents(res.data);
      })
      .catch((err) => {
        console.log(err);
        // setLoading(false);
      });
  }, []);

  const onChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const filterBySearch = agents.filter((el) => {
    if (search) {
      return (
        el.businessName.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.code.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.city.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    } else {
      return el;
    }
  });

  const handleAgents = (code) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/agents/${code}`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setAgentInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Agent" title="All Agents" />

      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                <label className="sr-only" htmlFor="search-by-id">
                  Search by name or code or city
                </label>
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    id="search-by-id"
                    placeholder="Name / Code / City"
                    value={search}
                    onChange={onChange}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Agent Code</th>
                  <th className="border-0">City</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr key={i}>
                      <td width="200">{el.businessName}</td>
                      <td width="200">
                        {" "}
                        <span
                          data-toggle="modal"
                          data-target="#agentsDetailsModal"
                          onClick={() => handleAgents(el.code)}
                          style={{ cursor: "pointer", color: "#007bff" }}
                        >
                          {el.code}
                        </span>
                      </td>
                      <td>{el.city}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>

        {Object.keys(agentInfo).length === 0 &&
        agentInfo.constructor === Object ? null : (
          <AgentsDetailsModal agent={agentInfo} />
        )}
      </div>
    </div>
  );
}

export default AllAgentsCom;
