import React, { useState, useEffect } from "react";
import classnames from "classnames";
import axios from "axios";

function Horoscope(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="originCountryCode">
          Birth Country <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.originCountryCodeErr,
          })}
          id="originCountryCode"
          name="originCountryCode"
          onChange={props.handleChange}
          value={props.form.originCountryCode}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.countries.map((el, i) => (
            <option value={el.code} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">
          {props.form.originCountryCodeErr}
        </div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="horoscopeMatching">
          Matching Required? <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.horoscopeMatchingErr,
          })}
          id="horoscopeMatching"
          name="horoscopeMatching"
          onChange={props.handleChange}
          value={props.form.horoscopeMatching}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          <option value="true">Required</option>
          <option value="false">Not required</option>
        </select>
        <div className="invalid-feedback">
          {props.form.horoscopeMatchingErr}
        </div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="birthCity">Birth City</label>
        <input
          type="text"
          className={classnames("form-control", {
            "is-invalid": props.form.birthCityErr,
          })}
          id="birthCity"
          name="birthCity"
          onChange={props.handleChange}
          value={props.form.birthCity}
        />

        <div className="invalid-feedback">{props.form.birthCityErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="birthTime">Birth Time</label>
        <input
          type="time"
          className={classnames("form-control", {
            "is-invalid": props.form.birthTimeErr,
          })}
          id="birthTime"
          name="birthTime"
          onChange={props.handleChange}
          value={props.form.birthTime}
        />
        <div className="invalid-feedback">{props.form.birthTimeErr}</div>
      </div>

      <div className="form-group col-12 px-sm-2 mb-3">
        <label htmlFor="horoscopeDetail">Additional Info</label>
        <textarea
          className={classnames("form-control")}
          name="horoscopeDetail"
          onChange={props.handleChange}
          value={props.form.horoscopeDetail}
          rows="5"
          placeholder="Optional"
        />
      </div>
    </div>
  );
}

export default Horoscope;
