import React, { useState, useEffect } from "react";
import axios from "axios";

function Private(props) {
  const [originCountry, setOriginCountry] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`)
      .then((res) => {
        const filterByCard = res.data.countries.filter(
          (el) => el.code === props.data.post.personalInfo.originCountryCode
        );

        setOriginCountry(filterByCard[0].name);
      })
      .catch((err) => console.log(err));
  }, [props.data.post]);

  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Private Info</p>
          </div>
        </div>
        <div className="content" style={{ paddingTop: 10, paddingBottom: 10 }}>
          <p className="form-heading">Horoscope</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Birth Country</div>
                <div className="col-7 p-2 font-weight-300">{originCountry}</div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Matching Required?</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.makePhonePublic
                    ? "Essential"
                    : "Non Essential"}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Birth City</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.birthCity}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Birth Time</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.birthTime}
                </div>
              </div>
            </div>
          </div>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Horoscope Details</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.post.horoscopeDetail}
                </div>
              </div>
            </div>
          </div>
          <p className="form-heading">Contact Details</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Email</div>
                <div
                  className="col-7 p-2 font-weight-300"
                  style={{ wordWrap: "break-word" }}
                >
                  {props.data.post.email ? props.data.post.email : "-"}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Phone Number</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.phoneNumber}
                </div>
              </div>
            </div>
          </div>
          <p className="form-heading">Offline Responses</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">
                  Accept written interests via post
                </div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.preferences.showOfflineId ? "Enable" : "Disable"}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Send me the monthly magazine</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.preferences.subscribeMagazine
                    ? "Enable"
                    : "Disable"}
                </div>
              </div>
            </div>
          </div>

          <p className="form-heading">Account Info</p>
          <div className="row m-0 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 col-md-6 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">
                  Account Created by
                </div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.accountOwner == "SELF" ? "Self" : "Parent / Family"}
                </div>
              </div>
            </div>
          </div>

          {props.data.userAddress && (
            <>
              <p className="form-heading">Mailing Address</p>
              <div className="row m-0 px-2 mb-3 review-row">
                <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
                  <div className="row m-0">
                    <div className="col p-2 font-weight-300">
                      <p style={{ margin: 0 }}>{props.data.userAddress.name}</p>
                      <p style={{ margin: 0 }}>
                        {props.data.userAddress.line1}
                      </p>
                      <p style={{ margin: 0 }}>
                        {props.data.userAddress.line2}
                      </p>
                      <p style={{ margin: 0 }}>{props.data.userAddress.city}</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm p-0 ml-sm-1 mb-1 background-dark bg-white"></div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Private;
