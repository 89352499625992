import React, { useState, useEffect } from "react";

import Close from "../../../images/close-white.png";

function DeleteSiblingModal(props) {

  const deleteSibling = () => {
    props.handleDeleteSibling();
  }

  return (
    <div
      className="modal fade"
      id="deleteSiblingModal"
      tabIndex="-1"
      aria-labelledby="editApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editApproveModalLabel">
              Are you sure?
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="editApproveModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>
              Do you want to delete this sibling ?
            </p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={deleteSibling}
              style={{ width: 95 }}
              data-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteSiblingModal;
