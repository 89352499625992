import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import _12FromTo24Hours from "12fromto24hours";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import DeleteImageModal from "../../modals/DeleteImageModal";
import Config from "../../../../config";

function Images(props) {
  const [imageData, setImageData] = useState([]);
  const [deletingId, setDeletingId] = useState("");

  useEffect(() => {
    setImageData([]);
    if (props.imagesArray && props.imagesArray.length > 0) {
      for (let i = 0; i < props.imagesArray.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/images/${props.imagesArray[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            let obj = {id : props.imagesArray[i], data: data};
            setImageData((imageData) => [...imageData, obj]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    }
  }, [props.imagesArray]);

  const handleDeleteImage = () => {
    props.delImages(deletingId);    
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Images
        </Typography>
        <div className="form-row m-0">
          <div className="form-group col px-sm-2 mb-4">
            {imageData.length > 0 &&
              imageData.map((el, i) => (
                <div className="prev-images-div" key={i}>
                  <img
                    src={el.data}
                    className="prev-images"
                    alt="images"
                  />
                  <i
                    class="fas fa-times-circle delete-img-icon"
                    onClick={() => setDeletingId(el.id)}
                    data-toggle="modal"
                    data-target="#deleteImageModal"
                  ></i>
                </div>
              ))}

            <input
              type="file"
              id="imgUpload"
              className="imgUpload"
              name="photo"
              onChange={props.handleUpload}
              accept="image/x-png,image/jpeg,image/jpg"
            />
            {props.imagesArray.length < Config.POST_IMAGE_SLOTS && (
              <label className="img-upload-label" htmlFor="imgUpload">
                {props.imageUploading ? <span className="spinner-border spinner-border-sm"></span> : <i class="fas fa-plus add-img-icon"></i>}
              </label>
            )}
          </div>
        </div>
      </Paper>
      <DeleteImageModal handleDeleteImage={handleDeleteImage}/>
    </Grid>
  );
}

export default Images;