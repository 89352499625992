import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import EditRequestsCom from "../../components/admin/edit-requests/EditRequestsCom";

function EditRequests() {
  return (
    <AdminLayout>
      <EditRequestsCom />
    </AdminLayout>
  );
}

export default EditRequests;
