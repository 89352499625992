import React from "react";

import Close from "../../../images/close-white.png";

function AgentsDetailsModal(props) {
  return (
    <div
      className="modal fade"
      id="agentsDetailsModal"
      tabIndex="-1"
      aria-labelledby="agentDetailsModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="agentDetailsModalLabel">
              Agent Information ({props.agent.agentInfo.code})
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="paymentRejectModalClose"
            />
          </div>
          <div className="modal-body">
            <table className="" style={{ fontSize: 14 }}>
              <tr>
                <td width="150">Member ID</td>
                <td style={{ fontWeight: 300 }}>: {props.agent.memberId}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td style={{ fontWeight: 300 }}>: {props.agent.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td style={{ fontWeight: 300 }}>: {props.agent.phoneNumber}</td>
              </tr>
              <tr>
                <td>Business Name</td>
                <td style={{ fontWeight: 300 }}>
                  : {props.agent.agentInfo.businessName}
                </td>
              </tr>
              <tr>
                <td>City</td>
                <td style={{ fontWeight: 300 }}>
                  : {props.agent.agentInfo.city}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentsDetailsModal;
