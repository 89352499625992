import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";

function EditAdNameFormatModal(props) {
    return (
        <Dialog
            open={props.showModal}
            PaperProps={{
                style: {
                    minWidth: "500px",
                    minHeight: "300px",
                },
            }}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" className="modal-header">
                Edit Profile Details
            </DialogTitle>
            <DialogContent style={{ minHeight: 80 }}>
                <DialogContentText id="alert-dialog-description">
                    <label>Display Name Format</label>
                    <select
                        className="form-control"
                        value={props.nameFormat}
                        onChange={(e) => props.updateNameFormat(e.target.value)}
                    >
                        <option key={1} selected hidden value="-- Select --">
                            -- Select --
                        </option>
                        <option key={2} value="FIRST">First name only</option>
                        <option key={3} value="LAST">Last name only</option>
                        <option key={4} value="FIRST_LAST">Full name</option>
                        <option key={5} value="FIRST_LAST_CHAR1">
                            First name and first letter of last name
                        </option>
                        <option key={6} value="FIRST_CHAR1_LAST">
                            First letter of first name and full last name
                        </option>
                    </select>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    className="btn"
                    style={{ width: 95 }}
                    onClick={props.handleClose}
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-success"
                    style={{ width: 95 }}
                    onClick={() => props.handleSave()}
                >
                    Save
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default EditAdNameFormatModal;
