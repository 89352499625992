import { USER_DATA } from "../actions/types";

const initialState = {
  userData: localStorage.getItem("user_data")
    ? JSON.parse(localStorage.getItem("user_data"))
    : {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };

    default:
      return state;
  }
};

export default authReducer;
