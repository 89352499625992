import React from "react";

import Close from "../../../images/close-white.png";

function ArchiveModal(props) {
  return (
    <div
      className="modal fade"
      id="archiveModal"
      tabIndex="-1"
      aria-labelledby="archiveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="archiveModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="archiveModalClose"
            />
          </div>
          {props.disableArchivePost.memberId && (
            <>
              <div className="modal-body">
                <p style={{ marginBottom: 25 }}>
                  Do you want to archive this ad?
                </p>
                <p className="mb-2" style={{ fontSize: 13 }}>
                  Member ID : {props.disableArchivePost.memberId}
                </p>
                <p className="mb-2" style={{ fontSize: 13 }}>
                  Ad ID : {props.disableArchivePost.post.id}
                </p>
                <p className="mb-2" style={{ fontSize: 13 }}>
                  Name : {props.disableArchivePost.post.firstName}{" "}
                  {props.disableArchivePost.post.lastName}
                </p>
                <p className="mb-2" style={{ fontSize: 13 }}>
                  Phone :{props.disableArchivePost.phone}
                </p>
                {props.disableArchivePost.id}
              </div>
              <div className="modal-footer border-0 p-2">
                <button type="button" className="btn" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    props.handleArchive(props.disableArchivePost.memberId)
                  }
                  style={{ width: 95 }}
                >
                  {props.loadingDisableArchive ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Archive"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ArchiveModal;
