import React from "react";
import classnames from "classnames";

function Father(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fOriginCountryCode">
          Country of Residence <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.fOriginCountryCodeErr,
          })}
          id="fOriginCountryCode"
          name="fOriginCountryCode"
          onChange={props.handleChange}
          value={props.form.fOriginCountryCode}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.countries.map((el, i) => (
            <option value={el.code} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">
          {props.form.fOriginCountryCodeErr}
        </div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fEthnicityId">
          Ethnicity <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.fEthnicityIdErr,
          })}
          id="fEthnicityId"
          name="fEthnicityId"
          onChange={props.handleChange}
          value={props.form.fEthnicityId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.ethnicities.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.fEthnicityIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fReligionId">
          Religion <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.fReligionIdErr,
          })}
          id="fReligionId"
          name="fReligionId"
          onChange={props.handleChange}
          value={props.form.fReligionId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.religions.map((el, i) => (
            <option value={el.id} key={i}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.fReligionIdErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fCaste">Caste</label>
        <input
          type="text"
          className="form-control"
          id="fCaste"
          name="fCaste"
          onChange={props.handleChange}
          value={props.form.fCaste}
          autoComplete="off"
        />
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="fProfessionId">Profession</label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.fAdditionalInfoErr,
          })}
          id="fProfessionId"
          name="fProfessionId"
          onChange={props.handleChange}
          value={props.form.fProfessionId}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.professions.map((el, i) => (
            <option value={el.id} key={el.id}>
              {el.name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.fProfessionIdErr}</div>
      </div>

      {props.fShowOtherProf && (
        <div className="form-group col-md-6 px-sm-2 mb-3">
          <label htmlFor="fOtherProfession">
            Type Profession <span className="required-tag">*</span>
          </label>
          <input
            type="text"
            className={classnames("form-control", {
              "is-invalid": props.form.fOtherProfessionErr,
            })}
            id="fOtherProfession"
            name="fOtherProfession"
            onChange={props.handleChange}
            value={props.form.fOtherProfession}
            autoComplete="off"
          />
          <div className="invalid-feedback">
            {props.form.fOtherProfessionErr}
          </div>
        </div>
      )}

      <div className="form-group col-12 px-sm-2 mb-3">
        <label htmlFor="fAdditionalInfo">Additional Info</label>
        <textarea
          className={classnames("form-control", {
            "is-invalid": props.form.fAdditionalInfoErr,
          })}
          name="fAdditionalInfo"
          onChange={props.handleChange}
          value={props.form.fAdditionalInfo}
          rows="5"
          placeholder="Optional"
        />
        <div className="invalid-feedback">{props.form.fAdditionalInfoErr}</div>
      </div>
    </div>
  );
}

export default Father;
