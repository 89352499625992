import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";

const styles = {
  selectWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  select: {
    width: "150px",
    padding: "5px 20px 5px 20px",
    borderRadius: "5px",
    fontSize: "12px",
  },
  tableWrapper: {
    paddingLeft: "20px",
    paddingTop: "20px",
  },
};

function Stats(props) {
  const [range, setRange] = useState("7-days");
  const [from, setFrom] = useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const [to, setTo] = useState(moment().format("YYYY-MM-DD"));
  const [appeared, setAppeared] = useState(0);
  const [detailedView, setDetailedView] = useState(0);
  const [mismatched, setMismatched] = useState(0);
  const [like, setLike] = useState(0);
  const [youLiked, setYouLiked] = useState(0);
  const [matches, setMatches] = useState(0);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.ad.id}/stats?from=${from}&to=${to}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        setAppeared(res.data.impressions);
        setDetailedView(res.data.views);
        setMismatched(res.data.prefRejections);
        setLike(res.data.incomingInterests);
        setYouLiked(res.data.outgoingInterests);
        setMatches(res.data.matches);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [from]);

  const handleDateRange = (e) => {
    let from = null;
    switch (e.target.value) {
      case "7-days":
        from = moment().subtract(7, "days").format("YYYY-MM-DD");
        break;
      case "4-weeks":
        from = moment().subtract(28, "days").format("YYYY-MM-DD");
        break;
      case "3-months":
        from = moment().subtract(3, "months").format("YYYY-MM-DD");
        break;
      case "12-months":
        from = moment().subtract(12, "months").format("YYYY-MM-DD");
        break;
    }
    setFrom(from);
    setRange(e.target.value);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 15 }}
        >
          User Statistics
        </Typography>
        <div style={styles.selectWrapper}>
          <select
            name="minAge"
            value={range}
            onChange={handleDateRange}
            style={styles.select}
          >
            <option key="1" value="7-days">
              Last 7 days
            </option>
            <option key="2" value="4-weeks">
              Last 4 weeks
            </option>
            <option key="3" value="3-months">
              Last 3 months
            </option>
            <option key="4" value="12-months">
              Last 12 months
            </option>
          </select>
        </div>
        <div className="row m-0" style={styles.tableWrapper}>
          <div>
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr style={{ fontSize: "12px" }}>
                  <th width="200" className="border-0">
                    Appeared on Search
                  </th>
                  <th width="200" className="border-0">
                    Detailed View
                  </th>
                  <th width="200" className="border-0">
                    Blocked due to preference mismatches
                  </th>
                  <th width="200" className="border-0">
                    Interests Received
                  </th>
                  <th width="200" className="border-0">
                    Interests Sent
                  </th>
                  <th width="200" className="border-0">
                    Matches
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="200">{appeared}</td>
                  <td width="200">{detailedView}</td>
                  <td width="200">{mismatched}</td>
                  <td width="200">{like}</td>
                  <td width="200">{youLiked}</td>
                  <td width="200">{matches}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default Stats;
