import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import ViewVirtualAgentsAdsCom from "../../components/admin/virtual-agent/ViewVirtualAgentsAdsCom";

function AllAdsByVirtualAgents() {
  return (
    <AdminLayout>
      <ViewVirtualAgentsAdsCom />
    </AdminLayout>
  );
}

export default AllAdsByVirtualAgents;
