import React, { useState, useRef} from "react";
import axios from "axios";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import Close from "../../../images/close-white.png";

function AddSiblingModal(props) {
  const [relationshipType, setRelationshipType] = useState(null);
  const [relationship, setRelationship] = useState(null);
  const [maritalstatus, setMaritalstatus] = useState(null);
  const [profession, setProfession] = useState(1);
  const [otherprofession, setOtherprofession] = useState(null);
  const addSibilingsModalClose = useRef();

  const disabled = !relationshipType || !relationship || !maritalstatus || (!otherprofession && profession == 7);

  const addSibling = async () => {
    const data = {
      "isMarried": maritalstatus === "YES",
      "professionId": parseInt(profession),
      "otherProfession": otherprofession ? otherprofession.trim() : otherprofession,
      "relationship": relationship,
      "relationshipType": relationshipType
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${props.adid}/siblings`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      );
      props.getAd();
      addSibilingsModalClose.current.click();
      setRelationshipType("")
      setRelationship("")
      setMaritalstatus("")
      setProfession(1)
      setOtherprofession("")
    } catch (error) {
      console.log(error);
    }
  }
  
  return (
    <div
      className="modal fade"
      id="addSibilingsModal"
      tabIndex="-1"
      aria-labelledby="paymentApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentApproveModalLabel">
              Add Sibling
            </h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="addSibilingsModalClose"
              ref={addSibilingsModalClose}
            />
          </div>
          <div className="modal-body">
            <div className="form-group col-12 p-0 mb-3 m-auto">
              <div className="mb-2">
                <h6 className="mb-1">Relationship Type</h6>
                <select
                className="form-control"
                name="fCountryOfResidency"
                value={relationshipType}
                onChange={(e)=>setRelationshipType(e.target.value)}
                >
                  <option selected hidden value="null">
                    -- Select --
                  </option>
                  <option key="1" value="YOUNGER">
                    Younger 
                  </option>
                  <option key="2" value="OLDER">
                    Older
                  </option>
                  <option key="3" value="TWIN">
                    Twin 
                  </option>
                </select>
              </div>
              <div className="mb-2">
                <h6 className="mb-1">Relationship</h6>
                <select
                className="form-control"
                name="fCountryOfResidency"
                value={relationship}
                onChange={(e)=>setRelationship(e.target.value)}
                >
                  <option selected hidden value="null">
                    -- Select --
                  </option>
                  <option key="1" value="SISTER">
                    Sister 
                  </option>
                  <option key="2" value="BROTHER">
                    Brother
                  </option>
                </select>
              </div>
              <div className="mb-2">
                <h6 className="mb-1">Married status</h6>
                <select
                className="form-control"
                name="fCountryOfResidency"
                value={maritalstatus}
                onChange={(e)=>setMaritalstatus(e.target.value)}
                >
                  <option selected hidden value="null">
                    -- Select --
                  </option>
                  <option key="1" value="YES">
                    Married
                  </option>
                  <option key="2" value="NO">
                    Unmarried
                  </option>
                </select>
              </div>
              <div className="mb-2">
                <h6 className="mb-1">Profession</h6>
                <select
                className="form-control"
                name="fCountryOfResidency"
                value={profession}
                onChange={(e)=>setProfession(e.target.value)}
                >
                  {props.professionOptions.map((option, i) => (
                    <option key={i} value={option.id}>
                      {option.type}
                    </option>
                  ))}
                </select>
              </div>

              {( profession == 7) &&
              <div className="mb-2">
                <h6 className="mb-1">Other Profession</h6>
                <input
                  type="text"
                  className="form-control"
                  value={otherprofession}
                  onChange={(e)=>setOtherprofession(e.target.value)}
                />
              </div>
              }
              
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={addSibling}
              style={{ width: 155 }}
              disabled={disabled}
            >
              {false ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Add Sibling"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSiblingModal;
