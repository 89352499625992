import React from "react";

function Personal(props) {
  return (
    <div className="ad-form-sec">
      <form noValidate>
        <div className="form-row header">
          <div className="form-group col px-sm-2 mb-0">
            <p className="heading mb-0">Payment Info</p>
          </div>
        </div>
        <div className="content" style={{ paddingTop: 10 }}>
          <div className="row mx-0 my-3 px-2 review-row">
            <div className="col-sm p-0 mr-sm-1 mb-1 background-dark">
              <div className="row m-0">
                <div className="col-5 p-2">Payment Status</div>
                <div className="col-7 p-2 font-weight-300">
                  {props.data.paymentComplete ? "Paid" : "Not paid"}
                </div>
              </div>
            </div>
            <div className="col-sm p-0 ml-sm-1 mb-1 "></div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Personal;
