import React from "react";
import AdminLayout from "../../components/admin/layouts/AdminLayout";
import UnpublishReviewsCom from "../../components/admin/reviews/unPublishReviewsCom";

const UnPublishReviews = () => {
  return (
    <AdminLayout>
      <UnpublishReviewsCom />
    </AdminLayout>
  );
};

export default UnPublishReviews;
