import { Grid, Typography } from "@material-ui/core";
import React from "react";

import { CalHeight } from "../../methods/calHeight";
import "./style.css";

import maleIcon from "../../../../../../images/userMale.png";
import femaleIcon from "../../../../../../images/userFemale.png";
import { stringToTitleCase } from "../../methods/stringToTitleCase";

const PdfHeader = ({ headerInfo }) => {
  const getUserinfo = () => {
    let userInfo = "";

    const age = headerInfo?.personalInfo?.age || "";
    const profession = headerInfo?.personalInfo?.profession?.name || "";
    const height = CalHeight(headerInfo?.personalInfo?.height || "");

    userInfo = `${age} • ${profession} • ${height}`;
    return userInfo;
  };

  return (
    <Grid
      direction="row"
      container
      justifyContent="space-between"
      className="pdf-header"
      style={{ color: "white" }}
    >
      <Grid
        item
        xs
        direction="row"
        container
        alignItems="center"
        className="pdf-header-data-section"
        wrap="nowrap"
      >
        <Grid item style={{ width: "20%" }}>
          <img
            src={
              headerInfo?.personalInfo?.gender === "FEMALE"
                ? femaleIcon
                : maleIcon
            }
            width="100%"
          />
        </Grid>
        <Grid direction="column">
          <Typography variant="h4" style={{fontWeight: 500}}>
            {stringToTitleCase(headerInfo?.personalInfo?.displayName || "")}
          </Typography>
            <Typography variant="h5" style={{fontWeight: 400}}>
              {getUserinfo()}
            </Typography>
            <Typography variant="h5" style={{fontWeight: 400}}>
              {headerInfo?.personalInfo?.residentCountry?.code == "ZZ"
                ? headerInfo?.personalInfo?.residentCountryOther?.name
                : headerInfo?.personalInfo?.residentCountry?.name || ""}
            </Typography>
            <Typography variant="body1">
              {headerInfo?.phone || ""}
            </Typography>
            <Typography variant="body1">
              {headerInfo?.email}
            </Typography>
        </Grid>
      </Grid>
      <img
        src="https://static.poruwa.lk/images/em2/poruwa_square_sinhala_white-logo.png"
        height="15%"
      />
    </Grid>
  );
};

export default PdfHeader;
