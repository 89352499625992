export const CalHeight = (heightInCm) => {
  let height = "";
  if (heightInCm !== undefined && heightInCm !== "") {
    const totalInches = Math.round(heightInCm / 2.54);
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;

    height = `${feet}' ${inches}"`;
  }

  return height;
};
