import Action from "../../actions/genericData";

const initialState = {
    religions: [],
    ethnicities: [],
    maritalStatuses: [],
    residentCountries: [],
};

const genericDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case Action.SET_GENERIC_DATA:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    };
};

export default genericDataReducer;