import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import NICUnverifyModal from "../../modals/NICUnverifyModal";
import qs from "qs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const AcceptedIdRequest = (props) => {
  const [nicImage, setNicImage] = useState("");
  const [nicImage2, setNicImage2] = useState("");

  useEffect(() => {
    getIdPhotos();
  }, [props.request]);

  const getIdPhotos = () => {
    if (
      props.ad &&
      props.request
    ) {
      if (props.request.nicImageId) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${props.request.id}/images/${props.request.nicImageId}`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            setNicImage(data);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
      if (props.request.nicImage2Id) {
        axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${props.request.id}/images/${props.request.nicImage2Id}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);
          setNicImage2(data);
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
      } else {
      }
    }
  };

  const handleUnverifyId = () => {
    let data = qs.stringify({
      memberId : props.ad.memberId
    });

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/unverify`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAdminOrOperatorL2()}`,
          },
        }
      )
      .then((res) => {
        NotificationManager.success("NIC Unverified!", "Success");
        props.refreshAd();
      })
      .catch((err) => {
        NotificationManager.error("Action failed!", "Error");
      });
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={0} className="post-details-con">
          <Typography variant="h6" className="heading">
            Verified Id
          </Typography>
          <p style={{ fontSize: 12, marginBottom: 0, color: "#7e7e7e" }}>
            {moment
              .utc(props.request.createdAt)
              .local()
              .format("DD-MMM-YYYY hh:mm a")}
          </p>
          <div className="row m-0">
            <div className="col p-0">
              {props.request  && (
                <>
                  <p className="review-sub-sec-heading">ID Photos</p>
                  <PhotoProvider>
                    {nicImage &&
                      <PhotoConsumer src={nicImage} intro={nicImage}>
                        <img
                          src={nicImage}
                          alt="horescope images"
                          className="photos"
                          style={{ marginTop: 10 }}
                        />
                      </PhotoConsumer>
                    }
                  </PhotoProvider>
                  <PhotoProvider>
                    {nicImage2 &&
                      <PhotoConsumer src={nicImage2} intro={nicImage2}>
                        <img
                          src={nicImage2}
                          alt="horescope images"
                          className="photos"
                          style={{ marginTop: 10 }}
                        />
                      </PhotoConsumer>
                    }
                  </PhotoProvider>
                </>
              )}
            </div>
          </div>
          <div className="row m-0">
            <div className="col p-0">
              {isAdminOrOperatorL2() && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-4 ml-2"
                    data-toggle="modal"
                    data-target="#nicUnverifyModal"
                    style={{
                      width: "100px",
                      float: "right",
                    }}
                  >
                    Unverify
                  </button>
                </>
              )}
            </div>
          </div>
        </Paper>
      </Grid>
      <NICUnverifyModal handleRejectNIC={handleUnverifyId} id={props.request.id} />
    </>
  );
};

export default AcceptedIdRequest;
