import React from "react";
import Close from "../../../images/close-white.png";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";

function ViewNICModal(props) {
  return (
    <div
      className="modal fade"
      id="viewNICModal"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">NIC</h5>
            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
            />
          </div>
          <div className="modal-body">
            <div style={{display:"flex", justifyContent:"center"}}>
              {props.loading ? 
                <div
                 className="spinner-border spinner-border-sm"
                 role="status"
                 style={{margin:"auto"}}
                >
                 <span className="sr-only">Loading...</span>
               </div> :
                <div style={{display:"flex", justifyContent: "space-between", paddingTop: "40px", paddingBottom: "40px"}}>
                  <div style={props.nic2 ? {width: "45%"} : {width: "100%"}}>
                      <PhotoProvider>
                        {props.nic &&
                          <PhotoConsumer src={props.nic} intro={props.nic}>
                            <img
                              src={props.nic}
                              alt="horescope images"
                              className="photos"
                              style={{ marginTop: 10 }}
                            />
                          </PhotoConsumer>
                        }
                    </PhotoProvider>
                  </div>
                  {props.nic2 && <div style={{width: "45%"}}>
                    <PhotoProvider>
                      {props.nic2 &&
                        <PhotoConsumer src={props.nic2} intro={props.nic2}>
                          <img
                            src={props.nic2}
                            alt="horescope images"
                            className="photos"
                            style={{ marginTop: 10 }}
                          />
                        </PhotoConsumer>
                      }
                    </PhotoProvider>
                  </div>}
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewNICModal;