import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import CreateAdCom from "../../components/admin/create-ad/CreateAdCom";

function CreateAd() {
  return (
    <AdminLayout>
      <CreateAdCom />
    </AdminLayout>
  );
}

export default CreateAd;
