import { Typography } from "@material-ui/core";
import React from "react";

import "./style.css";

const PdfDescription = ({ profileDescription }) => {
  return (
    <>
      <Typography className="pdf-description">{profileDescription}</Typography>
    </>
  );
};

export default PdfDescription;
