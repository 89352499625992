import React from "react";
import Navbar from "../navbar/Navbar";
import SideBar from "../sidebar/SideBar";

function AdminLayout(props) {
  return (
    <div className="container-fluid p-0">
      <Navbar />
      <div className="row m-0">
        <div className="admin-sidebar-col desktop">
          <SideBar />
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default AdminLayout;
