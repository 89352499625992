import httpAdapter, { HttpMethod, ApiVersion } from "../../utils/http-adaper";

const GenericApi = {
  getCommunitiesByReligionId: async ({ religionId }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/communities",
      authneticated: false,
      queryParams: { religionId },
    });
  },
  getCountryRegionsByCountryCode: async ({ countryCode }) => {
    return await httpAdapter.request({
      method: HttpMethod.GET,
      apiVersion: ApiVersion.V1,
      path: "generic/regions",
      authneticated: false,
      queryParams: { countryCode },
    });
  },
};

export default GenericApi;
