import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import qs from "qs";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import ViewNICModal from "../modals/ViewNICModal";
import NICRejectModal from "../modals/NICRejectModal";
import NICAcceptModal from "../modals/NICAcceptModal";

import { isAuthenticatedBoth } from "../../../auth/authBoth";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function VerifiNICCom() {
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [nicImage, setNicImage] = useState("");
  const [nicImage2, setNicImage2] = useState("");
  const [nicId, setNicId] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("");

  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    getReqiestsList();
  }, []);


  const getReqiestsList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/admin/id_verifications`, {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      })
      .then((res) => {
        setRequests(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    setPage(value);
  };

  const handleChangeReason = (reason) => {
    setRejectedReason(reason);
  }

  const filterBySearch = requests.filter((el) => {
    if (search) {
      return (
        el.id.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.memberId.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        el.personalInfo.fname.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        el.personalInfo.lname.toLowerCase().indexOf(search.toLowerCase()) !==
          -1 ||
        (el.phone &&
          el.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  const handleNICModal = (el) => {
    setImageLoading(true);
    setNicImage2("");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${el.id}/images/${el.nicImageId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        let data = URL.createObjectURL(res.data);
        setNicImage(data);
        // setImageLoading(false);
      })
      .catch((err) => {
        setImageLoading(false);
        console.log(err);
        return [];
      });
    if (el.nicImage2Id) {
      axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${el.id}/images/${el.nicImage2Id}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        let data = URL.createObjectURL(res.data);
        setNicImage2(data);
        setImageLoading(false);
      })
      .catch((err) => {
        setImageLoading(false);
        console.log(err);
        return [];
      });
    } else {
      setImageLoading(false);
    }
  };

  const handleRejectOrAcceptNIC = (id, state) => {
    let data = "";
    if (state) {
      data = qs.stringify({
        accept : state
      });
    } else {
      data = qs.stringify({
        accept : state,
        reason: rejectedReason
      });
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${id}/verify`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        if (state) {
          NotificationManager.success("NIC accepted!", "Success");
        } else {
          NotificationManager.success("NIC rejected!", "Success");
        }
        getReqiestsList();
      })
      .catch((err) => {
        NotificationManager.error("Action failed!", "Error");
      });
  }

  const handleGetPostId = (memberId) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator/search_users?memberId=${memberId}`,

        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        history.push(`/post?id=${res.data[0].post.id}`)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   pagination
  const noOfPages = Math.ceil(filterBySearch.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = filterBySearch.slice(indexOfFirst, indexOfLast);

  return (
    <div className="content-col">
      <Breadcrumb title="Pendings Requests" />
      <div className="row m-0">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            {/* <div className="form-row align-items-center">
              <div className="col-auto">
                <div className="input-group mb-0" style={{ width: "280px" }}>
                  <input
                    type="text"
                    className="form-control pr-5"
                    placeholder="Post ID / Name / Phone / Email"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="off"
                    style={{ borderRadius: "5px" }}
                  />
                  <i
                    className="fas fa-search"
                    style={{
                      position: "absolute",
                      top: "12px",
                      right: "8px",
                      color: "#949494",
                      zIndex: 3,
                    }}
                  ></i>
                </div>
              </div>
            </div> */}
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
          <div className="content p-0">
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Role</th>
                  <th className="border-0">Member ID</th>
                  <th className="border-0">Account Owner</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentItems.length ? (
                  currentItems.map((el, i) => (
                    <tr
                      key={i}
                    >
                      <td width="100" style={{ cursor: "pointer" }} onClick={() => handleGetPostId(el.user.memberId)}>{el.user.name}</td>
                      <td width="125" style={{ cursor: "pointer" }} onClick={() => handleGetPostId(el.user.memberId)}>{el.user.role}</td>
                      <td width="125" style={{ cursor: "pointer" }} onClick={() => handleGetPostId(el.user.memberId)}>{el.user.memberId}</td>
                      <td width="200" style={{ cursor: "pointer" }} onClick={() => handleGetPostId(el.user.memberId)}>{el.user.accountOwner}</td>
                      <td width="290" style={{display:"flex"}}>
                        <button
                          className="btn btn-dark d-block ml-2"
                          data-toggle="modal"
                          data-target="#viewNICModal"
                          onClick={() => handleNICModal(el)}
                        >
                          View NIC
                        </button>
                        <button
                          className="btn btn-dark d-block ml-2"
                          data-toggle="modal"
                          data-target="#nicAcceptModal"
                          onClick={() => setNicId(el.id)}
                        >
                          Accept
                        </button>
                        <button
                          className="btn btn-dark d-block ml-2"
                          data-toggle="modal"
                          data-target="#nicRejectModal"
                          onClick={() => setNicId(el.id)}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row m-0">
          <div className="col p-0">
            <div className="pagination-div">
              {noOfPages > 1 && (
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={handlePagination}
                  className="pagi"
                />
              )}
            </div>
          </div>
        </div>
        <ViewNICModal nic={nicImage} nic2={nicImage2} loading={imageLoading} />
        <NICRejectModal handleRejectNIC={handleRejectOrAcceptNIC} id={nicId} rejectedReason={rejectedReason} handleChangeReason={handleChangeReason} />
        <NICAcceptModal handleAcceptNIC={handleRejectOrAcceptNIC} id={nicId} />
      </div>
      <NotificationContainer />
    </div>
  );
}

export default VerifiNICCom;
