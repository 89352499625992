import React, { useState, useEffect } from "react";
import Close from "../../../images/close-white.png";
import PropTypes from 'prop-types';

const ChangeStatusModal = ({post, loadingChangeStatus, handleChangeStatus}) => {
  ChangeStatusModal.propTypes = {
    post: PropTypes.object.isRequired,
    handleChangeStatus: PropTypes.func.isRequired,
    loadingChangeStatus: PropTypes.bool.isRequired
  };
  const { post: postObj, } = post;
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (postObj) {
      setStatus(postObj.status);
    }
  }, [post]);

  const handleSave = () => {
    // handleChangeStatus(status, comment);
    handleChangeStatus(comment);
  }

  return (
    <div
      className="modal fade"
      id="changeStatusModal"
      tabIndex="-1"
      aria-labelledby="changeStatusModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="changeStatusModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="changeStatusModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>Do you want to change status of this ad?</p>
            {/* <div>
              <label>Status</label>
              <select
                className="form-control"
                id="status"
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value="DISABLED">Disabled</option>
                <option value="PAYMENT_PENDING">Payment Pending</option>
                <option value="EXPIRED">Expired</option>
              </select>
            </div> */}
            <div style={{marginTop: '18px'}}>
              <label>Comment</label>
              <textarea
                className="form-control"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                rows="5"
                placeholder="Optional"
                style={{width: "100%"}}
              />
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSave}
              style={{ width: 95 }}
              // disabled={(postObj && status !== postObj.status && status !== "") ? false : true}
            >
              {loadingChangeStatus ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeStatusModal;
