exports.isAdminOrOperatorL2 = () => {
  if (typeof window !== "undefined") {
    if (
      localStorage.getItem("at") &&
      (localStorage.getItem("ur") === "ADMIN" ||
        localStorage.getItem("ur") === "OPERATOR_L2")
    ) {
      return localStorage.getItem("at");
    } else {
      return false;
    }
  } else {
    return false;
  }
};
