const storageKey = "interest-search-filter";
const FiltersContext = {
  getFilterValue: () => {
    return sessionStorage.getItem(storageKey);
  },
  setInterestType: (value) => {
    sessionStorage.setItem(storageKey, value);
  },
};

export default FiltersContext;
