import React, { useState, useEffect } from "react";
import axios from "axios";

import { isAuthenticated } from "../../../auth/auth";

function FormLinks(props) {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    setImageData([]);

    if (props.images && props.images.length > 0) {
      for (let i = 0; i < props.images.length; i++) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/agent_posts/scanned/${props.scanId}/images/${props.images[i]}?width=500&height=500`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticated()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);

            setImageData((imageData) => [...imageData, data]);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
    } else {
      setImageData([]);
    }
  }, [props.scanId]);

  return imageData.map((item, index) => (
    <a
      href={item}
      alt="scaned forms"
      width="100"
      target="_blank"
      style={{ textDecoration: "none", display: "block" }}
    >
      Capture {index + 1}
    </a>
  ));
}

export default FormLinks;
