import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import IndividualKPIsCom from "../../components/admin/virtual-agent/IndividualKPIsCom";

function IndividualKPIs() {
  return (
    <AdminLayout>
      <IndividualKPIsCom />
    </AdminLayout>
  );
}

export default IndividualKPIs;
