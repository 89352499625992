// import packages
import React from "react";

import { storeCreateAdDetails } from "../../../actions/storeCreateAdDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import Stepper from "../stepper/Stepper";
import Personal from "./review-components/Personal";
import Parent from "./review-components/Parent";
import Private from "./review-components/Private";
import ConfirmBtn from "./review-components/ConfirmBtn";

function ReviewAdCom(props) {
  return (
    <div className="content-col">
      <Breadcrumb mainTitle="Agent" title="Create Ad" />

      <div className="container-fluid inner-content">
        <Stepper />

        <div className="ad-form-sec">
          {!props.isEmptyPost && (
            <>
              <Personal data={props.data} />
              <Parent data={props.data} />
              <Private data={props.data} />
              <ConfirmBtn />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators({ storeCreateAdDetails }, dispatch);
};

const mapStateToProps = (state) => {
  return {
    data: state.createAd.data,
    isEmptyPost:
      Object.keys(state.createAd.data).length === 0 &&
      state.createAd.data.constructor === Object,
  };
};

export default connect(mapStateToProps, matchDispatchToProps)(ReviewAdCom);
