import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import VertualAgentsKPIsCom from "../../components/admin/virtual-agent/VertualAgentsKPIsCom";

function VertualAgentsKPIs() {
  return (
    <AdminLayout>
      <VertualAgentsKPIsCom />
    </AdminLayout>
  );
}

export default VertualAgentsKPIs;
