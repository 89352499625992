import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import { isAuthenticated } from "../../../auth/auth";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../auth/authAdminOrOperatorL2";
import moment from "moment";
import qs from "qs";

import DisableModal from "../modals/DisableModal";
import ArchiveModal from "../modals/ArchiveModal";
import RemoveUserAccountModal from "../modals/RemoveUserAccountModal";
import PhoneInput from "react-phone-input-2";

function AllAdsCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(
    sessionStorage.getItem("all_ads_page_no")
      ? JSON.parse(sessionStorage.getItem("all_ads_page_no"))
      : 1
  );
  const [search, setSearch] = useState(
    sessionStorage.getItem("all_ads_search_key")
      ? sessionStorage.getItem("all_ads_search_key")
      : ""
  );
  const [searchBy, setSearchBy] = useState(
    sessionStorage.getItem("all_ads_search_by")
      ? sessionStorage.getItem("all_ads_search_by")
      : "Member ID"
  );
  const [disableArchivePost, setDisableArchivePost] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDisableArchive, setLoadingDisableArchive] = useState(false);

  const [adsWithoutPosts, setAdsWithoutPosts] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [loadingRemovingInReviewPost, setLoadingRemovingInReviewPost] = useState(false);

  const itemsPerPage = 25;
  const history = useHistory();

  useEffect(() => {
    if (search) {
      searchFun();
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    sessionStorage.setItem("all_ads_page_no", 1);
    setPage(1);
    searchFun();
  };

  const searchFun = () => {
    if (search) {
      console.log(search)
      setLoading(true);
      sessionStorage.setItem("all_ads_search_key", search);
      sessionStorage.setItem("all_ads_search_by", searchBy);

      let queryData = "";

      switch (searchBy) {
        case "Member ID":
          queryData = `memberId=${search}`;
          break;
        case "Post ID":
          queryData = `postId=${search}`;
          break;
        case "Email":
          queryData = `email=${search}`;
          break;
        case "Phone":
          queryData = `phone=${encodeURIComponent(search)}`;
          break;
        case "Name":
          queryData = `fname=${search}&lname=${search}`;
          break;
      }

      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/operator/search_users?${queryData}`,

          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          const filterAllPosts = res.data.filter((el) => el.post);
          const adsListWithoutPosts = res.data.filter((el) => !el.post);
          setAdsWithoutPosts(adsListWithoutPosts);

          const sortByDate = filterAllPosts.sort((a, b) =>
            b.createdAt.localeCompare(a.createdAt)
          );

          setLoading(false);
          setAds(sortByDate);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleDisable = (postId) => {
    setLoadingDisableArchive(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/account/posts/${postId}/disable`,
        {},
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setLoadingDisableArchive(false);
        document.getElementById("disableModalClose").click();
        searchFun();
        NotificationManager.success("Ad disabled successfully!", "Success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingDisableArchive(false);
      });
  };

  const handleArchive = (memberId) => {
    setLoadingDisableArchive(true);

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/generic/archive_user`,
        `memberId=${memberId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        setLoadingDisableArchive(false);
        document.getElementById("archiveModalClose").click();
        searchFun();
        NotificationManager.success("Ad archived successfully!", "Success");
      })
      .catch((err) => {
        console.log(err);
        setLoadingDisableArchive(false);
      });
  };

  const handlePagination = (event, value) => {
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    sessionStorage.setItem("all_ads_page_no", value);

    setPage(value);
  };

  const handleRemoveInReviewPost = () => {
    setLoadingRemovingInReviewPost(true);
    const data = qs.stringify({
      memberId: memberId
    });
    axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/operator/delete_user`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setLoadingRemovingInReviewPost(false);
          searchFun();
          NotificationManager.success("User removed", "Success");
          document.getElementById("removeInReviewPostModalClose").click();
        })
        .catch((err) => {
          console.log(err);
          setLoadingRemovingInReviewPost(false);
          NotificationManager.error("Removing user failed", "Error");
        });
  }

  //   pagination
  const noOfPages = Math.ceil(ads.length / itemsPerPage);
  const indexOfLast = page * itemsPerPage;
  const indexOfFirst = indexOfLast - itemsPerPage;
  const currentItems = ads.slice(indexOfFirst, indexOfLast);

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Ads" title="Search" />

        <div className="row m-0">
          <div className="col p-0">
            <form
              style={{
                display: "block",
                width: "fit-content",
                margin: "auto",
                marginTop: 40,
              }}
            >
              <div className="form-row align-items-center">
                <div className="col-auto">
                  <div className="d-flex flex-row">
                    <div className="dropdown">
                      <button
                        className="btn btn-outline-secondary dropdown-toggle text-dark"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          background: "#ececec",
                        }}
                      >
                        {searchBy}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setSearchBy("Member ID")}
                        >
                          Member ID
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setSearchBy("Post ID")}
                        >
                          Post ID
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setSearchBy("Email")}
                        >
                          Email
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setSearchBy("Phone")}
                        >
                          Phone
                        </button>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => setSearchBy("Name")}
                        >
                          Name
                        </button>
                      </div>
                    </div>
                    {searchBy == "Phone" ? (
                      <PhoneInput
                        country="lk"
                        value={search}
                        enableSearch={true}
                        disableSearchIcon={true}
                        countryCodeEditable={false}
                        prefix="+"
                        onChange={(phone) => setSearch(phone)}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`Type ${searchBy}`}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        autoComplete="off"
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                        }}
                      />
                    )}
                    <button
                      className="btn btn-dark d-block ml-2 text-white"
                      onClick={handleSearch}
                      style={{ width: 50 }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <i className="fas fa-search"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {ads.length > 0 ? (
          <>
            <div className="row m-0 mt-3">
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <p style={{ fontSize: 14, marginBottom: 10 }}>
                  {ads.length > 0
                    ? `Showing ${indexOfFirst + 1} - ${
                        indexOfFirst + currentItems.length
                      } out of ${ads.length} results`
                    : "No results"}
                </p>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Ad ID</th>
                        <th className="border-0">Post ID</th>
                        <th className="border-0">First Name</th>
                        <th className="border-0">Last Name</th>
                        <th className="border-0">Phone</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Status</th>
                        {isAuthenticated() && <th className="border-0"></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((el, i) => (
                        <tr key={i} style={{ cursor: "pointer" }}>
                          <td
                            width="200"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.memberId}{el.post.operatorCreated ? <label style={{marginLeft:"10px", display:"inline"}} className="createdby-label">Agent</label> : null}
                          </td>
                          <td
                            width="100"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.post.id}
                          </td>
                          <td
                            width="125"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.post.firstName}
                          </td>
                          <td
                            width="125"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.post.lastName}
                          </td>
                          <td
                            width="150"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.phone ? el.phone : "-"}
                          </td>
                          <td
                            width="200"
                            onClick={() =>
                              history.push(`/post?id=${el.post.id}`)
                            }
                          >
                            {el.email ? el.email : "-"}
                          </td>
                          <td>{el.post.status}</td>
                          {isAdminOrOperatorL2() && (
                            <td>
                              <div className="dropdown">
                                <button
                                  className="btn dropdown-toggle disable-archive-action-btn p-0"
                                  type="button"
                                  id="dropdownMenu2"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenu2"
                                >
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#disableModal"
                                    onClick={() => setDisableArchivePost(el)}
                                  >
                                    Disable
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#archiveModal"
                                    onClick={() => setDisableArchivePost(el)}
                                  >
                                    Archive
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {noOfPages > 1 && (
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handlePagination}
                        className="pagi"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p className="text-center mt-3">No Records</p>
        )}

        {adsWithoutPosts.length > 0 ? (
          <>
          <div className="container-fluid inner-content pt-0 pb-4">
            <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
              <div>Results without a post</div>
              <div className="content p-0">
                <table
                  className="table table-hover table-responsive common-table border mb-0"
                  style={{ fontSize: 14 }}
                >
                  <thead className="header">
                    <tr>
                      <th className="border-0">Ad ID</th>
                      <th className="border-0">First Name</th>
                      <th className="border-0">Created At</th>
                      <th className="border-0">Phone</th>
                      <th className="border-0">Email</th>
                      {(isAuthenticatedBoth()) && <th className="border-0">Account</th>}
                      {isAdminOrOperatorL2() && <th className="border-0"> Temp. Account</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {adsWithoutPosts.map((el, i) => (
                      <tr key={i}>
                        <td
                          width="100"
                        >
                          {el.memberId}
                        </td>
                        <td
                          width="125"
                        >
                          {el.name}
                        </td>
                        <td
                          width="225"
                        >
                          {moment.utc(el.createdAt).local().format("YYYY-MM-DD h:mm:ss a")}
                        </td>
                        <td
                          width="150"
                        >
                          {el.phone ? el.phone : "-"}
                        </td>
                        <td
                          width="200"
                        >
                          {el.email ? el.email : "-"}
                        </td>
                        {(isAuthenticatedBoth()) &&
                          <td>
                            <button
                              type="button"
                              data-toggle="modal"
                              data-target="#removeInReviewPostModal"
                              className="btn"
                              onClick={() => {
                                setMemberId(el.memberId);
                              }}
                              style={{textDecoration: 'underline', color: '#75a9e2'}}
                            >
                              Remove
                            </button>
                          </td>
                        }
                        <td>
                          <button 
                           style={{textDecoration: 'underline', color: '#75a9e2'}}
                           className="btn"
                            onClick={()=> history.push({
                              pathname :`/dashboard/virtual-ad-create`, 
                              state: {memberId: el.memberId}})}>
                            Create Account
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
          ) : (
            <div></div>
          )
        }
      </div>

      <DisableModal
        disableArchivePost={disableArchivePost}
        handleDisable={handleDisable}
        loadingDisableArchive={loadingDisableArchive}
      />

      <ArchiveModal
        disableArchivePost={disableArchivePost}
        handleArchive={handleArchive}
        loadingDisableArchive={loadingDisableArchive}
      />

      <RemoveUserAccountModal
        handleRemoveInReviewPost={handleRemoveInReviewPost}
        loadingRemovingInReviewPost={loadingRemovingInReviewPost}
      />

      <NotificationContainer />
    </>
  );
}

export default AllAdsCom;
