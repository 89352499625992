import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import SendBankDetailsCom from "../../components/admin/sendBankDetails/SendBankDetailsCom";

function SendBankDetails() {
  return (
    <AdminLayout>
      <SendBankDetailsCom />
    </AdminLayout>
  );
}

export default SendBankDetails;
