import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'
import { Paper, Grid, Typography } from "@material-ui/core";
import moment from "moment";

const TopAddPaymentHistory = (props) => {

  const [payment, setPayment] = useState([]);

  useEffect(() => {
    if (props?.featuredPayment?.length > 0) {
      const sortByDate = props?.featuredPayment?.sort((a, b) =>
        a.createdAt.localeCompare(b.createdAt)
      );
      setPayment(sortByDate);
    }
  }, [props.featuredPayment]);

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Featured Add Payment History
        </Typography>
        <div className="row m-0" style={{ paddingLeft: "20px" }}>
          <div>
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th width="300" className="border-0">
                    Date
                  </th>
                  <th width="300" className="border-0">
                    Time
                  </th>
                  <th width="300" className="border-0">
                    Amount
                  </th>
                  <th width="300" className="border-0">
                    Payment Method
                  </th>
                </tr>
              </thead>
              <tbody>
                {payment?.length > 0 ? (
                  payment?.map((el, i) => (
                    <tr key={i}>
                      <td width="300">
                        {moment
                          .utc(el?.createdAt)
                          .local()
                          .format("Do MMM YYYY")}
                      </td>
                      <td width="300">
                        {moment.utc(el?.createdAt).local().format("hh:mm:ss A")}
                      </td>
                      <td width="300">
                        {el?.currency}&nbsp;{el?.amount}
                      </td>
                      <td width="300">
                        {el?.type == "BANK_ACCT"
                          ? "Bank Tx"
                          : el?.type[0] + el?.type.slice(1).toLowerCase()}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ textAlign: "center" }}>
                    <td colSpan="4" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

TopAddPaymentHistory.propTypes = {
  featuredPayment : PropTypes.array.isRequired
}

export default TopAddPaymentHistory;
