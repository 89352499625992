import React, { useState, useEffect } from "react";
import axios from "axios";
import { Paper, Grid, Typography } from "@material-ui/core";
import _12FromTo24Hours from "12fromto24hours";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";
import AddServiceCommentModal from "../../modals/AddServiceCommentModal";
import moment from "moment";

function ServiceComments(props) {
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getComments();
  }, [props.ad]);

  const handleAddedSuccess = () => {
    document.getElementById("addCommentModalClose").click();
    NotificationManager.success("Comment added successfully!", "Success");
    getComments();
  }

  const getComments = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/operator/service_comments?memberId=${props.ad.memberId}`,
        {
          headers: {
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          }
        }
      )
      .then((res) => {
        setComments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        className="complete-ad-div settings-container post-details-con"
      >
        <Typography
          variant="h6"
          className="heading"
          style={{ marginBottom: 30 }}
        >
          Service Comments
        </Typography>

        <div className="row m-0" style={{paddingLeft:'20px'}}>
          <button
            className="btn btn-success btn-sm m-1"
            style={{ width: 80 }}
            data-toggle="modal"
            data-target="#addServiceCommentModal"
          >
            Add New
          </button>
        </div>

        <div className="row m-0" style={{paddingLeft:'20px', paddingTop:'20px'}}>
          <div>
            <table
              className="table table-hover table-responsive common-table border mb-0"
              style={{ fontSize: 14 }}
            >
              <thead className="header">
                <tr>
                  <th className="border-0">Created Date</th>
                  <th className="border-0" style={{ width: "50%" }}>Comment</th>
                  <th className="border-0">Added By</th>
                </tr>
              </thead>
              <tbody>
                {comments.length ? (
                  comments.map((el, i) => (
                    <tr key={i}
                    >
                      <td style={{display:"flex"}}>
                        {el.idVerified ? <i style={{color:"#2979ff", fontSize:"12px", marginLeft:"6px"}} className="fas fa-check-circle"></i> : null}
                        <p className="mb-0">
                          {moment
                            .utc(el.createdAt)
                            .local()
                            .format("DD-MMM-YYYY hh:mm a")}
                        </p>
                      </td>
                      <td style={{ width: "50%" }}>{el.comment}</td>
                      <td>{el.addedBy.name} - {el.addedBy.memberId}</td>
                      
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" width="480">
                      No Recods
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Paper>
      <NotificationContainer />
      <AddServiceCommentModal
        handleAddedSuccess={handleAddedSuccess}
        memberId={props.ad.memberId}
      />
    </Grid>
  );
}

export default ServiceComments;