import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

function EditAdNameModal(props) {
  return (
    <Dialog
      open={props.showModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
      onClose={() => props.setShowModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-header">
        Edit Profile Details
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            style={{ marginBottom: "8px" }}
            value={props.fName}
            onChange={(e) => props.updateFname(e.target.value)}
          />
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            style={{ marginBottom: "8px" }}
            value={props.lName}
            onChange={(e) => props.updateLname(e.target.value)}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn"
          style={{ width: 95 }}
          onClick={() => props.setShowModal(false)}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-success"
          style={{ width: 95 }}
          onClick={() => props.handleSave()}
        >
          Save
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default EditAdNameModal;
