import React from "react";

import AdminLayout from "../components/admin/layouts/AdminLayout";
import AllInterestsCom from "../components/admin/interests/AllInterestsCom";
// import AllAgentAdsCom from "../components/admin/agents-ads/AllAgentAdsCom";

function AllAgentAds() {
  return (
    <AdminLayout>
      <AllInterestsCom />
    </AdminLayout>
  );
}

export default AllAgentAds;
