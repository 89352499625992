import React from "react";

import Close from "../../../images/close-white.png";

function EditApproveModal(props) {
  return (
    <div
      className="modal fade"
      id="editApproveModal"
      tabIndex="-1"
      aria-labelledby="editApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editApproveModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="editReqApproveModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>
              Do you want to approve this edit request?
            </p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => props.handleEditReq(props.id, "", true)}
              style={{ width: 95 }}
            >
              {props.loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Approve"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditApproveModal;
