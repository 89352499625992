import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import VerifyPhoneNumberCom from "../../components/admin/verify-phone/VerifyPhoneNumberCom";

function VerifyPhoneNumber() {
  return (
    <AdminLayout>
      <VerifyPhoneNumberCom />
    </AdminLayout>
  );
}

export default VerifyPhoneNumber;
