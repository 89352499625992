import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import Config from "../../../config";
import { isEmpty } from "lodash";


function EditAdDetailModal(props) {
  const labelExtractor = isEmpty(props.labelExtractor) ? "type" : props.labelExtractor;
  const valueExtractor = isEmpty(props.valueExtractor) ? "id" : props.valueExtractor;

  const handleUpdate = () => {
    props.handleSave();
  }

  return (
    <Dialog
      open={props.showModal}
      PaperProps={{ style: {
        minWidth: '500px',
        minHeight: '300px',
      }}}
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        className="modal-header"
      >
        Edit Profile Details
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText>
          {props.title}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {props.inputType === 'select' &&
            <select
              className="form-control"
              value={props.value}
              onChange={props.handleChange}
            >
              {props.options.map((option, i) => (
                <option key={i} value={option[valueExtractor]}>
                  {option[labelExtractor]}
                </option>
              ))}
            </select>
          }
          {(props.title == "Profession" && props.value == Config.OTHER_PROFESSION_ID ) &&
            <>
            <input
              type="text"
              className="form-control"
              style={{marginTop: "10px"}}
              onChange={props.handleOtherProfession}
              maxLength={30}
            />
            <p style={{ fontSize: '12px', fontWeight: 400, color: 'black', lineHeight: '16px', margin: '3px 0px 2px 3px' }}>{`${props?.otherProfession?.length ?? 0}/30`}</p>
          </>
          }
          {props.inputType === 'text' &&
            <input
              type="text"
              className="form-control"
              value={props.value}
              onChange={props.handleChange}
            />
          }
          {props.inputType === 'time' &&
            <input
              type="time"
              className="form-control"
              value={props.value}
              onChange={props.handleChange}
            />
          }
          {props.inputType === 'date' &&
            <input
              type="date"
              className="form-control"
              value={props.value}
              onChange={props.handleChange}
            />
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.loadingModal ?
          <>
            <button
              type="button"
              className="btn"
              style={{ width: 95 }}
            >
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
            <button
              type="button"
              className="btn btn-success"
              style={{ width: 95 }}
            >
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </button>
          </>
          :
          <>
            <button
              type="button"
              className="btn"
              onClick={props.closeModal}
              style={{ width: 95 }}
            >
            Close
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleUpdate}
              style={{ width: 95 }}
            >
              Save
            </button>
          </>
        }
      </DialogActions>
    </Dialog>
  );
}

export default EditAdDetailModal;
