import React from "react";

import Close from "../../../images/close-white.png";

function ConfirmAddReviewModal(props) {
  return (
    <div
      className="modal fade"
      id="confirmAddReviewModal"
      tabIndex="-1"
      aria-labelledby="editApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="editApproveModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="editApproveModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>
              Do you want to add this review ?
            </p>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              No
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => props.addReview()}
              style={{ width: 95 }}
              data-dismiss="modal"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmAddReviewModal;
