import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";

function PromptModal(props) {
  return (
    <Dialog
      open={props.showModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "100px",
        },
      }}
      onClose={props.closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-header">
        <Typography>{props.heading && props.heading}</Typography>
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText>
          <Typography>{props.title ? props.title : "Enter details"}</Typography>
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          {props.inputType === "email" && (
            <input
              type="email"
              name="email"
              className="form-control"
              value={props.value}
              onChange={props.handleChange}
            />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          <button
            type="button"
            className="btn"
            onClick={props.closeModal}
            style={{ width: 95 }}
          >
            Close
          </button>
          <button
            type="button"
            className={`btn btn-${props.btnStyle || "dark"}`} //use Bootstrap button styles
            style={{ width: 95 }}
            onClick={props.onActionClick}
            disabled={props.isActionDisabled ? true : false}
          >
            {props.loadingModal ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>{props.actionName ? props.actionName : "Save"}</>
            )}
          </button>
        </>
      </DialogActions>
    </Dialog>
  );
}

export default PromptModal;
