import React from "react";

import AdminLayout from "../../components/admin/layouts/AdminLayout";
import ReviewAdVirtualAgentCom from "../../components/admin/virtual-agent/ReviewAdVirtualAgentCom";

function ReviewAdVirtualAgent() {
  return (
    <AdminLayout>
      <ReviewAdVirtualAgentCom />
    </AdminLayout>
  );
}

export default ReviewAdVirtualAgent;
