import React from "react";
import { Grid, Typography } from "@material-ui/core";

const DataField = ({
  fieldName,
  fieldValue,
  fieldStyle,
  Icon,
  onClickShowModal
}) => {
  return (
    <Grid
      container
      direction="row"
      style={{ columnGap: 13, paddingBottom: 8, paddingTop: 8, ...fieldStyle }}
    >
      <Typography
        variant="body1"
        style={{
          width: "40%",
          textAlign: "right",
          fontWeight: 500,
          fontSize: 14,
        }}
      >
        {fieldName}
      </Typography>

      <Typography
        variant="body1"
        style={{ width: "45%", fontSize: 14 }}
        onClick={onClickShowModal}
      >
        {fieldValue}
      </Typography>
      {Icon !== undefined && (
        <Icon/>
      )}
    </Grid>
  );
};

export default DataField;
