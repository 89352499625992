import axios from "axios";

export const getGenericData = () => async(dispatch) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v1/generic/filters`);
        const sortedReligions = response.data.religions.sort(
            (a, b) => a.sortOrder - b.sortOrder
          );
  
          const sortedEthnicities = response.data.ethnicities.sort(
            (a, b) => a.sortOrder - b.sortOrder
          );
  
          const sortedMaritalStatuses = response.data.civilStatuses.sort(
            (a, b) => a.sortOrder - b.sortOrder
          );

          const residentCountries = response.data.countries.filter((el) =>  el.code !== "ZZ")

          const religions = sortedReligions.filter((el) => {
            return el.id !== "other" && el.id !== "none";
          })

          const ethnicities = sortedEthnicities.filter((el) => {
            return el.id !== "other"
          })

          const maritalStatuses = sortedMaritalStatuses.filter((el) => {
            return el.id !== "other"
          })

        dispatch({
            type: "SET_GENERIC_DATA",
            payload: {
                religions,
                ethnicities,
                maritalStatuses,
                residentCountries,
            },
        });
    } catch (error) {
        console.log(error);
    }
};

export default {
    SET_GENERIC_DATA: "SET_GENERIC_DATA"
} 