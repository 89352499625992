import React from "react";
import Close from "../../../images/close-white.png";

function BlacklistModal(props) {
  return (
    <div
      className="modal fade"
      id="blacklistModal"
      tabIndex="-1"
      aria-labelledby="blacklistModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="blacklistModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="blacklistModalClose"
            />
          </div>
          {props.post.memberId && (
            <>
              <div className="modal-body">
                <p style={{ marginBottom: 25 }}>
                  Do you want to blacklist this number ?
                </p>
                <p className="mb-2" style={{ fontSize: 13 }}>
                  Phone :{props.post.phone}
                </p>
              </div>
              <div className="modal-footer border-0 p-2">
                <button type="button" className="btn" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() =>
                    props.handleBlacklist(props.post.phone)
                  }
                  style={{ width: 95 }}
                >
                  {props.loadingBlacklisting ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Blacklist"
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BlacklistModal;
