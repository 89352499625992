import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";

import groom from "../../../images/groom.png";
import bride from "../../../images/bride.png";

import { Rating } from "@material-ui/lab";
import React, { useMemo } from "react";
import moment from "moment";

const ReviewModal = ({
  openModal,
  handleClose,
  data,
  onButtonClick,
  loadingModal,
}) => {
  const headerSecondRow = useMemo(() => {
    const personalInfo = data?.post?.personalInfo;
    if (personalInfo === undefined) {
      return "";
    }
    let label = "";
    label += personalInfo.age ?? "";
    label += ` ${personalInfo.residentCity ?? ""}`;
    label += ` ${personalInfo.profession.name ?? ""}`;
    return label;
  }, [data]);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      PaperProps={{
        style: { minWidth: "40%" },
      }}
    >
      <>
        <DialogTitle id="alert-dialog-title" className="modal-header">
          Review
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#fff" }}>
          <Grid direction="column" container style={{ rowGap: 20 }}>
            <Grid
              direction="row"
              container
              className="review-header-row"
              wrap="nowrap"
            >
              <img
                src={data.post.personalInfo.gender === "MALE" ? groom : bride}
                className="review-gender-img"
              />

              <Grid container direction="column">
                <Typography variant="h6">
                  {data.post.personalInfo.displayName ?? "User Name"}
                </Typography>
                <Typography variant="body2" className="font-weight-light">
                  {headerSecondRow}
                </Typography>
                <Typography variant="body2" className="font-weight-light">
                  {data !== undefined
                    ? `Member Id: ${data.post.memberId}`
                    : "Member Id"}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              direction="row"
              container
              alignItems="center"
              style={{ columnGap: 5 }}
            >
              <Typography variant="body2">Published On:</Typography>
              <Typography variant="body2" style={{ fontWeight: 500 }}>{`${moment
                .utc(data.createdAt)
                .local()
                .format("YYYY-MM-DD h:mm A")}`}</Typography>
            </Grid>
            <Grid direction="column" container className="review-container">
              <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ alignItems: "center" }}
              >
                <Typography className="review-heading">
                  Match Rating and Review
                </Typography>
                <Rating size="large" disabled value={data.matchRating} />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ alignItems: "center" }}
              >
                <Box component="section" className="comment-section">
                  <Typography className="review-text">
                    {data.matchReview == "" ? "No comment" : data.matchReview}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid direction="column" container className="review-container">
              <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ alignItems: "center" }}
              >
                <Typography className="review-heading">
                  Product Rating and Review
                </Typography>
                <Rating
                  defaultValue={data !== undefined && data.overallRating}
                  size="medium"
                  disabled
                />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                style={{ alignItems: "center" }}
              >
                <Box component="section" className="comment-section">
                  <Typography className="review-text">
                    {data.overallReview == ""
                      ? "No comment"
                      : data.overallReview}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>{" "}
      </>

      <DialogActions>
        <>
          <button
            type="button"
            className="btn"
            onClick={handleClose}
            style={{ width: 95 }}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-dark"
            style={{ width: 95 }}
            onClick={onButtonClick}
          >
            {loadingModal ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <>{"Publish"}</>
            )}
          </button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ReviewModal;
