import React from "react";

import PhoneInput from "react-phone-input-2";

function Contact(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="email">Email</label>
        <input
          type="text"
          className="form-control"
          id="email"
          name="email"
          onChange={props.handleChange}
          value={props.form.email}
          autoComplete="off"
        />
        <div className="invalid-feedback">{props.form.emailErr}</div>
      </div>

      <div className="input-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="phone">
          Phone Number <span className="required-tag">*</span>
        </label>
        <div className="input-group">
          <PhoneInput
            country={props.form.residentCountryCode.toLowerCase()}
            value={props.phone}
            enableSearch={true}
            disableSearchIcon={true}
            countryCodeEditable={false}
            prefix="+"
            onChange={(phone) => props.handlePhone(phone)}
          />
        </div>
        <p className="cus-error">{props.form.phoneErr}</p>
      </div>
    </div>
  );
}

export default Contact;
