import React, { useState, useEffect } from "react";
import axios from "axios";
import classnames from "classnames";

function Habits(props) {
  return (
    <div className="form-row m-0">
      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="drinking">
          Drinking <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.drinkingErr,
          })}
          id="drinking"
          name="drinking"
          value={props.form.drinking}
          onChange={props.handleChange}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          <option key="1" value="YES">
            Yes
          </option>
          <option key="2" value="NO">
            No
          </option>
          <option key="3" value="OCCASIONAL">
            Occasionally
          </option>
        </select>
        <div className="invalid-feedback">{props.form.drinkingErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="smoking">
          Smoking <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.smokingErr,
          })}
          id="smoking"
          name="smoking"
          value={props.form.smoking}
          onChange={props.handleChange}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          <option key="1" value="YES">
            Yes
          </option>
          <option key="2" value="NO">
            No
          </option>
        </select>
        <div className="invalid-feedback">{props.form.smokingErr}</div>
      </div>

      <div className="form-group col-md-6 px-sm-2 mb-3">
        <label htmlFor="foodPreferenceId">
          food Preference <span className="required-tag">*</span>
        </label>
        <select
          className={classnames("form-control", {
            "is-invalid": props.form.foodPreferenceIdErr,
          })}
          id="foodPreferenceId"
          name="foodPreferenceId"
          value={props.form.foodPreferenceId}
          onChange={props.handleChange}
        >
          <option defaultValue hidden>
            -- Select --
          </option>
          {props.filters.foodPreferences.map((el) => (
            <option value={el.id} key={el.id}>
              {el.type}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">{props.form.foodPreferenceIdErr}</div>
      </div>
    </div>
  );
}

export default Habits;
