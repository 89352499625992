import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Paper, Grid, Typography } from "@material-ui/core";
import { PhotoProvider, PhotoConsumer } from "react-photo-view";
import { isAuthenticated } from "../../../../auth/auth";
import { isAuthenticatedBoth } from "../../../../auth/authBoth";
import { isAdminOrOperatorL2 } from "../../../../auth/authAdminOrOperatorL2";
import NICRejectModal from "../../modals/NICRejectModal";
import NICAcceptModal from "../../modals/NICAcceptModal";
import qs from "qs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const PendingIdRequest = (props) => {
  const [nicImage, setNicImage] = useState("");
  const [nicImage2, setNicImage2] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");

  useEffect(() => {
    getPendingIdPhotos();
  }, [props.request]);

  const getPendingIdPhotos = () => {
    if (
      props.ad &&
      props.request
    ) {
      if (props.request.nicImageId) {
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${props.request.id}/images/${props.request.nicImageId}`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
              responseType: "blob",
            }
          )
          .then((res) => {
            let data = URL.createObjectURL(res.data);
            console.log(data)
            setNicImage(data);
          })
          .catch((err) => {
            console.log(err);
            return [];
          });
      }
      if (props.request.nicImage2Id) {
        axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${props.request.id}/images/${props.request.nicImage2Id}`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
            responseType: "blob",
          }
        )
        .then((res) => {
          let data = URL.createObjectURL(res.data);
          setNicImage2(data);
        })
        .catch((err) => {
          console.log(err);
          return [];
        });
      } else {
      }
    }
  };

  const handleChangeReason = (reason) => {
    setRejectedReason(reason);
  }

  const handleRejectOrAcceptNIC = (id, state) => {
    let data = "";
    if (state) {
      data = qs.stringify({
        accept : state
      });
    } else {
      data = qs.stringify({
        accept : state,
        reason: rejectedReason
      });
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/admin/id_verifications/${id}/verify`,
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`,
          },
        }
      )
      .then((res) => {
        if (state) {
          NotificationManager.success("NIC accepted!", "Success");
        } else {
          NotificationManager.success("NIC rejected!", "Success");
        }
        props.getPendingIdRequests(props.ad.memberId);
      })
      .catch((err) => {
        NotificationManager.error("Action failed!", "Error");
      });
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper elevation={0} className="post-details-con">
          <Typography variant="h6" className="heading">
            Pending Id Request
          </Typography>
          <p style={{ fontSize: 12, marginBottom: 0, color: "#7e7e7e" }}>
            {moment
              .utc(props.request.createdAt)
              .local()
              .format("DD-MMM-YYYY hh:mm a")}
          </p>
          <div className="row m-0">
            <div className="col p-0">
              {props.request  && (
                <>
                  <p className="review-sub-sec-heading">ID Photos</p>
                  <PhotoProvider>
                    {nicImage &&
                      <PhotoConsumer src={nicImage} intro={nicImage}>
                        <img
                          src={nicImage}
                          alt="horescope images"
                          className="photos"
                          style={{ marginTop: 10 }}
                        />
                      </PhotoConsumer>
                    }
                  </PhotoProvider>
                  <PhotoProvider>
                    {nicImage2 &&
                      <PhotoConsumer src={nicImage2} intro={nicImage2}>
                        <img
                          src={nicImage2}
                          alt="horescope images"
                          className="photos"
                          style={{ marginTop: 10 }}
                        />
                      </PhotoConsumer>
                    }
                  </PhotoProvider>
                </>
              )}
            </div>
          </div>
          <div className="row m-0">
            <div className="col p-0">
              {isAdminOrOperatorL2() && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-danger mt-4 ml-2"
                    data-toggle="modal"
                    data-target="#nicRejectModal"
                    style={{
                      width: "100px",
                      float: "right",
                    }}
                  >
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-success mt-4"
                    data-toggle="modal"
                    data-target="#nicAcceptModal"
                    style={{ width: 100, float: "right" }}
                  >
                    Accept
                  </button>
                </>
              )}
            </div>
          </div>
        </Paper>
      </Grid>
      <NICRejectModal handleRejectNIC={handleRejectOrAcceptNIC} id={props.request.id} rejectedReason={rejectedReason} handleChangeReason={handleChangeReason} />
      <NICAcceptModal handleAcceptNIC={handleRejectOrAcceptNIC} id={props.request.id} />
      <NotificationContainer />
    </>
  );
};

export default PendingIdRequest;
