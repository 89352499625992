import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import axios from "axios";
import qs from "qs";

import { storeUserDetails } from "../../../actions/storeUserDetails";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LogoCol from "./LogoCol";

function CommonLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [authErr, setAuthErr] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      setEmailErr("");
    } else {
      setPassword(e.target.value);
      setPasswordErr("");
    }
  };

  // validate
  const validate = () => {
    let emailErr = "";
    let passwordErr = "";

    if (!email) {
      emailErr = "Email is required";
    }
    //  else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    //   emailErr = "Email is invalid";
    // }

    if (!password) {
      passwordErr = "Password is required";
    }

    if (emailErr || passwordErr) {
      setEmailErr(emailErr);
      setPasswordErr(passwordErr);

      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        userId: email,
        password: password,
      });

      axios
        .post(`${process.env.REACT_APP_API_URL}/v1/auth/create_session`, data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;",
          },
        })
        .then((res) => {
          localStorage.setItem("at", res.data.token);

          axios
            .get(`${process.env.REACT_APP_API_URL}/v1/account`, {
              headers: {
                Authorization: `Bearer ${res.data.token}`,
              },
            })
            .then((res) => {
              if (res.data.role === "ADMIN" || res.data.role === "OPERATOR" || res.data.role === "OPERATOR_L2") {
                localStorage.setItem("un", res.data.name);
                localStorage.setItem("ur", res.data.role);

                props.history.push("/dashboard");
              } else {
                setAuthErr("Access denied for this user");
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          if (
            err.response.data.code === 1102 ||
            err.response.data.code === 1104
          ) {
            setAuthErr("Incorrect username or/and password!");
          }
          setLoading(false);
        });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6 login-reg-col">
          <div className="login-reg-container">
            <p className="heading">Welcome to Admin Dashboard</p>
            <p style={{ fontSize: 14, color: "#dc3545" }}>{authErr}</p>
            <form noValidate>
              <div className="form-group p-0">
                <label htmlFor="email">Email / Member ID</label>
                <input
                  type="email"
                  className={classnames("form-control", {
                    "is-invalid": emailErr,
                  })}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  value={email}
                />
                <div className="invalid-feedback">{emailErr}</div>
              </div>
              <div className="form-group p-0 mb-0">
                <label htmlFor="inputPassword4">Password</label>
                <input
                  type="password"
                  className={classnames("form-control", {
                    "is-invalid": passwordErr,
                  })}
                  id="pwd"
                  name="password"
                  onChange={handleChange}
                  value={password}
                />
                <div className="invalid-feedback">{passwordErr}</div>
              </div>

              <button
                type="submit"
                className="btn-submit"
                onClick={handleSubmit}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
                {loading ? "" : "Login"}
              </button>
            </form>
            {/* <Link to="/forget-password" className="forget-password-link">
              Forgot your Password?
            </Link> */}
            {/* <p className="mt-2 form-bellow-link">
              Don't have an account?{" "}
              <Link to="/Create-an-account" className="login-now-link">
                Create an account
              </Link>
            </p> */}
          </div>
        </div>

        <LogoCol />
      </div>
    </div>
  );
}

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      storeUserDetails,
    },
    dispatch
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withRouter(CommonLogin));
