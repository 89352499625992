import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import Filter from "./Filter";
import { isAuthenticatedBoth } from "../../../auth/authBoth";
import CommentsModal from "../modals/CommentsModal";
import RemoveUserAccountModal from "../modals/RemoveUserAccountModal";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function FollowUpOnlyAccountCom() {
  const [ads, setAds] = useState([]);
  const [page, setPage] = useState(
    sessionStorage.getItem("followup_account_page")
      ? JSON.parse(sessionStorage.getItem("followup_account_page"))
      : 1
  );
  const [noOfPages, setNoOfPages] = useState(1);
  const [totAds, setTotAds] = useState(0);
  const [search, setSearch] = useState("");
  const [comments, setComments] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [comment, setComment] = useState("")
  const [commentErr, setCommentErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(
    sessionStorage.getItem("followup_account_from_date")
      ? sessionStorage.getItem("followup_account_from_date")
      : ""
  );
  const [to, setTo] = useState(
    sessionStorage.getItem("followup_account_to_date")
      ? sessionStorage.getItem("followup_account_to_date")
      : ""
  );
  const [loadingRemovingInReviewPost, setLoadingRemovingInReviewPost] = useState(false);

  const itemsPerPage = 25;

  useEffect(() => {
    let from;
    let to;

    if (
      sessionStorage.getItem("followup_account_from_date") ||
      sessionStorage.getItem("followup_account_to_date")
    ) {
      from = sessionStorage.getItem("followup_account_from_date");
      to = sessionStorage.getItem("followup_account_to_date");
    } else {
      from = moment().subtract(4, "days").format("YYYY-MM-DD");
      to = moment().subtract(2, "days").format("YYYY-MM-DD");
    }

    setFrom(from);
    setTo(to);

    handleSearchFunc(from, to);
  }, []);

  const handleSearchBtn = (from, to) => {
    sessionStorage.setItem("followup_account_page", 1);

    setPage(1);

    handleSearchFunc(from, to);
  };

  const handleSearchFunc = (from, to) => {
    var fromDate = moment(from, "YYYY-MM-DD");
    var toDate = moment(to, "YYYY-MM-DD");

    if (!fromDate.isValid() || !toDate.isValid()) {
    } else {
      setLoading(true);

      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }/v1/operator/users?from=${from}&to=${to}&pageNum=${
            page - 1
          }&pageSize=${itemsPerPage}&accountsOnly=true&postsOnly=false`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          const sortByDate = res.data.results.sort((a, b) =>
            b.createdAt.localeCompare(a.createdAt)
          );
          setAds(sortByDate);
          setNoOfPages(res.data.pageCount);
          setTotAds(res.data.total);

          sessionStorage.setItem("followup_account_from_date", from);
          sessionStorage.setItem("followup_account_to_date", to);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const addComment = () => {
    if (comment) {
      setLoading(true);

      const data = qs.stringify({
        memberId: memberId,
        comment,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/operator/add_comment`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          handleSearchFunc(from, to);

          axios
            .get(
              `${
                process.env.REACT_APP_API_URL
              }/v1/operator/users?from=${from}&to=${to}&pageNum=${
                page - 1
              }&pageSize=${itemsPerPage}&accountsOnly=true&postsOnly=false`,
              {
                headers: {
                  Authorization: `Bearer ${isAuthenticatedBoth()}`,
                },
              }
            )
            .then((res) => {
              const findNewComments = res.data.results.filter(
                (el) => el.memberId === memberId
              );

              setComment("");
              setComments(findNewComments[0].comments);
              setLoading(false);
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setCommentErr("Please type your comment");
    }
  };

  useEffect(() => {
    handleSearchFunc(from, to);
    sessionStorage.setItem("followup_account_page", page);
  }, [page]);

  const handlePagination = (event, value) => {
    setPage(value);
    // scroll to top
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const filterBySearch = ads.filter((el) => {
    if (search) {
      return (
        el.memberId.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        (el.name 
          && el.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.phone &&
          el.phone.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
        (el.email 
          && el.email.toLowerCase().indexOf(search.toLowerCase()) !== -1)
      );
    } else {
      return el;
    }
  });

  const handleRemoveInReviewPost = () => {
    setLoadingRemovingInReviewPost(true);
    const data = qs.stringify({
      memberId: memberId
    });
    axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/operator/delete_user`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setLoadingRemovingInReviewPost(false);
          NotificationManager.success("User removed", "Success");
          handleSearchFunc(from, to);
          document.getElementById("removeInReviewPostModalClose").click();
        })
        .catch((err) => {
          console.log(err);
          setLoadingRemovingInReviewPost(false);
          NotificationManager.error("Removing user failed", "Error");
        });
  }

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="Follow-up" title="User Account Only" />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Filter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              handleSearchBtn={handleSearchBtn}
              loading={loading}
            />

            <div className="row m-0">
              <div className="col p-0">
                <form
                  style={{
                    display: "block",
                    width: "fit-content",
                    margin: "auto",
                    marginTop: 0,
                  }}
                >
                  <div className="form-row align-items-center">
                    <div className="col-auto">
                      <div
                        className="input-group mb-0"
                        style={{ width: "350px" }}
                      >
                        <input
                          type="text"
                          className="form-control pr-5"
                          placeholder="Member ID / Name / Phone / Email"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          autoComplete="off"
                          style={{ borderRadius: "5px" }}
                        />
                        <i
                          className="fas fa-search"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "8px",
                            color: "#949494",
                            zIndex: 3,
                          }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="row m-0 mt-3">
              <div className="col p-0">
                <div className="pagination-div">
                  {noOfPages > 1 && (
                    <Pagination
                      count={noOfPages}
                      page={page}
                      onChange={handlePagination}
                      className="pagi"
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <p style={{ fontSize: 14, marginBottom: 10 }}>
                  {ads.length > 0
                    ? `Showing ${(page - 1) * itemsPerPage} - ${
                        (page - 1) * itemsPerPage + ads.length
                      } out of ${totAds} results`
                    : "No results"}
                </p>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead className="header">
                      <tr>
                        <th className="border-0">Created Date</th>
                        <th className="border-0">Ad ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Phone</th>
                        <th className="border-0">Email</th>
                        <th className="border-0">Comments</th>
                        {(isAuthenticatedBoth()) && <th className="border-0">Account</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {filterBySearch.length ? (
                        filterBySearch.map((el, i) => (
                          <tr key={i}>
                            <td width="80">
                              <p className="mb-0" style={{ width: 80 }}>
                                {moment
                                  .utc(el.createdAt)
                                  .local()
                                  .format("DD-MMM-YYYY hh:mm a")}
                              </p>
                            </td>
                            <td width="100">{el.memberId}</td>
                            <td width="125">{el.name}</td>
                            <td width="150">{el.phone ? el.phone : "-"}</td>
                            <td width="150">{el.email ? el.email : "-"}</td>
                            <td width="400" style={{ position: "relative" }}>
                              <p style={{ width: 300 }} className="mb-1">
                                {el.comments.length > 0
                                  ? el.comments.sort((a, b) =>
                                      b.createdAt.localeCompare(a.createdAt)
                                    )[0].comment
                                  : "-"}
                              </p>
                              <p
                                style={{ width: 300, fontSize: 12 }}
                                className="mb-0 text-muted"
                              >
                                {el.comments.length > 0
                                  ? moment
                                      .utc(
                                        el.comments.sort((a, b) =>
                                          b.createdAt.localeCompare(a.createdAt)
                                        )[0].createdAt
                                      )
                                      .local()
                                      .format("DD MMM YYYY hh:mm a")
                                  : ""}
                              </p>

                              <i
                                className="fas fa-plus add-comment-btn"
                                data-toggle="modal"
                                data-target="#comments-modal"
                                style={{
                                  position: "absolute",
                                  bottom: 6,
                                  right: 6,
                                }}
                                onClick={() => {
                                  setComments(el.comments);
                                  setMemberId(el.memberId);
                                }}
                              ></i>
                            </td>
                            {(isAuthenticatedBoth()) &&
                              <td>
                                <button
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#removeInReviewPostModal"
                                  className="btn"
                                  onClick={() => {
                                    setMemberId(el.memberId);
                                  }}
                                  style={{textDecoration: 'underline', color: '#75a9e2'}}
                                >
                                  Remove
                                </button>
                              </td>
                            }
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3" width="480">
                            No Records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row m-0">
                <div className="col p-0">
                  <div className="pagination-div">
                    {noOfPages > 1 && (
                      <Pagination
                        count={noOfPages}
                        page={page}
                        onChange={handlePagination}
                        className="pagi"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <CommentsModal
        comments={comments}
        comment={comment}
        setComment={setComment}
        commentErr={commentErr}
        setCommentErr={setCommentErr}
        addComment={addComment}
        loading={loading}
      />

      <RemoveUserAccountModal
        handleRemoveInReviewPost={handleRemoveInReviewPost}
        loadingRemovingInReviewPost={loadingRemovingInReviewPost}
      />

      <NotificationContainer />
    </>
  );
}

export default FollowUpOnlyAccountCom;
