import React, { useState } from "react";
import axios from "axios";
import qs from "qs";
import PhoneInput from "react-phone-input-2";
import ReactCodeInput from "react-verification-code-input";

import Breadcrumb from "../breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

function VerifyPhoneNumberCom() {
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [optCode, setOptCode] = useState("");
  const [optCodeErr, setOptCodeErr] = useState("");
  const [phoneId, setPhoneId] = useState("");
  const [message, setMessage] = useState("");
  const [sms, setSMS] = useState(true);
  const [call, setCall] = useState(false);
  const [isVerifyView, setIsVerifyView] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePhone = (phone) => {
    setPhone(`+${phone}`);
    setPhoneErr("");
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (phone) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/v1/auth/user_exists?userId=${`${phone}`}&idType=PHONE`,
          {
            headers: {
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            }
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setPhoneErr("phone number is already in use. Please try another.");
          } else if (res.status === 204) {
            setLoading(true);

            let data = qs.stringify({
              number: `+${phone}`,
              channel: sms ? "sms" : "call",
            });

            let ALPHABET = "1ypjZR6TdO8Y9X3IhMe07nWJEKDFftiw24agbBNzGklxCsmZHqucVLUvP5QSro";
            let bb = Math.round((new Date()).getTime() / 1000);
            const tso = bb;
            let aa = "";
            while (bb > 0) {
              let tss = (ALPHABET[bb % ALPHABET.length]).toString();
              aa = aa+tss;
              bb= parseInt(bb / ALPHABET.length);
            }

            axios
              .post(`${process.env.REACT_APP_API_URL}/v1/phonenumbers`, data, {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `Bearer ${isAuthenticatedBoth()}`,
                  "x-timestamp": tso,
                  "x-client-id": aa,
                },
              })
              .then((res) => {
                setPhoneId(res.data.id);
                setIsVerifyView(true);
                setLoading(false);
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
                setPhoneErr(err.response.data.message);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setPhoneErr("Phone is required");
    }
  };

  const handleVerify = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/v1/phonenumbers/${phoneId}/verify`,
        qs.stringify({ code: optCode }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${isAuthenticatedBoth()}`
          },
        }
      )
      .then((res) => {
        setMessage(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        setOptCodeErr(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="content-col">
      <Breadcrumb title="Verify Phone Number" />

      <div className="row m-0 mt-5">
        <div className="col p-0">
          <form
            style={{
              display: "block",
              width: "fit-content",
              margin: "auto",
              marginTop: 40,
            }}
          >
            <div className="form-row align-items-center">
              <div className="col-auto">
                {!isVerifyView ? (
                  <>
                    <div
                      className="input-group mb-0"
                      style={{ width: "225px" }}
                    >
                      <h6 className="mb-2">Phone Number</h6>
                      <PhoneInput
                        country="lk"
                        value={phone}
                        enableSearch={true}
                        disableSearchIcon={true}
                        countryCodeEditable={false}
                        prefix="+"
                        onChange={(phone) => handlePhone(phone)}
                      />
                      <p
                        className="text-danger mt-1 mb-0"
                        style={{ fontSize: 14 }}
                      >
                        {phoneErr}
                      </p>
                      <div style={{ width: 150 }}>
                        <div className="custom-control custom-radio mt-4">
                          <input
                            type="radio"
                            id="customRadio1"
                            name="customRadio"
                            className="custom-control-input"
                            onChange={() => {
                              setSMS(true);
                              setCall(false);
                            }}
                            checked={sms}
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="customRadio1"
                          >
                            SMS
                          </label>
                        </div>
                        <div className="custom-control custom-radio mt-3 mb-4">
                          <input
                            type="radio"
                            id="customRadio2"
                            name="customRadio"
                            className="custom-control-input"
                            onChange={() => {
                              setSMS(false);
                              setCall(true);
                            }}
                            checked={call}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customRadio2"
                          >
                            Call
                          </label>
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-dark d-block m-auto "
                      onClick={handleSearch}
                      style={{ width: 65 }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "Send"
                      )}
                    </button>
                  </>
                ) : message ? (
                  <>
                    <p className="text-center text-success">
                      <i className="far fa-check-circle fa-3x"></i>
                    </p>
                    <p className="text-center">{message}</p>
                    <p
                      className="text-center mt-3 text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsVerifyView(false);
                        setMessage("");
                        setPhone("");
                      }}
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </p>
                  </>
                ) : (
                  <>
                    <h6 className="mb-2">OPT Code</h6>
                    <ReactCodeInput
                      fields={4}
                      onChange={(val) => setOptCode(val)}
                    />
                    <p className="text-danger mt-1" style={{ fontSize: 14 }}>
                      {optCodeErr}
                    </p>

                    <button
                      className="btn btn-dark d-block "
                      onClick={handleVerify}
                      style={{ width: 65, margin: "auto", marginTop: 25 }}
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        "verify"
                      )}
                    </button>
                    <p
                      className="text-center mt-3 text-muted"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsVerifyView(false);
                        setMessage("");
                      }}
                    >
                      <i className="fas fa-angle-left"></i> Back
                    </p>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="container-fluid inner-content pt-3 pb-4">
        <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}></div>
      </div>
    </div>
  );
}

export default VerifyPhoneNumberCom;
