import React, { useState } from "react";

import { Link, withRouter } from "react-router-dom";
import { Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import Logo from "../../../images/logo.png";
import SideBar from "../sidebar/SideBar";

function Navbar(props) {
  const [sideBarDrawer, setSideBarDrawer] = useState(false);

  // drawer user account
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setSideBarDrawer(open);
  };

  const closeDrawer = () => {
    setSideBarDrawer(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("at");
    localStorage.removeItem("un");
    localStorage.removeItem("ur");

    props.history.push("/");
  };

  return (
    <React.Fragment>
      <nav className="navbar sticky-top p-3 client-nav-bar">
        <MenuIcon className="menu-btn" onClick={toggleDrawer("left", true)} />

        <Link to="/dashboard">
          <img src={Logo} className="logo" alt="Logo" />
        </Link>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <span
              className="nav-link"
              onClick={handleLogout}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-power-off"></i> Logout
            </span>
          </li>
        </ul>
      </nav>

      <Drawer
        anchor={"left"}
        open={sideBarDrawer}
        onClose={() => closeDrawer(false)}
      >
        <div className="admin-sidebar-col drawer">
          <SideBar closeDrawer={closeDrawer} />
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default withRouter(Navbar);
