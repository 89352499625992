import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import './_modal.scss'
import Config from "../../../config";

function EditParentsProfession({
  showModal,
  setShowModal,
  professionOptions,
  selectedProfession,
  updateOtherProfession,
  handleSave,
  handleChange,
  parentProfession,
}) {
  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          minWidth: "500px",
          minHeight: "300px",
        },
      }}
      onClose={() => setShowModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className="modal-header">
        Edit Profile Details
      </DialogTitle>
      <DialogContent style={{ minHeight: 80 }}>
        <DialogContentText id="alert-dialog-description">
          <label>Select Profession</label>
          <select
            className="form-control"
            value={parentProfession}
            onChange={handleChange}
          >
            {professionOptions.map((option, i) => (
              <option key={i} value={option.id} selected>
                {option.type}
              </option>
            ))}
          </select>
        </DialogContentText>

        {selectedProfession === Config.OTHER_PROFESSION_ID ? (
          <DialogContentText id="alert-dialog-description">
            <label>profession</label>
            <input
              type="text"
              className="form-control"
              style={{ marginBottom: "8px" }}
              onChange={(e) => updateOtherProfession(e.target.value)}
            />
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        <div className="parent-modal-main-btn-container">
          <button
            type="button"
            className="btn close-btn"
            onClick={() => setShowModal(false)}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => handleSave()}
          >
            Save
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default EditParentsProfession;
