import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import Breadcrumb from "../breadcrumb/Breadcrumb";
import Filter from "../follow-ups/Filter";

import { isAuthenticated } from "../../../auth/auth";
import { isAuthenticatedBoth } from "../../../auth/authBoth";

function IndividualKPIsCom() {
  const [kpi, setKpi] = useState([]);
  const [thisWeek, setThisWeek] = useState({});
  const [thisWeekDates, setThisWeekDates] = useState("");
  const [thisMonth, setThisMonth] = useState({});
  const [thisMonthDates, setThisMonthDates] = useState("");
  const [lastMonth, setLastMonth] = useState({});
  const [lastMonthDates, setLastMonthDates] = useState("");
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const { id } = useParams();

  useEffect(() => {
    let from;
    let to;

    from = moment().subtract(14, "days").format("YYYY-MM-DD");
    to = moment().format("YYYY-MM-DD");

    setFrom(from);
    setTo(to);

    handleSearchFunc(from, to);

    const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
    const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
    const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
    const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
    const startOfLastMonth = moment()
      .subtract(1, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfLastMonth = moment()
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    getKPIs(startOfWeek, endOfWeek).then((res) => {
      setThisWeek(res.data);
      setThisWeekDates(
        `${moment(startOfWeek).format("DD MMM")} - ${moment(endOfWeek).format(
          "DD MMM"
        )}`
      );
    });

    getKPIs(startOfMonth, endOfMonth).then((res) => {
      setThisMonth(res.data);
      setThisMonthDates(
        `${moment(startOfMonth).format("DD MMM")} - ${moment(endOfMonth).format(
          "DD MMM"
        )}`
      );
    });

    getKPIs(startOfLastMonth, endOfLastMonth).then((res) => {
      setLastMonth(res.data);
      setLastMonthDates(
        `${moment(startOfLastMonth).format("DD MMM")} - ${moment(
          endOfLastMonth
        ).format("DD MMM")}`
      );
    });
  }, []);

  const getKPIs = (from, to) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/v1/admin/operator_stats?operatorId=${id}&from=${from}&to=${to}`,
      {
        headers: {
          Authorization: `Bearer ${isAuthenticatedBoth()}`,
        },
      }
    );
  };

  const handleSearchFunc = (from, to) => {
    let fromDate = moment(from, "YYYY-MM-DD");
    let toDate = moment(to, "YYYY-MM-DD");

    if (fromDate.isValid() || toDate.isValid()) {
      setLoading(true);

      axios
          .get(
            `${process.env.REACT_APP_API_URL}/v1/admin/operator_stats?operatorId=${id}&from=${from}&to=${to}`,
            {
              headers: {
                Authorization: `Bearer ${isAuthenticatedBoth()}`,
              },
            }
          )
          .then((res) => {
            setKpi(res.data);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
    }
  };

  return (
    <>
      <div className="content-col" style={{ position: "relative" }}>
        <Breadcrumb mainTitle="KPIs" title={id} />
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <div className="container-fluid inner-content pt-0 pb-0">
              <div
                className="ad-form-sec"
                style={{ maxWidth: "fit-content", marginBottom: 0 }}
              >
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <thead>
                      <tr>
                        <td width="150"></td>
                        <td width="150">
                          <p className="m-0">This Week</p>
                          <p className="m-0" style={{ fontSize: 10 }}>
                            {thisWeekDates}
                          </p>
                        </td>
                        <td width="150">
                          <p className="m-0">This Month</p>
                          <p className="m-0" style={{ fontSize: 10 }}>
                            {thisMonthDates}
                          </p>
                        </td>
                        <td width="150">
                          <p className="m-0">Last Month</p>
                          <p className="m-0" style={{ fontSize: 10 }}>
                            {lastMonthDates}
                          </p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="250">New Comments</td>
                        <td width="150">{thisWeek.numCommentsNew}</td>
                        <td width="150">{thisMonth.numCommentsNew}</td>
                        <td width="150">{lastMonth.numCommentsNew}</td>
                      </tr>
                      <tr>
                        <td width="250">New Conversions</td>
                        <td width="150">{thisWeek.numNewConversions}</td>
                        <td width="150">{thisMonth.numNewConversions}</td>
                        <td width="150">{lastMonth.numNewConversions}</td>
                      </tr>
                      <tr>
                        <td width="250">New Comments For Expired</td>
                        <td width="150">{thisWeek.numCommentsExpired}</td>
                        <td width="150">{thisMonth.numCommentsExpired}</td>
                        <td width="150">{lastMonth.numCommentsExpired}</td>
                      </tr>
                      <tr>
                        <td width="250">Renew Conversions</td>
                        <td width="150">{thisWeek.numRenewConversions}</td>
                        <td width="150">{thisMonth.numRenewConversions}</td>
                        <td width="150">{lastMonth.numRenewConversions}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <Filter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
              handleSearchBtn={handleSearchFunc}
              loading={loading}
            />

            <div className="container-fluid inner-content pt-0 pb-4">
              <div className="ad-form-sec" style={{ maxWidth: "fit-content" }}>
                <div className="content p-0">
                  <table
                    className="table table-hover table-responsive common-table border mb-0"
                    style={{ fontSize: 14 }}
                  >
                    <tbody>
                      <tr>
                        <td width="270">New Comments</td>
                        <td width="150">{kpi.numCommentsNew}</td>
                      </tr>
                      <tr>
                        <td width="270">New Conversions</td>
                        <td width="150">{kpi.numNewConversions}</td>
                      </tr>
                      <tr>
                        <td width="270">New Comments For Expired</td>
                        <td width="150">{kpi.numCommentsExpired}</td>
                      </tr>
                      <tr>
                        <td width="270">Renew Conversions</td>
                        <td width="150">{kpi.numRenewConversions}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default IndividualKPIsCom;
