// import packages
import React, { useState } from "react";
import axios from "axios";
import classnames from "classnames";
import qs from "qs";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Breadcrumb from "../../admin/breadcrumb/Breadcrumb";

import { isAuthenticatedBoth } from "../../../auth/authBoth";

const initial = {
  fname: "",
  agentCode: "",
  agentPhone: "",
  agentCity: "",
  agentBusinessName: "",
  password: "",
  conPassword: "",

  fnameErr: "",
  agentCodeErr: "",
  agentPhoneErr: "",
  agentCityErr: "",
  agentBusinessNameErr: "",
  passwordErr: "",
  conPasswordErr: "",
};

function CreateAgentCom(props) {
  const [form, setForm] = useState(initial);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
      [e.target.name + "Err"]: "",
    });
  };

  const handlePhone = (phone) => {
    const str = phone.split("94")[1];
    const newPhone = parseInt(str, 10);

    setForm({
      ...form,
      agentPhone: `94${newPhone}`,
      agentPhoneErr: "",
    });
  };

  // validate
  const validate = () => {
    let fnameErr = "";
    let agentCodeErr = "";
    let agentPhoneErr = "";
    let agentCityErr = "";
    let agentBusinessNameErr = "";
    let passwordErr = "";
    let conPasswordErr = "";

    if (!form.fname) {
      fnameErr = "First name is required";
    }

    if (!form.agentCode) {
      agentCodeErr = "Agent code is required";
    }

    if (!form.agentPhone) {
      agentPhoneErr = "Agent phone is required";
    }

    if (!form.agentCity) {
      agentCityErr = "Agent city is required";
    }

    if (!form.agentBusinessName) {
      agentBusinessNameErr = "Agent business name is required";
    }

    if (!form.password) {
      passwordErr = "Password is required";
    } else if (form.password.length < 8) {
      passwordErr = "Password should be at least 8 characters long";
    }

    if (form.conPassword === "") {
      conPasswordErr = "Confirmation password is required";
    } else if (form.password !== form.conPassword) {
      conPasswordErr = "Passwords should match";
    }

    if (
      (fnameErr,
      agentCodeErr,
      agentPhoneErr,
      agentCityErr,
      agentBusinessNameErr,
      passwordErr || conPasswordErr)
    ) {
      setForm({
        ...form,
        fnameErr,
        agentCodeErr,
        agentPhoneErr,
        agentCityErr,
        agentBusinessNameErr,
        passwordErr,
        conPasswordErr,
      });
      return false;
    }

    return true;
  };

  // submit change password
  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);

      const data = qs.stringify({
        name: form.fname,
        idType: "MEMBER_ID",
        agentCode: form.agentCode,
        agentPhone: `+${form.agentPhone}`,
        agentCity: form.agentCity,
        agentBusinessName: form.agentBusinessName,
        password: form.password,
      });

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/v1/admin/auth/register_agent`,
          data,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${isAuthenticatedBoth()}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);

          NotificationManager.success("Registered successfully!", "Success");

          setForm(initial);
        })
        .catch((err) => {
          if (err.response.data.code === 1111) {
            setForm({
              ...form,
              agentCodeErr: "Agent code already exists",
            });
          } else if (err.response.data.code === 1107) {
            setForm({
              ...form,
              agentPhoneErr: "Phone number used by other account",
            });
          }

          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="content-col">
        <Breadcrumb mainTitle="Agent" title="Register Agent" />

        <div className="container-fluid inner-content">
          <div className="ad-form-sec">
            <form noValidate>
              <div className="form-row header">
                <div className="form-group col px-sm-2 mb-0">
                  <p className="heading mb-0">Register Agent</p>
                </div>
              </div>

              <div className="content py-3">
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="fname">
                    First Name <span className="required-tag">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": form.fnameErr,
                    })}
                    id="fname"
                    name="fname"
                    onChange={handleChange}
                    value={form.fname}
                  />
                  <div className="invalid-feedback">{form.fnameErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="fname">
                    Phone Number <span className="required-tag">*</span>
                  </label>
                  <div className="input-group">
                    <PhoneInput
                      country="lk"
                      disableDropdown={true}
                      value={props.agentPhone}
                      enableSearch={true}
                      disableSearchIcon={true}
                      countryCodeEditable={false}
                      prefix="+"
                      onChange={(phone) => handlePhone(phone)}
                    />
                  </div>
                  <p className="cus-error">{form.agentPhoneErr}</p>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="agentCode">
                    Agent Code <span className="required-tag">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": form.agentCodeErr,
                    })}
                    id="agentCode"
                    name="agentCode"
                    onChange={handleChange}
                    value={form.agentCode}
                  />
                  <div className="invalid-feedback">{form.agentCodeErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="agentCity">
                    Agent City <span className="required-tag">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": form.agentCityErr,
                    })}
                    id="agentCity"
                    name="agentCity"
                    onChange={handleChange}
                    value={form.agentCity}
                  />
                  <div className="invalid-feedback">{form.agentCityErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="agentBusinessName">
                    Agent Business Name <span className="required-tag">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": form.agentBusinessNameErr,
                    })}
                    id="agentBusinessName"
                    name="agentBusinessName"
                    onChange={handleChange}
                    value={form.agentBusinessName}
                  />
                  <div className="invalid-feedback">
                    {form.agentBusinessNameErr}
                  </div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="password">
                    Create Password <span className="required-tag">*</span>
                  </label>
                  <input
                    type="password"
                    className={classnames("form-control", {
                      "is-invalid": form.passwordErr,
                    })}
                    id="password"
                    name="password"
                    onChange={handleChange}
                    value={form.password}
                  />
                  <div className="invalid-feedback">{form.passwordErr}</div>
                </div>
                <div className="form-group col-sm-6 px-2 3">
                  <label htmlFor="conPassword">
                    Confirm Password <span className="required-tag">*</span>
                  </label>
                  <input
                    type="password"
                    className={classnames("form-control", {
                      "is-invalid": form.conPasswordErr,
                    })}
                    id="password"
                    name="conPassword"
                    onChange={handleChange}
                    value={form.conPassword}
                  />
                  <div className="invalid-feedback">{form.conPasswordErr}</div>
                </div>
              </div>
              <button
                type="button"
                className="btn-submit mt-3"
                onClick={handleSubmit}
                style={{ width: 100, float: "right" }}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  "Create"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default CreateAgentCom;
