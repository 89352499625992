import React from "react";
import classnames from "classnames";

import Close from "../../../images/close-white.png";

function PaymentApproveModal(props) {
  return (
    <div
      className="modal fade"
      id="paymentApproveModal"
      tabIndex="-1"
      aria-labelledby="paymentApproveModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="paymentApproveModalLabel">
              Are you sure?
            </h5>

            <img
              src={Close}
              alt="close"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              width="50px"
              style={{ cursor: "pointer" }}
              id="paymentApproveModalClose"
            />
          </div>
          <div className="modal-body">
            <p style={{ marginBottom: 25 }}>
              Do you want to approve this payment?
            </p>

            <div className="form-group col-4 p-0 mb-3 m-auto text-center">
              <label htmlFor="amount">Approved Amount</label>
              <input
                type="number"
                className={classnames("form-control", {
                  "is-invalid": props.amountErr,
                })}
                id="amount"
                name="amount"
                onChange={props.handleChange}
                value={props.amount}
                autoComplete="off"
                style={{ textAlign: "right" }}
              />
              <div className="invalid-feedback">{props.amountErr}</div>
            </div>
          </div>
          <div className="modal-footer border-0 p-2">
            <button type="button" className="btn" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() =>
                props.handleApprove(props.scanId, props.agentCode, true)
              }
              style={{ width: 95 }}
            >
              {props.loading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Approve"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentApproveModal;
